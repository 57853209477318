import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import MUIDataTable from "mui-datatables";
import {
	Typography,
	Button,
	Menu,
	MenuItem,
	Modal,
	Fade,
	Backdrop,
	useMediaQuery,
	createMuiTheme,
	MuiThemeProvider,
	FormControl,
	Select,
	IconButton,
	Input,
	Paper,
} from "@material-ui/core";
import { Link } from "react-router-dom";
// import { Redirect } from "react-router";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import api from "../utils/api";
// import { getUserID } from "../cookies";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import { STATUS, ROLES } from "../utils/constants";
import { parseIdFromLink } from "../utils/helpers";
import theme from "../theme";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	contentContainer: {
		width: "95%",
		// maxWidth: theme.screen.maxWidth,
		display: "flex",
		flexDirection: "column",
	},
	tableHeading: {
		display: "flex",
		justifyContent: "space-between",
		margin: theme.spacing(1),
		marginBottom: theme.spacing(4),
	},
	title: {
		fontWeight: "bold",
	},
	createButton: {},
	table: {
		zIndex: "50!important",
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalPaper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	modalMessage: {
		margin: theme.spacing(2),
	},
	confirmationMessage: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	confirmationActionButton: {
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
		width: 100,
	},
	errorModalMessage: {
		margin: theme.spacing(2),
		color: theme.palette.error.main,
	},

	successModalMessage: {
		margin: theme.spacing(2),
		color: theme.palette.primary.dark,
	},

	deleteActionButton: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.error.contrastText,
		"&:hover": {
			backgroundColor: theme.palette.error.dark,
		},
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
		width: 100,
	},
	storeButton: {
		margin: theme.spacing(0.5),
		fontWeight: "700",
	},
	searchField: {
		margin: theme.spacing(0, 0, 2, 2),
	},
}));
/* 
const getUsers = async () => {
  return api
    .getUsers()
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};
*/
const getUsersWithParams = async (page, size) => {
	return await api
		.getUsersWithParams(page, size)
		.then((res) => res)
		.catch(() => {
			throw STATUS.ERROR;
		});
};

const getUserByUsername = async (username) => {
	return await api
		.getUserByUsername(username)
		.then((res) => res)
		.catch(() => {
			throw STATUS.ERROR;
		});
};

const parseError = (error) => {
	if (error.includes("violates foreign key constraint")) {
		if (error.includes('on table "store"')) {
			return "Please ensure this user's stores have been deleted before deleting the user.";
		} else if (error.includes('on table "brand"')) {
			return "Please ensure this user's brand has been deleted before deleting the user.";
		} else if (error.includes('on table "product"')) {
			return "Please ensure that all brand and store products have been deleted";
		} else {
			return "Please ensure all items associated with this user have been deleted. (stores, brand and products)";
		}
	} else return "There was an error updating the user: \n" + error;
};

const disableUser = async (id) => {
	return api
		.updateUser(id, { enabled: false })
		.then((res) => res)
		.catch((res) => res);
};

const enableUser = async (id) => {
	return api
		.updateUser(id, { enabled: true })
		.then((res) => res)
		.catch((res) => res);
};

const promoteUser = async (id, type) => {
	return api
		.promoteUser(id, type)
		.then((res) => res)
		.catch((res) => res);
};

const deleteUser = async (id) => {
	return api
		.deleteUser(id)
		.then((res) => res)
		.catch((res) => res);
};

function ActionMenu(props) {
	const [anchorEl, setAnchorEl] = useState(null);

	const { id, showConfirmation, role, showPromotion, enabled, showDeletion } =
		props;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button variant="contained" onClick={handleClick}>
				<MoreHorizIcon />
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						handleClose();
					}}
					component={Link}
					to={`/admin/users/${id}`}
					style={{ color: "#000000", textDecoration: "none" }}
				>
					Edit
				</MenuItem>
				{/* <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          Copy Credentials
        </MenuItem> */}
				{role === ROLES.ROLE_USER && (
					<MenuItem
						onClick={() => {
							handleClose();
							showPromotion();
						}}
					>
						Promote
					</MenuItem>
				)}
				<MenuItem
					onClick={() => {
						handleClose();
						showConfirmation(enabled);
					}}
				>
					{enabled ? "Disable" : "Enable"}
				</MenuItem>
				<MenuItem
					onClick={() => {
						handleClose();
						showDeletion();
					}}
				>
					Delete
				</MenuItem>
			</Menu>
		</div>
	);
}

function ManageUsersView(props) {
	// ao: props when search activates
	const { search, usernameText } = props;

	const classes = useStyles();

	const [totalRecords, setTotalRecords] = useState(0);
	const [maxPage, setmaxPage] = useState(0);
	const [pageSize, setPageSize] = useState(50); // changed from 500 for now.
	const [pageData, setPageData] = useState([]);
	const [flatData, setFlatData] = useState([]);
	//0th page is always loaded by default
	const [pagesLoaded, setPagesLoaded] = useState([0]);
	const [page, setPage] = useState(0);
	const [searchState, setSearchState] = useState("");
	const [errorMsg, setErrorMsg] = useState("");

	const [loading, setLoading] = useState(false);
	const [loadingError, setLoadingError] = useState(false);
	const [disableConfirm, setDisableConfirm] = useState(null);
	const [enableConfirm, setEnableConfirm] = useState(null);
	const [disabling, setDisabling] = useState(false);
	const [disableMessage, setDisableMessage] = useState("");
	const [disabled, setDisabled] = useState(0);

	const [deleting, setDeleting] = useState(false);
	const [deleteMessage, setDeleteMessage] = useState("");
	const [deleteConfirm, setDeleteConfirm] = useState(null);
	const [deleted, setDeleted] = useState(0);
	//promotion, enable, disable are updates that dont shift the table data
	const [updateID, setUpdateID] = useState(null);
	//user deletion shifts page data so its special
	const [deleteID, setDeleteID] = useState(null);

	const [promotionConfirm, setPromotionConfirm] = useState(null);
	const [promoting, setPromoting] = useState(false);
	const [promotionMessage, setPromotionMessage] = useState("");
	const [promoted, setPromoted] = useState(0);

	const [boxText, setBoxText] = useState("");

	const handleBlur = (event) => {
		setBoxText(event.target.value);
	};

	const handleSubmit = (event) => {
		// ao: disable <enter> on search box.
		event.preventDefault();
	};

	useEffect(() => {
		setLoading(true);

		if ((pageData === undefined || pageData.length === 0) && !search) {
			getUsersWithParams(maxPage, pageSize)
				.then((res) => {
					//console.log("FRESH LOAD: ",res);
					setTotalRecords(res.page.totalElements);
					let initialData = [{ page: page, data: res._embedded.users }];
					setPageData(initialData);
					let data = [];
					for (let dataset of initialData) {
						data = [...flatData, ...dataset.data];
					}
					//set flatData array for the table
					setFlatData(data);
					setLoading(false);
				})
				.catch(() => setLoadingError(true));
		}
		// ao: display search results of user by username when search activates
		else if (search && usernameText) {
			setPageData([]);
			getUserByUsername(usernameText)
				.then((res) => {
					setTotalRecords(res.page.totalElements);
					let initialData = [{ page: page, data: res._embedded.users }];
					setPageData(initialData);
					let data = [];
					for (let dataset of initialData) {
						data = [...dataset.data];
					}
					//set flatData array for the table
					setFlatData(data);
					setLoading(false);
					// ao: handle update & delete cases when action taken from search results.
					if (disableConfirm) {
						setDisabling(false);
						setDisableConfirm(null);
						setDisableMessage(STATUS.SUCCESS);
						setErrorMsg("User was succesfully disabled");
					}
					if (enableConfirm) {
						setDisabling(false);
						setEnableConfirm(null);
						setDisableMessage(STATUS.SUCCESS);
						setErrorMsg("User was succesfully enabled");
					}
					if (deleteConfirm) {
						setDeleting(false);
						setDeleteConfirm(null);
						setDeleteMessage(STATUS.SUCCESS);
						setErrorMsg("User was succesfully deleted");
						setDeleteID(null);
					}
				})
				.catch(() => setLoadingError(true));
		}
		//if were not deleting or updating a user then we must be going to the next page so append the data
		else if (!deleteID && !updateID) {
			getUsersWithParams(maxPage, pageSize)
				.then((res) => {
					setTotalRecords(res.page.totalElements);
					let newPageData = [
						...pageData,
						{ page: page, data: res._embedded.users },
					];
					setPageData(newPageData);
					let data = [];
					for (let dataset of newPageData) {
						data = [...flatData, ...dataset.data];
					}
					//data = data.map((rows) => ({ ...rows, locations: rows.locations.map(location => location.city).join(", ") }))
					//set flatData array for the table
					setFlatData(data);
					setLoading(false);
				})
				.catch(() => setLoadingError(true));
		}
		// otherwise we must be doing some update or delete opertion
		else {
			let updateFrom = null;
			let copy = null;
			if (deleteID !== null) {
				//console.log("WERE DELETING BOYS: ", deleteID)
				for (let page of pageData) {
					for (let row of page.data) {
						let id = parseIdFromLink(row._links.self.href);
						if (id === deleteID) {
							updateFrom = page.page;
							break;
						}
					}
				}
				copy = pageData;
				for (
					let marker = updateFrom;
					marker <= pagesLoaded.length - 1;
					marker++
				) {
					getUsersWithParams(marker, pageSize)
						//eslint-disable-next-line
						.then((res) => {
							setTotalRecords(res.page.totalElements);
							copy[marker].page = marker;
							copy[marker].data = res._embedded.users;
							let data = [];
							for (let dataset of copy) {
								data = [...data, ...dataset.data];
							}
							setFlatData(data);
							setDeleting(false);
							setErrorMsg("User was succesfully deleted");
							setDeleteMessage(STATUS.SUCCESS);
							setDeleteConfirm(null);
							setPageData(copy);
							setDeleteID(null);
							setLoading(false);
						})
						.catch(() => setLoadingError(true));
				}
			} else {
				copy = pageData;
				for (let page of pageData) {
					for (let row of page.data) {
						let id = parseIdFromLink(row._links.self.href);
						if (id === updateID) {
							updateFrom = page.page;
							break;
						}
					}
				}
				getUsersWithParams(updateFrom, pageSize)
					.then((res) => {
						setTotalRecords(res.page.totalElements);
						copy[updateFrom].page = updateFrom;
						copy[updateFrom].data = res._embedded.users;

						let data = [];
						for (let dataset of copy) {
							data = [...data, ...dataset.data];
						}
						//set the flattened data
						setFlatData(data);
						//handle the three update cases
						if (disableConfirm) {
							setDisabling(false);
							setDisableConfirm(null);
							setDisableMessage(STATUS.SUCCESS);
							setErrorMsg("User was succesfully disabled");
						}
						if (enableConfirm) {
							setDisabling(false);
							setEnableConfirm(null);
							setDisableMessage(STATUS.SUCCESS);
							setErrorMsg("User was succesfully enabled");
						}

						if (promotionConfirm) {
							setPromoteType("");
							setPromoting(false);
							setPromotionConfirm(null);
							setErrorMsg("User was succesfully promoted");
							setPromotionMessage(STATUS.SUCCESS);
						}

						setPageData(copy);
						setUpdateID(null);
						setLoading(false);
					})
					.catch(() => setLoadingError(true));
			}
		}
		// eslint-disable-next-line
	}, [maxPage, pageSize, disabled, promoted, deleted, search, usernameText]);

	/*
  const userData = pageData
    ? pageData.sort((elem1, elem2) => {
        const first = parseIdFromLink(elem1._links.self.href);
        const second = parseIdFromLink(elem2._links.self.href);

        return second - first;
      })
    : [];
*/
	const columns = [
		{
			name: "_links.self.href",
			label: "ID",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) => parseIdFromLink(value),
			},
		},
		{
			name: "firstName",
			label: "First Name",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "lastName",
			label: "Last Name",
			options: {
				filter: false,
				sort: true,
			},
		},
		{
			name: "username",
			label: "Username (Email)",
			options: {
				filter: false,
				sort: true,
			},
		},

		{
			name: "createDateTime",
			label: "Time Created (EST)",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (value) =>
					moment(moment.utc(value).local().toDate()).format("YYYY-MM-DD-hh:mm"),
				//customBodyRender: (value) =>  moment(value).format("YYYY-MM-DD-hh:mm"),
			},
		},

		{
			name: "enabled",
			label: "Enabled",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) => (value ? "Yes" : "No"),
			},
		},
		{
			name: "role.name",
			label: "Role",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (value) =>
					value === ROLES.ROLE_SUPER_ADMIN
						? "Admin"
						: value === ROLES.ROLE_STORE_ADMIN
						? "Store Owner"
						: value === ROLES.ROLE_USER
						? "User"
						: "N/A",
			},
		},
		{
		  name: "_links.self.href",
		  label: "Stores/Brands Owned (Click to Edit)",
		  options: {
		    filter: false,
		    sort: false,
		    customBodyRender: (value) => {

		       //uncomment when we get brands coming in aswell from the GET to users
		      let role = flatData.find((user) => user._links.self.href === value)
		      .role.name
		      let owned=[];
		      role === ROLES.ROLE_ADMIN || role === ROLES.ROLE_SUPER_ADMIN || role === ROLES.ROLE_STORE_ADMIN
		        //ADMINS can own both stores and brands so we spread operate both to concatenate
		        ?  owned = [...(flatData.find((user) => user._links.self.href === value).stores), ...(flatData.find((user) => user._links.self.href === value).brands)]
		        //store owners only need stores
		        : role === ROLES.ROLE_STORE_ADMIN
		        ? owned = flatData.find((user) => user._links.self.href === value)
		        //If were not any of the above we must be a regular user so owned array is empty
		        : owned = [];

		      //console.log(owned)
		      //const stores = flatData.find((user) => user._links.self.href === value)
		        //.stores;
		      //console.log(flatData)
		      //return owned.map((stores) => (
		      return owned.map((own) => (

		        <Button
		          size="small"
		          className={classes.storeButton}
		          variant="outlined"
		          component={Link}

		          to={`/admin/edit-store/${parseIdFromLink(
		            own._links.self.href,
		            0,
		            "{?projection}"
		          )}`}

		          style={{
		            color: own.published ? "#599e57" : "#ed685f",
		            border: own.published
		              ? "2px solid #599e57"
		              : "2px solid #ed685f",
		          }}
		        >
		          {own.name}
		        </Button>
		      ));
		    },
		  },
		},
		{
		  name: "_links.self.href",
		  label: "Actions",
		  options: {
		    filter: false,
		    sort: false,
		    customBodyRender: (value) => (
		      <ActionMenu
		        id={parseIdFromLink(value)}
		        enabled={
		          flatData.find((user) => user._links.self.href === value).enabled
		        }
		        showConfirmation={(enabled) => {
		          if (enabled) {
		            setDisableConfirm(parseIdFromLink(value), setUpdateID(parseIdFromLink(value)));
		          } else {
		            setEnableConfirm(parseIdFromLink(value), setUpdateID(parseIdFromLink(value)));
		          }
		        }}
		        showPromotion={() => setPromotionConfirm(parseIdFromLink(value), setUpdateID(parseIdFromLink(value)))}
		        showDeletion={() => setDeleteConfirm(parseIdFromLink(value), setDeleteID(parseIdFromLink(value)))}
		        role={
		          flatData.find((user) => user._links.self.href === value).role.name
		        }
		      ></ActionMenu>
		    ),
		  },
		},
	];

	//const pdata = pageData;
	//console.log([...pageData.data]);

	const mobile = useMediaQuery("(max-width:767px)");

	const [promoteType, setPromoteType] = useState("");

	const options = {
		filterType: "checkbox",
		selectableRows: "none",
		download: false,
		print: false,
		viewColumns: false,
		rowsPerPage: pageSize,
		rowsPerPageOptions: [20, 50, 100, 500, 1000],
		elevation: 2,
		page: page,
		searchText: searchState ? searchState : "",
		count: totalRecords, // this is the number of records in the DB
		//serverSide: true,
		responsive: mobile ? "scrollMaxHeight" : "stacked",
		onTableChange: (action, tableState) => {
			//console.log(action, tableState);

			if (action === "changePage") {
				//console.log("table  page: ", tableState.page);
				setmaxPage(tableState.page > maxPage ? tableState.page : maxPage);
				setPage(tableState.page);
				//if the current page is not in our pages loaded we add it, else do nothing
				if (!pagesLoaded.includes(tableState.page))
					setPagesLoaded([...pagesLoaded, tableState.page]);

				//console.log("page variable:", maxPage);
			}

			if (action === "changeRowsPerPage") {
				setPageSize(tableState.rowsPerPage);
				setmaxPage(0);
				setPageData([]);
				setFlatData([]);
				//console.log("Page Size Changed to: ", pageSize);
			}

			if (action === "search") {
				setSearchState(tableState.searchText);
			}
		},
	};

	const getMuiTheme = () =>
		createMuiTheme({
			...theme,
			overrides: {
				MUIDataTableToolbar: {
					root: {
						display: "flex !important",
					},
					left: {
						flexGrow: "1",
					},
					actions: {
						flexGrow: "0",
						display: "flex",
					},
				},
				MUIDataTablePagination: {
					root: {
						padding: "0px !important",
					},
					selectRoot: {
						margin: "2px",
					},
				},
				MuiToolbar: {
					regular: {
						paddingLeft: "24px",
					},
					gutters: {
						// paddingLeft: "0px !important"
					},
				},
				MuiTablePagination: {
					actions: {
						marginLeft: "5px",
					},
				},
				MuiIconButton: {
					root: {
						padding: "4px",
					},
				},
			},
		});

	return (
		<div className={classes.root}>
			{loading && !loadingError && (
				<CircularProgress size={50} color="inherit" />
			)}
			{(!loading || loadingError) && (
				<div className={classes.contentContainer}>
					<div className={classes.tableHeading}>
						<Typography variant="h4" className={classes.title}>
							Users
						</Typography>
						<Button
							variant="contained"
							color="primary"
							className={classes.createButton}
							component={Link}
							to={`/admin/users/new`}
							style={{ color: "#ffffff", textDecoration: "none" }}
						>
							Add New User
						</Button>
					</div>

					{/* ao: username searchbox */}
					<Paper
						component="form"
						elevation={0}
						className={classes.searchField}
						onSubmit={handleSubmit}
					>
						<Input
							className={classes.input}
							placeholder="Find user by username"
							onBlur={handleBlur}
							// inputProps={{ 'aria-label': 'search google maps' }}
						/>
						<IconButton
							type="submit"
							className={classes.iconButton}
							aria-label="search"
							component={Link}
							to={`/admin/users/search/${boxText}`}
						>
							<SearchIcon />
						</IconButton>
					</Paper>
					<MuiThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							className={classes.table}
							data={flatData}
							columns={columns}
							options={options}
						/>
					</MuiThemeProvider>
				</div>
			)}
			<Modal
				className={classes.modal}
				open={disableConfirm !== null || enableConfirm !== null}
				onClose={() => {
					setDisableConfirm(null);
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={disableConfirm !== null || enableConfirm !== null}>
					<div className={classes.modalPaper}>
						<Typography className={classes.confirmationMessage}>
							{`Are you sure you want to ${
								disableConfirm ? "disable" : "enable"
							} this user?`}
						</Typography>
						{/* <Typography
              className={classes.confirmationMessage}
              variant="body2"
              color="error"
            >
              WARNING: This will also delete any stores, products, deals
              associated with that user!
            </Typography> */}
						<div>
							<Button
								variant="contained"
								color="default"
								className={classes.confirmationActionButton}
								onClick={() => {
									setDisableConfirm(null);
									setEnableConfirm(null);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className={classes.confirmationActionButton}
								onClick={() => {
									setDisabling(true);
									if (disableConfirm) {
										disableUser(disableConfirm)
											.then((status) => {
												if (status.status === 400) {
													setErrorMsg(parseError(status.message[0]));
													setDisabling(false);
													setDisableConfirm(null);
													setDisableMessage("");
													setUpdateID(null);
												} else {
													setDisabled(disabled + 1);
												}
											})
											.catch((status) => {
												setDisabling(false);
												setDisableConfirm(null);
												setDisableMessage(status);
											});
									} else if (enableConfirm) {
										enableUser(enableConfirm)
											.then((status) => {
												if (status.status === 400) {
													setErrorMsg(parseError(status.message[0]));
													setDisabling(false);
													setEnableConfirm(null);
													setUpdateID(null);
													setDisableMessage("");
												} else {
													setDisabled(disabled + 1);
												}
											})
											.catch((status) => {
												setDisabling(false);
												setEnableConfirm(null);
												setDisableMessage(status);
											});
									}
								}}
							>
								{disabling && <CircularProgress size={24} color="inherit" />}
								{!disabling && disableConfirm && "Disable"}
								{!disabling && enableConfirm && "Enable"}
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>

			<Modal
				className={classes.modal}
				open={deleteConfirm !== null}
				onClose={() => {
					setDeleteConfirm(null);
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={deleteConfirm !== null}>
					<div className={classes.modalPaper}>
						<Typography className={classes.confirmationMessage}>
							{
								//console.log( data.find((user) => user._links.self.href))
							}
							{`Are you sure you want to delete user with ID: ${deleteID}`}
						</Typography>
						{/* <Typography
              className={classes.confirmationMessage}
              variant="body2"
              color="error"
            >
              WARNING: This will also delete any stores, products, deals
              associated with that user!
            </Typography> */}
						<div>
							<Button
								variant="contained"
								color="default"
								className={classes.confirmationActionButton}
								onClick={() => {
									setDeleteConfirm(null);
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className={classes.deleteActionButton}
								onClick={() => {
									setDeleting(true);
									deleteUser(deleteConfirm)
										.then((status) => {
											//setDeleted(deleted + 1);
											//console.log(status)
											if (status.status === 400) {
												setErrorMsg(parseError(status.message[0]));
												setDeleting(false);
												setDeleteConfirm(null);
												setDeleteMessage("");
												setDeleteID(null);
											} else {
												setDeleted(deleted + 1);
											}
										})
										.catch((status) => {
											setDeleting(false);
											setDeleteConfirm(null);
											setDeleteMessage(status);
										});
								}}
							>
								{deleting && <CircularProgress size={24} color="inherit" />}
								{!deleting && deleteConfirm && "Delete"}
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
			<Modal
				className={classes.modal}
				open={promotionConfirm !== null}
				onClose={() => {
					setPromotionConfirm(null);
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={promotionConfirm !== null}>
					<div className={classes.modalPaper}>
						<Typography className={classes.confirmationMessage}>
							Please select the type of client this user will be promoted to.
						</Typography>
						<FormControl
							variant="outlined"
							style={{ width: mobile ? 100 : 200, margin: "10px" }}
						>
							<Select
								value={promoteType}
								onChange={(event) => setPromoteType(event.target.value)}
							>
								{["Store Owner"].map((option) => (
									<MenuItem key={option} value={option}>
										{`${option}`}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Typography
							className={classes.confirmationMessage}
							variant="body2"
							color="error"
						>
							WARNING: This change cannot be undone.
						</Typography>
						<div>
							<Button
								variant="contained"
								color="default"
								className={classes.confirmationActionButton}
								onClick={() => {
									setPromotionConfirm(null);
									setPromoteType("");
								}}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.confirmationActionButton}
								onClick={() => {
									setPromoting(true);

									const type =
										promoteType === "Store Owner"
											? "store"
											: promoteType === "Brand Owner"
											? "brand"
											: "";

									promoteUser(promotionConfirm, type)
										.then((status) => {
											if (status.status === 400) {
												setErrorMsg(parseError(status.message[0]));
												setPromoting(false);
												setPromotionConfirm(null);
												setPromotionMessage("");
												setUpdateID(null);
											} else {
												setPromoted(promoted + 1);
											}
										})
										.catch((status) => {
											setPromoteType("");
											setPromoting(false);
											setPromotionConfirm(null);
											setPromotionMessage(status);
										});
								}}
							>
								{promoting && <CircularProgress size={24} color="inherit" />}
								{!promoting && "Promote"}
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
			<Modal
				className={classes.modal}
				open={errorMsg !== ""}
				onClose={() => {
					setErrorMsg("");
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={errorMsg !== ""}>
					<div className={classes.modalPaper}>
						<Typography
							className={
								!(
									deleteMessage === STATUS.SUCCESS ||
									promotionMessage === STATUS.SUCCESS ||
									disableMessage === STATUS.SUCCESS
								)
									? classes.errorModalMessage
									: classes.successModalMessage
							}
						>
							{errorMsg}
						</Typography>
						<div>
							<Button
								variant="contained"
								color="default"
								className={classes.confirmationActionButton}
								onClick={() => {
									setErrorMsg("");
								}}
							>
								Ok
							</Button>
						</div>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}

export default ManageUsersView;
