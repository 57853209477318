import { useState, useEffect, useCallback } from 'react';
import api from '../../../utils/api';

const useServiceItemData = (storeId) => {
  const [serviceItemsData, setServiceItemsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //State to trigger API calls useEffect
  const [dataToSave, setDataToSave] = useState(null);

  //TODO - consolidate parseServiceItem and addItem object creation
  const parseServiceItem = useCallback(
    (rawSvcItem) => {
      //Takes in single svc item data and returns parsed data
      const storeIdHref =
        rawSvcItem?._embedded.store._links.self.href.replace('{?projection}', '') || `/stores/${storeId}`; //Does embedded need to be read?  Or can we just use the storeId passed in?
      const svcItemData = {
        id: rawSvcItem.id || rawSvcItem._links.self.href.split('/').pop().replace('{?projection}', ''),
        name: rawSvcItem.name,
        description: rawSvcItem.description,
        price: rawSvcItem.price,
        durationInMinutes: rawSvcItem.durationInMinutes,
        isArchived: rawSvcItem.isArchived ?? false,
        store: storeIdHref,
      };
      return svcItemData;
    },
    [storeId]
  );

  useEffect(() => {
    //Get data from API
    const fetchServiceItems = async () => {
      try {
        const rawAllSvcItemsData = storeId && (await api.getServiceItemsByStore(storeId)); //[]  TODO - Proper Call doesn't exist yet... using work around in api
        const allServiceItems = rawAllSvcItemsData.map((item) => parseServiceItem(item));
        setServiceItemsData(allServiceItems);
      } catch (error) {
        console.log('error', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    storeId && fetchServiceItems();
    if (!storeId) {
      setServiceItemsData([]);
      setLoading(false);
      setError('Error - No Store Id set, cannot fetch service items');
    }
  }, [storeId, parseServiceItem]);

  useEffect(() => {
    //UseEffect handles API calls for saving and deleting service items
    //Uses action/payload pattern to determine what API call to make, set in removeServiceItem and saveServiceItem
    const saveServiceItems = async () => {
      try {
        const dataPayload = dataToSave.payload;
        const operationType = dataToSave.action;
        const API_CALLS = {
          CREATE: api.createServiceItem,
          UPDATE: api.updateServiceItem,
          DELETE: api.deleteServiceItem,
        };
        let callToSaveToApi = API_CALLS[operationType];
        const rawSvcItemData = await callToSaveToApi(dataPayload);
        const svcItemData = operationType === 'DELETE' ? null : parseServiceItem(rawSvcItemData);
        //Replace Service Item in state with new data

        //================Update State================
        let newServiceItemDataForState =
          operationType !== 'DELETE' &&
          serviceItemsData.map((item) => {
            const itemIsBeingUpdated = item.id === svcItemData.id;
            const itemIsBeingCreated = operationType === 'CREATE' && item.id === null; //Blank item line won't have an id to match against - should make null "NEW" to be explicit
            if (itemIsBeingCreated || itemIsBeingUpdated) return svcItemData; // replace item with new data
            return item; // return original item
          });

        if (operationType === 'DELETE') {
          newServiceItemDataForState = serviceItemsData.filter((item) => item.id !== dataPayload); //Remove item from state
        }
        setServiceItemsData(newServiceItemDataForState);
      } catch (error) {
        console.log('error', error);
        setError(error);
      } finally {
        setDataToSave(null);
        setLoading(false);
      }
    };

    dataToSave && saveServiceItems();

    //eslint-disable-next-line
  }, [dataToSave]);

  const addServiceItem = () => {
    const blankServiceItem = {
      id: null,
      name: '',
      description: '',
      price: '',
      duration: '',
      store: `/stores/${storeId}`,
    };
    setServiceItemsData([...serviceItemsData, blankServiceItem]);
  };

  const saveServiceItem = (serviceItem) => {
    //when creating need to add store href - maybe at item lvl
    console.log('serviceItem hook', serviceItem);
    setDataToSave({ action: serviceItem.id ? 'UPDATE' : 'CREATE', payload: serviceItem });
  };

  const removeServiceItem = (serviceItemId) => {
    setDataToSave({ action: 'DELETE', payload: serviceItemId });
  };

  return {
    serviceItems: serviceItemsData,
    loading,
    error,
    actions: { addServiceItem, saveServiceItem, removeServiceItem },
  };
};

export default useServiceItemData;
