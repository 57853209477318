import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip, makeStyles } from '@material-ui/core';
import ReviewStarSelector from './ReviewStarSelector';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import DialogGeneric from '../../DialogGeneric';
import ReviewForm from './ReviewForm';

const useStyles = makeStyles((theme) => ({
  reviewItemContainer: {
    color: theme.palette.common.font,
    margin: theme.spacing(4, 0),
  },
  reviewTitle: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem',
    },
  },
  rewviewRatingContainer: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  starIcon: {
    color: theme.palette.common.characters,
    height: '48px',
    width: '48px',
    [theme.breakpoints.down('sm')]: {
      height: '24px',
      width: '24px',
    },
  },
  reviewAuthorInfo: {
    marginTop: theme.spacing(1),
  },
  authorLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ownerIcons: {
    color: theme.palette.common.characters,
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.characters,
      /* TODO - should get a slight darker color than characters to give empahasis */
      borderBottom: `1px solid ${theme.palette.common.characters}`,
    },
  },
}));

export default function ReviewItem({ review, actions }) {
  const classes = useStyles();

  const [mode, setReviewMode] = useState('display'); // ['display', 'edit'
  const [openDialog, setOpenDialog] = useState(false);

  const dateToDisplay =
    review?.createDateTime &&
    new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(new Date(review?.createDateTime));

  const reviewForDisplay = (
    <>
      <Typography variant="h3" className={classes.reviewTitle}>
        {review?.title}
      </Typography>
      <div className={classes.rewviewRatingContainer}>
        <ReviewStarSelector rating={review?.rating} disabled />
      </div>
      <Typography className={classes.reviewBody}>{review?.description}</Typography>
      <div className={classes.authorLine}>
        <Typography
          className={classes.reviewAuthorInfo}
        >{`${review?.reviewerFirstName} - ${dateToDisplay}`}</Typography>
        {review?.currUserIsAuthor && (
          <span className={classes.ownerControls}>
            {/* TODO - add edit button - changes mode to edit rendering the review form in edit mode */}
            {/* TODO - delete should have confirmation before actually deleting */}
            <Tooltip title="Edit Your Review">
              <EditIcon onClick={() => setReviewMode('edit')} className={classes.ownerIcons}>
                Edit
              </EditIcon>
            </Tooltip>
            <Tooltip title="Delete Review">
              <DeleteForeverIcon onClick={() => setOpenDialog(true)} className={classes.ownerIcons} />
            </Tooltip>
          </span>
        )}
      </div>
      <DialogGeneric
        /* Dialog to confirm deleting the review */
        openDialog={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirm={async () => {
          await actions?.deleteReview(review?.reviewId);
          setOpenDialog(false);
        }}
        dialogText={{ title: 'Delete your review?', body: 'Are you sure?  This cannot be undone.' }}
      />
    </>
  );

  return (
    <div className={classes.reviewItemContainer}>
      {mode === 'display' && reviewForDisplay}
      {mode === 'edit' && <ReviewForm reviewToEdit={review} actions={actions} setReviewMode={setReviewMode} />}
    </div>
  );
}
