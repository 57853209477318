import { useState, useEffect } from 'react';
import api from '../../../utils/api';
import cookies from '../../../cookies';

export default function useReviewData(storeId) {
  const [reviews, setReviews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataForApi, setDataForApi] = useState(null); //Handles API calls in useEffect
  const [savingStatus, setSavingStatus] = useState({ error: null, status: null }); //Handles API calls in useEffect

  const userId = cookies.getUserID();
  const userFirstName = cookies.getFirstName();

  useEffect(() => {
    //Initial load (required storeId)
    setIsLoading(true);
    storeId &&
      api
        .getStoreReviews(storeId)
        .then((response) => {
          const updatedReviews = response.map((review) => {
            const authorUserId = review.user._links.self.href.split('/').pop().replace('{?projection}', '');
            const reviewId = review._links.self.href.split('/').pop().replace('{?projection}', '');
            return { ...review, reviewId, currUserIsAuthor: authorUserId === userId };
          });
          setReviews(updatedReviews);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
  }, [storeId, userId]);

  useEffect(() => {
    //handles add, delete, update using a payload pattern
    const handleDataForApi = async () => {
      setSavingStatus({ error: null, status: 'pending' });
      try {
        const { action, payload } = dataForApi;
        switch (action) {
          case 'ADD':
            const newReview = await api.createReview(payload.review, payload.storeId, payload.userId);
            const newReviewId = newReview._links.self.href.split('/').pop().replace('{?projection}', '');
            newReview.reviewId = newReviewId;
            newReview.currUserIsAuthor = true;
            newReview.reviewerFirstName = userFirstName;
            setReviews([...reviews, newReview]);
            setSavingStatus({ error: null, status: 'resolved' });
            break;
          case 'DELETE':
            await api.deleteReview(payload.reviewId);
            setReviews(reviews.filter((review) => review.reviewId !== payload.reviewId));
            setSavingStatus({ error: null, status: null });
            break;
          case 'UPDATE':
            const updateRes = await api.updateReview(payload.reviewId, payload.review);
            const updatedReview = updateRes.data;
            updatedReview.reviewId = payload.reviewId;
            updatedReview.currUserIsAuthor = true;
            updatedReview.reviewerFirstName = userFirstName;
            setReviews(
              reviews.map((review) => {
                if (review.reviewId === payload.reviewId) {
                  return updatedReview;
                }
                return review;
              })
            );
            setSavingStatus({ error: null, status: null });
            break;
          default:
            break;
        }
      } catch (error) {
        setSavingStatus({ error: error, status: 'failed' });
      } finally {
        setDataForApi(null);
      }
    };

    dataForApi && handleDataForApi();
    //eslint-disable-next-line
  }, [dataForApi]);

  const addReview = (review) => {
    setDataForApi({ action: 'ADD', payload: { review, storeId, userId } }); //storeId and userId provided out of scope
  };

  const deleteReview = (reviewId) => {
    reviewId && setDataForApi({ action: 'DELETE', payload: { reviewId } });
  };

  const updateReview = (reviewId, updatedReview) => {
    reviewId && updatedReview && setDataForApi({ action: 'UPDATE', payload: { reviewId, review: updatedReview } });
  };

  return {
    reviews,
    isLoading,
    error,
    actions: { addReview, deleteReview, updateReview, status: savingStatus.status, error: savingStatus.error },
  };
}
