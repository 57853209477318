import React, { useState, useEffect, useRef, Fragment } from 'react';
import CarouselComponent from '../components/CarouselComponent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles, IconButton, useMediaQuery } from '@material-ui/core';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useStateValue } from '../state';
import api from '../utils/api';
import { STATUS, CATEGORIES } from '../utils/constants';
import { parseIdFromLink, abbreviate, allStoresRedirect } from '../utils/helpers';
import { banners } from '../assets/banners/index';
import SimpleCard from '../components/SimpleCard';
import NewestDealImage from '../components/NewestDealImage';
import NewestDealImageSml from '../components/NewestDealImageSml';

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: '10px',
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2),
    },
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.black,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1.5),
    },
  },
  bannerImage: {
    width: '100%',
    maxHeight: '500px',
    minHeight: '200px',
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: `cover`,
    aspectRatio: '1245/500',
  },
  // carousel: {
  // 	width: "100%",
  // 	maxHeight: 500,
  // 	aspectRatio: "1245/500",
  // },
  // subLinkBar: {
  // 	height: "66px",
  // 	width: "100%",
  // 	backgroundColor: theme.palette.primary.main,
  // 	paddingLeft: theme.spacing(8),
  // 	display: "flex",
  // 	alignItems: "center",
  // },
  // link: {
  // 	color: theme.palette.common.black,
  // 	marginRight: theme.spacing(8),
  // 	textTransform: "capitalize",
  // 	fontWeight: 700,
  // 	cursor: "pointer",
  // 	"&:hover": {
  // 		color: theme.palette.primary.contrastText,
  // 	},
  // },
  bannerContainer: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '140px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      // marginBottom: 0,
      height: '70px',
    },
  },
  // location: {
  // 	color: theme.palette.primary.dark,
  // 	textTransform: "uppercase",
  // 	letterSpacing: "5px",
  // 	textAlign: "center",
  // 	[theme.breakpoints.down("sm")]: {
  // 		letterSpacing: "0px",
  // 		fontSize: "larger",
  // 	},
  // },
  bannerTitle: {
    color: theme.palette.primary.black,
    textTransform: 'uppercase',
    letterSpacing: '5px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0px',
      fontSize: 'larger',
    },
  },
  // title: {
  // 	color: theme.palette.primary.main,
  // 	textTransform: "uppercase",
  // 	letterSpacing: "5px",
  // 	textAlign: "center",
  // 	[theme.breakpoints.down("sm")]: {
  // 		letterSpacing: "0px",
  // 		fontSize: "large",
  // 	},
  // },
  // quote: {
  // 	color: theme.palette.primary.contrastText,
  // 	fontSize: "medium",
  // 	marginLeft: "5%",
  // 	marginRight: "5%",
  // },
  bannerNote: {
    color: theme.palette.primary.dark,
    fontSize: 'small',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '0.5rem',
  },
  // author: {
  // 	color: theme.palette.primary.main,
  // 	[theme.breakpoints.down("sm")]: {
  // 		// paddingBottom: "1rem",
  // 		fontSize: "large",
  // 	},
  // },
  // heading: {
  // 	marginTop: theme.spacing(3),
  // 	marginBottom: theme.spacing(1),
  // 	textTransform: "uppercase",
  // 	letterSpacing: "5px",
  // 	[theme.breakpoints.down("sm")]: {
  // 		marginLeft: theme.spacing(0.3),
  // 		marginTop: theme.spacing(0.5),
  // 		fontSize: "14pt",
  // 	},
  // },
  newestTitle: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  newestLocation: {
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  emptyList: {
    color: theme.palette.common.white,
    fontSize: 'small',
    fontStyle: 'italic',
    marginTop: theme.spacing(-3),
  },
  allDealsTitle: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  // stylistContainer: {
  // 	display: "flex",
  // 	justifyContent: "space-between",
  // },
  // viewAll: {
  // 	fontWeight: 700,
  // 	"&:hover": {
  // 		color: theme.palette.primary.dark,
  // 	},
  // },
  // dealGrid: {
  //   margin: theme.spacing(2),
  //   marginBottom: theme.spacing(5),
  //   display: 'grid',
  // 	justifyContent: 'center',
  //   gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  //   gridGap: theme.spacing(3),
  //   [theme.breakpoints.down('md')]: {
  //     gridTemplateColumns: '1fr 1fr',
  //   },
  // },
  newestDealOuterBox: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'grid',
    flexDirection: 'row',
    gridTemplateColumns: '3fr 2fr',
    [theme.breakpoints.down('md')]: {},
    alignItems: 'stretch',
  },
  newestDealInnerBox: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    [theme.breakpoints.down('md')]: {},
  },
  // newestCard0: {
  // 	backgroundColor: theme.palette.primary.black,
  // 	display: 'flex',
  // 	gridColumnStart: "1",
  // 	gridColumnEnd: "2",
  // 	gridRowStart: "1",
  // 	gridRowEnd: "span 2",
  // },
  // badgeContainer: {
  // 	position: "relative",
  // 	textAlign: "center",
  // },
  // badgeText: {
  // 	postition: "absolute",
  // 	color: 'white',
  // 	top: "50%",
  // 	left: "50%",
  // 	transform: 'translate(1rem, -6rem)',
  // 	fontFamily: 'Quicksand',
  // 	fontStyle: 'normal',
  // 	fontWeight: 'bold',
  // 	fontSize: '1.5rem',
  // },
  gridListContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: theme.screen.maxWidth,
    margin: '0 auto',
    justifyContent: 'center',
    overflow: 'hidden',
    // height: '100%',
    // '&::-webkit-scrollbar': {
    // 	display: 'none',
    // },
  },
  gridList: {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    width: '-webkit-fill-available',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // bottom: '-17px',
    // '&::-webkit-scrollbar-thumb': {
    // 	background: 'transparent', /* make scrollbar transparent */
    // 	'-webkit-appearance': 'none',
    // 	width: 0,
    // 	height: 0,
    // }
  },
  gridListCentred: {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    width: '-webkit-fill-available',
    justifyContent: 'center',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // bottom: '-17px',
    // '&::-webkit-scrollbar-thumb': {
    // 	background: 'transparent', /* make scrollbar transparent */
    // 	'-webkit-appearance': 'none',
    // 	width: 0,
    // 	height: 0,
    // }
  },
  categoryHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
  },
  scrollArrows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    height: '80%',
  },
  leftIndicator: {
    color: 'white',
    padding: 0,
  },
  rightIndicator: {
    color: 'white',
    padding: 0,
  },
  simpleCard: {
    // padding: '0px, 12px, 0px, 12px',
  },
}));

const getDealStore = async (id) => {
  return api
    .getDealStore(id)
    .then((res) => res.data)
    .catch(() => STATUS.ERROR);
};

const getDealsByLocation = async (id, published) => {
  const deals = await api.getDealsByLocation(id, published);
  const dealsWithStores = await addStoresToDeals(deals);
  return dealsWithStores;
};

const addStoresToDeals = async (deals) => {
  let promises = deals.map((deal) => {
    const store = getDealStore(parseIdFromLink(deal._links.store.href, -1, 'store{?projection}'));
    return store;
  });
  const stores = await Promise.all(promises);
  const DealsWithStores = deals.map((deal, index) => {
    return { ...deal, store: stores[index] };
  });
  return DealsWithStores;
};

const getDealRanksByLocation = async (id) => {
  return api
    .getDealRanksByLocation(id)
    .then((res) => res.data._embedded)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

// const getDealRanks = async (id) => {
// 	return api
// 		.getDealRanks(id)
// 		.then((res) => res.data._embedded)
// 		.catch(() => {
// 			throw STATUS.ERROR;
// 		});
// };

// ** Move to separate component for future use
// function NewBadge(props) {
// 	const classes = useStyles();
// 	return (
// 		<div className={classes.badgeContainer}>
// 			<img
// 				src={require(`../assets/badges/newBadge.svg`)}
// 				alt={`new-badge`}
// 				style={{}}
// 			/>
// 			<h6 className={classes.badgeText}>New</h6>
// 		</div>
// 	)
// };

function DealsView(props) {
  const [allDeals, setAllDeals] = useState([]);
  const [featuredRanks, setFeaturedRanks] = useState([]);
  // const [allRanks, setAllRanks] = useState([]);
  // const [featuredDeals, setFeaturedDeals] = useState([]);
  // const [unrankedDeals, setUnrankedDeals] = useState([]);
  // const [topRatedDeals, setTopRatedDeals] = useState([]);
  // const [featuredStylists, setFeaturedStylists] = useState([]);
  // const [newestDeals, setNewestDeals] = useState([]);
  // const title = `Newest Deals`;

  const [loadingDeals, setLoadingDeals] = useState(true);
  const [{ location }] = useStateValue();

  //   const [existingCategories, setExistingCategories] = useState([]);

  const [scrollX, setScrollX] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);

  const refHair = useRef(null);
  const refStylists = useRef(null);
  const refLashes = useRef(null);
  const refBrows = useRef(null);
  const refNails = useRef(null);
  const refMakeup = useRef(null);
  const refAesthetics = useRef(null);
  const refWaxing = useRef(null);
  const refFacial = useRef(null);
  const refSelfcare = useRef(null);
  const refSkincare = useRef(null);
  const refTanning = useRef(null);
  const ref = useRef(null);

  const mobile = useMediaQuery('(max-width:767px)');
  const tablet = useMediaQuery('(max-width:1024px)');

  const scroll = (scrollOffset, refCat) => {
    refCat.current.scrollLeft += scrollOffset;
    setScrollX(scrollX + scrollOffset);
    if (Math.floor(refCat.current.scrollWidth - refCat.current.scrollLeft) <= refCat.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheck = () => {
    setScrollX(ref.current.scrollLeft);
    if (Math.floor(ref.current.scrollWidth - ref.current.scrollLeft) <= ref.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckHair = () => {
    setScrollX(refHair.current.scrollLeft);
    if (Math.floor(refHair.current.scrollWidth - refHair.current.scrollLeft) <= refHair.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckStylists = () => {
    setScrollX(refStylists.current.scrollLeft);
    if (
      Math.floor(refStylists.current.scrollWidth - refStylists.current.scrollLeft) <= refStylists.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckLashes = () => {
    setScrollX(refLashes.current.scrollLeft);
    if (Math.floor(refLashes.current.scrollWidth - refLashes.current.scrollLeft) <= refLashes.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckBrows = () => {
    setScrollX(refBrows.current.scrollLeft);
    if (Math.floor(refBrows.current.scrollWidth - refBrows.current.scrollLeft) <= refBrows.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckNails = () => {
    setScrollX(refNails.current.scrollLeft);
    if (Math.floor(refNails.current.scrollWidth - refNails.current.scrollLeft) <= refNails.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckMakeup = () => {
    setScrollX(refMakeup.current.scrollLeft);
    if (Math.floor(refMakeup.current.scrollWidth - refMakeup.current.scrollLeft) <= refMakeup.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckAesthetics = () => {
    setScrollX(refAesthetics.current.scrollLeft);
    if (
      Math.floor(refAesthetics.current.scrollWidth - refAesthetics.current.scrollLeft) <=
      refAesthetics.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckWaxing = () => {
    setScrollX(refWaxing.current.scrollLeft);
    if (Math.floor(refWaxing.current.scrollWidth - refWaxing.current.scrollLeft) <= refWaxing.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckFacial = () => {
    setScrollX(refFacial.current.scrollLeft);
    if (Math.floor(refFacial.current.scrollWidth - refFacial.current.scrollLeft) <= refFacial.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckSelfcare = () => {
    setScrollX(refSelfcare.current.scrollLeft);
    if (
      Math.floor(refSelfcare.current.scrollWidth - refSelfcare.current.scrollLeft) <= refSelfcare.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckSkincare = () => {
    setScrollX(refSkincare.current.scrollLeft);
    if (
      Math.floor(refSkincare.current.scrollWidth - refSkincare.current.scrollLeft) <= refSkincare.current.offsetWidth
    ) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const scrollCheckTanning = () => {
    setScrollX(refTanning.current.scrollLeft);
    if (Math.floor(refTanning.current.scrollWidth - refTanning.current.scrollLeft) <= refTanning.current.offsetWidth) {
      setScrollEnd(true);
    } else {
      setScrollEnd(false);
    }
  };

  const refSwitch = (category) => {
    switch (category) {
      case 'Hair':
        return refHair;
      case 'Stylists':
        return refStylists;
      case 'Lashes':
        return refLashes;
      case 'Brows':
        return refBrows;
      case 'Nails':
        return refNails;
      case 'Makeup':
        return refMakeup;
      case 'Aesthetics':
        return refAesthetics;
      case 'Waxing':
        return refWaxing;
      case 'Facial':
        return refFacial;
      case 'Selfcare':
        return refSelfcare;
      case 'Skincare':
        return refSkincare;
      case 'Tanning':
        return refTanning;
      default:
        return ref;
    }
  };

  const scrollCheckSwitch = (category) => {
    switch (category) {
      case 'Hair':
        return scrollCheckHair;
      case 'Stylists':
        return scrollCheckStylists;
      case 'Lashes':
        return scrollCheckLashes;
      case 'Brows':
        return scrollCheckBrows;
      case 'Nails':
        return scrollCheckNails;
      case 'Makeup':
        return scrollCheckMakeup;
      case 'Aesthetics':
        return scrollCheckAesthetics;
      case 'Waxing':
        return scrollCheckWaxing;
      case 'Facial':
        return scrollCheckFacial;
      case 'Selfcare':
        return scrollCheckSelfcare;
      case 'Skincare':
        return scrollCheckSkincare;
      case 'Tanning':
        return scrollCheckTanning;
      default:
        return scrollCheck;
    }
  };

  const classes = useStyles();
  // change this for deal banners
  const bannerImages = banners['stylists'.toLowerCase()];
  useEffect(() => {
    setLoadingDeals(true);

    console.log('fetching deals...');
    Promise.all([
      getDealsByLocation(parseIdFromLink(location.link), true),
      getDealRanksByLocation(parseIdFromLink(location.link)),
    ])
      // getDealsByLocation(parseIdFromLink(location.link))
      .then((res) => {
        // console.log("****res[0]", res[0]);
        console.log('****res[1].featuredDealRanks', res[1].featuredDealRanks);
        setAllDeals(
          res[0]
            // .filter((deal) => deal.category === category)
            .concat()
            // ao: remove expired deals
            .filter((deal) => new Date(deal.endDate) > new Date())
            // .sort((first, second) => first.name.localeCompare(second.name))
            .sort((deal1, deal2) => new Date(deal2.startDate) - new Date(deal1.startDate))
        );
        setFeaturedRanks(
          res[1].featuredDealRanks
            // ao: remove expired deals
            .filter((deal) => new Date(deal.deal.endDate) > new Date())
            .sort((deal1, deal2) => deal1.rank - deal2.rank)
        );
        // const topRanked = res
        // 	.concat()
        // 	// .filter(
        // 	// 	(deal) => deal.category === category && deal.numOfReviews > 0
        // 	// )
        // 	.sort((first, second) =>
        // 		second.avgRating - first.avgRating !== 0
        // 			? second.avgRating - first.avgRating
        // 			: second.numOfReviews - first.numOfReviews !== 0
        // 			? second.numOfReviews - first.numOfReviews
        // 			: first.name.localeCompare(second.name)
        // 	)
        // 	.slice(0, 15);

        // const topDeals = topRanked.map((deal) => {
        // 	return { deal };
        // });
        // setTopRatedDeals(topDeals);

        setLoadingDeals(false);
        // setCategoriesReady(true);
      })
      //   .then(() => {
      //     const categories = allDeals.map((deal) => deal.store.category);
      //     setExistingCategories(categories);
      //   })
      //Above categories doesn't seem to do anything

      // .then(() => console.log("existingCategories: ", existingCategories))

      // 	setCategoriesReady(true);
      // 	setLoadingDeals(false);
      // })
      .catch((e) => {
        console.log(e);
      });

    //eslint-disable-next-line
  }, [location]);

  // useEffect(() => {
  // 	if (!loadingDeals) {
  // 		// console.log("fetching legacy...");
  // 		console.log("fetching stores...");
  // 		allDeals.map((deal) => {
  // 			getDealStore(parseIdFromLink(deal._links.store.href, -1, "store{?projection}"))
  // 			.then((res) => {
  // 				deal.store = res;
  // 			})
  // .then(() => {
  // 	switch (deal.store.catergoy) {
  // 		case "Hair":
  // 			setHair((hair) => hair.push(deal));
  // 			// setHairReady(true);
  // 			break;
  // 		case "Stylists":
  // 			setStylists((stylists) => [...stylists, deal]);
  // 			// setStylistsReady(true);
  // 			break;
  // 		case "Lashes":
  // 			setLashes((lashes) => [...lashes, deal]);
  // 			// setLashesReady(true);
  // 			break;
  // 		case "Brows":
  // 			setBrows((brows) => [...brows, deal]);
  // 			// setBrowsReady(true);
  // 			break;
  // 		case "Nails":
  // 			setNails((nails) => [...nails, deal]);
  // 			setNailsReady(true);
  // 			break;
  // 		case "Makeup":
  // 			setMakeup((makeup) => [...makeup, deal]);
  // 			// setMakeupReady(true);
  // 			break;
  // 		case "Aesthetics":
  // 			setAesthetics((aesthetics) => [...aesthetics, deal]);
  // 			// setAestheticsReady(true);
  // 			break;
  // 		case "Waxing":
  // 			setWaxing((waxing) => [...waxing, deal]);
  // 			// setWaxingReady(true);
  // 			break;
  // 		case "Facial":
  // 			setFacial((facial) => [...facial, deal]);
  // 			// setFacialReady(true);
  // 			break;
  // 		case "Selfcare":
  // 			setSelfcare((selfcare) => [...selfcare, deal]);
  // 			// setSelfcareReady(true);
  // 			break;
  // 		case "Skincare":
  // 			setSkincare((skincare) => [...skincare, deal]);
  // 			// setSkincareReady(true);
  // 			break;
  // 		case "Tanning":
  // 			setTanning((tanning) => [...tanning, deal]);
  // 			// setTanningReady(true);
  // 			break;
  // 		default:
  // 	}
  // })
  // getDealRanks(parseIdFromLink(location.link)).then((res) => {
  // 	const featuredRanks = res.featuredRanks
  // 		? res.featuredRanks
  // 				// .filter((deal) => deal.category === category)
  // 				.sort((deal1, deal2) => deal1.rank - deal2.rank)
  // 				.map((deal) => {
  // 					return deal;
  // 				})
  // 		: [];

  // const featuredStylistsRanks = res.featuredRanks
  // 	? res.featuredRanks
  // 			.filter((deal) => deal.category.toLowerCase() === "stylists")
  // 			.sort((deal1, deal2) => deal1.rank - deal2.rank)
  // 			.map((deal) => {
  // 				return deal;
  // 			})
  // 	: [];

  // const allRanks = res.allRanks
  // 	? res.allRanks
  // 			.filter((deal) => deal.category === category)
  // 			.sort((deal1, deal2) =>
  // 				deal1.rank - deal2.rank !== 0
  // 					? deal1.rank - deal2.rank
  // 					: deal1.deal.name.localeCompare(deal2.deal.name)
  // 			)
  // 			.map((deal) => {
  // 				return deal;
  // 			})
  // 	: [];
  // setFeaturedDeals(featuredRanks);
  // setAllRanks(allRanks);
  // setFeaturedStylists(featuredStylistsRanks);
  // let remainingDealIds = [];
  // let allRanksIds = [];
  // allRanksIds = allRanks.map((rank) =>
  // 	parseIdFromLink(rank.deal._links.self.href, 0, "{?projection}")
  // );
  // let allDealsIds = [];
  // allDealsIds = allDeals.map((deal) =>
  // 	parseIdFromLink(deal._links.self.href, 0, "{?projection}")
  // );
  // remainingDealIds = allDealsIds.filter(
  // 	(id) => !allRanksIds.includes(id)
  // );
  // let remainingDeals = allDeals.filter((deal) => {
  // 	return remainingDealIds.includes(
  // 		parseIdFromLink(deal._links.self.href, 0, "{?projection}")
  // 	);
  // });

  // setUnrankedDeals(remainingDeals);
  // 		})
  // 		setCategoriesReady(true);
  // 	}
  // }, [loadingDeals, location, allDeals]);

  return (
    <>
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          {/* <LinkBar landing={false} /> */}

          {/* Carousel */}
          <CarouselComponent>
            {bannerImages &&
              bannerImages.map((url, index) => (
                <div key={index} className={classes.bannerImage} style={{ backgroundImage: `url(${url})` }}></div>
              ))}
            {/* <div
							className={classes.bannerImage}
							style={{ backgroundImage: "url(" + hairBanner + ")" }}
						/> */}
          </CarouselComponent>
          {/* End carousel */}
          {/* Banner text container */}
          <Container className={classes.bannerContainer} maxWidth="false">
            <Typography className={classes.bannerTitle} variant="h4">
              {' '}
              Looking for a deal?
            </Typography>
            <Typography className={classes.bannerNote} variant="h6">
              CHECK OUR NEWEST DEALS
            </Typography>
          </Container>
          {/* End text container */}
          {console.log('***featuredRanks: ', featuredRanks)}
          <Typography variant="h4" className={classes.newestTitle}>
            Newest Deals
          </Typography>
          <Typography variant="h6" className={classes.newestLocation}>
            {location && `${location.city}, ${abbreviate(location.province)}`}{' '}
          </Typography>
          {featuredRanks.length === 0 && <Typography className={classes.emptyList}>Currently not available</Typography>}
          <div className={classes.newestDealOuterBox}>
            {!loadingDeals &&
              featuredRanks.length > 0 &&
              featuredRanks
                .filter((rank, index) => {
                  return index === 0;
                })
                .map((rank, index) => {
                  return (
                    <NewestDealImage
                      key={index}
                      item={rank.deal}
                      itemPath={`/deals/${allStoresRedirect(location, rank.deal)}`}
                      type={'Deals'}
                      // className={`classes.newestCard0`}
                    ></NewestDealImage>
                  );
                })}
            <div className={classes.newestDealInnerBox}>
              {!loadingDeals &&
                featuredRanks.length > 1 &&
                featuredRanks
                  .filter((rank, index) => {
                    return index > 0 && index < 3;
                  })
                  .map((rank, index) => {
                    return (
                      <NewestDealImageSml
                        key={index}
                        item={rank.deal}
                        itemPath={`/deals/${allStoresRedirect(location, rank.deal)}`}
                        type={'Deals'}
                        className={`classes.newestCard${index}`}
                      ></NewestDealImageSml>
                    );
                  })}
            </div>
            {/* {loadingDeals && (
							<Fragment>
								<SkeletonSimpleCard></SkeletonSimpleCard>
								<SkeletonSimpleCard></SkeletonSimpleCard>
								<SkeletonSimpleCard></SkeletonSimpleCard>
							</Fragment>
						)} */}
          </div>

          {CATEGORIES.map(
            (category) =>
              allDeals.filter((deal) => deal.store.category === category).length > 0 && (
                <Fragment>
                  <div className={classes.categoryHeader}>
                    <Typography variant="h4" className={classes.allDealsTitle}>
                      {category} Deals
                    </Typography>
                    {!mobile && (
                      <div className={classes.scrollArrows}>
                        {scrollX !== 0 &&
                          allDeals.filter((deal) => deal.store.category === category).length >
                            (mobile ? 2 : tablet ? 4 : 5) && (
                            <IconButton style={{ padding: 0 }} onClick={() => scroll(-135.258, refSwitch(category))}>
                              <ChevronLeftIcon fontSize="large" className={classes.leftIndicator} />
                            </IconButton>
                          )}
                        <span style={{ fontSize: '1.5rem' }}>O</span>
                        {!scrollEnd &&
                          allDeals.filter((deal) => deal.store.category === category).length >
                            (mobile ? 2 : tablet ? 4 : 5) && (
                            <IconButton style={{ padding: 0 }} onClick={() => scroll(135.258, refSwitch(category))}>
                              <ChevronRightIcon fontSize="large" className={classes.rightIndicator} />
                            </IconButton>
                          )}
                      </div>
                    )}
                  </div>
                  <div className={classes.gridListContainer}>
                    {!loadingDeals && allDeals.filter((deal) => deal.store.category === category).length > 0 && (
                      <GridList
                        className={
                          allDeals.filter((deal) => deal.store.category === category).length <
                          (mobile ? 3 : tablet ? 4 : 5)
                            ? classes.gridListCentred
                            : classes.gridList
                        }
                        style={{ margin: '0rem 0.25rem 0rem 0.25rem' }}
                        cellHeight={'auto'}
                        spacing={24}
                        cols={
                          allDeals.filter((deal) => deal.store.category === category).length < 5
                            ? allDeals.filter((deal) => deal.store.category === category).length
                            : 5
                        }
                        ref={refSwitch(category)}
                        onScroll={scrollCheckSwitch(category)}
                      >
                        {allDeals
                          .filter((deal) => deal.store.category === category)
                          .map((deal, index) => {
                            return (
                              <GridListTile
                                key={deal.name}
                                style={
                                  mobile
                                    ? {
                                        width: '40%',
                                        paddingTop: '12px',
                                        paddingRight: '6px',
                                        paddingBottom: '12px',
                                        paddingLeft: '6px',
                                      }
                                    : tablet
                                    ? {
                                        width: '25%',
                                        // padding: '0px, 12px, 0px, 12px',
                                        paddingTop: '0px',
                                        paddingRight: '12px',
                                        paddingBottom: '0px',
                                        paddingLeft: '12px',
                                      }
                                    : {
                                        width: '20%',
                                        paddingTop: '0px',
                                      }
                                }
                              >
                                <SimpleCard
                                  key={index}
                                  // className={classes.simpleCard}
                                  item={deal}
                                  itemPath={`/deals/${allStoresRedirect(location, deal)}`}
                                  type={'Deals'}
                                ></SimpleCard>
                              </GridListTile>
                            );
                          })}
                      </GridList>
                    )}
                  </div>
                </Fragment>
              )
          )}

          {/* <div>
						{allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
							<Typography variant="h4" className={classes.allDealsTitle}>
								Aesthetics Deals Single Grid
							</Typography>
						}
						<div>
							{scrollX !== 0 && (
								<IconButton onClick={() => scroll(-135.258, ref)}>
									<ChevronLeftIcon fontSize="large" className={classes.leftIndicator} />
								</IconButton>
							)}
							{!scrollEnd && (
								<IconButton onClick={() => scroll(135.258, ref)}>
									<ChevronRightIcon fontSize="large" className={classes.rightIndicator} />
								</IconButton>
							)}
						</div>
					</div> */}

          {/* {allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
							<div className={classes.gridListContainer} >	
								{!loadingDeals && allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
									<GridList 
										className={classes.gridList}
										style={{ margin: '0.25rem'}}
										cellHeight={'auto'}
										spacing={24}
										cols={allDeals.filter((deal) => deal.store.category === "Aesthetics").length < 5 ? allDeals.filter((deal) => deal.store.category === "Aesthetics").length : 5} 
										ref={ref} 
										onScroll={scrollCheck}
									>
										{
											allDeals
												.filter((deal) => deal.store.category === "Aesthetics")
												.map((deal, index) => {
													return (
														<GridListTile key={deal.name} >
															<SimpleCard
																key={index}
																item={deal}
																itemPath={`/deals/${allStoresRedirect(
																	location,
																	deal
																)}`}
																type={"Deals"}
															></SimpleCard>
														</GridListTile>
													);

												})}
									</GridList>
								}
							</div>
					} */}

          {/* {allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
						<Typography variant="h4" className={classes.allDealsTitle}>
							Aesthetics Deals Carousel
						</Typography>
					}
					{allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
						<div className={classes.gridListContainer}>	
							{!loadingDeals && allDeals.filter((deal) => deal.store.category === "Aesthetics").length > 0 && 
								<Carousel>
									{
										allDeals
											.filter((deal) => deal.store.category === "Aesthetics")
											.map((deal, index) => {
												return (
														<SimpleCard
															key={index}
															item={deal}
															itemPath={`/deals/${allStoresRedirect(
																location,
																deal
															)}`}
															type={"Deals"}
														></SimpleCard>
												);

											})}
								</Carousel>
							}
						</div>
					}	 */}
        </div>
      </div>
    </>
  );
}

export default DealsView;

// {/* Featured stylists */}
// <Typography variant='h4' className={classes.heading}>featured hair stylists</Typography>
// <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a>
// <Container className={classes.stylistContainer}>
//     {hairCategoryData.featuredStylists.map((item, index) => (
//       <StylistItem key={index} item={item} location={{city: "Toronto", province: 'ON'}}/>
//     ))}
// </Container>
// {/* End featured stylists */}
// {/* Popular stylists */}
// <Typography variant='h4' className={classes.heading}>top hair stylists</Typography>
// <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a>
// <Container className={classes.stylistContainer}>
//     {hairCategoryData.popularStylists.map((item, index) => (
//       <StylistItem key={index} item={item} location={{city: "Toronto", province: 'ON'}}/>
//     ))}
// </Container>
// {/* End popular stylists */}
// {/* Products */}
// <Typography variant='h4' className={classes.heading}>view all</Typography>
// {/* <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a> */}
// <ProductRow
//     listData={hairCategoryData.products}
//     loading={false}
//   />
// {/* End products*/}
