/* eslint-disable no-useless-escape */
import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import api from '../utils/api';
import { STATUS, ROLES } from '../utils/constants';
import { TextField, Typography, Button, Modal, Backdrop, Fade, FormControl, Select, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingError from '../components/LoadingError';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%',
  },
  cancelButton: {
    width: 200,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  formAction: {
    display: 'flex',
    alignSelf: 'flex-end',
  },
  formButton: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
    marginLeft: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  formNote: {
    marginLeft: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

const getUser = async (id) => {
  return api
    .getUser(id)
    .then((res) => res)
    .catch((error) => {
      return STATUS.ERROR;
    });
};

const createUser = async (data, role) => {
  return api
    .createUser(data, role === 'Store Owner' ? ROLES.ROLE_STORE_ADMIN : ROLES.ROLE_USER)
    .then(() => STATUS.SUCCESS)
    .catch((error) => {
      if (error.message[0].includes('ERROR: duplicate key value violates unique constraint')) {
        throw STATUS.DUPLICATE;
      } else throw STATUS.ERROR;
    });
};

const updateUser = async (id, data) => {
  return api
    .updateUser(id, data)
    .then(() => STATUS.SUCCESS)
    .catch((error) => {
      throw STATUS.ERROR;
    });
};

function AddEditUserView(props) {
  const classes = useStyles();

  const { editMode, history } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailRepeat, setEmailRepeat] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [roleType, setRoleType] = useState('');

  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState('');
  const [loadingError, setLoadingError] = useState(false);
  const [nonUniqueEmail, setNonUniqueEmail] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  useEffect(() => {
    if (editMode) {
      getUser(props.match.params.id)
        .then((data) => {
          setFirstName(data.firstName);
          setLastName(data.lastName);
          setEmail(data.username);
          setEmailRepeat(data.username);
          setDataReady(true);
        })
        .catch(() => setLoadingError(true));
    } else setDataReady(true);
  }, [editMode, props.match]);

  const validate = () => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      email === emailRepeat &&
      email !== '' &&
      password === passwordRepeat &&
      password !== '' &&
      validateEmail() &&
      validatePassword() &&
      (editMode || roleType !== '')
    );
  };

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = () => {
    //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
    const containsUpperCase = password.match(/[A-Z]/);
    const containsLowerCase = password.match(/[a-z]/);
    const containsDigit = password.match(/\d/);
    const containsSpecial = password.match(/[^a-zA-Z\d]/);

    return (
      password.length >= 8 &&
      password.length <= 50 &&
      containsDigit &&
      containsSpecial &&
      containsUpperCase &&
      containsLowerCase
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        {!dataReady && !loadingError && <Typography style={{ alignSelf: 'center' }}>Loading...</Typography>}
        {loadingError && <LoadingError onClick={() => history.push('/client')} browse="Users"></LoadingError>}
        {dataReady && (
          <Fragment>
            <Typography variant="h4" className={classes.title}>
              {editMode ? 'Edit ' : 'Add '} User
            </Typography>
            <Fragment>
              <TextField
                value={firstName}
                className={classes.textField}
                label="First Name"
                variant="filled"
                error={firstName === ''}
                onChange={(event) => setFirstName(event.target.value.replace(/[^A-Za-z]/g, '').slice(0, 100))}
              />
              <TextField
                className={classes.textField}
                label="Last Name"
                variant="filled"
                value={lastName}
                error={lastName === ''}
                onChange={(event) => setLastName(event.target.value.replace(/[^A-Za-z]/g, '').slice(0, 100))}
              />
              {!editMode && (
                <FormControl variant="filled" className={classes.textField}>
                  <Typography>Select Client Type:</Typography>
                  <Select
                    value={roleType}
                    onChange={(event) => setRoleType(event.target.value)}
                    error={roleType === ''}
                  >
                    {['Store Owner', 'User'].map((option) => (
                      <MenuItem key={option} value={option}>
                        {`${option}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <TextField
                className={classes.textField}
                label="Email"
                variant="filled"
                value={email}
                error={email === '' || !validateEmail() || nonUniqueEmail}
                onChange={(event) => {
                  setNonUniqueEmail(false);
                  setEmail(event.target.value.slice(0, 100));
                }}
              />
              <TextField
                className={classes.textField}
                label="Email Confirmation"
                variant="filled"
                value={emailRepeat}
                error={email !== emailRepeat || emailRepeat === ''}
                onChange={(event) => setEmailRepeat(event.target.value.slice(0, 100))}
              />
              <Typography variant="body2" className={classes.formNote}>
                Password Requirements: Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
              </Typography>
              <TextField
                className={classes.textField}
                label="Password"
                variant="filled"
                error={password === '' || !validatePassword()}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <TextField
                className={classes.textField}
                label="Password Confirmation"
                variant="filled"
                error={password !== passwordRepeat || passwordRepeat === ''}
                value={passwordRepeat}
                onChange={(event) => setPasswordRepeat(event.target.value)}
              />
            </Fragment>
            <div className={classes.formAction}>
              <Button
                className={classes.formButton}
                variant="contained"
                component={Link}
                to={`/admin/users`}
                style={{ color: '#000000', textDecoration: 'none' }}
              >
                Cancel
              </Button>
              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  if (validate()) {
                    setSaving(true);
                    if (editMode) {
                      updateUser(props.match.params.id, {
                        firstName: firstName,
                        lastName: lastName,
                        username: email.toLowerCase(),
                        password: password,
                      })
                        .then(() => {
                          setSaving(false);
                          setStatus(STATUS.SUCCESS);
                        })
                        .catch((error) => {
                          setSaving(false);
                          setStatus(error);
                        });
                    } else {
                      createUser(
                        {
                          firstName: firstName,
                          lastName: lastName,
                          password: password,
                          username: email.toLowerCase(),
                          verified: true,
                        },
                        roleType
                      )
                        .then(() => {
                          setSaving(false);
                          setStatus(STATUS.SUCCESS);
                        })
                        .catch((error) => {
                          setSaving(false);
                          if (error === STATUS.DUPLICATE) {
                            setNonUniqueEmail(true);
                          }
                          setStatus(error);
                        });
                    }
                  }
                }}
              >
                {saving && <CircularProgress size={24} color="inherit" />}
                {!saving && 'Save'}
              </Button>
            </div>
          </Fragment>
        )}
        <Modal
          className={classes.modal}
          open={status !== ''}
          onClose={() => {
            setStatus('');
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={status !== ''}>
            <div className={classes.modalPaper}>
              {status === STATUS.SUCCESS && (
                <Fragment>
                  <Typography className={classes.statusMessage}>Successfully saved!</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/admin/users"
                    style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                </Fragment>
              )}
              {(status === STATUS.ERROR || status === STATUS.DUPLICATE) && (
                <Fragment>
                  {status === STATUS.ERROR && (
                    <Typography className={classes.statusMessage}>
                      There was an issue while saving your data. Please try again.
                    </Typography>
                  )}
                  {status === STATUS.DUPLICATE && (
                    <Typography className={classes.statusMessage}>
                      An account with this email address already exists.
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setStatus('')}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                </Fragment>
              )}
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default AddEditUserView;
