import React, { useState } from 'react';
import { TextField, Button, Typography, Card } from '@material-ui/core';
import ReviewStarSelector from './ReviewStarSelector';
import { makeStyles } from '@material-ui/core';
import cookies from '../../../cookies';

const useStyles = makeStyles((theme) => ({
  reviewForm: {
    // borderTop: `1px solid ${theme.palette.common.font}`,
    // borderBottom: `1px solid ${theme.palette.common.font}`,
    padding: theme.spacing(2, 0),
    borderRadius: '10%',
  },
  textField: {
    '& div.MuiInput-underline:hover:not(.Mui-disabled)::before': {
      //underline color when hovered
      borderBottomColor: `${theme.palette.common.background}`,
    },
  },
  reviewFormHeader: {
    color: theme.palette.common.font,
    fontSize: '1.5rem',
    marginBottom: theme.spacing(2),
  },
  statusText: {
    color: theme.palette.common.font,
    fontSize: '1.25rem',
    marginTop: theme.spacing(2),
  },
}));

export default function ReviewForm({ actions, reviewToEdit, setReviewMode }) {
  const classes = useStyles();
  const [title, setTitle] = useState(reviewToEdit?.title || '');
  const [body, setBody] = useState(reviewToEdit?.description || '');
  const [rating, setRating] = useState(reviewToEdit?.rating || 0);

  const isLoggedInAsUser = cookies.getJWT() !== undefined && cookies.getRole() === 'ROLE_USER';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const review = {
      title: title,
      description: body,
      rating: rating,
    };
    //==========Edit review
    if (reviewToEdit) {
      await actions.updateReview(reviewToEdit.reviewId, review); //Hook has the storeId, and userId
    } else {
      //==========Create review
      await actions.addReview(review); //Hook has the storeId, and userId
    }
    //Reset form after save
    setTitle('');
    setBody('');
    setRating(0);
    reviewToEdit && setReviewMode('display');
  };
  const disableForm = actions?.status === 'pending';
  const disableSubmitButton = title === '' || body === '' || rating === 0 || disableForm;
  return (
    <>
      {!isLoggedInAsUser && <div>Login or Sign Up to leave a review</div>}
      {isLoggedInAsUser && actions?.status === null && (
        <Card style={{ padding: '16px' }}>
          <form onSubmit={handleSubmit} className={classes.reviewForm}>
            <Typography variant="h3" className={classes.reviewFormHeader}>
              Leave a review
            </Typography>
            <TextField
              className={classes.textField}
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              fullWidth
              margin="normal"
              disabled={disableForm}
            />

            <ReviewStarSelector setRating={setRating} rating={rating} disabled={disableForm} />

            <TextField
              className={classes.textField}
              label="Review"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              // rows={4}
              disabled={disableForm}
            />
            <Button type="submit" variant="contained" color="primary" disabled={disableSubmitButton}>
              Submit
            </Button>
            {reviewToEdit && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setReviewMode('display')}
                style={{ marginLeft: '16px' }}
              >
                Cancel
              </Button>
            )}
          </form>
        </Card>
      )}
      {actions?.status === 'failed' && (
        <Typography
          className={classes.statusText}
        >{`Error: ${actions?.error.status} - ${actions?.error.message}`}</Typography>
      )}
      {actions?.status === 'pending' && <Typography className={classes.statusText}>Submitting review...</Typography>}
      {actions?.status === 'resolved' && (
        <Typography className={classes.statusText}>Your review has been saved</Typography>
      )}
    </>
  );
}
