import React, { useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import api from "../utils/api";
import { STATUS } from "../utils/constants";
import cookies from "../cookies";
import {
  Typography,
  Modal,
  Backdrop,
  Fade,
  Button,
  Grid,
  TextField,
  InputAdornment,
  Tooltip
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  contentContainer: {
    width: "100%",
    maxWidth: theme.screen.maxWidth,
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  formContainer: {
    width: "100%",
    maxWidth: theme.screen.maxWidth - 500,
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(6)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  statusMessage: {
    margin: theme.spacing(2)
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2)
  },
  formButton: {
    fontWeight: "bold",
    margin: theme.spacing(1, 0, 0, 0)
  },
  formNote: {
    marginBottom: theme.spacing(1)
  },
  endAdornment: {
    color: theme.palette.grey.A700
  }
}));

const getUser = async id => {
  return api
    .getUser(id)
    .then(res => res)
    .catch(error => {
      return STATUS.ERROR;
    });
};

const checkPassword = async (email, password) => {
  return api
    .authenticate(email, password)
    .then(res => res)
    .catch(error => {
      throw STATUS.ERROR;
    });
};

const updateUser = async (id, data) => {
  return api
    .updateUser(id, data)
    .then(() => STATUS.SUCCESS)
    .catch(error => {
      if (error.message[0].includes("duplicate")) {
        throw STATUS.DUPLICATE;
      } else {
        throw STATUS.ERROR;
      }
    });
};

function AddEditUserView(props) {
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordMask, setPasswordMask] = useState(true);
  const [currentPasswordMask, setCurrentPasswordMask] = useState(true);

  const [duplicateEmail, setDuplicateEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState("");
  const [loadingError, setLoadingError] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [changeEmailMode, setChangeEmailMode] = useState(false);
  const [changePasswordMode, setChangePasswordMode] = useState(false);

  const [submittedProfileChange, setSubmittedProfileChange] = useState(false);
  const [submittedPasswordChange, setSubmittedPasswordChange] = useState(false);

  const [initial, setInitial] = useState({});

  const userId = cookies.getUserID();

  useEffect(() => {
    getUser(userId)
      .then(data => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.username);
        setDataReady(true);

        setInitial({
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username
        });
      })
      .catch(() => setLoadingError(true));
  }, [userId]);

  const profileChanged = () => {
    return !(
      firstName === initial.firstName &&
      lastName === initial.lastName &&
      email === initial.username
    );
  };

  const validateNameChange = () => {
    return firstName !== "" && lastName !== "";
  };

  const validatePasswordChange = () => {
    return (
      password === passwordConfirm &&
      password !== "" &&
      validatePassword() &&
      currentPassword !== password
    );
  };

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePassword = () => {
    //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
    const containsUpperCase = password.match(/[A-Z]/);
    const containsLowerCase = password.match(/[a-z]/);
    const containsDigit = password.match(/\d/);
    const containsSpecial = password.match(/[^a-zA-Z\d]/);

    return (
      password.length >= 8 &&
      password.length <= 50 &&
      containsDigit &&
      containsSpecial &&
      containsUpperCase &&
      containsLowerCase
    );
  };

  const submitProfileChange = async () => {
    setSubmittedProfileChange(true);
    if (changeEmailMode && validateEmail() && email === emailConfirm) {
      setSaving(true);
      checkPassword(initial.username, currentPassword)
        .then(res => {
          updateUser(cookies.getUserID(), {
            username: email.toLowerCase(),
            password: currentPassword
          })
            .then(() => {
              setInitial({
                firstName,
                lastName,
                username: email
              });
              setChangeEmailMode(false);
              setSaving(false);
              setStatus(STATUS.SUCCESS);
            })
            .catch(error => {
              setSaving(false);
              if (error === STATUS.DUPLICATE) {
                setDuplicateEmail(true);
              } else {
                setStatus(error);
              }
            });
        })
        .catch(error => {
          setSaving(false);
          setInvalidPassword(true);
        });
    } else if (validateNameChange()) {
      setSaving(true);
      updateUser(cookies.getUserID(), {
        firstName,
        lastName
      })
        .then(() => {
          setInitial({
            firstName,
            lastName,
            username: email.toLowerCase()
          });
          setEditMode(false);
          setSaving(false);
          setStatus(STATUS.SUCCESS);
        })
        .catch(error => {
          setSaving(false);
          setStatus(error);
        });
    }
  };

  const submitPasswordChange = async () => {
    setSubmittedPasswordChange(true);
    if (validatePasswordChange()) {
      setSaving(true);
      checkPassword(initial.username, currentPassword)
        .then(res => {
          updateUser(cookies.getUserID(), {
            password
          })
            .then(() => {
              setCurrentPassword("");
              setPassword("");
              setPasswordConfirm("");
              setChangePasswordMode(false);
              setSubmittedPasswordChange(false);
              setSaving(false);
              setStatus(STATUS.SUCCESS);
            })
            .catch(error => {
              setSaving(false);
              setStatus(error);
            });
        })
        .catch(error => {
          setSaving(false);
          setInvalidPassword(true);
        });
    }
  };

  const resetProfile = () => {
    setFirstName(initial.firstName);
    setLastName(initial.lastName);
    setEmail(initial.username);
    setEmailConfirm("");
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Typography variant="h4" className={classes.title}>
          My Profile
        </Typography>
        {!dataReady && !loadingError && <Typography>Loading...</Typography>}
        {loadingError && (
          <Typography>There was an issue fetching your profile.</Typography>
        )}
        {dataReady && (
          <div className={classes.formContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  // autoComplete="fname"
                  id="first-name-input"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  // id="firstName"
                  label="First Name"
                  disabled={!editMode}
                  error={submittedProfileChange && firstName === ""}
                  helperText={
                    submittedProfileChange && firstName === ""
                      ? "Enter a first name"
                      : ""
                  }
                  // autoFocus
                  value={firstName}
                  onChange={event => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  // id="lastName"
                  id="last-name-input"
                  label="Last Name"
                  name="lastName"
                  // autoComplete="lname"
                  disabled={!editMode}
                  error={submittedProfileChange && lastName === ""}
                  helperText={
                    submittedProfileChange && lastName === ""
                      ? "Enter a last name"
                      : ""
                  }
                  value={lastName}
                  onChange={event => setLastName(event.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="username"
                  label="Email Address"
                  name="email"
                  type="username"
                  value={email}
                  error={!validateEmail() || duplicateEmail}
                  helperText={
                    !validateEmail()
                      ? "Enter a valid email"
                      : duplicateEmail
                      ? "This email is already taken"
                      : ""
                  }
                  onChange={event => {
                    if (duplicateEmail) {
                      setDuplicateEmail(false);
                    }
                    if (!changePasswordMode) {
                      setEmail(event.target.value);
                    }
                  }}
                  disabled={!changeEmailMode && !changePasswordMode}
                />
              </Grid>
              {changeEmailMode && (
                <Fragment>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="confirm-email"
                      label="Confirm Email Address"
                      name="email"
                      error={email !== emailConfirm}
                      helperText={
                        email !== emailConfirm ? "Emails do not match" : ""
                      }
                      value={emailConfirm}
                      onChange={event => setEmailConfirm(event.target.value)}
                      disabled={!changeEmailMode}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="currentPassword"
                      label="Current Password"
                      type={currentPasswordMask ? "password" : ""}
                      id="current-password"
                      value={currentPassword}
                      error={
                        (submittedPasswordChange && currentPassword === "") ||
                        invalidPassword
                      }
                      helperText={
                        submittedPasswordChange && currentPassword === ""
                          ? "Enter your current password"
                          : invalidPassword
                          ? "Invalid password"
                          : ""
                      }
                      onChange={event => {
                        setInvalidPassword(false);
                        setCurrentPassword(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {currentPasswordMask && (
                              <Tooltip
                                title="Show password"
                                placement="bottom"
                                className={classes.endAdornment}
                              >
                                <VisibilityIcon
                                  onClick={() =>
                                    setCurrentPasswordMask(!currentPasswordMask)
                                  }
                                />
                              </Tooltip>
                            )}
                            {!currentPasswordMask && (
                              <Tooltip title="Hide password" placement="bottom">
                                <VisibilityOffIcon
                                  onClick={() =>
                                    setCurrentPasswordMask(!currentPasswordMask)
                                  }
                                  className={classes.endAdornment}
                                />
                              </Tooltip>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Fragment>
              )}
              {changeEmailMode && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        resetProfile();
                        setSubmittedProfileChange(false);
                        setChangeEmailMode(false);
                      }}
                      variant="contained"
                      color="default"
                      className={classes.formButton}
                      disabled={saving}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                      onClick={() => {
                        if (!saving) {
                          submitProfileChange();
                        }
                      }}
                      disabled={!profileChanged() || currentPassword === ""}
                    >
                      {saving && <CircularProgress size={24} color="inherit" />}
                      {!saving && "Save Changes"}
                    </Button>
                  </Grid>
                </Fragment>
              )}
              {changePasswordMode && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.formNote}>
                      Password Requirements: Length 8-50, 1 Uppercase, 1
                      Lowercase, 1 Digit, 1 Special Character
                    </Typography>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="newPassword"
                      label="New Password"
                      type={password && passwordMask ? "password" : ""}
                      autoComplete="off"
                      id="new-password"
                      error={
                        (submittedPasswordChange &&
                          (password === "" || !validatePassword())) ||
                        (currentPassword === password && currentPassword !== "")
                      }
                      helperText={
                        submittedPasswordChange &&
                        (password === "" || !validatePassword())
                          ? "Password does not meet requirements"
                          : currentPassword === password &&
                            currentPassword !== ""
                          ? "New password cannot be the same as your current password"
                          : ""
                      }
                      value={password}
                      onChange={event => setPassword(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {passwordMask && (
                              <Tooltip
                                title="Show password"
                                placement="bottom"
                                className={classes.endAdornment}
                              >
                                <VisibilityIcon
                                  onClick={() => setPasswordMask(!passwordMask)}
                                />
                              </Tooltip>
                            )}
                            {!passwordMask && (
                              <Tooltip title="Hide password" placement="bottom">
                                <VisibilityOffIcon
                                  onClick={() => setPasswordMask(!passwordMask)}
                                  className={classes.endAdornment}
                                />
                              </Tooltip>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="confirmNewPassword"
                      label="Confirm New Password"
                      type={passwordConfirm ? "password" : ""}
                      autoComplete="off"
                      id="confirm-new-password"
                      error={password !== passwordConfirm}
                      helperText={
                        password !== passwordConfirm
                          ? "Passwords do not match"
                          : ""
                      }
                      value={passwordConfirm}
                      onChange={event => setPasswordConfirm(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="currentPassword"
                      label="Current Password"
                      type={
                        currentPassword && currentPasswordMask ? "password" : ""
                      }
                      id="current-password"
                      value={currentPassword}
                      error={
                        (submittedPasswordChange && currentPassword === "") ||
                        invalidPassword
                      }
                      helperText={
                        submittedPasswordChange && currentPassword === ""
                          ? "Enter your current password"
                          : invalidPassword
                          ? "Invalid password"
                          : ""
                      }
                      onChange={event => {
                        setInvalidPassword(false);
                        setCurrentPassword(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {currentPasswordMask && (
                              <Tooltip
                                title="Show password"
                                placement="bottom"
                                className={classes.endAdornment}
                              >
                                <VisibilityIcon
                                  onClick={() =>
                                    setCurrentPasswordMask(!currentPasswordMask)
                                  }
                                />
                              </Tooltip>
                            )}
                            {!currentPasswordMask && (
                              <Tooltip title="Hide password" placement="bottom">
                                <VisibilityOffIcon
                                  onClick={() =>
                                    setCurrentPasswordMask(!currentPasswordMask)
                                  }
                                  className={classes.endAdornment}
                                />
                              </Tooltip>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                </Fragment>
              )}
              {!editMode && !changePasswordMode && !changeEmailMode && (
                <Fragment>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={() => setChangeEmailMode(true)}
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                    >
                      Change Email
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      onClick={() => setChangePasswordMode(true)}
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </Fragment>
              )}
              {editMode && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        resetProfile();
                        setSubmittedProfileChange(false);
                        setEditMode(false);
                      }}
                      variant="contained"
                      color="default"
                      className={classes.formButton}
                      disabled={saving}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                      onClick={() => {
                        if (!saving) {
                          submitProfileChange();
                        }
                      }}
                      disabled={!profileChanged()}
                    >
                      {saving && <CircularProgress size={24} color="inherit" />}
                      {!saving && "Save Changes"}
                    </Button>
                  </Grid>
                </Fragment>
              )}
              {changePasswordMode && (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      onClick={() => {
                        setCurrentPassword("");
                        setPassword("");
                        setPasswordConfirm("");
                        setSubmittedProfileChange(false);
                        setChangePasswordMode(false);
                      }}
                      variant="contained"
                      color="default"
                      className={classes.formButton}
                      disabled={saving}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.formButton}
                      onClick={() => {
                        if (!saving) {
                          submitPasswordChange();
                        }
                      }}
                      disabled={
                        currentPassword === "" ||
                        password === "" ||
                        passwordConfirm === ""
                      }
                    >
                      {saving && <CircularProgress size={24} color="inherit" />}
                      {!saving && "Change Password"}
                    </Button>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </div>
        )}
        <Modal
          className={classes.modal}
          open={status !== ""}
          onClose={() => {
            setStatus("");
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={status !== ""}>
            <div className={classes.modalPaper}>
              {status === STATUS.SUCCESS && (
                <Fragment>
                  <Typography className={classes.statusMessage}>
                    Successfully updated!
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setStatus("")}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                </Fragment>
              )}
              {status === STATUS.ERROR && (
                <Fragment>
                  <Typography className={classes.statusMessage}>
                    There was an issue while saving your data. Please try again.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setStatus("")}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                </Fragment>
              )}
              {/* {status === "CREDENTIALS_CHANGED" && (
                <Fragment>
                  <Typography className={classes.statusMessage}>
                    Your login information was updated. Please login with your new credentials.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                </Fragment>
              )} */}
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default AddEditUserView;
