/* Indv appt item for the itinerary calendar
Shows start and end time, service, and customer first name

Clicking on the item will open a modal with more details and the option to edit or delete the appt
Modal will be singular and live in the parent view (will drill down)
Can eventually add a popover with full appointment details (include notes, cusomter details, etc)
*/
import React from 'react';
import lightFormat from 'date-fns/lightFormat';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  apptItemRoot: {
    display: 'flex',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  apptTime: {
    marginRight: theme.spacing(4),
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      marginRight: theme.spacing(1),
    },
  },
  apptType: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
}));

export default function AppointmentItem({ appointment, openApptDetails }) {
  const classes = useStyles();

  const formatTime = (time) => {
    if (!time) return null;
    return lightFormat(new Date(time), 'h:mm a');
  };

  const handleClick = (e) => {
    openApptDetails(e, appointment);
  };

  const startTime = formatTime(appointment.startDateTime);
  const endTime = formatTime(appointment.endDateTime);

  return (
    <div className={classes.apptItemRoot} onClick={handleClick}>
      <Typography className={classes.apptTime}>
        {startTime} - {endTime}
      </Typography>
      <Typography className={classes.apptType}>{appointment.service}</Typography>
      <Typography className={classes.apptCustomer}>{` - ${appointment.customerName}`}</Typography>
    </div>
  );
}
