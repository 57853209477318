/* Month calendar
7 columns (days of the week) x 5 rows (weeks in a month)
Uses display grid to layout the days of the month
preceeding days of the month are greyed out and trailing days are greyed out
*/
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { isSameDay } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  monthContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gridTemplateRows: 'repeat(6, auto)',
  },
  dayOfMonth: {
    height: '150px',
    border: '1px solid grey',
    padding: '4px',
    overflow: 'hidden',
  },
  appt: {
    backgroundColor: theme.palette.common.background,
    color: theme.palette.common.font,
    border: '0.5px solid white',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
}));

const today = new Date();

export default function Index({ selectedDay = today, appointments = [], onApptClick, handleClickDate }) {
  const classes = useStyles();

  const handleClick = (e, appointment, clickedDay) => {
    e.stopPropagation();
    const targetClass = e.target.className;
    if (targetClass === 'dayOfMonthNum') return null; //Do not open new appointment form if day of month is clicked, changes to day view
    onApptClick(e, appointment ?? 'NEW', new Date(`${clickedDay} 00:00`));
  };

  //Selected day is highlighed and determines the month to display
  //Days of the week are displayed at the top of the calendar
  //Days of the month are displayed in a grid
  //Days of the month that are not in the current month are greyed out
  const selectedMonth = selectedDay.getMonth();
  const selectedYear = selectedDay.getFullYear();
  //First Week Day of the month (0-6) - Index to start the calendar
  const firstWeekDayOfMonth = new Date(selectedYear, selectedMonth, 1).getDay();
  //Number of days in the month
  const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();

  const calendarContent = [];
  //Iterate through calendar grid, null for days not in the month, "dd DDD" for days in the month
  for (let index = 0; index < 35; index++) {
    //Days of the month, adds appointments where applicable
    const dayOfMonth = index - firstWeekDayOfMonth + 1;
    const day = new Date(selectedYear, selectedMonth, dayOfMonth).toDateString();

    if (index < firstWeekDayOfMonth || index >= daysInMonth + firstWeekDayOfMonth) {
      //Empty grids Preceeding and trailing days of the month
      calendarContent.push(<div></div>);
    } else {
      const appointmentsForDay = appointments
        .filter((appointment) => {
          return isSameDay(new Date(appointment.startDateTime), new Date(day));
        })
        .sort((a, b) => a.startDateTime.localeCompare(b.startDateTime));

      const MAX_APPTS_PER_DAY = 4;
      const isOverDayLimit = appointmentsForDay.length > MAX_APPTS_PER_DAY;
      const numOfHiddenAppts = appointmentsForDay.length - MAX_APPTS_PER_DAY;

      calendarContent.push(
        <div className={classes.dayOfMonth} onClick={(e) => handleClick(e, null, day)}>
          <h3 className="dayOfMonthNum" onClick={handleClickDate} style={{ cursor: 'pointer' }}>
            {dayOfMonth}
          </h3>
          {/* Add appointments to the day */}
          {/* List up to 4 appointments for the day */}
          {appointmentsForDay.slice(0, MAX_APPTS_PER_DAY).map((appointment) => {
            const apptStartTimeFormatted = new Date(appointment.startDateTime).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
            const apptString = `${apptStartTimeFormatted} - ${appointment.service}, ${appointment.customerName}`;
            return (
              <div className={classes.appt} onClick={(e) => handleClick(e, appointment, day)}>
                {apptString}
              </div>
            );
          })}
          {isOverDayLimit && <div className="moreAppts">{`+${numOfHiddenAppts} more`}</div>}
        </div>
      );
    }
  }

  return (
    <div className={classes.monthContainer}>
      <>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => {
          return <div>{day}</div>;
        })}
      </>
      {calendarContent}
    </div>
  );
}
