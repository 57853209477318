import { useState, useEffect } from 'react';
import api from '../../../utils/api';

export default function useStoreServiceItems(storeId) {
  const [serviceItems, setServiceItems] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    api
      .getServiceItemsByStore(storeId)
      .then((response) => {
        setServiceItems(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [storeId]);

  return { serviceItems, isLoading, error };
}
