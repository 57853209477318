import api from '../../../utils/api';
import { useState, useEffect, useCallback } from 'react';
import { determinePinChanges, isProtectFields } from '../helpers/adminLogHelpers';

export default function useAdminLogsData(props) {
  const mode = props?.mode; //Couldn't destructure props so used assignment
  /* 
  Ideally admin logs will leverage adminMessages and send to a fixed storeID owned by the admin
  Refered to as logs in this file
  Hook will handle data for Admin Logs,
  Posts logs
  Fetch logs for admin (read/unread)
  Archive logs (read)
  Delete logs (delete from server)
  Helpers for encoding and parse logs
  hold state for logs (read and unread)
  */
  //==================== Constants ====================
  const API_ENV = process.env.REACT_APP_ENV;
  const ADMIN_STORE_ID_FOR_MESSAGES = process.env.REACT_APP_MSG_STORE_ID; //34346;
  const ADMIN_STORE_ID_LINK_FOR_MESSAGES = `https://${API_ENV}.s7daw.com:8080/api/v1/stores/${ADMIN_STORE_ID_FOR_MESSAGES}`;

  //==================== State ====================
  const [logs, setLogs] = useState({ read: [], unread: [] });
  const [error, setError] = useState(null);
  const [refresher, setRefresher] = useState(0);

  //==================== API Calls ====================
  const archiveLog = async (messageId) => {
    //Msg Link(s) is an array of links to the message
    try {
      setError(null);
      //Makes the message as Read
      await api.markStoreMessageRead(messageId, ADMIN_STORE_ID_LINK_FOR_MESSAGES); // Convert links to string >> misleading change where array needed
      //Move the message from 'unread' to 'read' in state
      const newUnreadLogs = logs.unread.filter((log) => log.messageId !== messageId);
      const logToArchive = logs.unread.find((log) => log.messageId === messageId);
      const newReadLogs = [...logs.read, { ...logToArchive, isRead: true }];
      setLogs({ unread: newUnreadLogs, read: newReadLogs });
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const deleteLog = async (messageId) => {
    //DANGEROUS - Deletes the message from the server
    try {
      setError(null);
      await api.deleteAdminMessage(messageId);
      //Remove the message from state
      const newUnreadLogs = logs.unread.filter((log) => log.messageId !== messageId);
      const newReadLogs = logs.read.filter((log) => log.messageId !== messageId);
      setLogs({ unread: newUnreadLogs, read: newReadLogs });
    } catch (err) {
      console.log(`Error deleting log ${messageId}`);
      setError(err);
    }
  };
  const postLog = useCallback(async (message) => {
    try {
      setError(null);
      await api.createAdminMessage(message.title, message.body, [ADMIN_STORE_ID_LINK_FOR_MESSAGES], null); //title, body, unread store(s) array, read store(s)
    } catch (err) {
      console.log(err);
      setError(err);
    }
    //eslint-disable-next-line
  }, []);

  const fetchLogsByType = useCallback(async (messageType) => {
    try {
      if (messageType !== 'read' && messageType !== 'unread') {
        throw new Error('Invalid message type argument');
      }
      const rawRes = await api.getMessagesByStore(ADMIN_STORE_ID_FOR_MESSAGES, messageType);
      return rawRes.data._embedded.adminMessages;
    } catch (err) {
      console.log('While fetching individual log:', messageType, err);
      throw err; //Error will be caught by the fetchLogs function in useEffect
    }
    //eslint-disable-next-line
  }, []);

  //==================== Helpers ====================
  const encodeLogBody = (changeLog) => {
    const { formChanges, pinChanges } = changeLog;
    const flatChanges = [];
    formChanges.forEach((change) => {
      flatChanges.push(`${change.field}: ${change.oldValue} -> ${change.newValue}`);
    });

    pinChanges.deleteCitys.forEach((pin) => {
      flatChanges.push(`Location Removed: ${pin.city}`);
    });
    pinChanges.newCitys.forEach((pin) => {
      flatChanges.push(`Location Added: ${pin.city}`);
    });
    return flatChanges.join(',');
  };

  const encodeLogTitle = (changeLog) => {
    const { storeID, storeName, changedBy } = changeLog;
    const title = [storeID, storeName, changedBy].toString();
    return title;
  };

  const parseLogs = (logs) => {
    if (!logs) return [];
    const parsedLogs = logs.map((log) => {
      const { title: rawTitle, body: rawBody, createDateTime } = log;
      const messageId = log._links.self.href.split('/').pop();
      const [storeID, storeName, changedByUserName] = rawTitle.split(',');
      const changes = rawBody.split(',');

      const logObj = {};
      logObj.messageId = messageId;
      logObj.messageLink = log._links.self.href;
      logObj.storeId = storeID;
      logObj.storeName = storeName;
      logObj.isRead = log.readStoresCount > 0 ? true : false; //TODO - brittle, pass a flag with the data being parsed
      logObj.changes = changes;
      logObj.changedBy = changedByUserName;

      const DATE_FORMAT_OPTIONS = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        // timeZone: 'America/New_York', //Timezone not needed as it should display in the user's local time
      };
      const logCreateDateTimeAsUTC = new Date(createDateTime + 'Z'); //Server has time as UTC but doesn't return the Z or offset (-0000)
      const dateTimeFormatter = new Intl.DateTimeFormat([], DATE_FORMAT_OPTIONS);

      logObj.createDateTime = dateTimeFormatter.format(new Date(logCreateDateTimeAsUTC));
      return logObj;
    });
    return parsedLogs;
  };

  const logChanges = (oldFormData, newFormData, oldPinData, newPinData, storeID, storeName, changedByUserName) => {
    const FIELDS_TO_LOG = ['publish', 'storeName']; // fields to track

    //For storeName - need to indicate the state of the store lock at the time of the change.
    // oldFormData.lockProtectedFields is the lock state at the time of the change

    //escape hatch for new stores
    console.log('=====oldformdata', oldFormData);
    if (!oldFormData) {
      return { formChanges: null, pinChanges: null, storeID, storeName, changedBy: changedByUserName };
    }
    console.log('====determingin changes...');
    const isStoreNameLocked = isProtectFields(oldFormData.protectFields, oldFormData.published);

    const changes = [];
    Object.keys(oldFormData).forEach((key) => {
      if (oldFormData[`${key}`] !== newFormData[`${key}`]) {
        if (FIELDS_TO_LOG.includes(key)) {
          let fieldChangedTitle = key.charAt(0).toUpperCase() + key.slice(1); //key with capitalize first letter
          if (key === 'storeName') {
            fieldChangedTitle = !isStoreNameLocked ? 'Store Name - unlocked' : 'Store Name - Locked';
          }

          changes.push({
            field: fieldChangedTitle,
            oldValue: oldFormData[`${key}`],
            newValue: newFormData[`${key}`],
          });
        }
      }
    });

    const pinChanges = determinePinChanges(newPinData, oldPinData);
    return { formChanges: changes, pinChanges, storeID, storeName, changedBy: changedByUserName };
  };

  const createAndSendChangeLog = useCallback(async (changeInfo) => {
    console.log('START createAndSendChangeLog');
    const { storeID, storeName, oldFormData, newFormData, oldPinData, newPinData, changedBy } = changeInfo;
    const changeLog = logChanges(oldFormData, newFormData, oldPinData, newPinData, storeID, storeName, changedBy);
    console.log('oldformdata', oldFormData);
    const body = changeInfo?.oldFormData ? encodeLogBody(changeLog) : 'Bussiness Created';
    if (body === '') return; //Don't send empty logs

    const title = encodeLogTitle(changeLog);
    const logMessage = { title, body };

    try {
      console.log('START postLog', logMessage);
      await postLog(logMessage);
      console.log('END postLog - success');
    } catch (err) {
      console.error(err);
      // - [ ] TODO: Display error message to user? Or just log it?
    }
    //eslint-disable-next-line
  }, []);

  const refreshAdminLogs = () => {
    //trigger a refresh of the logs by fetching from the server
    setRefresher((p) => p + 1);
  };

  //==================== Effects ====================
  //Fetch logs on mount, only while in admin mode, using async await.
  //Fetch and set unreadLogs first, then fetch and set readLogs
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setError(null);
        const unreadLogs = await fetchLogsByType('unread');
        const readLogs = await fetchLogsByType('read');
        setLogs({ read: parseLogs(readLogs), unread: parseLogs(unreadLogs) });
      } catch (err) {
        console.log('Error while fetching logs', err);
        setError(err);
      }
    };

    if (mode === 'admin') fetchLogs();
  }, [fetchLogsByType, mode, refresher]);

  //==========Exports / Return ===========
  return {
    logs,
    unreadCount: logs.unread.length,
    createAndSendChangeLog,
    tableActions: {
      archiveLog,
      deleteLog,
    },
    refreshAdminLogs,
    error,
  };
}
