import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, Typography, TextField, Input } from '@material-ui/core';
import { format, addMinutes } from 'date-fns';
import { determineAvailableTimes } from './BookingClientHelper';
import api from '../../../utils/api';
import cookies from '../../../cookies';
import { formatPhoneNumber } from '../../../utils/helpers';
import ClientTimeSelectPopover from './ClientTimeSelectPopover';
// import TimeSelectDropDown from './TimeSelectDropDown';
import { AddToCalendarButton } from 'add-to-calendar-button-react';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 340,
    [theme.breakpoints.down('sm')]: {
      minWidth: 250,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  totalContainer: {
    display: 'flex',
    gap: 4,
    alignItems: 'center',
    margin: 8,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  dateTimeSelectorContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export default function Index({ storeInfo, service, closeDialog }) {
  const classes = useStyles();
  const userID = cookies.getUserID();
  // const today = new Date().toISOString().split('T')[0];
  const weekDaysWhenClosed = [];
  //Check storeInfo.hours, and determine if any weekdays are closed, add to weekDaysWhenClosed array as number of day (0-6)
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  weekDays.forEach((day, index) => {
    if (!storeInfo.hours[`${day.toLowerCase()}Open`]) {
      weekDaysWhenClosed.push(index);
    }
  });

  const storeTimeZoneAbbrv = new Date()
    .toLocaleTimeString('en-us', { timeZone: storeInfo.timeZone, timeZoneName: 'short' })
    .split(' ')[2]; //ie EST

  // const { storeId, businessHours, appointments } = storeInfo;
  const [mode, setMode] = useState('SELECT_TIME'); //['SELECT_TIME', 'SELECT_STYLIST', 'CONFIRM', 'SUBMIT', LOGIN, CREATE_ACCOUNT]
  const [selectedDay, setSelectedDay] = useState(null); //String of date only (ie 2024-02-22)
  const [selectedApptDateTime, setSelectedApptDateTime] = useState(null); //Must be a time coordinate rather than an object "2024-02-22 10:00 EST"
  const [apptNotes, setApptNotes] = useState('');

  //====================================================================
  //User Info fetching and setting
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    const getUserInfo = async () => {
      const res = await api.getUser(userID);
      console.log('res', res);
      setUserInfo({
        firstName: res.firstName,
        phone: res.phone,
        email: res.username,
        id: userID,
        idLink: res._links.self.href,
      });
    };
    if (userID) getUserInfo();
  }, [userID]);

  const handleUpdatePhone = (event) => {
    const newPhoneNum = event.target.value.replace(/\D/g, '').slice(0, 10);
    setUserInfo({ ...userInfo, phone: newPhoneNum });
  };
  //====================================================================
  // Appointment Slots logic
  const [storeAppointments, setStoreAppointments] = useState([]);
  useEffect(() => {
    //get available times for selectedDay
    const getAndSetAvailableTimes = async () => {
      const res = await api.getAppointmentsByStoreAndDate(storeInfo._links.self.href, null, {
        projection: 'datesOnly',
      });
      //Add "Z" to start and End Date Time, return all fields
      const allAppointments = res.map((appt) => {
        return {
          ...appt,
          startDateTime: appt.startDateTime + 'Z',
          endDateTime: appt.endDateTime + 'Z',
        };
      });
      setStoreAppointments(allAppointments); //Keep appts in state as its likely smaller than reverse of available slots
      //TODO - need to focus on the selected date or a smaller range of dates (ie 7 days from today)
    };
    getAndSetAvailableTimes();
  }, [storeInfo, service]);
  //====================================================================
  //Save Appt to DB
  const [apptToSave, setApptToSave] = useState(null);

  useEffect(() => {
    const saveAppt = async () => {
      try {
        await api.createAppointment(apptToSave);
        setApptToSave(null);
        setMode('BOOKED');
      } catch (error) {
        console.error('Error saving appointment:', error);
        //TODO - handle error
      }
      setApptToSave(null);
    };
    if (apptToSave) saveAppt();
  }, [apptToSave]);

  //====================================================================

  const handleChangeDay = (event) => {
    const newValue = event.target.value;
    const selectedDateToStoreTimeZone = new Date(newValue).toISOString().split('T')[0];
    setSelectedDay(selectedDateToStoreTimeZone);
    setSelectedApptDateTime(null);
  };

  const handleChangeApptTime = (apptStartTime) => {
    setSelectedApptDateTime(apptStartTime);
    //Close time picker and display selected time
  };

  const handleChangeApptNotes = (event) => {
    setApptNotes(event.target.value);
  };

  const svcDuration = service.durationInMinutes;
  const apptEndTime = addMinutes(new Date(selectedApptDateTime), svcDuration); // Appt time + Duration of svc

  const handleConfirmAppt = () => {
    //Trigger API call to create appointment

    /* 
    {
    "service": "Hair cut",
    "customerName":"Jan S",
    "customerPhone": "5551235555",
    "customerEmail":"",
    "notes":"likes color",
    "createdBy": "https://api.dev.beautimap.com:8080/api/v1/users/1107",
    "store": "https://api.dev.beautimap.com:8080/api/v1/stores/1110",
    "startDateTime": "2024-03-06T05:15:00",
    "endDateTime": "2024-03-06T05:45:00"
    }
    */
    setApptToSave({
      service: service._links.self.href, //serviceID - saved as text parsed only on the front end
      customerName: userInfo.firstName,
      customerPhone: userInfo.phone,
      customerEmail: userInfo.email,
      notes: apptNotes,
      createdBy: userInfo.idLink,
      store: storeInfo._links.self.href,
      startDateTime: selectedApptDateTime,
      endDateTime: apptEndTime.toISOString(),
      price: service.price, //number
    });
  };

  /* See available appointments
  Have the ability to to to next available (ie booked up for curr day and later << or if scrolled too far?)

   */
  const formattedPrice = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD', //CAD adds CA to the front
  }).format(service.price);

  const availableTimesForDay =
    selectedDay && determineAvailableTimes(storeInfo, selectedDay, storeAppointments, svcDuration); //maybe better to make list for whole block?

  const apptDetailsLocal = {
    name: `${service.name} at ${storeInfo.name}`,
    location: `${storeInfo.address.street}, ${storeInfo.address.city}, ${storeInfo.address.province}`,
    startDate: selectedApptDateTime ? format(new Date(selectedApptDateTime), 'yyyy-MM-dd') : '',
    endDate: selectedApptDateTime ? format(new Date(selectedApptDateTime), 'yyyy-MM-dd') : '',
    startTime: selectedApptDateTime ? format(new Date(selectedApptDateTime), 'HH:mm') : '',
    endTime: selectedApptDateTime ? format(apptEndTime, 'HH:mm') : '',
    timezone: storeInfo.timezone,
  };

  //================================================== Components (modes)
  const MAX_LEAD_TIME_DAYS = 30; //days
  const pricingSection = (
    <div id="bookingTotal" className={classes.totalContainer}>
      <Typography variant="h6">{`${formattedPrice}`}</Typography>
      <Typography variant="caption">+ applicable taxes</Typography>
      <Typography variant="caption" style={{ paddingLeft: 4 }}>
        due at the time of the appointment
      </Typography>
    </div>
  );

  const allowBooking = selectedApptDateTime && userInfo?.phone?.length === 10;
  const addressText = (
    <Typography>{`at ${storeInfo?.address?.street}, ${storeInfo?.address?.city} ${storeInfo?.address?.province}`}</Typography>
  );

  // Handle date if safari (selectedDay needs to use / instead of - to create new dates)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const formattedDate = selectedDay && isSafari ? selectedDay.replace(/-/g, '/') : selectedDay;

  const selectApptTime = (
    <>
      <div id="apptDetails">
        <h2>{`${service.name} (${service.durationInMinutes} min) at ${storeInfo.name}`}</h2>
        {addressText}
        {pricingSection}
      </div>
      {/* Staff -to be added later -  default to any, give option to change (will affect available times) */}
      <div id="apptDateTime" className={classes.dateTimeSelectorContainer}>
        <FormControl className={classes.formControl} variant="filled">
          <InputLabel id="select-appt-day-label">Date:</InputLabel>
          <Select labelId="select-appt-day-label" id="appt-day-select" value={selectedDay} onChange={handleChangeDay}>
            {/* Today + 30days */}
            {Array.from({ length: MAX_LEAD_TIME_DAYS }, (_, i) => {
              //TODO refactor with dateFNS as v date is coming out wrong - needs to be relative to store time zone
              const nextDay = new Date().setDate(new Date().getDate() + i);
              const dayOfWeek = new Date(nextDay).getDay();
              if (weekDaysWhenClosed.includes(dayOfWeek)) return null; // Closed - null will not render
              const v = new Date(nextDay).toISOString().split('T')[0]; //TODO  - Should consolidate the dates into one format
              const formattedDay = format(nextDay, 'dd MMM yy - EEEE');
              return <MenuItem value={v}>{formattedDay}</MenuItem>;
            })}
          </Select>
        </FormControl>

        {/*  <TimeSelectDropDown
          selectedDay={selectedDay}
          selectedApptDateTime={selectedApptDateTime}
          handleChangeApptTime={handleChangeApptTime}
          availableTimesForDay={availableTimesForDay}
        /> */}

        {/* Popover containing mini verions of "day calendar" showing both available slots and full spots  */}
        <ClientTimeSelectPopover
          selectedDay={selectedDay && new Date(`${formattedDate} ${storeTimeZoneAbbrv}`)}
          selectedApptDateTime={selectedApptDateTime}
          handleChangeApptTime={handleChangeApptTime}
          availableTimesForDay={availableTimesForDay}
          // storeAppointments={storeAppointments}
        />
        {/* ----------------------------------------------- */}
      </div>
      {/* <TextField label="Notes for appointment" multiline rows={2} variant="outlined" />  << Wait until there is demand for public to use this, leave it for business in mean time */}
      <div style={{ marginBottom: 16, width: '100%' }}>
        <TextField
          id="standard-basic"
          label="Phone Number"
          value={userInfo?.phone?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')}
          onChange={handleUpdatePhone}
        />
        <FormControl fullWidth className={classes.margin}>
          <InputLabel htmlFor="standard-adornment-amount">Notes for appointment:</InputLabel>
          <Input id="standard-adornment-amount" value={apptNotes} onChange={handleChangeApptNotes} />
        </FormControl>
      </div>
      <div style={{ display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={() => setMode('CONFIRM')} disabled={!allowBooking}>
          Continue
        </Button>
      </div>
    </>
  );

  const confirmation = (
    <div>
      <h2>Please confirm your appointment details:</h2>
      <Typography>{`${service.name} at ${storeInfo.name}`}</Typography>
      {addressText}
      <Typography>{`For: ${userInfo?.firstName} - ${formatPhoneNumber(userInfo?.phone)}`}</Typography>

      <Typography>On: {`${format(new Date(selectedApptDateTime), 'EEEE dd MMMM yyyy h:mm aaa')}`}</Typography>
      {apptNotes && <Typography>Notes to business: {`${apptNotes}`}</Typography>}

      {pricingSection}

      <div style={{ display: 'flex', gap: 16, alignItems: 'center', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={() => setMode('SELECT_TIME')}>
          BACK
        </Button>
        <Button variant="contained" color="primary" onClick={handleConfirmAppt}>
          Confirm and Book
        </Button>
      </div>
    </div>
  );

  const saveConfirmed = (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <h2>Your appointment has been booked!</h2>
      <Typography>{`See you on ${format(new Date(selectedApptDateTime), 'EEEE dd MMMM yyyy h:mm aaa')}`}</Typography>
      {addressText}

      <Typography>{`You will receive a confirmation email and text message shortly.`}</Typography>

      <div style={{ margin: '8px 0' }}>
        <AddToCalendarButton
          id="addToCalendarButton"
          options={['Apple', 'Google', 'Outlook.com', 'Microsoft365', 'iCal']}
          trigger="click"
          forceOverlay
          //Appt details
          name={apptDetailsLocal.name}
          location={apptDetailsLocal.location}
          startDate={apptDetailsLocal.startDate}
          endDate={apptDetailsLocal.endDate}
          startTime={apptDetailsLocal.startTime}
          endTime={apptDetailsLocal.endTime}
          timeZone={apptDetailsLocal.timezone}
        />
      </div>

      <Button variant="contained" color="primary" onClick={closeDialog}>
        Close
      </Button>
    </div>
  );

  //===============================================Render
  return (
    <div style={{ padding: 8 }}>
      {/* Make in one long form for first phase?  End state have steps
      1- Choose Service 
      1.a choose stylist - For future use
      2- Choose Available date time << next avail by default - will need to feed in appointment times (trimmed) then revese the time and business hours
      << Also need business settings for buffer time and hours
      3 - Login or create account - skip if logged in
      4 - Notes for appointment
      5 - Confirm
      6 - Submit (api call to create appointment and send email or text to client and stylist - will also need to verify time @ booking and on submit to avoid double booking) 
      
      */}
      {mode === 'SELECT_TIME' && selectApptTime}
      {/* Login - If logged in skip, else show login/ register - run solely in modal DONT redirect*/}

      {/* Confirmation */}
      {mode === 'CONFIRM' && confirmation}
      {/* {mode === 'CONFIRM'  && inlineLoginOrRegister} */}

      {/* Confirmed - Success */}
      {mode === 'BOOKED' && saveConfirmed}
    </div>
  );
}
