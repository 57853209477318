import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DialogGeneric({ openDialog, handleClose, handleConfirm, dialogText }) {
  const { title, body, confirmBtn, cancelBtn } = dialogText;
  return (
    <div>
      <Dialog
        style={{ zIndex: 3000 }} //Service Items Modal is 2000, navbar is 1000
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            {cancelBtn || 'No, go back'}
          </Button>
          <Button onClick={handleConfirm} color="default" autoFocus>
            {confirmBtn || 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
