import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { handleImage, openStatus } from '../utils/helpers';
import Rating from '@material-ui/lab/Rating';
import { parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  container: {
    width: '100%',
    marginTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E3E3E3',
    cursor: 'pointer',
  },
  selected: {
    width: '100%',
    marginTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #344966',
    borderRadius: '20px',
    cursor: 'pointer',
    backgroundColor: '#F2F4F8',
  },

  storeImage: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    marginLeft: '10px',
  },
  storeInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    fontSize: '11px',
  },
  storeTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
}));

export default function MapsStoreCard(props) {
  const { store, handleMarkerPosition } = props;

  const { selected } = props;

  const styles = useStyles();
  return (
    <div
      id={parseIdFromLink(store._links.self.href)}
      className={
        selected
          ? store._links.self.href === selected._links.self.href
            ? styles.selected
            : styles.container
          : styles.container
      }
      onClick={(e) => {
        handleMarkerPosition(e, store);
      }}
    >
      <img
        src={handleImage(store.storeImage, { placeholderType: 'BUSINESS' })}
        className={styles.storeImage}
        alt={store.name}
      />
      <div className={styles.storeInfo}>
        <span className={styles.storeTitle}>{store.name}</span>
        <span>{`${store.address.city}, ${store.address.province}`}</span>
        <span>{store.storeType}</span>
        {openStatus(store.hours) && <span style={{ color: 'green' }}>Open </span>}
        {!openStatus(store.hours) && <span style={{ color: 'red' }}>Closed </span>}
        <Rating name="read-only" value={store.avgRating} precision={0.5} size="small" readOnly />
        {`${Number(store.avgRating).toFixed(2)} (${store.numOfReviews} reviews)`}
      </div>
    </div>
  );
}
