import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {makeStyles} from '@material-ui/core/styles'
import theme from '../theme';
function CarouselComponent(props) {
  console.log('height', props.height)
  console.log('children', props.children)

  const useStyles = makeStyles(theme => ({
    carousel: {
      width: '100%',
      height: props.height
    }
  }))

  const classes = useStyles();

  return (
    <Carousel 
    className={classes.carousel}
    animation='slide' 
    navButtonsAlwaysInvisible={true}
    stopAutoPlayOnHover={false}
    indicatorIconButtonProps={{
    style: {
      color: theme.palette.common.gray
     }
    }}
    activeIndicatorIconButtonProps={{
      style: {
      color: theme.palette.primary.main
      }
    }}
    indicatorContainerProps={{
      style: {
        position: 'absolute',
        bottom: 20
      }
    }}
    >
      {props.children}
    </Carousel>
  );
}

export default CarouselComponent;