import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { QRCode } from 'react-qrcode-logo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QRCodeGenerator({ target, logo, className, name }) {
  const [open, setOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const [qrDots, setQRDots] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoChange = () => {
    setShowLogo(!showLogo);
  };

  return (
    <>
      <Button variant="contained" color="primary" className={className} onClick={handleClickOpen}>
        {`My QR Code`}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-slide-title">{`QR Code for ${name}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography variant="h6">Options:</Typography>

            <hr />
            <FormGroup row>
              <FormControlLabel
                control={<Switch checked={showLogo} onChange={handleLogoChange} name="logoInQR" color="primary" />}
                label="Add Logo to QR Code"
              />
              <FormControlLabel
                control={<Switch checked={qrDots} onChange={() => setQRDots(!qrDots)} name="qrDots" color="primary" />}
                label="Use Dots instead of Squares"
              />
            </FormGroup>
            <br />

            <div style={{ display: 'flex', justifyContent: 'Center' }}>
              <QRCode
                id={`QR-${name}`}
                value={target}
                logoImage={showLogo ? logo : null}
                enableCORS={true}
                qrStyle={qrDots ? 'dots' : 'squares'}
                // eyeColor="blue"
                // eyeRadius={150}
              />
            </div>
            <Typography variant="h6">Links to:</Typography>
            <Typography variant="p">{target}</Typography>
            <hr />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //On Click Saves the QR Code as a PNG
              const canvas = document.getElementById(`QR-${name}`);
              const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
              let downloadLink = document.createElement('a');
              downloadLink.href = pngUrl;
              downloadLink.download = `Beautimap-QR-${name}.png`;
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
            color="primary"
            style={{ textShadow: '1px 1px 1px #ff9780' }}
          >
            Save
          </Button>
          <Button onClick={handleClose} color="primary" style={{ textShadow: '1px 1px 1px #ff9780' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
