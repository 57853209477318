/* List hours of the day for day and week views of calendar

00:00 ----

01:00 ----

02:00 ----
etc

*/
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  daysHours: {},
  hourBlock: {
    position: 'relative',
    height: '48px',
    width: '48px',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
  hourText: {
    position: 'absolute',
    top: -12,
    backgroundColor: 'white',
    zIndex: 1,
    paddingRight: '4px',
    fontSize: '0.8rem',
  },
}));

export default function TimesOfDayList({ timeFormat, BLOCK_HEIGHT = 48 }) {
  const classes = useStyles();
  const generate24hrTime = () => {
    let hours = [];
    for (let i = 0; i < 24; i++) {
      // Push hours in 00:00 format
      hours.push(`${i.toString().padStart(2, '0')}:00`); // 00:00
    }
    return hours;
  };

  const generate12hrTime = () => {
    //Generates 12hr time 1am, 2am, etc
    let hours = [];
    for (let i = 0; i < 24; i++) {
      if (i < 12) {
        if (i === 0) hours.push(`12am`);
        else {
          hours.push(`${i}am`);
        }
      } else if (i === 12) {
        hours.push(`${i}pm`);
      } else {
        hours.push(`${i - 12}pm`);
      }
    }
    return hours;
  };
  const daysHours = timeFormat === '12hr' ? generate12hrTime() : generate24hrTime(); //Prop option to be passed to select time format 12 vs 24hr

  return (
    <div classname={classes.daysHours}>
      {daysHours.map((hour) => {
        return (
          <div key={hour} className={classes.hourBlock} style={{ height: BLOCK_HEIGHT }}>
            <span className={classes.hourText}>{hour}</span>
          </div>
        );
      })}
    </div>
  );
}
