import React, { useEffect, useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { STATUS, SCREEN_STATES } from '../utils/constants';
import api from '../utils/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import PreviewContext from '../PreviewContext';
import ErrorDetails from './ErrorDetails';
import QRCodeGenerator from './QRCodeGenerator/Index';
import ServiceItemsManager from './ServiceItemsManager/Index';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 0, 0),
    backgroundColor: '#ebebeb',
    border: '1px solid #bbbbbb',
    // borderRadius: "5px",
    // theme 'sm' padding is 16px
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto auto auto',
    gridColumnGap: theme.spacing(4),
    gridRowGap: theme.spacing(1),
    alignItems: 'center',
    justifyItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridColumnGap: theme.spacing(2),
      gridRowGap: 0,
    },
  },
  text: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    gridRowStart: 1,
    gridRowEnd: 3,
    color: theme.palette.common.font,
  },
  editButton: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 3,
    gridRowEnd: 4,
    width: '100%',
    margin: theme.spacing(1),
    color: theme.palette.common.font,
  },
  backToMainButton: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 4,
    gridRowEnd: 5,
    width: '100%',
    margin: theme.spacing(1),
    color: theme.palette.common.font,
  },
  publishButton: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 3,
    gridRowEnd: 4,
    width: '100%',
    margin: theme.spacing(1),
    color: theme.palette.common.font,
  },
  clientButton: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 4,
    gridRowEnd: 5,
    width: '100%',
    margin: theme.spacing(1),
    color: theme.palette.common.font,
  },
  servicesButton: {
    gridColumnStart: 1,
    gridColumnEnd: 2,
    gridRowStart: 4,
    gridRowEnd: 5,
    width: '100%',
    margin: theme.spacing(1),
    color: theme.palette.common.font,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  confirmationMessage: {
    margin: theme.spacing(2),
  },
  terms: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  formActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  formButton: {
    margin: theme.spacing(1),
    width: 100,
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

// const getTCAccepted = async () => {
//   return api
//     .getUser(cookies.getUserID())
//     .then((res) => res.tcAccepted)
//     .catch(() => {
//       throw STATUS.ERROR;
//     });
// };

// const updateUser = async (id) => {
//   return api
//     .updateUser(id, { tcAccepted: true })
//     .then(() => STATUS.SUCCESS)
//     .catch((error) => {
//       throw error;
//     });
// };

const publishItem = async (id, brand) => {
  return api
    .updateStore(id, { published: true })
    .then(() => STATUS.SUCCESS)
    .catch((error) => {
      throw error;
    });
};

function PreviewActions(props) {
  const classes = useStyles();

  const { storeId, storeInfo } = props;

  //Generate URL using
  //Use first category of store, make array, select first, and change to string
  const storeCategory = storeInfo?.category.split(',')[0];
  //Store Location replace spaces with dashes
  const storeLocation = {
    city: storeInfo?.address?.city.replace(/\s+/g, '-'),
    province: storeInfo?.address?.province.replace(/\s+/g, '-'),
  };
  const storeURL =
    window.location.origin +
    `/${storeCategory}/${storeLocation.province}/${storeLocation.city}/${storeId}/${storeInfo?.slug}`.toLowerCase();
  const storeLogo = storeInfo?._embedded.file?.url;
  const QRCodeInfo = { target: storeURL, logo: storeLogo, name: storeInfo?.name };

  const [published, setPublished] = useState();
  // const [status, setStatus] = useState("");
  // const [termsAccepted, setTermsAccepted] = useState(false);
  // const [initialAccepted, setInitialAccepted] = useState(false);
  const [publish, setPublish] = useState(false);
  const [saving, setSaving] = useState(false);
  const [clientRedirect, setClientRedirect] = useState(false);
  const [saveError, setSaveError] = useState(false);
  // const [successSave, setSuccessSave] = useState(false);

  const preview = useContext(PreviewContext);
  useEffect(() => {
    api.getStore(storeId).then((res) => {
      setPublished(res.data.published);
    });
  }, [storeId]);

  // useEffect(() => {
  //   getTCAccepted()
  //     .then((accepted) => {
  //       setInitialAccepted(accepted ? accepted : false);
  //       setTermsAccepted(accepted ? "accepted" : false);
  //     })
  //     .catch((error) => setStatus(error));
  // }, []);

  let actionButtonText = '';
  const editPrefix = 'Edit';
  const backText = 'Back to Preview';

  switch (preview.state.screen) {
    case SCREEN_STATES.STORE_DETAILS:
      actionButtonText = editPrefix + ' Store Details';
      break;

    case SCREEN_STATES.EDIT_STORE:
      actionButtonText = backText;
      break;

    case SCREEN_STATES.DEAL_DETAILS:
      actionButtonText = editPrefix + ' This Deal';
      break;

    case SCREEN_STATES.EDIT_DEAL:
      actionButtonText = backText;
      break;

    default:
      actionButtonText = 'error';
      break;
  }

  const actionButton = () => {
    switch (preview.state.screen) {
      case SCREEN_STATES.STORE_DETAILS:
        // preview.changeScreen(SCREEN_STATES.EDIT_STORE);
        // Navigate to /client/edit/stores/:storeId
        window.location.assign(`/client/edit/stores/${storeId}`);
        break;

      case SCREEN_STATES.EDIT_STORE:
        preview.changeScreen(SCREEN_STATES.STORE_DETAILS);
        break;

      case SCREEN_STATES.DEAL_DETAILS:
        preview.changeScreen(SCREEN_STATES.EDIT_DEAL, preview.state.dealId);
        break;

      case SCREEN_STATES.EDIT_DEAL:
        preview.changeScreen(SCREEN_STATES.DEAL_DETAILS, preview.state.dealId);
        break;

      default:
        break;
    }
  };

  const publishStore = () => {
    setSaving(true);

    publishItem(storeId)
      .then(() => {
        setSaving(false);
        // setSuccessSave(true);
      })
      .catch((err) => {
        setSaving(false);
        setSaveError(err);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.gridContainer}>
        <Button variant="contained" color="primary" className={`${classes.editButton}`} onClick={() => actionButton()}>
          {actionButtonText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.publishButton}`}
          onClick={() => setPublish(true)}
          disabled={published}
        >
          {published ? 'Already Published' : 'Publish'}
        </Button>
        {[
          SCREEN_STATES.PRODUCT_DETAILS,
          SCREEN_STATES.EDIT_PRODUCT,
          SCREEN_STATES.DEAL_DETAILS,
          SCREEN_STATES.EDIT_DEAL,
        ].includes(preview.state.screen) && (
          <Button
            variant="contained"
            color="primary"
            className={`${classes.backToMainButton}`}
            onClick={() => preview.changeScreen(SCREEN_STATES.STORE_DETAILS)}
          >
            {`Back To Store Page`}
          </Button>
        )}
        {/* beaut-126 */}
        {/* {preview.state.screen === SCREEN_STATES.STORE_DETAILS && (
          <Button
            variant="contained"
            color="primary"
            className={`${classes.backToMainButton}`}
            onClick={() => window.location.assign(`/client/stores/${storeId}/deals`)}
          >
            {`Manage Deals`}
          </Button>
        )} */}
        {preview.state.screen === SCREEN_STATES.STORE_DETAILS && (
          <>
            <QRCodeGenerator
              className={`${classes.clientButton}`}
              target={QRCodeInfo.target}
              logo={QRCodeInfo.logo}
              name={QRCodeInfo.name}
            />
            <ServiceItemsManager className={`${classes.servicesButton}`} name={QRCodeInfo.name} storeId={storeId} />
          </>
        )}
        {/* <Button
          variant="contained"
          color="primary"
          className={classes.clientButton}
          onClick={() => setClientRedirect(true)}
        >
          Go To Client Portal
        </Button> */}
      </div>
      <Modal
        className={classes.modal}
        open={publish}
        onClose={() => {
          setPublish(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={publish}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              {`Are you sure you want to publish this business? It will be available for the public to view. This action can be undone later.`}
            </Typography>
            {/* {!initialAccepted && (
							<Typography>
								As this is the first store you are publishing, you will need to
								accept the terms and conditions before proceeding.
							</Typography>
						)} */}
            {/* {initialAccepted === false && (
							<div className={classes.terms}>
								<TermsConditionsPopup
									termsAccepted={termsAccepted}
									setTermsAccepted={(val) => setTermsAccepted(val)}
								></TermsConditionsPopup>
							</div>
						)} */}
            <div className={classes.formActions}>
              <Button
                className={classes.formButton}
                variant="contained"
                onClick={() => {
                  setPublish(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                // disabled={!termsAccepted}
                onClick={() => {
                  publishStore();
                  setTimeout(() => {
                    window.location.reload();
                  }, 200);
                }}
              >
                {saving && <CircularProgress size={24} color="inherit" />}
                {!saving && 'Publish'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      {/* <Modal
				className={classes.modal}
				open={status === STATUS.ERROR}
				onClose={() => {
					window.location.reload();
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={status === STATUS.ERROR}>
					<div className={classes.modalPaper}>
						<Typography className={classes.confirmationMessage}>
							An error occurred while fetching user data.
						</Typography>
						<div className={classes.formActions}>
							<Button
								className={classes.formButton}
								variant="contained"
								onClick={() => window.location.reload()}
							>
								Reload
							</Button>
						</div>
					</div>
				</Fade>
			</Modal> */}
      <Modal
        className={classes.modal}
        open={clientRedirect}
        onClose={() => {
          setClientRedirect(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={clientRedirect}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              Are you sure you want to proceed to the client portal?
            </Typography>
            <div className={classes.formActions}>
              <Button
                className={classes.formButton}
                variant="contained"
                color="primary"
                onClick={() => setClientRedirect(false)}
              >
                No
              </Button>
              <Button
                className={classes.formButton}
                variant="contained"
                color="default"
                onClick={() =>
                  window.location.assign(
                    brand ? "/client/brands" : "/client/stores"
                  )
                }
              >
                Yes
              </Button>
            </div>
          </div>
        </Fade> */}
      </Modal>
      <Modal
        className={classes.modal}
        open={saveError}
        onClose={() => {
          if (saveError) {
            setSaveError(false);
          } else {
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={saveError}>
          <Fragment>
            {saveError && (
              <div className={classes.modalPaper}>
                <Fragment>
                  <Typography className={classes.statusMessage}>
                    There was an issue while saving your data. Please try again.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setSaveError(false);
                    }}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                  <ErrorDetails error={saveError} data={'N/A'}></ErrorDetails>
                </Fragment>
              </div>
            )}
            {/* {successSave && (
							<div className={classes.modalPaper}>
								<Fragment>
									<Typography className={classes.statusMessage}>
										Published successfully.
									</Typography>
									<Button
										variant="contained"
										color="primary"
										onClick={() => {
											setSuccessSave(false);
										}}
										className={classes.statusAcceptButton}
									>
										Ok
									</Button>
								</Fragment>
							</div>
						)} */}
          </Fragment>
        </Fade>
      </Modal>
    </div>
  );
}

export default PreviewActions;
