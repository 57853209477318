import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AboutUsContent from '../components/AboutUsContent';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
  },
}));

function TermsConditonsView(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Beautimap | About Us</title>
        <meta name="description" content="Beautimap About Us" />
      </Helmet>
      <div className={classes.innerContainer}>
        <AboutUsContent />
      </div>
    </div>
  );
}

export default TermsConditonsView;
