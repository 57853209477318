import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { formatPhoneNumber } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.font,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: '15px',
    marginBottom: theme.spacing(2),
    border: `10px solid ${theme.palette.common.background}`,
    // fontSize: '1.5rem',
    //On hover add box shadow
    // keep text center but line up left side of text

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: `10px solid ${theme.palette.common.characters}`,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.common.font,
      color: theme.palette.common.font,
    },
  },
  ctaText: {
    fontSize: '1.5rem',
    textAlign: 'center',
    textAnchor: 'start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
}));

export default function CallAction({ phoneNumber, businessName }) {
  const classes = useStyles();
  if (!phoneNumber) return null;
  //Format phone number from 'xxxxxxxxxx' to display xxx-xxx-xxxx
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  /* TODO: Drill business name for call now, if its too long (x char) use "us" */
  return (
    <Link className={classes.ctaContainer} href={`tel:${phoneNumber}`}>
      <Typography className={classes.ctaText}>{`Call ${businessName || 'us'}`}</Typography>
      <Typography className={classes.ctaText}>{formattedPhoneNumber}</Typography>
    </Link>
  );
}
