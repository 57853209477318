import { format, addMinutes, isAfter, isBefore, differenceInCalendarDays } from 'date-fns';

const DEFAULT_STORE_BOOKING_SETTINGS = {
  bufferTime: 15,
};

export const isBusinessClosed = (storeHours, selectedDate) => {
  selectedDate = new Date(selectedDate);
  const selectedDayOfWeek = format(selectedDate, 'EEEE').toLowerCase();
  const openTimeForSelectedDate = storeHours[selectedDayOfWeek + 'Open'];
  const closeTimeForSelectedDate = storeHours[selectedDayOfWeek + 'Close'];
  return !openTimeForSelectedDate || !closeTimeForSelectedDate;
};

export const isBeforeToday = (selectedDate, todayMidnight) => {
  const isSelectedDateBeforeToday = differenceInCalendarDays(selectedDate, todayMidnight) < 0;
  return isSelectedDateBeforeToday;
};

export const listAllSlotsWihinStoreHours = (storeHours, selectedDate, apptInterval = 15) => {
  //apptInterval is in minutes, should come from store settings but default to 15 minutes
  //[] TODO < below 4 lines are duplicated in is business closed, refactor
  selectedDate = new Date(selectedDate);
  const selectedDayOfWeek = format(selectedDate, 'EEEE').toLowerCase();

  const openTimeForSelectedDate = storeHours[selectedDayOfWeek + 'Open'];
  const closeTimeForSelectedDate = storeHours[selectedDayOfWeek + 'Close'];
  //Iterate by interval from open time until close time, do not include closing time
  //TODO - storehours maybe dirty open maybe null and closed might have an old value
  const openTime = new Date(`01/01/2000 ${openTimeForSelectedDate}`);
  const closeTime = new Date(`01/01/2000 ${closeTimeForSelectedDate}`);
  const availableTimes = [];
  while (isBefore(openTime, closeTime)) {
    availableTimes.push(format(openTime, 'HH:mm:ss'));
    openTime.setMinutes(openTime.getMinutes() + apptInterval);
  }
  return availableTimes;
};

export const removeUnavailableTimesFromDay = (
  selectedDate,
  apptIntervalsForDate,
  selectedDateAppointments,
  apptBufferInMin = 15,
  storeTimeZoneAbbrv,
  svcDurationInMin = 15
) => {
  const parsedDate = selectedDate.replace(/-/g, '/'); //for safari
  /*
  TODO - Params are fragile refactor to an object
   - Available times needs to be in local date times (appt start times)
   - Appointments will be in ISO format with Z time << will put into a date object for comparisons
   - Buffer time is in minutes
   */
  //================================================ Exit early if no appointments
  if (selectedDateAppointments.length === 0) {
    return apptIntervalsForDate.map((slot) => new Date(`${parsedDate} ${slot} ${storeTimeZoneAbbrv}`).toISOString());
  }
  //================================================ Convert Available times into date objects (store time zone)
  const daysTimeSlotsAsObject = apptIntervalsForDate.map((time) => {
    return new Date(`${parsedDate} ${time} ${storeTimeZoneAbbrv}`);
  });
  //================================================ Convert Appointments into date objects (store time zone)
  const appointmentsInDateObj = selectedDateAppointments.map((appointment) => {
    const endTimePlusBuffer = addMinutes(new Date(appointment.endDateTime), apptBufferInMin); //temp rmv appt buffer time
    const apptTimes = { start: new Date(appointment.startDateTime), end: endTimePlusBuffer };
    return apptTimes;
  });
  //================================================ Remove times that are too close to appointments
  //Remove times that are too close to appointments
  //Iterate appointments (likely less than slots) and remove slots that are too close to appointments

  const filteredSlots = daysTimeSlotsAsObject.filter((possStartTime) => {
    const isSlotTooCloseToAppt = appointmentsInDateObj.some((appt) => {
      const possEndTime = addMinutes(possStartTime, svcDurationInMin + apptBufferInMin);
      const apptStart = appt.start;
      const apptEnd = appt.end;
      // Does the possibleAppt overlap with the current appt?
      //Does the possAppt start time fall within the current appt?
      const isOverlap = isAfter(possStartTime, apptStart) && isBefore(possStartTime, apptEnd);
      const isOverlap2 = isAfter(possEndTime, apptStart) && isBefore(possEndTime, apptEnd);
      //Does the current appt start time fall within the possible appt?
      const isOverlap3 = isAfter(apptStart, possStartTime) && isBefore(apptStart, possEndTime);
      const isOverlap4 = isAfter(apptEnd, possStartTime) && isBefore(apptEnd, possEndTime);

      const isSameTime = possStartTime.getTime() === apptStart.getTime();

      const overlapExists = isSameTime || isOverlap || isOverlap2 || isOverlap3 || isOverlap4;

      return overlapExists;
    });
    return !isSlotTooCloseToAppt;
  });

  return filteredSlots.map((slot) => slot.toISOString());
};

export const determineAvailableTimes = (storeInfo, selectedDateString, appointments, svcDurationInMin) => {
  console.log('Determining available times for:', selectedDateString);
  const hours = storeInfo.hours;
  // const appointments = mockAppointments;
  const storeTimeZone = storeInfo.storeTimeZone;

  const selectedDateAppointments = appointments
    .filter((appointment) => {
      //TODO - may need better comparison b/w of time zones offset
      return appointment.startDateTime.includes(selectedDateString); //Appointments will be in ISO format with Z time
    })
    .map((appointment) => {
      //Add unavailable title to store appointments
      return { ...appointment, service: 'Unavailable' };
    });

  const storeTimeZoneAbbrv = new Date()
    .toLocaleTimeString('en-us', { timeZone: storeTimeZone, timeZoneName: 'short' })
    .split(' ')[2]; //ie EST
  const todayMidnight = new Date(new Date().toISOString().split('T')[0] + storeTimeZoneAbbrv); //Today's date at midnight store time

  const parsedDate = selectedDateString.replace(/-/g, '/'); //for safari
  const dateString = parsedDate + ' EST';
  const selectedDateObj = new Date(dateString); //Adds timezone of the store (CANNOT use user's local time could cause error if traveling)

  //Would these be better as thrown errors?  Review later
  if (isBusinessClosed(hours, selectedDateObj)) throw new Error('Unable to determine avilable times - closed');
  if (isBeforeToday(selectedDateObj, todayMidnight))
    throw new Error('Unable to determine avilable times - beforeToday');
  //================================== Create intervals for the day (within store hours) ==================================
  const allDaysSlots = listAllSlotsWihinStoreHours(hours, selectedDateObj); //Params hours, selectedDate, apptInterval = 15

  const availableSlotsForDay = removeUnavailableTimesFromDay(
    selectedDateString,
    allDaysSlots,
    selectedDateAppointments,
    DEFAULT_STORE_BOOKING_SETTINGS.bufferTime, //apptBufferInMin
    storeTimeZoneAbbrv,
    svcDurationInMin
  );
  // Parse available times to appear as appointments {startDateTime, endDateTime, title: available}
  const availableTimesAsAppointments = availableSlotsForDay.map((slot) => {
    const endTime = addMinutes(new Date(slot), svcDurationInMin);
    return { startDateTime: slot, endDateTime: endTime.toISOString(), service: 'Available' };
  });

  const existingApptsWithBufferTime = selectedDateAppointments.map((appointment) => {
    const endTimePlusBuffer = addMinutes(new Date(appointment.endDateTime), DEFAULT_STORE_BOOKING_SETTINGS.bufferTime); //temp rmv appt buffer time
    return { ...appointment, endDateTime: endTimePlusBuffer.toISOString() };
  });

  const allSlots = [...existingApptsWithBufferTime, ...availableTimesAsAppointments];

  return allSlots;
  //================================
};
