import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import api from "../utils/api";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  successText: {},
}));

export default function ForgotPasswordView(props) {
  // const { history } = props;

  // if (cookies.getJWT() && cookies.getUserID() && cookies.getRole()) {
  //   history.goBack();
  // }

  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(false);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  const validateEmail = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(String(email).toLowerCase());
  };

  const sendReset = async () => {
    setSending(true);
    return api
      .sendReset(email.toLowerCase())
      .then(() => {
        setSending(false);
        setSent(true);
      })
      .catch((error) => {
        setSending(false);
        if (error.message.includes("not found")) {
          setEmailError(true);
        } else setError(true);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Your Password?
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            value={email}
            onChange={(event) => {
              setEmailError(false);
              setEmail(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            error={(submitted && !validateEmail()) || emailError || error}
            helperText={
              submitted && !validateEmail()
                ? "Enter a valid email address"
                : emailError
                ? "A user with this email does not exist"
                : error
                ? "Something went wrong"
                : ""
            }
          />
          <Button
            disabled={sending || sent}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              event.preventDefault();
              setSubmitted(true);
              if (validateEmail()) {
                sendReset();
              }
            }}
          >
            {!sending && !sent && "Send Reset Email"}
            {sending && <CircularProgress size={24} color="inherit" />}
            {sent && "Reset Email Sent!"}
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" to="/">
            Beautimap
          </Link>{" "}
          2021.
        </Typography>
      </Box>
    </div>
  );
}
