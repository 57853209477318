import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles((theme) => ({
  row: {
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  asterisk: {
    color: theme.palette.common.red,
    fontWeight: 700,
  },
  infoBlock: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingBottom: '0px !important',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'smaller',
    },
  },
  contentGrid: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '0px !important',
    },
  },
}));

function FormRow(props) {
  const { title, asterisk, message } = props;
  const classes = useStyles();
  return (
    <Grid container className={classes.row} alignItems="flex-start" item xs={12} spacing={3}>
      <Grid item className={classes.infoBlock} md={2}>
        <Typography variant="body1" className={classes.title} style={{ fontWeight: 700 }}>
          {title}
          {asterisk && <span className={classes.asterisk}>*</span>}
        </Typography>
      </Grid>
      {/** message = "" for shorter length */}
      <Grid item className={classes.contentGrid} xs={15} md={message !== undefined ? 5 : 7}>
        {props.children}
      </Grid>
      {message && (
        <Grid item xs={15} md={5}>
          <Typography variant="body1">{message}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default FormRow;
