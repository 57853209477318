import React, { useState } from 'react';
import { Button, Typography, Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import BookingClient from '../../AppointmentManager/BookingClient/Index.jsx';

import LoginPopover from '../../LoginPopover.js';

const useStyles = makeStyles((theme) => ({
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.font,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: '15px',
    // marginBottom: theme.spacing(2),
    border: `10px solid ${theme.palette.common.background}`,
    // fontSize: '1.5rem',
    //On hover add box shadow
    // keep text center but line up left side of text

    '&:hover': {
      cursor: 'pointer',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: `10px solid ${theme.palette.common.characters}`,
      textDecoration: 'underline',
      textDecorationColor: theme.palette.common.font,
      color: theme.palette.common.font,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  ctaText: {
    fontSize: '1.25rem',
    textAlign: 'center',
    textAnchor: 'start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}));

export default function CallAction({ serviceItem, storeInfo, isLoggedIn }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [loginAnchorEl, setLoginAnchorEl] = useState(null);

  const closeDialog = () => setOpenDialog(false);

  const handleClick = (e) => {
    //If logged in open dialog
    if (isLoggedIn) setOpenDialog(true);
    //if not opens login in nav bar
    if (!isLoggedIn) {
      console.log('Open login');
      setLoginAnchorEl(e.currentTarget);
    }
  };

  return (
    <>
      <Button className={classes.ctaContainer} onClick={handleClick}>
        <Typography className={classes.ctaText}>{isLoggedIn ? `Book` : 'Login to Book'}</Typography>
        {/* Opens modal with booking component, only need to drill store ID or link, as modal will handle its own data fetching*/}
      </Button>
      {/* Short circuit to only call data when needed  */}
      {openDialog && (
        <Dialog open={openDialog} maxWidth="lg">
          <DialogContent style={{ margin: 8 }}>
            <BookingClient closeDialog={closeDialog} service={serviceItem} storeInfo={storeInfo} />
          </DialogContent>
        </Dialog>
      )}
      {/* TODO - temporary ideall open login in nav bar - or has inline login after availability is selected
      Also if there are many services this popover is redundnat, may be able to move to parent.  
      - Move to parent on next pass (before login refactoring)
      */}
      {!isLoggedIn && <LoginPopover anchorEl={loginAnchorEl} closeProfileMenu={() => setLoginAnchorEl(null)} />}
    </>
  );
}
