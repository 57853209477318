import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  useContext,
  useCallback
} from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  TextField,
  Button,
  Paper,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import InputGroup from "../components/InputGroup";
import ImageUploader from "../components/ImageUploader";
import { DISCOUNT_TYPES, STATUS, SCREEN_STATES } from "../utils/constants";
import { isEqual } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../utils/api";
import LoadingError from "../components/LoadingError";
import { parseIdFromLink } from "../utils/helpers";
import PublishConfirmModal from "../components/PublishConfirmModal";
import StoreButton from "../components/StoreButton";
import ErrorDetails from "../components/ErrorDetails";
import PreviewContext from "../PreviewContext";
import moment from 'moment'; //SDAW-548 

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    width: "100%",
    maxWidth: theme.screen.maxWidth,
    display: "flex",
    flexDirection: "column",
  },
  formHeading: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: "bold",
  },
  formTitle: {
    marginBottom: theme.spacing(4),
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formButton: {
    margin: theme.spacing(1),
    width: 100,
  },
  overviewInput: {
    display: "flex",
  },
  nameInput: {
    flexBasis: "50%",
  },
  activePeriodInput: {
    flexBasis: "50%",
  },
  datePicker: {
    marginTop: "-1px",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  discountDetailsWrapper: {
    display: "flex",
  },
  discountType: {
    flexBasis: "20%",
  },
  discountAmount: {
    flexBasis: "20%",
  },
  buyOneGetOne: {
    flexBasis: "20%",
  },
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  publishSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(8),
  },
  formControl: {
    width: "100%",
  },
  formControlPublish: {
    width: 200,
  },
  spendMinInput: {
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  limitationsInput: {
    width: 400,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

const getDeal = async (id) => {
  return api
    .getDeal(id)
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const createDeal = async (data) => {
  const body = {
    appliesTo: data.appliesTo,
    buy: data.discountType === DISCOUNT_TYPES[2] ? data.buy : "",
    buyAtLeastQuantity: data.buyAtLeastQuantity,
    description: data.description,
    discount: {
      type: data.discountType,
      amount:
        data.discountType === DISCOUNT_TYPES[0]
          ? data.discountPercentAmount
          : data.discountType === DISCOUNT_TYPES[1]
            ? data.discountDollarAmount
            : "",
    },
    file: data.file,
    endDate: data.endDate,
    get: data.discountType === DISCOUNT_TYPES[2] ? data.get : "",
    name: data.dealName,
    published: data.publish,
    spendAtLeast: data.spendAtLeast,
    startDate: data.startDate,
    buyAtLeastGrams: data.buyAtLeastGrams,
  };

  return api
    .createDeal(data.storeId, body)
    .then(() => STATUS.SUCCESS)
    .catch((error) => {
      throw error;
    });
};

const updateDeal = async (data) => {
  const body = {
    appliesTo: data.appliesTo,
    buy: data.discountType === DISCOUNT_TYPES[2] ? data.buy : "",
    buyAtLeastQuantity: data.buyAtLeastQuantity,
    description: data.description,
    discount: {
      type: data.discountType,
      amount:
        data.discountType === DISCOUNT_TYPES[0]
          ? data.discountPercentAmount
          : data.discountType === DISCOUNT_TYPES[1]
            ? data.discountDollarAmount
            : "",
    },
    endDate: data.endDate,
    file: data.file,
    get: data.discountType === DISCOUNT_TYPES[2] ? data.get : "",
    name: data.dealName,
    published: data.publish,
    spendAtLeast: data.spendAtLeast,
    startDate: data.startDate,
    buyAtLeastGrams: data.buyAtLeastGrams,
  };

  return api
    .updateDeal(data.id, body)
    .then(() => STATUS.SUCCESS)
    .catch((error) => {
      throw error;
    });
};

const uploadImage = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  return api
    .uploadImage(formData)
    .then((res) => res._links.self.href)
    .catch((error) => {
      throw error;
    });
};

function AddEditDealView(props) {
  const classes = useStyles();

  const { adminMode, editMode, history } = props;

  const [dealType, setDealType] = useState("");  //SDAW-548
  const [conflictingDeal, setConflictingDeal] = useState(""); //Sdaw-548
  const [submitted, setSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [dataReady, setDataReady] = useState(false);
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState("");
  const [publishConfirm, setPublishConfirm] = useState(false);
  const [imageReady, setImageReady] = useState(false);

  const [APIError, setAPIError] = useState("");

  const preview = useContext(PreviewContext);

  const handleStartDateChange = (date) => {
    if (!date) {
      return handleFormChange(formFields.startDate, { value: null });
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    handleFormChange(formFields.startDate, { value: date });
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      return handleFormChange(formFields.endDate, { value: null });
    }
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);
    handleFormChange(formFields.endDate, { value: date });
  };


  const title =
    (editMode ? "Edit" : "Create") + " Deal" + (editMode ? " Details" : "");

  let initial = useRef({});

  const formFields = {
    publish: "publish",
    dealName: "dealName",
    description: "description",
    image: "image",
    file: "file",
    startDate: "startDate",
    endDate: "endDate",
    discountType: "discountType",
    discountDollarAmount: "discountDollarAmount",
    discountPercentAmount: "discountPercentAmount",
    appliesTo: "appliesTo",
    spendAtLeast: "spendAtLeast",
    buyAtLeastGrams: "buyAtLeastGrams",
    buyAtLeastQuantity: "buyAtLeastQuantity",
    buy: "buy",
    get: "get",
  };

  const [formState, setFormState] = useState({});

  const storeId = !preview.state.screen
    ? props.match.params.id
    : preview.state.storeId;
  const dealId = !preview.state.screen
    ? props.match.params.dealId
    : preview.state.dealId;

  //==SDAW-548 Limit Active Deals ===========================================================
  /* 
  dealType State is updated based on the dates and existing deals
  Messaging will change based on the type
  validateSubmission will NOT allow invalid or conflict
  existingDeals is passed to this page as a location prop
   */
  const { existingDeals, activeDealLimit } = props.location.state;

  const overlapConflict = useCallback((allDeals, currForm) => {
    /* 
    Given all existing deals and the new deal form (proposed deal dates)
    Function will see if the new deal conflicts (breaches active deal limit) any of the deal days
    Method: iterate deal calendar dates, checking existing active and upcoming deals.  
    Rtns as soon as it breaches the limit. 
    */
    const dateInRange = (range, dateToCheck) => {
      /* 
      Helper
      Func checks if a given date falls within a range (object)
      Strips down dates only the compares those dates in UTC
       */
      if (range.startDate && range.endDate) {
        const startDateZ = new Date(range.startDate.slice(0, 10));  //Slice strips out date only (No timezone)
        const endDateZ = new Date(range.endDate.slice(0, 10));
        let dateToCheckZ = `${dateToCheck.getFullYear()}-${(dateToCheck.getMonth() + 1).toString().padStart(2, "0")}-${dateToCheck.getDate()}`
        dateToCheckZ = new Date(dateToCheckZ.toString());
        if (dateToCheckZ >= startDateZ && dateToCheckZ <= endDateZ) {
          return true;
        };
      };
      return false;
    };

    const newDealStartDay = currForm.startDate;
    const newDealEndDay = currForm.endDate;
    let newDealDays = Math.round(moment.duration(newDealEndDay - newDealStartDay).asDays()); //MUST be let for moment

    for (let saleDay = 0; saleDay < newDealDays; saleDay++) { //Iterate Sales days
      let overlapForDay = {};
      const saleDateToCheck = new Date(newDealStartDay)
      saleDateToCheck.setDate(saleDateToCheck.getDate() + saleDay);

      allDeals.forEach((exDeal) => {
        if (exDeal.status === 'Active' || exDeal.status === 'Upcoming') {
          const exDealRange = {
            startDate: exDeal.startDate,
            endDate: exDeal.endDate
          };
          const exDealID = parseIdFromLink(exDeal._links.self.href, 0, "{?projection}");
          if (exDealID !== dealId) { // If editing won't count itself
            if (dateInRange(exDealRange, saleDateToCheck)) {
              overlapForDay[exDealID] = exDeal // Add UNIQUE deals to the count
            }
          }
        };
      });
      if (activeDealLimit - Object.keys(overlapForDay).length < 1) {
        return saleDateToCheck.toString();  //Returns first day over limit
      }
    };
    return null; //Doesn't breach limit, No Overlap
  }, [activeDealLimit, dealId]);

  const dealMessage = (type) => {
    switch (type) {
      case "Active":
        return "Status when saved: Active";
      case "Upcoming":
        return "Status when saved: Upcoming";
      case "Expired":
        return "Status when saved: EXPIRED (can be edited later)";
      case "Invalid":
        return "Start Dates must be on or before the end date";
      case "Conflict":
        return `You already have ${activeDealLimit} deals on ${conflictingDeal && conflictingDeal.slice(0, 15)}.`;
      default: return "";
    }
  };

  const statusStyling = (status) => {
    //Assigns button colour based on dealType
    const red = { color: "#ed685f", border: "2px solid #ed685f" };
    const orange = { color: "#ff9500", border: "2px solid #ff9500" };
    const green = { color: "#599e57", border: "2px solid #599e57" };
    switch (status) {
      case "Active":
        return green;
      case "Upcoming":
      case "Expired":
        return orange;
      default:
        return red;
    }
  };

  useEffect(() => {
    const checkDates = (startDate, endDate) => {
      /* Checks the input dates and assigns a deal Type (State) */
      const today = new Date()
      setConflictingDeal("")
      if (endDate < startDate && endDate && startDate) {
        return "Invalid";
      } else if (endDate && endDate < today) {
        return "Expired";
      } else if (startDate > today && endDate > today) {
        setConflictingDeal(overlapConflict(existingDeals, formState))
        if (conflictingDeal) {
          return "Conflict"
        } else {
          return "Upcoming";
        }
      } else if (startDate && startDate <= today && endDate && endDate >= today) {
        setConflictingDeal(overlapConflict(existingDeals, formState))
        if (conflictingDeal) {
          return "Conflict"
        } else {
          return "Active";
        }
      } else {
        return ""
      }
    };
    setDealType(checkDates(formState.startDate, formState.endDate));
  }, [formState.startDate, formState.endDate, existingDeals, formState, conflictingDeal, overlapConflict]);

  //===========================================================================================

  useEffect(() => {
    if (editMode) {
      getDeal(dealId)
        .then((data) => {
          initial.current = {
            id: parseIdFromLink(data._links.self.href),
            storeId: storeId,
            dealName: data ? data.name : "",
            description: data ? data.description : "",
            publish: data ? data.published : false,
            image: data._embedded.file
              ? [{ preview: data._embedded.file.url }]
              : [],
            file: data._embedded.file
              ? data._embedded.file._links.self.href
              : "",
            startDate: data ? new Date(data.startDate) : null,
            endDate: data ? new Date(data.endDate) : null,
            discountType: data ? data.discount.type : "",
            discountDollarAmount:
              data && data.discount.type === DISCOUNT_TYPES[1]
                ? data.discount.amount
                : "",
            discountPercentAmount:
              data && data.discount.type === DISCOUNT_TYPES[0]
                ? data.discount.amount
                : "",
            buy:
              data && data.discount.type === DISCOUNT_TYPES[2] ? data.buy : "",
            get:
              data && data.discount.type === DISCOUNT_TYPES[2] ? data.get : "",
            appliesTo: data ? data.appliesTo : "",
            spendAtLeast:
              data && ![0, undefined].includes(data.spendAtLeast)
                ? data.spendAtLeast
                : "",
            buyAtLeastGrams:
              data && ![0, undefined].includes(data.buyAtLeastGrams)
                ? data.buyAtLeastGrams
                : "",
            buyAtLeastQuantity:
              data && ![0, undefined].includes(data.buyAtLeastQuantity)
                ? data.buyAtLeastQuantity
                : "",
          };

          setFormState(initial.current);

          setDataReady(true);
        })
        .catch(() => setLoadingError(true));
    } else {
      setFormState({
        storeId: storeId,
        dealName: "",
        description: "",
        publish: false,
        image: [],
        file: "",
        startDate: null,
        endDate: null,
        discountType: "",
        discountDollarAmount: "",
        discountPercentAmount: "",
        buy: "",
        get: "",
        appliesTo: "",
        spendAtLeast: "",
        buyAtLeastGrams: "",
        buyAtLeastQuantity: "",
      });
      setDataReady(true);
    }
  }, [dealId, storeId, editMode]);

  const handleFormChange = (type, payload) => {
    switch (type) {
      default:
        setFormState({
          ...formState,
          [`${type}`]: payload.value,
        });
    }
  };

  const validate = (field) => {
    switch (field) {
      // case formFields.discountType:
      case formFields.dealName:
      case formFields.description:
      // case formFields.appliesTo:
        if (formState[`${field}`] === "" || formState[`${field}`] === null) {
          return false;
        } else {
          return true;
        }
      case formFields.startDate:
      case formFields.endDate:
        if (formState[`${field}`] === "" || formState[`${field}`] === null) {
          return false;
        } else {
          if (!formState[field].valueOf() || formState[field].toISOString().length !== 24) { //24 = full ISO Date time string
            return false;
          } else {
            return true;
          }
        }
      // case formFields.buy:
      // case formFields.get:
      //   if (formState.discountType === DISCOUNT_TYPES[2])
      //     return formState[`${field}`];
      //   else return true;

      case formFields.image:
        return formState.image.length > 0;

      // case formFields.discountPercentAmount:
      //   return (
      //     formState.discountType !== DISCOUNT_TYPES[0] ||
      //     (formState.discountType === DISCOUNT_TYPES[0] &&
      //       formState.discountPercentAmount !== "" &&
      //       formState.discountPercentAmount <= 100)
      //   );

      // case formFields.discountDollarAmount:
      //   return (
      //     formState.discountType !== DISCOUNT_TYPES[1] ||
      //     (formState.discountType === DISCOUNT_TYPES[1] &&
      //       formState.discountDollarAmount !== "")
      //   );

      case formFields.buyAtLeastGrams:
        if (formState.buyAtLeastGrams) {
          return (
            formState.spendAtLeast === "" && formState.buyAtLeastQuantity === ""
          );
        } else return true;

      case formFields.spendAtLeast:
        if (formState.spendAtLeast) {
          return (
            formState.buyAtLeastGrams === "" &&
            formState.buyAtLeastQuantity === ""
          );
        } else return true;

      case formFields.buyAtLeastQuantity:
        if (formState.buyAtLeastQuantity) {
          return (
            formState.spendAtLeast === "" && formState.buyAtLeastGrams === ""
          );
        } else return true;

      default:
        return true;
    }
  };

  const validateSubmission = () => {
    setSubmitted(true);
    let valid = true;

    //SDAW-584 
    if (dealType === "Invalid" || dealType === "Conflict") {
      setFormError(true);
      return false;
    }
    //=================================================

    Object.keys(formState).forEach((field) => {
      if (!validate(field)) {
        setFormError(true);
        valid = false;
      }
    });

    return valid;
  };

  const closeErrorDialog = () => {
    setFormError(false);
  };

  useEffect(() => {
    if (imageReady) {
      if (editMode) {
        updateDeal(formState)
          .then((status) => {
            setSaving(false);
            setStatus(status);
          })
          .catch((error) => {
            setImageReady(false);
            setSaving(false);
            setAPIError(error ? error : {});
            setStatus(STATUS.ERROR);
          });
      } else {
        createDeal(formState)
          .then((status) => {
            setSaving(false);
            setStatus(status);
          })
          .catch((error) => {
            setImageReady(false);
            setSaving(false);
            setAPIError(error ? error : {});
            setStatus(STATUS.ERROR);
          });
      }
    }
  }, [formState, editMode, imageReady]);

  //sdaw-548 - StartDate can be +/- 45 Days from Today
  const maxStartDate = new Date();
  maxStartDate.setDate(maxStartDate.getDate() + 45);
  const minStartDate = new Date();
  minStartDate.setDate(minStartDate.getDate() - 45);

  return (
    <div className={classes.root}>
      {!dataReady && !loadingError && <Typography>Loading...</Typography>}
      {loadingError && (
        <LoadingError
          onClick={() =>
            history.push(
              `/ ${adminMode ? "admin/stores" : "client/stores"
              } / ${storeId} / deals`
            )
          }
          browse="of My Store Deals"
        ></LoadingError>
      )}
      {dataReady && (
        <div className={classes.contentContainer}>
          <div className={classes.formHeading}>
            <Typography variant="h4" className={classes.title}>
              {title}
            </Typography>
            {!preview.state.screen && (
              <StoreButton
                label="Back to "
                id={storeId}
                path={`/${adminMode ? `admin/stores/` : `client/stores/`}${storeId}/deals`}
              ></StoreButton >
            )
            }
          </div >
          <Paper elevation={3}>
            <form className={classes.form}>
              <Typography variant="h6" className={classes.formTitle}>
                Deal Information
              </Typography>
              {/* <div className={classes.overviewInput}> */}
              {/* <div className={classes.nameInput}> */}
              <InputGroup title="Overview">
                <TextField
                  label="Deal Name"
                  variant="filled"
                  error={submitted && !validate(formFields.dealName)}
                  fullWidth
                  value={formState.dealName}
                  onChange={(event) =>
                    handleFormChange(formFields.dealName, {
                      value: event.target.value.slice(0, 200),
                    })
                  }
                />
              </InputGroup>
              {/* </div> */}
              {/* <div className={classes.activePeriodInput}> */}
              <InputGroup title="Active Period">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={submitted && !validate(formFields.startDate)}
                    className={classes.datePicker}
                    minDate={minStartDate}
                    maxDate={maxStartDate} //sdaw-548 
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Start Date"
                    value={formState.startDate}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change start date",
                    }}
                  />
                  <KeyboardDatePicker
                    error={submitted && !validate(formFields.endDate)}
                    className={classes.datePicker}
                    minDate={formState.startDate || Date()} //sdaw548
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="End Date"
                    value={formState.endDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change end date",
                    }}
                  />
                  {/* sdaw 548 > Message appear based on type */}
                  {dealType ?
                    <Button
                      size="small"
                      className={classes.storeButton}
                      variant="outlined"
                      disabled={true}
                      style={statusStyling(dealType)}
                    >
                      {dealMessage(dealType)} {/* SDAW-584 */}
                    </Button>
                    : null
                  }
                </MuiPickersUtilsProvider>
              </InputGroup>
              {/* </div> */}
              {/* </div> */}
              <InputGroup noTitle>
                <TextField
                  label="Description"
                  variant="filled"
                  fullWidth
                  multiline
                  rows="10"
                  error={submitted && !validate(formFields.description)}
                  value={formState.description}
                  onChange={(event) =>
                    handleFormChange(formFields.description, {
                      value: event.target.value.slice(0, 5000),
                    })
                  }
                />
              </InputGroup>
              <InputGroup title="Image">
                <ImageUploader
                  files={formState.image}
                  setFiles={(value) =>
                    handleFormChange(formFields.image, {
                      value,
                    })
                  }
                  error={submitted && !validate(formFields.image)}
                ></ImageUploader>
              </InputGroup>

              {/* <div className={classes.discountDetailsWrapper}> */}
              {/* <div className={classes.discountType}> */}

              {/* Temporarily Removing Discount Details Type and Appies To and Limitations*/}
              {/* <InputGroup title="Discount Details">
                <FormControl
                  variant="filled"
                  className={classes.formControl}
                  error={submitted && !validate(formFields.discountType)}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={formState.discountType}
                    onChange={(event) => {
                      handleFormChange(formFields.discountType, {
                        value: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value={""}>N/A</MenuItem>
                    {DISCOUNT_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputGroup>

              {formState.discountType === DISCOUNT_TYPES[0] && (
                <div className={classes.discountAmount}>
                  <InputGroup title="Percent (%) Off">
                    <TextField
                      label="Percent Off Amount"
                      variant="filled"
                      error={
                        (submitted &&
                          !validate(formFields.discountPercentAmount)) ||
                        (formState.discountType === DISCOUNT_TYPES[0] &&
                          formState.discountPercentAmount > 100)
                      }
                      fullWidth
                      value={formState.discountPercentAmount}
                      onChange={(event) => {
                        let value = event.target.value
                          .replace(/[^0-9.]/gi, "")
                          .split(".");
                        value[1] = value[1] ? value[1].slice(0, 2) : undefined;
                        value =
                          value[0] +
                          (event.target.value.includes(".") ? "." : "") +
                          (value[1] ? value[1] : "");
                        handleFormChange(formFields.discountPercentAmount, {
                          value,
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </InputGroup>
                </div>
              )}
              {formState.discountType === DISCOUNT_TYPES[1] && (
                <div className={classes.discountAmount}>
                  <InputGroup title="Amount ($) Off">
                    <TextField
                      label="Dollar Off Amount"
                      variant="filled"
                      error={
                        submitted && !validate(formFields.discountDollarAmount)
                      }
                      fullWidth
                      value={formState.discountDollarAmount}
                      onChange={(event) => {
                        let value = event.target.value
                          .replace(/[^0-9.]/gi, "")
                          .split(".");
                        value[1] = value[1] ? value[1].slice(0, 2) : undefined;
                        value =
                          value[0] +
                          (event.target.value.includes(".") ? "." : "") +
                          (value[1] ? value[1] : "");
                        handleFormChange(formFields.discountDollarAmount, {
                          value,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </InputGroup>
                </div>
              )}
              {formState.discountType === DISCOUNT_TYPES[2] && (
                <div className={classes.buyOneGetOne}>
                  <InputGroup title="Buy X Get Y Free">
                    <TextField
                      label="Buy"
                      variant="filled"
                      error={submitted && !validate(formFields.buy)}
                      fullWidth
                      value={formState.buy}
                      onChange={(event) =>
                        handleFormChange(formFields.buy, {
                          value: event.target.value.replace(/[^0-9]/gi, ""),
                        })
                      }
                    />
                    <TextField
                      label="Get"
                      variant="filled"
                      error={submitted && !validate(formFields.get)}
                      fullWidth
                      value={formState.get}
                      onChange={(event) =>
                        handleFormChange(formFields.get, {
                          value: event.target.value.replace(/[^0-9]/gi, ""),
                        })
                      }
                    />
                  </InputGroup>
                </div>
              )}
              {formState.discountType === DISCOUNT_TYPES[0] &&
                formState.discountPercentAmount > 100 && (
                  <InputGroup title="">
                    <Typography variant="body2" className={classes.error}>
                      Please ensure the discount amount is at or under 100%
                    </Typography>
                  </InputGroup>
                )}

              <InputGroup noTitle>
                <TextField
                  error={submitted && !validate(formFields.appliesTo)}
                  label="Applies To"
                  variant="filled"
                  fullWidth
                  value={formState.appliesTo}
                  onChange={(event) =>
                    handleFormChange(formFields.appliesTo, {
                      value: event.target.value.slice(0, 200),
                    })
                  }
                />
              </InputGroup>
              <div className={classes.spendMinInput}>
                <InputGroup title="Limitations (Optional)">
                  <TextField
                    label="Spend Minimum"
                    variant="filled"
                    error={submitted && !validate(formFields.spendAtLeast)}
                    fullWidth
                    value={formState.spendAtLeast}
                    onChange={(event) =>
                      handleFormChange(formFields.spendAtLeast, {
                        value: event.target.value.replace(/[^0-9]/gi, ""),
                      })
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </InputGroup>
              </div>
              <div className={classes.limitationsInput}>
                <InputGroup noTitle>
                  <TextField
                    label="Purchase Minimum (Weight)"
                    variant="filled"
                    error={submitted && !validate(formFields.buyAtLeastGrams)}
                    fullWidth
                    value={formState.buyAtLeastGrams}
                    onChange={(event) => {
                      let value = event.target.value
                        .replace(/[^0-9.]/gi, "")
                        .split(".");
                      value[1] = value[1] ? value[1].slice(0, 1) : undefined;
                      value =
                        value[0] +
                        (event.target.value.includes(".") ? "." : "") +
                        (value[1] ? value[1] : "");
                      handleFormChange(formFields.buyAtLeastGrams, {
                        value,
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">grams</InputAdornment>
                      ),
                    }}
                  />
                </InputGroup>
              </div>
              <div className={classes.limitationsInput}>
                <InputGroup noTitle>
                  <TextField
                    label="Purchase Minimum (Quantitity)"
                    variant="filled"
                    error={
                      submitted && !validate(formFields.buyAtLeastQuantity)
                    }
                    fullWidth
                    value={formState.buyAtLeastQuantity}
                    onChange={(event) =>
                      handleFormChange(formFields.buyAtLeastQuantity, {
                        value: event.target.value.replace(/[^0-9]/gi, ""),
                      })
                    }
                  />
                </InputGroup>
              </div>
              {(!validate(formFields.buyAtLeastQuantity) ||
                !validate(formFields.buyAtLeastGrams) ||
                !validate(formFields.spendAtLeast)) && (
                  <Typography variant="body2" className={classes.error}>
                    Please select only one type of limitation for this deal
                  </Typography>
                )}
                 */}
              {/* END of temporarily removed fields. */}

              <Divider className={classes.divider} />
              <div className={classes.formActions}>
                {!preview.state.screen && (
                  <Button
                    className={classes.formButton}
                    variant="contained"
                    component={Link}
                    to={`/${adminMode ? "admin/stores" : "client/stores"
                      }/${storeId}/deals`}
                    style={{ color: "#000000", textDecoration: "none" }}
                  >
                    Cancel
                  </Button>
                )}
                {preview.state.screen && (
                  <Button
                    variant="contained"
                    className={classes.formButton}
                    onClick={() =>
                      preview.changeScreen(
                        SCREEN_STATES.DEAL_DETAILS,
                        preview.state.dealId
                      )
                    }
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (validateSubmission()) {
                      setSaving(true);
                      if (!editMode) {
                        uploadImage(formState.image[0])
                          .then((url) => {
                            handleFormChange(formFields.file, { value: url });
                            setImageReady(true);
                          })
                          .catch((error) => {
                            setSaving(false);
                            setAPIError(error ? error : {});
                            setStatus(STATUS.ERROR);
                          });
                      } else if (
                        initial.current.image[0] &&
                        initial.current.image[0].preview !==
                        formState.image[0].preview
                      ) {
                        uploadImage(formState.image[0])
                          .then((url) => {
                            handleFormChange(formFields.file, {
                              value: url,
                            });
                            setImageReady(true);
                          })
                          .catch((error) => {
                            setSaving(false);
                            setAPIError(error ? error : {});
                            setStatus(STATUS.ERROR);
                          });
                      } else {
                        setImageReady(true);
                      }
                    }
                  }}
                  disabled={editMode && isEqual(initial.current, formState)}
                >
                  {saving && <CircularProgress size={24} color="inherit" />}
                  {!saving && "Save"}
                </Button>
              </div>
            </form>
          </Paper>
          <Dialog open={formError} onClose={closeErrorDialog}>
            <DialogTitle>{"Missing or Incorrect Data"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please ensure all required fields are filled correctly
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeErrorDialog} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div >
      )}
      <Modal
        className={classes.modal}
        open={status !== ""}
        onClose={() => {
          setStatus("");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={status !== ""}>
          <div className={classes.modalPaper}>
            {status === STATUS.SUCCESS && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  Successfully saved!
                </Typography>
                {!preview.state.screen && (
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/${adminMode ? "admin/stores" : "client/stores"
                      }/${storeId}/deals`}
                    style={{ color: "#FFFFFF", textDecoration: "inherit" }}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                )}
                {preview.state.screen && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.statusAcceptButton}
                    onClick={() =>
                      preview.changeScreen(
                        SCREEN_STATES.DEAL_DETAILS,
                        preview.state.dealId
                      )
                    }
                  >
                    Ok
                  </Button>
                )}
              </Fragment>
            )}
            {status === STATUS.ERROR && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  There was an issue while saving your data. Please try again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSaving(false);
                    setStatus("");
                  }}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
                <ErrorDetails error={APIError} data={formState}></ErrorDetails>
              </Fragment>
            )}
          </div>
        </Fade>
      </Modal>
      <PublishConfirmModal
        item="deal"
        status={publishConfirm}
        setStatus={(val) => setPublishConfirm(val)}
        current={formState.publish}
        changePublish={() =>
          handleFormChange(formFields.publish, {
            value: !formState.publish,
          })
        }
      ></PublishConfirmModal>
    </div >
  );
}

export default AddEditDealView;
