import React from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton, Link } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  footerRoot: {
    backgroundColor: theme.palette.common.background,
    padding: theme.spacing(2, 13, 2, 13),
    position: 'relative',
    color: theme.palette.common.font,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3, 1, 3),
    },
  },
  scrollTop: {
    position: 'absolute',
    bottom: 100,
    right: 200,
  },
  footer: {
    // width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // flexFlow: 'space-between',
  },

  infoContainer: {
    color: theme.palette.common.font,
    minWidth: '240px',
    maxWidth: '280px',
    flexGrow: 1,
    marginBottom: 0,
    '& h4': {
      textShadow: `none`,
    },
  },
  title: {
    // minHeight: '100px',
    color: theme.palette.common.font,
    '& h4': {
      color: theme.palette.common.font,
      fontSize: '16px !important',
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      '& h4': {
        fontSize: '16px !important',
        textDecoration: 'underline',
      },
    },
  },
  iconContainer: {
    display: 'flex',
    gap: '8px',
  },
  icon: {
    width: '35px',
    height: '35px',
    marginRight: theme.spacing(3),
  },
  mediaIcon: {
    color: theme.palette.common.font, // SVGs need to be wired to accept fill color
    padding: 0,
    borderRadius: 0,
  },
  growItem: {
    flexGrow: 10,
  },
  email: {
    position: 'relative',
    display: 'flex',
    height: '40px',
    border: '1px black solid',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(0),
    marginLeft: 0,
    marginTop: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto',
    },
  },
  emailIcon: {
    padding: theme.spacing(0, 2),
    color: theme.palette.common.black,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from emailIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  emailButton: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      fontSize: '0.75em',
      padding: theme.spacing(0.5, 1, 0.5, 1),
    },
  },
  links: {
    color: theme.palette.common.font,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.font,
      textDecoration: 'underline',
    },
  },
}));

function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footerRoot}>
      <div className={classes.footer}>
        <div className={classes.infoContainer}>
          <Link className={classes.links} href="/about-us">
            About Us
          </Link>
          <br />
          <Link className={classes.links} href="/privacy-policy">
            Term and Policy
          </Link>
        </div>
        <div className={classes.infoContainer}>
          <Link className={classes.links} href="mailto: info@beautimap.com">
            Email Us
          </Link>
        </div>
        <div className={`${classes.infoContainer}`}>
          <Typography variant="body1" style={{ fontSize: 14 }}>
            @beautimap
          </Typography>
          <div className={classes.iconContainer}>
            <IconButton href="mailto: info@beautimap.com" className={classes.mediaIcon}>
              <EmailIcon color="primary" fontSize="large" style={{ width: 30, height: 30 }} />
            </IconButton>
            <IconButton href="https://www.instagram.com/beauti.map/" target="_blank" className={classes.mediaIcon}>
              <img src={require(`../assets/logos/iconInstagramFooter.svg`)} alt={`instagram-main-logo`} height="30" />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/profile.php?id=61554303221248"
              target="_blank"
              className={classes.mediaIcon}
            >
              <img src={require(`../assets/logos/iconFacebookFooter.svg`)} alt={`facebook-main-logo`} height="30" />
            </IconButton>
          </div>
        </div>
      </div>
      <div>
        <Typography variant="body1" style={{ fontSize: 12 }}>
          Copyright © 2021 Beautimap. All rights reserved.
        </Typography>
      </div>
    </footer>
  );
}

export default Footer;
