import React from "react";
import { makeStyles } from "@material-ui/styles";
import PrivacyPolicyContent from "../components/PrivacyPolicyContent";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(40),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerContainer: {
    width: "100%",
    maxWidth: theme.screen.maxWidth,
  },
}));

function TermsConditonsView(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Beautimap | Privacy Policy</title>
        <meta
          name="description"
          content="Beautimap Privacy Policy"
        />
      </Helmet>
      <div className={classes.innerContainer}>
        <PrivacyPolicyContent></PrivacyPolicyContent>
      </div>
    </div>
  );
}

export default TermsConditonsView;
