import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import cookies from '../cookies';
import { ROLES } from '../utils/constants';
import api from '../utils/api';
import { parseIdFromLink } from '../utils/helpers';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.background,
  },
  text: {
    color: theme.palette.common.font,
  },
}));

function ProfilePopover(props) {
  const { anchorEl, closeProfileMenu, location } = props;

  const role = cookies.getRole();
  const history = useHistory();
  const classes = useStyles();
  const storeId = useRef({});
  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;

  // When logout, remove all cookies and redirect to homepage
  const handleLogout = () => {
    cookies.removeAll();
    closeProfileMenu();
    /* If URL includes 'user-profile', '/client/', '/admin/' redirect to / */
    const path = window.location.pathname;
    if (path.includes('user-profile') || path.includes('client') || path.includes('admin')) {
      history.push('/');
    }
    window.location.reload();
  };

  useEffect(() => {
    api.getUser(cookies.getUserID()).then((res) => {
      if (res._embedded.stores) {
        storeId.current.id = parseIdFromLink(res._embedded.stores[0]._links.self.href, 0, '{?projection}');
        // storeId.preview = `/clientpreview/stores/${storeId.current}`
        storeId.current = {
          ...storeId.current,
          preview: `/client/preview/stores/${storeId.current.id}`,
          appointments: `/client/stores/${storeId.current.id}/appointments`,
        };
      } else {
        storeId.current = { ...storeId.current, preview: '/client/new-store' };
      }
    });
  }, [storeId]);

  const storeExists = storeId.current.preview !== '/client/new-store';

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={closeProfileMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <List component="nav" aria-label="admin-nav-panel" className={classes.root}>
        {location && (
          <>
            <ListItem button component={Link} href="/admin/user-profile">
              <ListItemText primary="Profile" className={classes.text} />
            </ListItem>
            <ListItem button component={Link} href="/admin">
              <ListItemText primary="Admin Portal" className={classes.text} />
            </ListItem>
            <ListItem button component={Link} href="/">
              <ListItemText primary="User Portal" className={classes.text} />
            </ListItem>
          </>
        )}
        {/**Render options based on role */}
        {role === 'ROLE_SUPER_ADMIN' && (
          <>
            <ListItem button component={Link} href="/admin/user-profile">
              <ListItemText primary="Your Profile" className={classes.text} />
            </ListItem>
            <ListItem button component={Link} href="/admin">
              <ListItemText primary="Admin Portal" className={classes.text} />
            </ListItem>
            <ListItem button component={Link} href="/">
              <ListItemText primary="User Portal" className={classes.text} />
            </ListItem>
          </>
        )}
        {role === ROLES.ROLE_STORE_ADMIN && (
          <>
            <ListItem button component={Link} href="/client/user-profile">
              <ListItemText primary="Profile" className={classes.text} />
            </ListItem>
            {storeExists && (
              <ListItem button component={Link} href={storeId.current.appointments}>
                <ListItemText primary="My Appointments" className={classes.text} />
              </ListItem>
            )}
            <ListItem button component={Link} href={storeId.current.preview}>
              <ListItemText primary={storeExists ? 'My Business' : 'Create your business'} className={classes.text} />
            </ListItem>
          </>
        )}
        {role === ROLES.ROLE_USER && (
          <ListItem button component={Link} href="/user-profile">
            <ListItemText primary="Profile" className={classes.text} />
          </ListItem>
        )}
        <Divider />
        <ListItem button component={Link} href="/maps">
          <ListItemText primary="Maps" className={classes.text} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemText primary="Logout" className={classes.text} />
        </ListItem>
      </List>
    </Popover>
  );
}

export default ProfilePopover;
