import React, { useCallback, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful";
import useClickOutside from "./useClickOutside";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	picker: {
		position: "relative",
	},

	swatch: {
		width: "28px",
		height: "28px",
		borderRadius: "8px",
		border: "3px solid #fff",
		boxShadow:
			"0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)",
		cursor: "pointer",
	},

	popover: {
		position: "absolute",
		top: "calc(100% + 2px)",
		right: "25px",
		borderRadius: "9px",
		boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
		[theme.breakpoints.down("sm")]: {
			left: 0,
		},
	},
}));

export const PopoverPicker = ({ color, onChange }) => {
	const popover = useRef();
	const [isOpen, toggle] = useState(false);
	const classes = useStyles();
	const close = useCallback(() => toggle(false), []);
	useClickOutside(popover, close);

	return (
		<div className={classes.picker}>
			<div
				className={classes.swatch}
				style={{ backgroundColor: color }}
				onClick={() => toggle(true)}
			/>

			{isOpen && (
				<div className={classes.popover} ref={popover}>
					<HexColorPicker color={color} onChange={onChange} />
				</div>
			)}
		</div>
	);
};
