import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {},
	content: {
		margin: theme.spacing(2),
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	contentInnerContainer: {
		width: "100%",
		maxWidth: theme.screen.maxWidth,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	title: {
		fontWeight: "bold",
		marginBottom: theme.spacing(2),
	},
	mainButton: {
		width: 300,
		margin: theme.spacing(2),
	},
}));

function AdminHomeView(props) {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<div className={classes.contentInnerContainer}>
					<Typography variant="h4" className={classes.title}>
						Admin Portal
					</Typography>
					<Button
						variant="contained"
						color="primary"
						className={classes.mainButton}
						component={Link}
						to={`/admin/users`}
						style={{ color: "#ffffff", textDecoration: "none" }}
					>
						Manage Users
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.mainButton}
						component={Link}
						to={`/admin/featured-popular`}
						style={{ color: "#ffffff", textDecoration: "none" }}
					>
						Featured and Popular Items
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.mainButton}
						component={Link}
						to={`/admin/stores`}
						style={{ color: "#ffffff", textDecoration: "none" }}
					>
						Manage Stores
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.mainButton}
						component={Link}
						to={`/admin/banners`}
						style={{ color: "#ffffff", textDecoration: "none" }}
					>
						Manage Banners
					</Button>
					<Button
						variant="contained"
						color="primary"
						className={classes.mainButton}
						component={Link}
						to={`/admin/listing-order`}
						style={{ color: "#ffffff", textDecoration: "none" }}
					>
						Manage Listing Order
					</Button>
					<Button
            variant="contained"
            color="primary"
            className={classes.mainButton}
            component={Link}
            to={`/admin/featured-deals`}
            style={{ color: "#ffffff", textDecoration: "none" }}
          >
            Manage Deals
          </Button>
				</div>
			</div>
		</div>
	);
}

export default AdminHomeView;
