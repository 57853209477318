import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import DayCalendar from '../DayCalendar/Index';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  popoverButton: {
    midWidth: 360,
    // flexGrow: 1,
    height: 56,
    color: theme.palette.common.font,
  },
  popoverContainer: {
    '& .MuiPopover-paper': {
      width: 360,
      paddingLeft: 8,
    },
  },
}));

export default function ClientTimeSelectPopover({
  selectedDay,
  selectedApptDateTime,
  handleChangeApptTime,
  availableTimesForDay, //Start times of available appointments
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); //Opens the popover
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!!selectedApptDateTime) {
      setAnchorEl(null);
    }
  }, [selectedApptDateTime]);

  const localizedTime = selectedApptDateTime ? format(new Date(selectedApptDateTime), 'h:mm a') : null;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={!selectedDay}
        className={classes.popoverButton}
      >
        {selectedApptDateTime ? `${localizedTime}` : 'Pick a Time'}
      </Button>
      <Popover
        id={id}
        className={classes.popoverContainer}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DayCalendar
          clientMode={true}
          selectedDay={selectedDay}
          appointments={availableTimesForDay}
          daysToShow={1}
          onApptClick={handleChangeApptTime}
        />
      </Popover>
    </>
  );
}
