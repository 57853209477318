/* Shows a list of appointments for a given store
Calendar days are grouped together then appointments are listed in order of time within that day

TODO - hide past appointments
*/
import React from 'react';
import Day from './Day';
import AppointmentItem from './AppointmentItem';
import { Typography } from '@material-ui/core';
import { isAfter } from 'date-fns';
import format from 'date-fns/format';
// import Popover from '@material-ui/core/Popover';
// import ApptDetailsCard from './ApptDetailsCard';

// const useStyles = makeStyles((theme) => ({}));

export default function ApptItinerary({ appointments, onApptClick, selectedDay }) {
  // const classes = useStyles();

  /* Need to separate group appointments by startTime date 
 Desired output would be 
 groupedAppointments = {
    2023-12-05: [appt1, appt2],
    2023-12-06: [appt3, appt4],
  }
  */

  // Filter out appointments that are before the selected day
  const filteredAppointments = appointments?.filter((appointment) => {
    const apptDay = new Date(appointment.startDateTime);
    return isAfter(apptDay, new Date(selectedDay));
  });

  const groupedAppointments = filteredAppointments
    ?.sort((x, y) => x.startDateTime.localeCompare(y.startDateTime))
    .reduce((acc, appointment) => {
      const calendarDate = new Date(appointment.startDateTime).toISOString().slice(0, 10);
      if (!acc[calendarDate]) acc[calendarDate] = [];
      acc[calendarDate].push(appointment);
      return acc;
    }, {});

  const noAppointments = filteredAppointments?.length === 0;
  const noApptData = filteredAppointments === null || filteredAppointments === undefined;
  return (
    <div className="listView">
      {/* List of days with their respective appointments, If not appointments do not display the day */}
      {filteredAppointments?.length > 0 &&
        Object.keys(groupedAppointments).map((date) => (
          <Day date={date}>
            {groupedAppointments[date].map((appointment) => (
              <AppointmentItem appointment={appointment} openApptDetails={onApptClick} />
            ))}
          </Day>
        ))}
      {/* End of List indicator - show end date of fetch range */}
      {/* TODO: <Typography>Showing appointments until ___x (end of fetch Range)____</Typography> */}
      {noAppointments && (
        <Typography style={{ paddingTop: '16px' }}>{`No Appointments on or after ${format(
          selectedDay,
          'eee dd MMM yyyy'
        )}`}</Typography>
      )}
      {noApptData && <Typography>Error: appointment data is no present</Typography>}
    </div>
  );
}
