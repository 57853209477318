//React
import React, { useEffect, useState } from 'react';
import { PreviewProvider } from '../PreviewContext';

//Utils
// import api from "../utils/api";

//Constants
import { ROLES, SCREEN_STATES } from '../utils/constants';

//Views
import AddEditDealView from './AddEditDealView';

//Components
import PreviewActions from '../components/PreviewActions';

//Styles
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import cookies from '../cookies';
import { parseIdFromLink } from '../utils/helpers';
import api from '../utils/api';
import BusinessViewIndex from '../components/BusinessView/Index';

// const getStoresForUser = async (id) => {
//   return api
//     .getStoresForUser(id)
//     .then((res) => res)
//     .catch((err) => err);
// };

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerContainer: {
    position: 'relative',
    width: '100%',
    maxWidth: theme.screen.maxWidth,
  },
  previewActions: {
    position: 'sticky',
    top: 120,
    maxWidth: theme.screen.maxWidth,
    width: '100%',
    // width: "100vw",
    zIndex: 2,
    transition: 'top 1s ease-in-out',
  },
  hidden: {
    top: '-220px',
  },
  text: { margin: theme.spacing(2) },
  offset: {
    height: 170,
    [theme.breakpoints.down('sm')]: {
      height: 220,
    },
  },
}));

const getStoresForUser = async (id) => {
  return api
    .getStoresForUser(id)
    .then((res) => res)
    .catch((err) => err);
};

function PreviewView(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [owner, setOwner] = useState(false);

  const [published, setPublished] = useState(false); // eslint-disable-line

  const [storeInfo, setStoreInfo] = useState(null); //Store Info used to generate QR Code

  const [state, setState] = useState({
    /*storeId: props.match.params.id,*/
    query: {},
    screen: SCREEN_STATES.STORE_DETAILS,
  });

  //============== useEffct to hide on scroll
  const [show, setShow] = useState(true);
  useEffect(() => {
    let previousScrollPosition = 0;
    let currentScrollPosition = 0;

    window.addEventListener('scroll', function (e) {
      // Get the new Value
      currentScrollPosition = window.pageYOffset;
      const scrollDifference = previousScrollPosition - currentScrollPosition;
      //Subtract the two and conclude
      if (scrollDifference < 0) {
        setShow(false);
      } else if (scrollDifference > 0) {
        setShow(true);
      }
      // Update the previous value
      // Threshold is 50px to prevent sensitivity on scroll
      if (Math.abs(scrollDifference) > 50) previousScrollPosition = currentScrollPosition;
    });
    //Clean up the event listener when the component unmounts.
    return () => window.removeEventListener('scroll', function (e) {});
  }, []);

  //==================================================

  const changeScreen = (screen, payload) => {
    let query = {};

    switch (screen) {
      case SCREEN_STATES.STORE_DETAILS:
        query = payload ? payload : {};
        break;

      case SCREEN_STATES.EDIT_STORE:
        break;

      default:
        break;
    }

    setState({
      ...state,
      query,
      screen,
    });

    window.scroll({
      top: 0,
      left: 0,
    });
  };

  const storeId = props.match.params.storeId;
  useEffect(() => {
    getStoresForUser(cookies.getUserID())
      .then((res) => {
        const authorized = res.find((store) => parseIdFromLink(store._links.self.href) === storeId);
        setOwner(authorized !== undefined);
        if (authorized) {
          setPublished(authorized.published);
        } else if ([ROLES.ROLE_ADMIN, ROLES.ROLE_SUPER_ADMIN].includes(cookies.getRole())) {
          setOwner(true);
          setPublished(false);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [props.match.params.id]);

  return (
    <div className={classes.root}>
      <div className={classes.innerContainer}>
        {((!loading && owner) ||
          cookies.getRole === ROLES.ROLE_ADMIN ||
          cookies.getRole === ROLES.ROLE_SUPER_ADMIN) && (
          <PreviewProvider value={{ state, changeScreen }}>
            <div className={`${classes.previewActions} ${!show && classes.hidden}`}>
              <PreviewActions storeId={storeId} storeInfo={storeInfo} />
            </div>
            <Typography className={classes.text}>
              Below is a preview of your business. This is how site visitors will see your available products, deals and
              business details. Please review and ensure all information is accurate and up to date. If there are any
              changes you need to make, click <strong>Edit</strong> to edit the current item you are viewing. If all
              looks good, click <strong>Publish</strong> to get your business up and running on the Beautimap website.
              If you need to add new products or deals, or unpublish an already published store, head over to the{' '}
              <strong>Client Portal</strong>
            </Typography>
            {state.screen === SCREEN_STATES.STORE_DETAILS && (
              <>
                <div style={{ border: '2px dashed orange' }}>
                  <BusinessViewIndex previewMode={true} previewActions={{ setStoreInfo }} {...props} />
                </div>
              </>
            )}
            {state.screen === SCREEN_STATES.EDIT_DEAL && <AddEditDealView previewMode editMode {...props} />}
          </PreviewProvider>
        )}
        {!loading && !owner && !error && (
          <Typography align="center" style={{ marginTop: '50px' }}>
            You are not authorized to view this preview.
          </Typography>
        )}
        {loading && (
          <Typography align="center" style={{ marginTop: '50px' }}>
            Loading preview...
          </Typography>
        )}
        {!loading && error && (
          <Typography align="center" style={{ marginTop: '50px' }}>
            An error occurred while fetching data.
          </Typography>
        )}
      </div>
    </div>
  );
}

export default PreviewView;
