/**
 * Date time picker for appointment form
 * Passes start and end time up to parent via useEffect and setFormTime (state in parent)
 * All field values are managed in this component
 * Date times are passed as a string (YYYY-MM-DD HH:MM AA) to the parent
 *
 * @component
 * @param {Object} existingAppointment - The existing appointment object, can be null
 * @param {Function} setFormTime - The function to set the form time in the parent component
 * @returns {JSX.Element} The appointment time selectors component - date picker, start time picker, end time picker
 */

import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import lightFormat from 'date-fns/lightFormat';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  appointmentTimeSelectors: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    appointmentTimeSelectors: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
}));

const timesFifteenMin = [];
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 4; j++) {
    let hour = i;
    let minute = j * 15;
    if (hour === 0) hour = 12;
    if (hour > 12) hour -= 12;
    hour = hour.toString().padStart(2, '0');
    minute = minute.toString().padStart(2, '0');

    const timeValue = `${hour}:${minute}`;
    const timeTitle = `${hour}:${minute} ${i < 12 ? 'AM' : 'PM'}`;
    timesFifteenMin.push({ timeValue, title: timeTitle });
  }
}

export default function AppointmentTimeSelectors({ existingAppointment, setFormTime, viewSelectedDate, overlapError }) {
  const classes = useStyles();
  const initialStartDate = existingAppointment?.startDateTime
    ? new Date(existingAppointment?.startDateTime)
    : new Date(viewSelectedDate);
  const initialEndDate = existingAppointment?.endDateTime ? new Date(existingAppointment?.endDateTime) : new Date();
  const initialStartTime = existingAppointment && lightFormat(initialStartDate, 'hh:mm a');
  const initialEndTime = existingAppointment && lightFormat(initialEndDate, 'hh:mm a');
  /* ======================================================================================== */
  const [selectedDate, setSelectedDate] = useState(initialStartDate); //Used to set the date for the appointment (assumes only same day appointments)
  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  //useEffect to pass formTime up to parent (for submission)
  useEffect(() => {
    if (selectedDate.toString() === 'Invalid Date') return; //Allows date to be typed in
    const startDateTime = `${selectedDate.toISOString().slice(0, 10)} ${startTime}`;
    const endDateTime = `${selectedDate.toISOString().slice(0, 10)} ${endTime}`;
    setFormTime({ start: startDateTime, end: endDateTime });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, startTime, endTime, existingAppointment]);

  return (
    <div
      className={classes.appointmentTimeSelectors}
      // style={{
      //   display: 'flex',
      //   flexDirection: 'row',
      //   justifyContent: 'flex-start',
      //   alignItems: 'center',
      //   gap: '1rem',
      // }}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="appointmentDate"
          label="Appointment Date"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <Autocomplete
          id="start-time-picker"
          value={startTime}
          style={{ width: '165px' }}
          // freeSolo
          options={timesFifteenMin.map((option) => option.title)}
          renderInput={(params) => {
            return <TextField {...params} label="Start Time" margin="normal" variant="outlined" />;
          }}
          onChange={(event, newValue) => setStartTime(newValue)} //User selected a value
          // onInputChange={(event, newInputValue) => {}}  //User typed in a value
        />

        {/* TODO - figure out how to have text box @ 165px but drop down @ 300px  (look into `.MuiAutocomplete-popper` lives @ body or popperComponent for custom*/}
        <Autocomplete
          id="end-time-picker"
          value={endTime}
          style={{ width: '165px' }}
          // freeSolo
          options={timesFifteenMin
            .filter((option) => {
              //Only show times after the start time
              const diffMin = differenceInMinutes(
                new Date(`${selectedDate.toDateString()} ${startTime}`),
                new Date(`${selectedDate.toDateString()} ${option.title}`)
              );
              return diffMin < 0;
            })
            .map((option) => option.title)}
          renderOption={(option) => {
            const optionDuration = differenceInMinutes(
              new Date(`${selectedDate.toDateString()} ${option}`),
              new Date(`${selectedDate.toDateString()} ${startTime}`)
            );
            let durationMsg = `${optionDuration} min`;
            if (optionDuration === 60) durationMsg = `1 hour`;
            if (optionDuration > 60)
              durationMsg = `${Math.floor(optionDuration / 60)} hours ${optionDuration % 60} min`;
            return `${option} (${durationMsg})`;
          }}
          renderInput={(params) => <TextField {...params} label="End Time" margin="normal" variant="outlined" />}
          onChange={(event, newValue) => setEndTime(newValue)} //User selected a value
          // onInputChange={(event, newInputValue) => {}}  //User typed in a value
        />
      </MuiPickersUtilsProvider>

      {overlapError && <Alert severity="warning">You already have an appointment during this time</Alert>}
    </div>
  );
}
