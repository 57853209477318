import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import api from "../utils/api";
import PageviewIcon from "@material-ui/icons/Pageview";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  previewIcon: {
    marginLeft: theme.spacing(1),
  },
}));

function StoreButton(props) {
  const classes = useStyles();

  const { id, path, label, disabled, overrideLabel, brand } = props;

  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (id && !brand) {
      api
        .getStore(id)
        .then((res) => {
          setStoreName(res.data.name);
        })
        .catch((err) => {
          setStoreName("Store Name Error");
        });
    } else if (id && brand) {
      api
        .getBrand(id)
        .then((res) => {
          setStoreName(res.data.name);
        })
        .catch((err) => {
          setStoreName("Brand Name Error");
        });
    }
  }, [id, brand]);

  return (
    <Button
      disabled={disabled}
      className={classes.root}
      component={Link}
      to={`${path}`}
      style={{ color: "inherit", textDecoration: "inherit" }}
      variant={disabled ? "outlined" : "contained"}
    >
      {!overrideLabel && (
        <strong>
          {storeName === "" ? "Loading..." : `${label}${storeName}`}
        </strong>
      )}
      {overrideLabel && <strong>{label}</strong>}
      {!disabled && (
        <PageviewIcon className={classes.previewIcon}></PageviewIcon>
      )}
    </Button>
  );
}

export default StoreButton;
