/* Used to display individual business info, as well shown within the 'preview' when adding or editing.
This component will featch the data and drill it to the individual components. 
Eventually will add a hook for handling data.
- [ ] STRETCH - Accept data from props (when provided in a link prop) - check for changes and update accordingly.
*/
import React, { useEffect } from 'react';
import BusinessInfo from './BusinessInfo';
import TextSection from './TextSection';
import ServiceList from './ServiceList';
import ImageGrid from './ImageGrid';
import ReviewSection from './ReviewSection/Index';
import CallToActionAside from './CallToAction/Index';
import useStoreData from './hooks/useStoreData';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  businessView: {
    maxWidth: '1245px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      // maxWidth: '398px',
      maxWidth: '100vw',
      margin: '12px',
    },
  },
  businessBodyContainer: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    marginTop: '24px',
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      margin: 0,
      padding: '4px',
    },
  },
}));

export default function BusinessViewIndex({ categoryBeingViewed, ...props }) {
  const classes = useStyles();
  const storeId = props.match.params.storeId;

  const { storeData: businessData } = useStoreData(storeId);

  useEffect(() => {
    // If preview mode set Store Data for QR Code generator
    if (props.previewMode && businessData) {
      props.previewActions.setStoreInfo(businessData);
    }
    //eslint-disable-next-line
  }, [storeId, businessData]);

  const showAnnouncement = businessData?.announcementTitle || businessData?.announcement;

  const pageTitle = businessData
    ? `${businessData?.name} - ${businessData.address?.city} ${businessData.address?.province} | Beautimap`
    : null;

  const metaDescription = businessData ? businessData.aboutUs : pageTitle;

  return (
    <div className={classes.businessView}>
      {businessData && (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name={pageTitle} content={metaDescription} />
        </Helmet>
      )}
      <BusinessInfo data={businessData} />
      <div className={classes.businessBodyContainer}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <TextSection title={`About ${businessData?.name}`} body={businessData?.aboutUs} />
          {showAnnouncement && (
            <TextSection title={`${businessData?.announcementTitle}`} body={businessData?.announcement} />
          )}
          <ServiceList data={businessData} storeId={storeId} />
          <ImageGrid data={businessData} imageArray={businessData?._embedded?.imageFiles} />
          <ReviewSection storeId={storeId} />
        </div>

        <CallToActionAside data={businessData} />
      </div>
    </div>
  );
}
