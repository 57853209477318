import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: { display: "flex", flexDirection: "column" },
    headerElement: {
        fontWeight: "bold",
        margin: theme.spacing(2, 4, 2, 4),
        alignSelf: "center",
        textDecoration: "none",
        fontSize: "18pt"
    },
    subHeaderElement: {
        fontWeight: "bold",
        margin: theme.spacing(2, 4, 2, 4),
        textDecoration: "none"
    },
    element: {
        margin: theme.spacing(2, 4, 2, 4)
    },
    section: {
        margin: theme.spacing(2, 4, 2, 14)
    },
    bulletpoint: {
        margin: theme.spacing(2, 4, 2, 6)
    },
}));

function SignUpThankYouContent() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.headerElement}>
                Thank you for signing up!
            </Typography>

            <Typography className={classes.subHeaderElement}>
                Please check your inbox / spam for a verification link
            </Typography>

            <Typography className={classes.element}>
                <img
                    src={require(`../assets/logos/Beautimap-logo.svg`)}
                    alt={`beautimap-logo`}
                    className={classes.logoHeader}
                />
            </Typography>
        </div>
    );
}

export default SignUpThankYouContent;
