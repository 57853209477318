/* Appointment model
{
    id: string,
    service: string, //first version will be string only; later will link to service object with optional string fallback
    customerName: string,
    customerPhoneNumber: string,
    startDateTime: string, //ISO 8601 format
    endDateTime: string, //ISO 8601 format
    notes: string
}

- [ ] ID field is added to objects (parsed from link url), verify where this occurs for initial data > should put in useData hook
ID is added on edit and create functions
*/
import { useState, useEffect } from 'react';
import api from '../../../utils/api';
import cookies from '../../../cookies';

//==================================================  Helpers
const formatApptDateTimeToZulu = (appt) => {
  const updatedAppt = { ...appt };
  const FIELDS_TO_CONVERT = ['startDateTime', 'endDateTime'];
  FIELDS_TO_CONVERT.forEach((field) => {
    updatedAppt[field] = updatedAppt[field] + 'Z'; //Add Zulu time (Server uses Zulu time)
  });
  return updatedAppt;
};
const addParsedIdToAppt = (appt) => {
  const updatedAppt = { ...appt };
  updatedAppt.id = updatedAppt._links.self.href.split('/').pop(); // Add "id" field to updated appt
  return updatedAppt;
};

const parseAppt = (appt, svcList) => {
  const parsedAppt = formatApptDateTimeToZulu(appt);
  //if svc name is a link convert to name from svcList
  if (parsedAppt.service.includes('https://')) {
    const serviceDetails = svcList.find((svc) => svc._links.self.href === parsedAppt.service);
    parsedAppt.service = serviceDetails.name;
  }
  return addParsedIdToAppt(parsedAppt);
};

//==================================================
const env = process.env.REACT_APP_ENV;
const userId = cookies.getUserID();
const userLink = `https://${env}.beautimap.com/users/${userId}`; // TODO get from react context rather than cookie

const useAppointmentData = (currStoreId) => {
  const [appointments, setAppointments] = useState(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [dataForApi, setDataForApi] = useState(null); //To trigger api call
  const [currStoreName, setCurrStoreName] = useState(null);
  const [storeSvcList, setStoreSvcList] = useState(null); //Used as pre fil options for new appt

  useEffect(() => {
    /* Get data from API */
    const fetchData = async () => {
      try {
        const storeData = currStoreId && (await api.getStore(currStoreId));
        const storeIdLink = `https://${env}.beautimap.com/stores/${currStoreId}`;
        // const dateRange = { start: null, end: null };
        const allAppointmentsFromApi = await api.getAppointmentsByStoreAndDate(storeIdLink);
        const storeSvcList = await api.getServiceItemsByStore(currStoreId);
        const parsedAppt = allAppointmentsFromApi.map((appt) => parseAppt(appt, storeSvcList)); // Add Z to app times, TODO refactor to update id here too.
        setCurrStoreName(storeData.data?.name);
        setAppointments(parsedAppt);
        setStoreSvcList(storeSvcList);
      } catch (error) {
        console.log('Initial appt fetch error', error);
        setError(error);
      }
    };

    fetchData();
  }, [currStoreId]);

  useEffect(() => {
    //For calling api
    const callApi = async () => {
      try {
        setStatus(dataForApi.action);

        let apiCall = null;
        if (dataForApi.action === 'CREATE') apiCall = api.createAppointment;
        if (dataForApi.action === 'EDIT') apiCall = api.updateAppointment;
        if (dataForApi.action === 'DELETE') apiCall = api.deleteAppointment;

        console.log('dataForApi', dataForApi);
        //===========API Call
        const apiRes = await apiCall(dataForApi.payload);
        console.log('apiRes', apiRes);
        //===========Success - update state
        if (dataForApi) {
          let updatedAppointments = [...appointments];
          if (dataForApi.action === 'CREATE') {
            apiRes.data.id = apiRes.data._links.self.href.split('/').pop(); // Add "id" field to updated appt
            const parsedAppt = formatApptDateTimeToZulu(apiRes.data);
            updatedAppointments = [...updatedAppointments, parsedAppt];
          }
          if (dataForApi.action === 'EDIT') {
            apiRes.data.id = apiRes.data._links.self.href.split('/').pop(); // Add "id" field to updated appt
            const parsedAppt = formatApptDateTimeToZulu(apiRes.data);
            updatedAppointments = updatedAppointments.map((appointment) => {
              return appointment.id === apiRes.data.id ? parsedAppt : appointment; //Replace appt info with updated info (if id matches)
            });
          }
          if (dataForApi.action === 'DELETE') {
            const deletedAppointmentId = dataForApi.payload;
            updatedAppointments = updatedAppointments.filter((appointment) => appointment.id !== deletedAppointmentId);
          }
          setAppointments(updatedAppointments);
        }

        setStatus(dataForApi.action === 'DELETE' ? 'idle' : 'succeeded');
      } catch (error) {
        console.log('api error', error);
        const errorMessage = error.response?.data?.message || error.message;
        setError(errorMessage);
        setStatus('failed');
      } finally {
        setDataForApi(null);
      }
    };
    dataForApi && callApi();
    //eslint-disable-next-line
  }, [dataForApi]);

  const createAppointment = (newAppointment) => {
    // Temp add id to new appointment
    // newAppointment.id = Math.floor(Math.random() * 1000);
    // const updatedAppointments = [...appointments, newAppointment];
    //Add store ID, and created by
    const storeIdLink = `https://${env}.beautimap.com/stores/${currStoreId}`;
    newAppointment.store = storeIdLink; //Who the appointment is for
    newAppointment.createdBy = userLink; //Who created the appointment (user or business owner)
    setDataForApi({ action: 'CREATE', payload: newAppointment });
  };

  const editAppointment = (updatedAppointment) => {
    setDataForApi({ action: 'EDIT', payload: updatedAppointment });
  };

  const deleteAppointment = (apptIdLink) => {
    const idToDelete = apptIdLink.split('/').pop(); //Parse from link
    setDataForApi({ action: 'DELETE', payload: idToDelete });
  };

  return {
    storeInfo: { id: currStoreId, name: currStoreName },
    appointments,
    storeSvcList,
    actions: {
      createAppointment,
      editAppointment,
      deleteAppointment,
      status,
      error,
      setStatus,
      setError,
    },
  };
};

export default useAppointmentData;
