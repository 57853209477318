import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom'; 
import "./index.css";
// import * as Sentry from "@sentry/browser";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// if (`${process.env.REACT_APP_SENTRY_DSN}` !== "") {
//   Sentry.init({
//     dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
//     environment: `${process.env.REACT_APP_SENTRY_ENV}`,
//   });
// }

ReactDOM.render(<Router><App /></Router>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
