import React, { useState, useEffect } from 'react';
import { Button, ButtonGroup, Slide, Snackbar, makeStyles } from '@material-ui/core';
import ApptItinerary from '../components/AppointmentManager/Itinerary/ApptItinerary';
import useAppointmentData from '../components/AppointmentManager/hook/useAppointmentData';
import Dialog from '@material-ui/core/Dialog';
import AppointmentForm from '../components/AppointmentManager/AppointmentForm/Index.jsx';
import DayCalendar from '../components/AppointmentManager/DayCalendar/Index.jsx';
import MonthCalendar from '../components/AppointmentManager/MonthCalendar/Index.jsx';
import { format, addDays, addMonths, subDays, subMonths, isSameMonth, lastDayOfWeek, startOfWeek } from 'date-fns';
import MiniCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; //Default styling for react-calendar

import Popover from '@material-ui/core/Popover';
import ApptDetailsCard from '../components/AppointmentManager/Itinerary/ApptDetailsCard.jsx';

//Select Imports
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  viewTitle: {
    color: theme.palette.common.font,
  },
  appointmentsView: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '250px auto',
    marginTop: theme.spacing(2), //Get out from under the navbar ([] TODO: need to look at why Nav bar is overlapping like this)
    alignSelf: 'center',
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    '& .MuiButton-label': {
      color: theme.palette.common.font,
    },
    //@ small screen
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      '& aside': {
        display: 'none',
      },
      padding: `0 ${theme.spacing(1)}px`,
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  miniCalendar: {
    border: `1px solid ${theme.palette.common.characters}`,
    borderRadius: '10px',
  },
  modeSelect: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.common.characters}`,
    },
    '& .MuiSelect-outlined': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  calendarControls: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      gap: '8px',
    },
  },
  formControl: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const VIEW_MODES = ['itinerary', 'month', 'week', 'day'];

//=========================================================================================================

export default function AppointmentsView(props) {
  const classes = useStyles();
  // Get query params from url
  const queryParams = new URLSearchParams(props.location.search);
  const paramViewMode = queryParams.get('m');
  const dateParam = queryParams.get('d');
  const paramDate =
    dateParam !== null && new Date(dateParam).toString() !== 'Invalid Date' ? new Date(`${dateParam} 00:00`) : null; //Needs time to prevent timezone issues
  const storeId = props.match.params.storeId;
  const { appointments, actions, storeInfo } = useAppointmentData(storeId);

  //==================Calendar State and Handlers==================
  const [selectedDate, setSelectedDate] = useState(paramDate || new Date());
  const [viewMode, setViewMode] = useState(VIEW_MODES.includes(paramViewMode) ? paramViewMode : 'month');

  //================Snackbar State and Handlers================
  const [showSnackbar, setShowSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };
  useEffect(() => {
    if (actions.status === 'success' || actions.status === 'DELETE') {
      setShowSnackbar(true);
    }
  }, [actions.status, actions.error]);

  //================Modal State and Handlers================
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [appointmentToEdit, setAppointmentToEdit] = useState(null);

  const handleCloseAddEditModal = (event, reason) => {
    if (reason === 'backdropClick') return; //Don't close if backdrop is clicked
    setOpenAddEditModal(false);
    setAppointmentToEdit(null);
    actions.setStatus('idle');
  };

  useEffect(() => {
    if (appointmentToEdit) {
      setOpenAddEditModal(true);
      // setAnchorEl(null);
    }
  }, [appointmentToEdit]);

  //==========================================================
  //Set the url params to the current view mode and date
  useEffect(() => {
    const urlParams = new URLSearchParams();
    urlParams.set('m', viewMode);
    const formattedSelectedDate = format(selectedDate, 'yyyy-MM-dd');
    urlParams.set('d', formattedSelectedDate);
    props.history.replace({ search: urlParams.toString() });
  }, [viewMode, selectedDate, props.history]);

  //================Popover State and Handlers================
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverDetails, setPopoverDetails] = useState(null); //Appointment in focus
  const handleClickAppt = (event, appointmentData, clickedDay) => {
    if (appointmentData !== 'NEW') {
      setPopoverDetails(appointmentData);
      setAnchorEl(event.currentTarget);
    }
    if (appointmentData === 'NEW') {
      setSelectedDate(clickedDay);
      setOpenAddEditModal(true);
    }
  };
  const handleCloseDetailsPopever = () => {
    //Hide popover then remove appointment in focus
    setAnchorEl(null);
    setPopoverDetails(null);
  };
  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'appt-popover' : undefined;
  //=================================================================

  let monthTitle = `${format(selectedDate, 'MMMM yyyy')}`;

  if (viewMode === 'week') {
    //If viewMode is Week and the first day of the week is not the same month as the last day of the week, then add the 2nd month to the title (ex: August - September 2021,)
    //For End of the year show as (ex: December 2021 - January 2022)
    const firstDayOfWeek = startOfWeek(selectedDate);
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay()); // Set to Sunday of the week
    const endOfWeek = lastDayOfWeek(selectedDate);
    const isWeekSameMonth = isSameMonth(firstDayOfWeek, endOfWeek);
    if (!isWeekSameMonth) {
      const isSameYear = endOfWeek.getYear() === firstDayOfWeek.getYear();
      monthTitle = `${format(firstDayOfWeek, isSameYear ? 'MMMM' : 'MMMM yyyy')} - ${format(endOfWeek, 'MMMM yyyy')}`;
    }
  }

  const changeDateFocus = ({ view, actionType }) => {
    let newDate = selectedDate;
    if (view === 'month') {
      newDate = actionType === 'increment' ? addMonths(newDate, 1) : subMonths(newDate, 1);
    } else if (view === 'week') {
      newDate = actionType === 'increment' ? addDays(newDate, 7) : subDays(newDate, 7);
    } else if (view === 'day' || view === 'itinerary') {
      newDate = actionType === 'increment' ? addDays(newDate, 1) : subDays(newDate, 1);
    }
    setSelectedDate(newDate);
  };

  const handleClickDate = (e) => {
    //Determine month using selectedDay
    const clickedDayOfMonth = e.target.innerText;
    const currMonth = selectedDate.getMonth();
    const currYear = selectedDate.getFullYear();
    const clickedDate = new Date(currYear, currMonth, clickedDayOfMonth, '00', '00', '00'); //Set time to 00:00:00

    setSelectedDate(clickedDate); //Set selectedDay to the clicked date
    setViewMode('day'); //Change view to day view
  };

  //==========================================================================================================
  return (
    <div className={classes.viewContainer}>
      <h1 className={classes.viewTitle}>{storeInfo.name ? `Appointments for ${storeInfo.name}` : 'Appointments'}</h1>
      <div className={classes.appointmentsView}>
        <aside style={{ paddingRight: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenAddEditModal(true);
            }}
          >
            {'+ Create Appointment'}
          </Button>
          <div style={{ marginTop: '16px' }}>
            <MiniCalendar
              className={classes.miniCalendar}
              calendarType="US"
              // showNavigation={false}
              onChange={setSelectedDate} //When something is pressed on mini calendar
              value={selectedDate} //Mini calendar highlighted date (Blue by default)
              // activeStartDate={hasMonthChanged && selectedDate} //What the mini calendar is looking at, should only change if month changes
              maxDetail="month"
              minDetail="year"
            />
            {/* Issue - mini calendar should re-render with current value date if its changed by other components - Check API for solution
          Possible work around it putting the component with the value in a state, and update with a useEffect
          This seems very hacky try API and maybe 1 or 2 other options first 
          >> Active start date prop kindof works but the mini cal's month & year arrows break */}
          </div>
        </aside>

        <main>
          <header className={classes.header}>
            <div className={classes.calendarControls}>
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <Button variant="outlined" color="primary" onClick={() => setSelectedDate(new Date())}>
                  Today
                </Button>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => changeDateFocus({ view: viewMode, actionType: 'decrement' })}
                  >
                    {'<'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => changeDateFocus({ view: viewMode, actionType: 'increment' })}
                  >
                    {'>'}
                  </Button>
                </ButtonGroup>
                <h2 style={{ margin: 0 }}>{monthTitle}</h2>
              </div>
              <FormControl className={classes.formControl}>
                <Select
                  id="modeSelector"
                  className={classes.modeSelect}
                  value={viewMode}
                  onChange={(v) => {
                    setViewMode(v.target.value);
                  }}
                  variant="outlined"
                >
                  <MenuItem value={'day'}>Day</MenuItem>
                  <MenuItem value={'week'}>Week</MenuItem>
                  <MenuItem value={'month'}>Month</MenuItem>
                  <MenuItem value={'itinerary'}>Appointment Schedule</MenuItem>
                </Select>
              </FormControl>
            </div>
          </header>
          {/* Working area (calendar) - will have 4 views - itinerary/ month / week / day */}
          {appointments !== null && (
            <>
              {viewMode === 'itinerary' && (
                <ApptItinerary
                  appointments={appointments ?? []}
                  // dataHookActions={{ setAppointmentToEdit, ...actions }}
                  onApptClick={handleClickAppt}
                  selectedDay={selectedDate}
                />
              )}
              {viewMode === 'month' && (
                <MonthCalendar
                  selectedDay={selectedDate}
                  appointments={appointments ?? []}
                  onApptClick={handleClickAppt}
                  handleClickDate={handleClickDate}
                />
              )}
              {(viewMode === 'week' || viewMode === 'day') && (
                <DayCalendar
                  selectedDay={selectedDate}
                  appointments={appointments ?? []}
                  daysToShow={viewMode === 'week' ? 7 : 1}
                  onApptClick={handleClickAppt}
                />
              )}
            </>
          )}

          <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Slide direction="up" in={true}>
              <div>
                {actions.status === 'DELETE' && <Alert severity="info">Deleting Appointment...</Alert>}
                {actions.status === 'succeeded' && <Alert severity="success">Success</Alert>}
              </div>
            </Slide>
          </Snackbar>
        </main>

        {/* =========================== Modal for add / edit  - will likely move to parent*/}

        {!!openAddEditModal && (
          <Dialog open={openAddEditModal} onClose={handleCloseAddEditModal} disableEscapeKeyDown maxWidth="xl">
            <AppointmentForm
              appointmentToEdit={appointmentToEdit}
              actions={{ handleCloseAddEditModal, ...actions }}
              selectedDate={selectedDate}
              appointments={appointments} //For validation to prevent double booking
            />
          </Dialog>
        )}

        {popoverDetails && (
          <Popover
            id={popoverId}
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={handleCloseDetailsPopever}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ApptDetailsCard
              appointment={popoverDetails}
              actions={{ handleCloseDetailsPopever, setAppointmentToEdit, ...actions }}
            />
          </Popover>
        )}
      </div>
    </div>
  );
}
