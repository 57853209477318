import { DAYS_OF_THE_WEEK } from './constants';
import momentTZ from 'moment-timezone';
import DOMPurify from 'dompurify';
import moment from 'moment';
import storeLogoPlaceholder from '../assets/placeholders/storeLogo.jpg';
import storeBannerPlaceholder from '../assets/placeholders/storeBanner.jpg';

export const categoryShorthand = (fullCategoryName) => {
  //Ref category list update, shortens category name to 1 word to match API
  //[ ] TODO: Look into making 'Hair removal' should become a single word rather than 'waxing' (old category)
  const shorthand = fullCategoryName.split('&')[0].trim().toLowerCase();
  return shorthand === 'hair removal' ? 'waxing' : shorthand;
};

export const formatPhoneNumber = (phoneNumber) => {
  //Format phone number from 'xxxxxxxxxx' to display xxx-xxx-xxxx
  return phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const determineMobileServiceType = (storeInfo) => {
  /* Currently not saved as enum in store object but determined by state of two varables from the stores record (storeInfo)
'storeOnly' storeInfo.mobileService === false && and street address is present (this will also require the street field to save)
'mobileOnly' storeInfo.mobileService === true && street address is not present (this will empty and clear the field on save if one was present)
'storeWithMobileService' storeInfo.mobileService ===  true && street address is present (this will also require the street field to save)
*/

  const MOBILE_SERVICE_TYPE = {
    STORE_ONLY: 'storeOnly',
    MOBILE_ONLY: 'mobileOnly',
    STORE_WITH_MOBILE_SERVICE: 'storeWithMobileService',
  };
  const mobileServiceFlag = storeInfo.mobileService;
  let mobileServiceType = MOBILE_SERVICE_TYPE.STORE_ONLY; //Default to store only if no data is present
  if (mobileServiceFlag === false && storeInfo.address.street) {
    mobileServiceType = MOBILE_SERVICE_TYPE.STORE_ONLY;
  } else if (mobileServiceFlag === true && !storeInfo.address.street) {
    mobileServiceType = MOBILE_SERVICE_TYPE.MOBILE_ONLY;
  } else if (mobileServiceFlag === true && !!storeInfo.address.street) {
    mobileServiceType = MOBILE_SERVICE_TYPE.STORE_WITH_MOBILE_SERVICE;
  }
  return mobileServiceType;
};

export const migrateStoreCategories = (allStores) => {
  if (!allStores) return console.log('NO STORES TO MIGRATE!!');
  /* Temp function to be used when new categories are deployed,  
  Will be used with a custom api call that patches existing stores with new categories 
  */
  const changesToMake = []; //Empty array for changes
  //Iterate stores
  allStores.forEach((store) => {
    const oldCategories = store.category.split(',').sort(); //Convert categories to array
    const newCategories = []; //Empty array for new categories

    //For each store, iterate categories
    oldCategories.forEach((oldCategory) => {
      //Match old category to new category and push to new array
      switch (oldCategory) {
        case 'Hair':
          newCategories.push('Hair');
          break;
        case 'Stylists':
          // Stylists is no longer a category and not being migrated
          newCategories.push('Aesthetics & Skin'); //Push for dev env (no stylings in live ATM)
          break;
        case 'Lashes':
        case 'Brows':
          newCategories.push('Lashes & Brows');
          break;

        case 'Nails':
          newCategories.push('Nails');
          break;
        case 'Makeup':
          newCategories.push('Makeup');
          break;

        case 'Aesthetics':
        case 'Skincare':
        case 'Facial':
        case 'Tanning':
          newCategories.push('Aesthetics & Skin');
          break;
        case 'Waxing':
          newCategories.push('Waxing');
          break;
        case 'Selfcare':
          newCategories.push('Selfcare');
          break;

        default:
          break;
      }
    });
    //Trim the array (remove duplicates) + Sort Alphabetically
    const trimmedCategories = [...new Set(newCategories)].sort();
    //Deep compare arrays to see if they are the same
    const hasCategoriesChanged = JSON.stringify(trimmedCategories) !== JSON.stringify(oldCategories); //Categories are already sorted alphabetically
    //If categories have changed, push the change to changesToMake array,
    if (hasCategoriesChanged) {
      changesToMake.push({
        storeId: store._links.self.href.replace('{?projection}', '').split('/').pop(), //Store ID
        newCategories: trimmedCategories.join(','), //New categories in string format
        oldCategories: oldCategories.join(','), //Old categories in string format
      });
    }
  });
  console.log('======Category Changes to Make=====');
  console.log(changesToMake);
  console.log('===========Running API=================');

  // Promise.all(
  //   changesToMake.map((change) => api.updateStore(`${change.storeId}`, { category: change.newCategories }))
  // ).then((res) => {
  //   console.log('===========API Results=================');
  //   console.log(res);
  //   // return changesToMake;
  // });
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const navbarTabLink = (navbarPath, province, city) => {
  return `${navbarPath}/${province.replace(/\s+/g, '-').toLowerCase()}/${city.replace(/\s+/g, '-').toLowerCase()}`;
};

// export const formatPhoneNumber = (number) => {
//   const code = number.slice(0, 3);
//   const first = number.slice(3, 6);
//   const second = number.slice(6, 10);

//   return code + '-' + first + '-' + second;
// };
export const validatePassword = (password) => {
  //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
  const containsUpperCase = password.match(/[A-Z]/);
  const containsLowerCase = password.match(/[a-z]/);
  const containsDigit = password.match(/\d/);
  const containsSpecial = password.match(/[^a-zA-Z\d]/);

  return (
    password.length >= 8 &&
    password.length <= 50 &&
    containsDigit &&
    containsSpecial &&
    containsUpperCase &&
    containsLowerCase
  );
};

export const validateURL = (url) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  if (url === '') {
    return true;
  }
  return pattern.test(url);
};

export const validateFacebook = (fb) => {
  const re = /^(https?:\/\/)?(www.)?facebook.com\/.+/i;
  if (fb === '') {
    return true;
  }
  return re.test(fb);
};

//https://www.techwalla.com/articles/how-to-choose-a-twitter-handle
// max 15 characters and underscore
export const validateTwitter = (tw) => {
  if (tw === '') {
    return true;
  }
  const reURL = /^((https?:\/\/)?(www.)?)?twitter.com\/[0-9a-z_]{1,15}$/i;
  const reHandle = /^@[0-9a-z_]{1,15}$/i;
  return reURL.test(tw) || reHandle.test(tw);
};

//https://stackoverflow.com/questions/15470180/character-limit-on-instagram-usernames
//Limit - 30 symbols. Username must contains only letters, numbers, periods and underscores.
export const validateInstagram = (ig) => {
  if (ig === '') {
    return true;
  }
  const reURL = /^((https?:\/\/)?(www.)?)?instagram.com\/[0-9a-z_.]{1,30}\/$/i;
  const reHandle = /^@[0-9a-z._]{1,30}$/i;
  return reURL.test(ig) || reHandle.test(ig);
};

// https://www.followchain.org/snapchat-handle/
// min - 3; max- 15; more rules but too complicated to implement
export const validateSnapchat = (sc) => {
  if (sc === '') {
    return true;
  }
  const reURL = /^((https?:\/\/)?(www.)?)?snapchat.com\/[0-9a-z_.]{3,15}$/i;
  const reHandle = /^@.{3,15}$/i;
  return reURL.test(sc) || reHandle.test(sc);
};

// exactly 10 numbers
export const validatePhoneNumber = (phone) => {
  const re = /^\d{10}$/;
  return re.test(phone);
};

export const validatePostalCode = (province, postalCode) => {
  if (postalCode === '') {
    return true;
  }
  const reUSA = /^\d{5}$/;
  const reCanada = /[A-Z]\d[A-Z]\s?\d[A-Z]\d/;

  if (province === 'USA') {
    return reUSA.test(postalCode);
  } else {
    return reCanada.test(postalCode);
  }
};

// must have at least one file
export const validateNonEmptyArray = (array) => {
  return array.length > 0;
};

// must not be an empty string
export const validateNonEmptyString = (input) => {
  return !(input === '');
};

export const getWeekDay = (offset) => {
  offset = offset ? offset : 0;

  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  let day = new Date().getDay();

  if (day + offset === 7) {
    day = 0;
  } else if (day + offset === -1) {
    day = 6;
  } else {
    day = day + offset;
  }

  return weekdays[day];
};

export const MOMENT_DATE_FORMAT = 'HH:mm a';

export const openStatus = (hours) => {
  if (hours === null) return false;

  const weekday = getWeekDay().toLowerCase();
  const open = moment(hours[`${weekday}Open`], MOMENT_DATE_FORMAT);
  const close = moment(hours[`${weekday}Close`], MOMENT_DATE_FORMAT);

  const prevWeekday = getWeekDay(-1).toLowerCase();
  const prevOpen = moment(hours[`${prevWeekday}Open`], MOMENT_DATE_FORMAT);
  const prevClose = moment(hours[`${prevWeekday}Close`], MOMENT_DATE_FORMAT);

  const currentTime = moment();

  //Today's opening is null but yesterday's closing is after the current time
  if (hours[`${weekday}Open`] === null && prevClose.isBefore(prevOpen) && currentTime.isBefore(prevClose)) {
    return true;
  }
  //Current time is before today's opening but yesterday's closing is after the current time
  else if (currentTime.isBefore(open) && prevClose.isBefore(prevOpen) && currentTime.isBefore(prevClose)) {
    return true;
  }
  //24 hours case
  else if (open.isSame(close) && open.isSame(moment('00:00:00', MOMENT_DATE_FORMAT))) {
    return true;
  }
  //Opens in the current day and closes in the next. Current time is after today's open
  else if (close.isBefore(open) && currentTime.isSameOrAfter(open)) {
    return true;
  }
  //Standard case, opening is before closing and the current time is between the two
  else if (open.isBefore(close) && currentTime.isSameOrAfter(open) && currentTime.isSameOrBefore(close)) {
    return true;
  }
  //Matches no cases
  else {
    return false;
  }
};

export const convertToDate = (str) => {
  const year = str.substring(0, 4);
  const month = str.substring(5, 7);
  const day = str.substring(8, 10);

  return new Date(year, month - 1, day);
};

// e.g. "17:00:00" -> "5:00 pm"
export const parseHour = (time) => {
  if (!time) return 'Closed';

  let hour = time.split(':')[0];
  const minute = time.split(':')[1];
  let period = ' am';

  if (hour === '00') {
    hour = 12;
  } else if (hour > 12) {
    hour = hour - 12;
    period = ' pm';
  } else if (hour === '12') {
    period = ' pm';
  }

  hour = String(hour).replace(/^0/, '');

  return hour + ':' + minute + period;
};

// [{sundayOpen: 10:00:00,...}] -> [{day: Sunday: open:10:00 pm, ...}...]
export const parseHoursObject = (hours) => {
  const parsedHours = [];
  DAYS_OF_THE_WEEK.forEach((day) => {
    parsedHours.push({
      day: day,
      open: parseHour(hours[`${day.toLowerCase()}Open`]),
      close: parseHour(hours[`${day.toLowerCase()}Close`]),
    });
  });
  return parsedHours;
};

// {sundayOpen: 10:00:00, ...} => {sundayOpen: 10:00 am, ...}
export const parseHoursObjToObj = (hours) => {
  const parsedHoursObj = {};
  DAYS_OF_THE_WEEK.forEach((day) => {
    if (!hours[`${day.toLowerCase()}Open`]) {
      parsedHoursObj[`${day.toLowerCase()}Open`] = 'Closed';
      parsedHoursObj[`${day.toLowerCase()}Close`] = '12:00 am';
    } else if (hours[`${day.toLowerCase()}Open`] === '00:00:00' && hours[`${day.toLowerCase()}Close`] === '00:00:00') {
      parsedHoursObj[`${day.toLowerCase()}Open`] = '24 Hours';
      parsedHoursObj[`${day.toLowerCase()}Close`] = '12:00 am';
    } else {
      parsedHoursObj[`${day.toLowerCase()}Open`] = parseHour(hours[`${day.toLowerCase()}Open`]);
      parsedHoursObj[`${day.toLowerCase()}Close`] = parseHour(hours[`${day.toLowerCase()}Close`]);
    }
  });

  return parsedHoursObj;
};

export const buildHour = (input) => {
  if (input === 'Closed' || input === '') {
    return '';
  }

  if (input === '24 Hours') {
    input = '12:00 am';
  }
  // eslint-disable-next-line
  let hour = input.split(/[\:, ]+/)[0];
  // eslint-disable-next-line
  const minute = input.split(/[\:, ]+/)[1];
  // eslint-disable-next-line
  const period = input.split(/[\:, ]+/)[2];

  if (hour.length < 2) hour = '0' + hour;

  if (period === 'am' && hour === '12') {
    hour = '00';
  } else if (period === 'pm' && hour !== '12') {
    hour = Number(hour) + 12;
  }

  return hour + ':' + minute;
};

// Make hours object to post/patch
export const buildHoursObject = (hours) => {
  const builtHours = {};

  DAYS_OF_THE_WEEK.forEach((day) => {
    if (hours[`${day.toLowerCase()}Open`] === 'Closed') {
      builtHours[`${day.toLowerCase()}Open`] = null;
      builtHours[`${day.toLowerCase()}Close`] = null;
    } else if (hours[`${day.toLowerCase()}Open`] === '24 Hours') {
      builtHours[`${day.toLowerCase()}Open`] = '00:00';
      builtHours[`${day.toLowerCase()}Open`] = '00:00';
    }
    builtHours[`${day.toLowerCase()}Open`] = buildHour(hours[`${day.toLowerCase()}Open`]);
    builtHours[`${day.toLowerCase()}Close`] = buildHour(hours[`${day.toLowerCase()}Close`]);
  });

  return builtHours;
};

export const formatURL = (url) =>
  url.slice(0, 8) === 'https://'
    ? url.slice(8, url.length)
    : url.slice(0, 7) === 'http://'
    ? url.slice(7, url.length)
    : url;

export const parseIdFromLink = (link, offset, strip) => {
  let id;
  if (strip) {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)].replace(strip, '');
  } else {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)];
  }
  return id;
};

// For timezone preference
const utcOffset = (offset) => {
  const hours = Math.floor(offset / 60);
  const mins = offset % 60;

  return `UTC -${hours}:${mins > 9 ? mins : `0${mins}`}`;
};

// For timezone preference
const zoneAbbr = (name) => {
  switch (name) {
    case 'America/Vancouver':
      return 'PT (Vancouver)';
    case 'America/Edmonton':
      return 'MT (Edmonton/Calgary)';
    case 'America/Regina':
      return 'CT (Regina)';
    case 'America/Winnipeg':
      return 'CT (Winnipeg)';
    case 'America/Toronto':
      return 'ET (Toronto)';
    case 'America/Halifax':
      return 'AT (Halifax)';
    case 'America/St_Johns':
      return 'NST/NDT (St. Johns)';
    default:
      break;
  }
};

// For timezone preference
export const timezones = momentTZ.tz
  .zonesForCountry('CA', true)
  .filter((zone) =>
    [
      'America/Vancouver',
      'America/Edmonton',
      'America/Regina',
      'America/Winnipeg',
      'America/Toronto',
      'America/Halifax',
      'America/St_Johns',
    ].includes(zone.name)
  )
  .map((zone) => ({
    name: zone.name,
    offset: zone.offset,
    utcOffset: utcOffset(zone.offset),
    abbr: zoneAbbr(zone.name),
  }))
  .sort((x, y) => x.offset - y.offset);

// build the store state based on data prop to initialize the state of the AddEditStore component

export const sanitize = (content) => {
  return process.browser ? DOMPurify.sanitize(content) : content;
};

// Create body object for post/patch based on storeState
export const buildStoreBodyObj = (data, locationInput, logoImageFilePath, filePath) => {
  return {
    ...data,
    locations: [locationInput],
    facebook: formatURL(data.facebook),
    instagram: formatURL(data.instagram),
    twitter: formatURL(data.twitter),
    snapchat: formatURL(data.snapchat),
    website: formatURL(data.website),
    hours: buildHoursObject(data.hours),
    logoImageFile: logoImageFilePath,
    file: filePath,
  };
};

export const abbreviate = (prov) => {
  switch (prov) {
    case 'Alberta':
      return 'AB';
    case 'British Columbia':
      return 'BC';
    case 'Manitoba':
      return 'MB';
    case 'New Brunswick':
      return 'NB';
    case 'Newfoundland and Labrador':
      return 'NL';
    case 'Northwest Territories':
      return 'NT';
    case 'Nova Scotia':
      return 'NS';
    case 'Nunavut':
      return 'NU';
    case 'Ontario':
      return 'ON';
    case 'Prince Edward Island':
      return 'PE';
    case 'Quebec':
      return 'QC';
    case 'Saskatchewan':
      return 'SK';
    case 'Yukon':
      return 'YK';
    default:
      return 'N/A';
  }
};

export const parseImgURL = (url) => {
  const imageURLParts = url.split('/');
  const imageFileName = imageURLParts[imageURLParts.length - 1];
  return imageFileName;
};

export const makeImageKitURL = (sourceImage, options) => {
  if (!sourceImage) return '';
  const imageFileName = parseImgURL(sourceImage);
  if (!options) options = { width: '200' }; //If options is forgotten will default to 200px wide
  const width = options.width ? options.width : 'false';
  const height = options.height ? options.height : 'false';
  const imageKitURL = `https://ik.imagekit.io/gln6kperair/${imageFileName}?tr=w-${width},h-${height}`;
  return imageKitURL;
};

export const handleImage = (sourceImageLink, options) => {
  const placeholderType = options?.placeholderType;
  if (!sourceImageLink) {
    // If no image is provided, return a placeholder
    // placeholder is specified at the component level b/c it may change
    let placeholderPath = '';
    switch (placeholderType) {
      case 'storeBanner':
        placeholderPath = storeBannerPlaceholder;
        break;
      case 'BUSINESS':
      case 'storefront':
      case 'store':
      case 'storeLogo':
        placeholderPath = storeLogoPlaceholder;
        break;
      default:
        placeholderPath = storeLogoPlaceholder;
    }
    return placeholderPath ?? '';
  }

  return sourceImageLink;
  //Beautimap does not use imagekit ... yet

  // if (sourceImage?.includes('blob:')) return sourceImage; //Image is added from the file system skips imagekit.
  // const imageFileName = parseImgURL(sourceImage);
  // if (!options) options = { width: '200' }; //If options is forgotten will default to 200px wide
  // const width = options.width ? options.width : 'false';
  // const height = options.height ? options.height : 'false';
  // const imageKitURL = `https://ik.imagekit.io/gln6kperair/${imageFileName}?tr=w-${width},h-${height}`;
  // return imageKitURL;
};

export const allStoresRedirect = (location, store) => {
  return `${location.province.replace(/\s+/g, '-').toLowerCase()}/${location.city
    .replace(/\s+/g, '-')
    .toLowerCase()}/${parseIdFromLink(store._links.self.href)}/${store.slug}`;
};

export const filterFeaturedList = (listType, featuredList, completeList) => {
  /* Filter valid deals, rmv duplicates, and Cross check to remove any feat items that don't exist in the location 
  completeList is optional
  Should handle all featured List types */
  //[] TODO: Add type handling / exit clause(s)
  if (!featuredList) return [];
  if (listType === 'deal') {
    const today = new Date();
    //Only Active & Upcoming Deals
    featuredList = featuredList.filter((deal) => today < convertToDate(deal.deal.endDate));
    completeList = completeList?.filter((deal) => today < convertToDate(deal.endDate));
  }

  let validItems = featuredList;
  if (completeList) {
    /* Keeps only items that belong in that location */
    const fullListIDs = completeList.map((item) => item._links.self.href.replace('{?projection}', ''));
    validItems = featuredList.filter((featItem) =>
      fullListIDs.includes(featItem[listType]._links.self.href.replace('{?projection}', ''))
    );
  }
  const unique = {};
  validItems.forEach((item) => {
    const entityID = parseIdFromLink(item[listType]._links.self.href, 0, '{?projection}');
    unique[entityID] = item;
  });
  const newData = Object.values(unique);
  const sorted = newData.sort((x, y) => x.rank - y.rank);

  return [...sorted];
  /* Tried to use new Set but since items are objects it treated them as unique */
};

export const capitalizeFirstOfEach = (value) => {
  if (!value) return '';
  const words = value.split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }

  return words.join(' ');
};
