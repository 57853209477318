import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function DeleteDialog({ open, handleClose, handleConfirm, name }) {
  return (
    <div>
      <Dialog
        style={{ zIndex: 3000 }} //Service Items Modal is 2000, navbar is 1000
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${name} Service?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once this service is deleted, it cannot be recovered. Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            No, go back
          </Button>
          <Button onClick={handleConfirm} color="default" autoFocus>
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
