/* Iterates 24hours in the day
Determines appointments that start within a given hour
Based on fixed height of 48px per hour, determines the height of the appointment on it duration (15min intervals)
*/
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { differenceInMinutes } from 'date-fns';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  daysHours: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  hourBlock: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: 48, //Will be overridden by inline style
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderRight: `1px solid ${theme.palette.primary.main}`,
    width: '100%',
    '&:hover': {
      zIndex: 2,
    },
  },
  appointment: {
    //Height and marginTop set inline based on start time and duration
    backgroundColor: 'teal',
    color: 'white',
    maxWidth: '97%',
    zIndex: 1,
    borderRadius: '6px',
    padding: '1px 6px',
    border: '1px solid white',
    position: 'absolute', //With margin (set dynamically), will allow appt to posn properly within hour block
    width: '100%',
    transition: 'height 0.3s',
  },
  apptText: {
    maxWidth: '100%',
    fontSize: '0.7rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'normal',
    },
  },
  hideOnSmallScreen: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ctrLeftItems: {
    display: 'flex',
    justifyContent: 'flex-start',
    // alignItems: 'center',
  },
  unavailable: {
    backgroundColor: 'lightGrey',
    color: 'black',
  },
  available: {
    backgroundColor: 'teal',
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      zIndex: 3,
      backgroundColor: 'green',
    },
  },
}));

export default function DayTimeBlocks({ appointments, onApptClick, day, clientMode, BLOCK_HEIGHT = 48 }) {
  const classes = useStyles();

  const QUARTER_HEIGHT = BLOCK_HEIGHT / 4;

  const handleClick = (e, appointment) => {
    e.stopPropagation();
    if (clientMode) {
      return appointment.service === 'Available' ? onApptClick(appointment.startDateTime, 'NEW', day) : null; //Event used to pass currentTarget for anchoring popper
    } else {
      return onApptClick(e, appointment ?? 'NEW', day); //Event used to pass currentTarget for anchoring popper
    }
  };
  const handleMouseEnter = (e, hoverHeight) => {
    const element = e.currentTarget;
    element.style.height = `${hoverHeight}px`;
  };
  const handleMouseLeave = (e) => {
    const element = e.currentTarget;
    element.style.height = `${QUARTER_HEIGHT}px`;
  };

  let hourBlocks = [];
  for (let i = 0; i < 24; i++) {
    const hour = i;
    hourBlocks.push(
      <div
        className={classes.hourBlock}
        id={`hour-${i}`}
        onClick={clientMode ? null : handleClick}
        style={{ height: BLOCK_HEIGHT }}
      >
        <div className="appointments" style={{ zIndex: 1, position: 'relative' }}>
          {/* - [ ] TODO: add concurrent appointments, need to be able to display side by side */}
          {appointments
            .filter((appointment) => {
              //Appointments starting within the hour
              const startHour = new Date(appointment.startDateTime).getHours();
              return startHour === hour;
            })
            .map((appointment) => {
              const apptStart = new Date(appointment.startDateTime);
              const apptEnd = new Date(appointment.endDateTime);
              const apptTimeSummary = `${format(apptStart, 'h:mm a')} - ${format(apptEnd, 'h:mm a')}`;
              const appointmentDurationInMin = differenceInMinutes(apptEnd, apptStart);
              const fullAppointmentHeight = (appointmentDurationInMin / 60) * BLOCK_HEIGHT;
              const appointmentStartMin = apptStart.getMinutes();
              const topOffset = (appointmentStartMin / 15) * QUARTER_HEIGHT;

              const apptSummary = clientMode
                ? `${appointment.service}`
                : `${appointment.service} - ${appointment.customerName}`;

              let classToUse =
                appointmentDurationInMin > 30 ? classes.appointment : `${classes.appointment} ${classes.ctrLeftItems}`;

              if (clientMode) {
                if (appointment.service === 'Available') classToUse = `${classToUse} ${classes.available}`;
                if (appointment.service === 'Unavailable') classToUse = `${classToUse} ${classes.unavailable}`;
              }
              const isExpandable = clientMode && appointment.service !== 'Unavailable';
              return (
                <div
                  key={appointment.id}
                  className={classToUse}
                  style={{
                    //Dynamic Styling, fixed styles in makeStyles
                    height: isExpandable ? QUARTER_HEIGHT : fullAppointmentHeight, //Based on duration
                    marginTop: topOffset, // How far into the hour block that the appointment starts
                  }}
                  onClick={(e) => handleClick(e, appointment)}
                  onMouseEnter={isExpandable ? (e) => handleMouseEnter(e, fullAppointmentHeight) : null}
                  onMouseLeave={isExpandable ? (e) => handleMouseLeave(e, fullAppointmentHeight) : null}
                >
                  <Typography className={classes.apptText}>
                    {clientMode
                      ? `${apptSummary}${appointment.service === 'Available' ? ` - ${apptTimeSummary}` : ''}` //Only show time for available slots
                      : apptSummary}
                  </Typography>
                  {!clientMode && appointmentDurationInMin > 30 && (
                    <Typography className={`${classes.apptText} ${classes.hideOnSmallScreen}`}>
                      {apptTimeSummary}
                    </Typography>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  return <div className={classes.daysHours}>{hourBlocks}</div>;
}
