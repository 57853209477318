import React from 'react';
import BusinessHours from './BusinessHours';
import CallAction from './CallAction';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  asideRoot: {
    position: 'relative',
    width: '100%',
    minWidth: '360px',
    height: '100%',
    padding: '16px',
  },
  stickContainer: {
    position: 'sticky',
    top: '150px',
  },
}));

export default function CallToActionIndex({ data }) {
  const classes = useStyles();
  return (
    <aside className={classes.asideRoot}>
      <div className={classes.stickContainer}>
        <CallAction phoneNumber={data?.phone} businessName={data?.name} />
        <BusinessHours storeHours={data?.hours} />
      </div>
    </aside>
  );
}
