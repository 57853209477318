import React, { useState } from 'react';
import FormRow from '../components/FormRow';
import SignUpThankYou from '../components/SignUpThankYou';
import TermsConditionsPopup from '../components/TermsConditionsPopup';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { STATUS, ROLES } from '../utils/constants';
import { validateEmail, validatePassword } from '../utils/helpers';
import { Helmet } from 'react-helmet';
import api from '../utils/api';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import cookies from '../cookies';
// import {parseIdFromLink} from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    // border: '2px red solid'
  },

  title: {
    // paddingBottom: theme.spacing(4),
    textTransform: 'uppercase',
    letterSpacing: '5px',
  },

  formContainer: {
    padding: theme.spacing(4),
    border: '1px solid',
    borderColor: theme.palette.common.gray,
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
  },

  paper: {
    textAlign: 'left',
    padding: theme.spacing(3),
    // boxShadow: 'none',
    // border: '2px purple solid'
  },
  signUpButton: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  cancelButton: {
    fontWeight: 700,
  },
  asterisk: {
    color: theme.palette.common.red,
    fontWeight: 700,
  },
  formControl: {},
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
}));

function CustomerRegisterView(props) {
  const [openTerms, setOpenTerms] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [passwordMask, setPasswordMask] = useState(true);
  // const [submitted, setSubmitted] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [ShowVerifyModal, setShowVerifyModal] = useState(false);

  const history = useHistory();
  // Create New User
  const createUser = async (data, role) => {
    return api
      .createUser(data, role)
      .then((res) => {
        console.log(res);
        return res;
      })
      .catch((error) => {
        if (error.message[0].includes('ERROR: duplicate key value violates unique constraint')) {
          throw STATUS.DUPLICATE;
        } else {
          setSignUpError(error.message[0]);
          throw STATUS.ERROR;
        }
      });
  };
  const validateInput = () => {
    let isValid = true;
    if (!validateEmail(email)) {
      setEmailError(true);
      isValid = false;
    }
    if (!validatePassword(password)) {
      setPasswordError(true);
      isValid = false;
    }
    if (passwordConfirm !== password) {
      setPasswordConfirmError(true);
      isValid = false;
    }
    // if (!phone && !validatePhoneNumber(phone)) {
    //   setPhoneError(true);
    //   isValid = false;
    // }
    return isValid;
  };

  const register = async (e) => {
    e.preventDefault();

    // Return if there's error in input
    if (!validateInput()) {
      return null;
    }

    const data = {
      firstName: firstName,
      lastName: lastName,
      password: password,
      username: email.toLowerCase().trim(),
      tcAccepted: termsAccepted,
    };

    setSaving(true);
    const role = isBusiness ? ROLES.ROLE_STORE_ADMIN : ROLES.ROLE_USER;
    // api.createUser will getRole and then create user
    return createUser(data, role)
      .then((res) => {
        setShowVerifyModal(true);
        setSaving(false);
      })
      .catch((error) => {
        // console.log('error', error);
        setSaving(false);
        if (error === STATUS.DUPLICATE) {
          setUserExists(true);
        } else if (error === STATUS.ERROR) {
          //setLoginError(true);
        } else setSignUpError(true);
      });
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Beautimap | Customer Signup</title>
        <meta name="description" content="Sign up to view and access the services listed in beautimap.com" />
      </Helmet>
      <div className={classes.contentContainer}>
        <Typography variant="body1" color="textPrimary" style={{ fontWeight: 700, marginBottom: '1rem' }}>
          Register &gt;
        </Typography>
        <Typography variant="h3" color="textPrimary" className={classes.title}>
          personal information
        </Typography>
        {/* <Typography variant='body1' color='textPrimary'>Are you a business owner? Sign up <Link href="/store-signup">here</Link></Typography> */}
        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: 700, marginBottom: '1rem', textAlign: 'right' }}
        >
          <span className={classes.asterisk}>*</span>&nbsp;Required Fields
        </Typography>
        {/* Input Form Container */}
        <Paper className={classes.formContainer} elevation={5}>
          <form onSubmit={(e) => register(e)}>
            {/* Email Input */}
            <FormRow
              title="Email"
              asterisk
              // message="Enter your email address to use as login ID"
            >
              <TextField
                size="small"
                required
                fullWidth
                variant="outlined"
                autoComplete="email"
                id="email-input"
                name="email"
                value={email}
                style={{ maxWidth: '220px' }}
                onChange={(e) => {
                  setEmailError(false);
                  setUserExists(false);
                  setEmail(e.target.value);
                }}
                error={emailError || userExists}
                helperText={
                  emailError
                    ? 'Please enter a valid email address'
                    : userExists
                    ? 'A user with this email already exists'
                    : ''
                }
              />
            </FormRow>
            <Typography variant="body1" style={{ margin: '10px' }}>
              Enter your email address to use as login ID
            </Typography>
            {/* End Email Input */}
            {/* Password Input */}
            <FormRow
              asterisk
              title="Password"
              // message="Password Requirements: Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character"
              style={{ width: '70%', border: '2px solid black' }}
            >
              <TextField
                size="small"
                required
                fullWidth
                id="password-input"
                name="password"
                variant="outlined"
                type={passwordMask ? 'password' : 'input'}
                value={password}
                style={{ maxWidth: '220px' }}
                onChange={(e) => {
                  setPasswordError(false);
                  setPassword(e.target.value);
                }}
                error={passwordError}
                helperText={passwordError ? 'Password does not match requirements' : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {passwordMask && (
                        <Tooltip title="Show password" placement="bottom">
                          <VisibilityIcon onClick={() => setPasswordMask(!passwordMask)} />
                        </Tooltip>
                      )}
                      {!passwordMask && (
                        <Tooltip title="Hide password" placement="bottom">
                          <VisibilityOffIcon onClick={() => setPasswordMask(!passwordMask)} />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </FormRow>
            <Typography variant="body1" style={{ margin: '10px' }}>
              Password Requirements: Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
            </Typography>
            {/* End Password Input */}
            {/* Confirm Password Input */}
            <FormRow asterisk title="Confirm Password" message="">
              <TextField
                size="small"
                required
                fullWidth
                type="password"
                id="confirm-password-input"
                name="passwordConfirm"
                variant="outlined"
                value={passwordConfirm}
                style={{ maxWidth: '220px' }}
                onChange={(e) => {
                  setPasswordConfirmError(false);
                  setPasswordConfirm(e.target.value);
                }}
                error={passwordConfirmError}
                helperText={passwordConfirmError ? 'Passwords do not match' : ''}
              />
            </FormRow>
            {/* End Confirm Password Input */}
            {/* First Name Input */}
            <FormRow title="First Name" asterisk message="">
              <TextField
                size="small"
                required
                fullWidth
                id="first-name-input"
                name="firstName"
                autoComplete="given-name"
                variant="outlined"
                value={firstName}
                style={{ maxWidth: '220px' }}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </FormRow>
            {/* End First Name Input */}
            {/* Last Name Input */}
            <FormRow asterisk title="Last Name" message="">
              <TextField
                size="small"
                required
                fullWidth
                autoComplete="family-name"
                id="last-name-input"
                name="lastName"
                variant="outlined"
                value={lastName}
                style={{ maxWidth: '220px' }}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </FormRow>
            {/* End Last Name Input */}
            {/**Business account check */}
            <FormRow title="">
              <FormGroup row>
                <FormControlLabel
                  label="I am a business owner"
                  control={
                    <Checkbox
                      checked={isBusiness}
                      onChange={() => setIsBusiness(!isBusiness)}
                      name="checked-is-business"
                    />
                  }
                />
              </FormGroup>
            </FormRow>

            {/**End Business account check */}
            <FormRow title="" style={{ display: 'flex' }}>
              <FormGroup row>
                <FormControlLabel
                  label={
                    <TermsConditionsPopup
                      termsAccepted={termsAccepted}
                      setTermsAccepted={(val) => setTermsAccepted(val)}
                      openTerms={openTerms}
                      setOpenTerms={setOpenTerms}
                    />
                  }
                  control={
                    <Checkbox
                      checked={termsAccepted}
                      onClick={() => setOpenTerms(true)}
                      //   onChange={() => setTermsAccepted(!termsAccepted)}
                      name="checked-terms-accepted"
                    />
                  }
                />
              </FormGroup>
            </FormRow>

            {/* Button Container */}
            <Grid container alignItems="center" item xs={12} spacing={3} className={classes.buttonContainer}>
              <Grid item xs={2} />
              <Grid item xs={5}>
                {/* <Paper className={classes.paper}> */}
                <Button
                  type="submit"
                  color="primary"
                  fullWidth
                  variant="contained"
                  disabled={!termsAccepted}
                  className={classes.signUpButton}
                >
                  {!saving && <Typography variant="body1">Join Us</Typography>}
                  {saving && <CircularProgress size={24} color="secondary" />}
                </Button>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={5}>
                {/* <Paper className={classes.paper}> */}
                <Button color="secondary" fullWidth variant="contained" className={classes.cancelButton}>
                  <Typography variant="body1">Cancel</Typography>
                </Button>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            {/* End Button Container */}
          </form>
        </Paper>
      </div>
      {/**Modal for sign up error*/}
      <Modal
        className={classes.modal}
        open={signUpError}
        onClose={() => {
          setSignUpError(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={signUpError}>
          <div className={classes.modalPaper}>
            <>
              <Typography className={classes.statusMessage}>
                There was an issue while saving your data. Please try again.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSignUpError(false)}
                className={classes.statusAcceptButton}
              >
                Ok
              </Button>
            </>
          </div>
        </Fade>
      </Modal>
      {/**End Modal for signup error */}
      {/**Modal for Signup Completion */}
      <Modal
        className={classes.modal}
        open={ShowVerifyModal}
        onClick={() => {
          setShowVerifyModal(false);
          history.push('/');
        }}
      >
        <Fade in={ShowVerifyModal}>
          <SignUpThankYou></SignUpThankYou>
        </Fade>
      </Modal>
      {/**End Modal for Signup Completion */}
    </div>
  );
}

export default CustomerRegisterView;
