import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core';
import TermsConditionsContent from './TermsConditionsContent';

const useStyles = makeStyles((theme) => ({
  root: {},
  openButton: { textTransform: 'none' },
  termsConditionsActions: {
    display: 'flex',
    // alignItems: "center",
    // justifyContent: "center"
  },
  acceptButton: {
    color: theme.palette.common.black,
  },
}));

export default function ScrollDialog({ setTermsAccepted, openTerms: open, setOpenTerms: setOpen }) {
  const [scroll, setScroll] = useState('paper');

  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    setTermsAccepted(true);
    setOpen(false);
  };
  const handleDisagree = () => {
    setTermsAccepted(false);
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.termsConditionsActions}>
        <Button
          onClick={() => {
            setScroll('paper');
            setOpen(true);
          }}
          className={classes.openButton}
        >
          <Typography variant="body1">Read and Accept Our Terms and Conditions</Typography>
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} scroll={scroll} aria-labelledby="terms-and-conditions" maxWidth="md">
        <DialogTitle id="terms-and-conditions-title">Read and Accept our Terms and Conditions</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          {/* <DialogContentText> */}
          <TermsConditionsContent />
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="secondary" variant="contained">
            Disagree
          </Button>
          <Button onClick={handleAccept} color="primary" variant="contained">
            I Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
