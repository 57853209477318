import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textContainer: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '398px',
      paddingRight: '0px',
    },
  },
  headingText: {
    color: theme.palette.common.font,
    fontSize: '2rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      marginBottom: '0.5rem',
    },
  },
  aboutText: {
    color: theme.palette.common.font,
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      letterSpacing: '1px',
    },
  },
}));

export default function TextSection({ title, body, titleVariant }) {
  const classes = useStyles();

  return (
    <section className={classes.textContainer}>
      <Typography variant={titleVariant || 'h2'} className={classes.headingText}>
        {title}
      </Typography>
      {body?.split('<br>').map((item, index) => (
        <Typography variant="body1" key={index} className={classes.aboutText}>
          {item}
        </Typography>
      ))}
    </section>
  );
}
