import React, { useState, useEffect, Fragment } from 'react';
import ErrorDetails from '../components/ErrorDetails';
import BannerUploader from '../components/CroppingTool/BannerUploader';
import InputGroup from '../components/InputGroup';
import Carousel from '../components/Carousel';
import CroppingTool from '../components/CroppingTool/Index';
// import noBanner from '../components/adminBanners/nobanner.jpg';

import {
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Fade,
  Backdrop,
  Divider,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { PopoverPicker } from '../components/adminBanners/PopoverPicker';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import api from '../utils/api';
import { CATEGORIES, STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {},

  bannerHeader: {
    width: '60%',
    fontSize: '48pt',
    lineHeight: 1.1,
    fontWeight: '600',
    color: '#ffffff',
    textShadow: '1px 1px #4e652e',
    marginLeft: theme.spacing(10),
    marginTop: theme.spacing(12),
    '@media (max-width: 1205px)': {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
      fontSize: '26pt !important',
      width: '90%',
    },
  },

  bannerButton: {
    boxShadow: '0px 0px',
    marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(10),
    marginLeft: 'auto',
    height: 50,
    width: 170,
    // [theme.breakpoints.down("md")]: {
    // 	marginLeft: theme.spacing(2),
    // },
  },

  bannerPreview: {
    zIndex: -1,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    // height: "40vh",
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: '25vh',
    },
  },
  previewTitle: {
    display: 'flex',
    color: 'black',
    fontSize: '20px',
    justifyContent: 'flex-start',
    marginTop: '20px',
    marginBottom: '20px',
  },
  textContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
  },
  textContentInnerContainer: {
    width: '100%',
    maxWidth: '1245px',
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'relative',
  },
  content: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentInnerContainer: {
    width: '100%',
    // maxWidth: "2000px",
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  formButton: {
    margin: theme.spacing(1),
    width: 100,
    alignSelf: 'flex-end',
  },
  bannerInfo: {
    marginTop: theme.spacing(3),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentEditor: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  tableHeader: {
    fontWeight: 'bold',
  },
}));

const saveBannerToApi = async (data, bannerNumber) => {
  const body = {
    [`bannerText${bannerNumber}`]: data.bannerTextCheckBox ? data.bannerText : '',
    [`file${bannerNumber}`]: data.file ? data.file : null, //Link to Image
    [`link${bannerNumber}`]: data.bannerLinkCheckBox ? data.link : '',
    [`name${bannerNumber}`]: data.name,
    [`textStylePos${bannerNumber}`]: data.bannerTextPosition,
    [`textStyleFontSize${bannerNumber}`]: data.fontSize,
    [`textStyleColor${bannerNumber}`]: data.fontColor,
    page: data.page,
  };

  const bannerId = parseIdFromLink(data.id);
  return api
    .saveTopBanner(bannerId, body)
    .then((res) => res)
    .catch((err) => {
      throw STATUS.ERROR;
    });
};

// const createBanner = async (data, bannerNumber) => {
//Totally banners, Needs to be synced up with page creation...
// };

const uploadImage = async (data, page, banner) => {
  const formData = new FormData();
  formData.append('file', data, `${page}-${banner}.png`); //3rd Param changes the filename type should be forced by uploader and cropper
  return api
    .uploadImage(formData)
    .then((res) => {
      return res._links.self.href;
    })
    .catch((error) => {
      throw error;
    });
};
function ManageBannersView(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    //TODO: we should probably use a constant from utils that is self building whenever something gets added to the nav bar in the future so this list auto updates...
    //"Deliveriys" must be used to match the API, when changing to deliveries need to change API, may have to change type on user pg too
    pages: [...CATEGORIES],
    selectedPage: '',
    selectedBanner: '',
    bannerMatch: {},
    banners: {
      formattedBanners: {},
    },
    loading: false,
    apiState: {
      apiError: false,
      error: false,
      apiMessage: '',
    },
    openManageModal: false,
    hasChanged: 0,
    saveState: {
      saving: false,
      error: false,
      saveError: false,
      saveSuccess: false,
    },

    saveData: {
      id: '',
      bannerText: '',
      bannerTextCheckBox: false,
      bannerLinkCheckBox: false,
      fontColor: '#aabbcc',
      fontSize: null,
      bannerTextPosition: '',
      link: '',
      file: [],
    },
    history: [],
    currHistoryIndex: 0,
  });

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    api
      .getTopBanners()
      .then((res) => {
        const bannersForAllPages = res.data._embedded.topBanners;
        /* List of all banner pages with names links, and associated info, essentially flat data for all pages
		Believe this nees to be pre- assigned (POST) to the API */
        if (bannersForAllPages?.length > 0) {
          const formattedInfo = {};
          bannersForAllPages.forEach((pageInfo) => {
            const parsedInfo = {};
            for (let i = 1; i <= 5; i++) {
              //Banner Object is fixed to 5 banners (due to API)
              parsedInfo[pageInfo[`name${i}`]] = {
                name: pageInfo[`name${i}`],
                text: pageInfo[`bannerText${i}`],
                textPosition: pageInfo[`textStylePos${i}`],
                fontSize: pageInfo[`textStyleFontSize${i}`] ? pageInfo[`textStyleFontSize${i}`] : 1,
                fontColor: pageInfo[`textStyleColor${i}`] ? pageInfo[`textStyleColor${i}`] : '#FFFFFF',
                seo: pageInfo[`seo${i}`] ? pageInfo[`seo${i}`] : false,
                link: pageInfo[`link${i}`],
                file: pageInfo[`file${i}`],
                mobile: pageInfo[`mobileFile${1}`],
                id: pageInfo._links.self.href,
                page: `${pageInfo.page}`,
              };
            }
            formattedInfo[pageInfo.page] = { ...parsedInfo };
          });

          setState((prev) => ({
            ...prev,
            banners: {
              ...state.banners,
              formattedBanners: formattedInfo,
            },
            apiState: {
              ...state.apiState,
              apiMessage: res.status,
              apiError: false,
            },
            loading: false,
          }));
        } else {
          //means the backend call had no topBanners
          setState((prev) => ({ ...prev, loading: false }));
        }
      })
      .catch((err) =>
        setState((prev) => ({
          ...prev,
          apiState: { ...state.apiState, apiError: true, error: err },
        }))
      );
    // eslint-disable-next-line
  }, [state.hasChanged]);

  const saveBanner = async () => {
    const currPage = state.selectedPage;
    const currBanner = state.selectedBanner;
    const bannerNum = state.selectedBanner.slice(-1);
    const changedImage = state.saveData.newImage; //boolean that becomes true if the uploader is used
    const fileLink = state.saveData.file._links?.self.href;

    const newBannerData = {
      ...state.saveData,
      name: currBanner,
      page: currPage,
      file: fileLink,
    };
    setState((prev) => ({
      //Set Spinner
      ...prev,
      saveState: { ...state.saveState, saving: true },
    }));
    try {
      console.log('Step 1');
      if (changedImage) {
        const newImageLink = await uploadImage(state.saveData.file, currPage, currBanner);
        newBannerData.file = newImageLink;
      }
      console.log('Image uploaded, saving to api');
      await saveBannerToApi(newBannerData, bannerNum);
      console.log('Success, saved');
      const newBanners = state.banners.formattedBanners;
      newBanners[currPage][currBanner] = newBannerData;
      setState((prev) => ({
        ...prev,
        banners: {
          ...prev.banners,
          formattedBanners: {
            ...newBanners,
          },
        },
        saveState: { ...state.saveState, saving: false, saveSuccess: true },
      }));
    } catch (err) {
      setState((prev) => ({
        ...prev,
        saveState: {
          ...state.saveState,
          saveError: true,
          error: err,
          saving: false,
        },
      }));
    }
  };

  const getExistingBannerID = (page) => {
    const existingBanners = state.banners.formattedBanners[page];
    return existingBanners ? existingBanners.Banner1.id : null;
  };

  const getExistingField = (field, banner) => {
    const currPage = state.selectedPage;
    const currBanner = state.banners.formattedBanners[currPage][banner];
    return currBanner ? currBanner[field] : null;
  };

  const setTextPosn = (posnCode) => {
    setState((prev) => {
      return {
        ...prev,
        saveData: {
          ...prev.saveData,
          bannerTextPosition: posnCode,
        },
      };
    });
  };

  const applyEdit = (value) => {
    setState((prev) => ({
      ...prev,
      saveData: {
        ...state.saveData,
        file: value,
        newImage: true,
      },
    }));
  };

  // const changeBannerText = (e) => {
  //   e.persist();
  //   const newText = e.target.value;
  //   setState((prev) => {
  //     return {
  //       ...prev,
  //       saveData: {
  //         ...prev.saveData,
  //         bannerText: newText,
  //       },
  //     };
  //   });
  // };

  const clearBannerInfo = () => {
    const emptyBanner = {
      id: state.saveData.id,
      page: state.selectedPage,
      bannerLinkCheckBox: false,
      link: null,
      bannerTextCheckBox: false,
      bannerText: null,
      bannerTextPosition: null,
      file: [],
      fontColor: null,
      fontSize: null,
      mobile: null,
      newImage: false,
      seo: false,
    };
    /* CANNOT CLEAR BANNER 1 --> ao: update - beaut-167 can clear banner 1 after changing it to roll back to using the defaut image */
    // if (state.selectedBanner !== "Banner1") {
    setState((prev) => {
      return {
        ...prev,
        saveData: { ...emptyBanner },
      };
    });
    setValue(1);
    // }
  };

  //======================================== TABS ===================================================
  const SEOBannerSelected = state.selectedBanner === 'Banner1';

  const [value, setValue] = useState(0); //[] If the variable name is changed to tabs they stop working, not sure why
  const changeTab = (e, value) => {
    setValue(value);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };
  //===========================================================================
  const setBannerFiles = (value, tabIndex) => {
    let newHistory = state.history;
    const lastIndex = state.history.length - 1;
    let currIndex = state.currHistoryIndex;

    if (currIndex === lastIndex) {
      /* On Last Item */
      newHistory.push(value);
    } else {
      /* Create a new history from current index */
      newHistory = newHistory.slice(0, currIndex + 1);
      newHistory.push(value);
    }

    currIndex++;
    setState((prev) => ({
      ...prev,
      saveData: { ...state.saveData, file: value, newImage: true },
      history: newHistory,
      currHistoryIndex: currIndex,
    }));
    if (tabIndex === 1) {
      setValue(0);
    }
  };

  //================================================================================================  UNDO

  const disableUndoRedo = () => {
    const historyLength = state.history.length;
    const disableButtons = {
      undo: false,
      redo: false,
    };
    if (state.currHistoryIndex <= 0) {
      disableButtons.undo = true;
    }

    if (state.currHistoryIndex >= historyLength - 1) {
      disableButtons.redo = true;
    }

    return disableButtons;
  };

  const undoCrop = () => {
    const indexOfLastFile = state.currHistoryIndex - 1;
    const lastImage = state.history[indexOfLastFile];
    setState((prev) => ({
      ...prev,
      saveData: { ...state.saveData, file: lastImage, newImage: true },
      currHistoryIndex: indexOfLastFile,
    }));
  };
  const redoCrop = () => {
    const historyLength = state.history.length;
    if (state.currHistoryIndex < historyLength) {
      const indexOfNextFile = state.currHistoryIndex + 1;
      const lastImage = state.history[indexOfNextFile];
      setState((prev) => ({
        ...prev,
        saveData: { ...state.saveData, file: lastImage, newImage: true },
        currHistoryIndex: indexOfNextFile,
      }));
    }
  };
  console.log('state', state);

  //================================================================================================
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <Typography variant="h4" className={classes.title}>
            Manage Banners
          </Typography>
          {state.APIError && <Typography>There was an issue fetching banner information from the server.</Typography>}
          {state.loading && <Typography>Loading...</Typography>}

          {!state.loading && !state.APIError && (
            <Fragment>
              <div className={classes.form}>
                <FormControl variant="filled" className={classes.formControl}>
                  {/*PAGE SELECTOR */}
                  <InputLabel>Select Page</InputLabel>
                  <Select
                    value={state.selectedPage ? state.selectedPage : ''}
                    onChange={(event) => {
                      setState((prev) => ({
                        ...prev,
                        selectedPage: event.target.value,
                        selectedBanner: '',
                        saveData: {
                          ...state.saveData,
                          id: getExistingBannerID(event.target.value),
                        },
                      }));
                    }}
                  >
                    {state.pages
                      // .sort((first, second) => first.localeCompare(second))
                      .map((page, index) => (
                        <MenuItem key={index} value={page}>
                          {page}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel>Select Banner</InputLabel>
                  <Select
                    disabled={!state.selectedPage}
                    //These are the banners for the selected page which will come in from the backend
                    value={state.selectedBanner ? state.selectedBanner : ''}
                    onChange={(event) => {
                      /* Sets preview Banner */
                      const bannerName = event.target.value;
                      const file = getExistingField('file', bannerName) || {};
                      setState((prev) => ({
                        ...prev,
                        selectedBanner: bannerName,

                        saveData: {
                          ...state.saveData,
                          bannerText: getExistingField('text', bannerName),
                          bannerTextCheckBox: getExistingField('text', bannerName) ? true : false,
                          bannerLinkCheckBox: getExistingField('link', bannerName) ? true : false,

                          //these ones are new
                          bannerTextPosition: getExistingField('textPosition', bannerName),
                          fontColor: getExistingField('fontColor', bannerName),
                          fontSize: getExistingField('fontSize', bannerName),
                          seo: getExistingField('link', bannerName) ? true : false,

                          link: getExistingField('link', bannerName),
                          mobile: getExistingField('mobile', bannerName),
                          page: getExistingField('page', bannerName),
                          file,
                          newImage: false, //[]bool flag that will change when a new file is uploaded
                        },
                        history: [file],
                      }));
                    }}
                  >
                    {/*BANNER SELECTOR.*/}
                    {/*NOTE: the NAMES of the banners here in this array are VERY important. We use it to match the names in the backend*/}
                    {state.selectedPage &&
                      ['Banner1', 'Banner2', 'Banner3', 'Banner4', 'Banner5'].map((bannerName, index) => {
                        // Object.keys(state.banners.formattedBanners[state.selectedPage]).map((bannerName, index) => {
                        const currPage = state.selectedPage;
                        const bannerFileExists = currPage && state.banners.formattedBanners[currPage][bannerName]?.file;
                        /* [] Not an ideal check but will work for v1 */
                        return (
                          <MenuItem key={index} value={bannerName}>
                            {bannerFileExists ? bannerName : `${bannerName} - INACTIVE`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              {/*BANNER UPLOAD / PREVIEW SECTION */}

              {state.selectedBanner && (
                <Fragment>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="h4" align="center" className={classes.previewTitle}>
                      Banner Preview
                    </Typography>
                    {/*[] Add flag for changes to show unsaved */}
                    {/*        {true && (
                      <Typography align="center" className={classes.previewTitle}>
                        (Unsaved changes)
                      </Typography>
                    )} */}
                    {/* ao: moved Save Banner Button to the bottom */}
                    {/* <Button
											variant="contained"
											color="primary"
											// JustifyContent="right"
											disabled={false}
											onClick={saveBanner}
											className={classes.okconfirmationButton}
										>
											{state.saveState.saving ? (
												<CircularProgress size={15} color="secondary" />
											) : (
												"Save Banner"
											)}
										</Button> */}
                  </span>
                  <Divider variant="middle" />
                  {state.saveData.file.url && (
                    <Card className={classes.bannerPreview}>
                      {state.saveData.file.url ? (
                        <Carousel
                          id="carouselPreview"
                          type={state.selectedPage}
                          previewBannerName={state.selectedBanner}
                          previewBannerData={{
                            ...state.saveData,
                          }}
                        />
                      ) : (
                        <h2>{`No Banner Image: LOADIGN:>${state.loading} ${state.saveData.file.url}`}</h2>
                      )}
                    </Card>
                  )}
                  {SEOBannerSelected && <h3>Note: Banner1 text cannot be edited due to SEO </h3>}

                  {/* [] If the text fields are in the Tabs they lose focus on changes/ re-render... fix later*/}
                  <span>
                    <Typography variant="h4" align="center" className={classes.previewTitle}>
                      Edit Banner Content
                    </Typography>
                    <Divider variant="middle" />
                    <div className={classes.bannerInfo}>
                      <List className={classes.contentEditor}>
                        {/*BANNER LINK CHECK BOX */}
                        <span style={{ width: '100%' }}>
                          {/*BANNER TEXT CHECK BOX */}
                          <ListItem alignItems="center">
                            <Checkbox
                              disabled={SEOBannerSelected}
                              checked={SEOBannerSelected || state.saveData.bannerTextCheckBox}
                              onChange={(event) =>
                                setState((prev) => ({
                                  ...prev,
                                  saveData: {
                                    ...state.saveData,
                                    bannerTextCheckBox: event.target.checked,
                                  },
                                }))
                              }
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <TextField
                              fullWidth
                              disabled={SEOBannerSelected || !state.saveData.bannerTextCheckBox}
                              label="Banner Text"
                              multiline={true}
                              value={SEOBannerSelected ? 'Banner 1 content is hard coded' : state.saveData.bannerText}
                              onChange={(e) => {
                                //this e.persist is magic..withiout it the code fails. Thanks stack overflow!
                                e.persist();
                                setState((prev) => ({
                                  ...prev,
                                  saveData: {
                                    ...state.saveData,
                                    bannerText: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </ListItem>
                          {/* Banner Link ============================================*/}
                          <ListItem alignItems="center">
                            <Checkbox
                              disabled={SEOBannerSelected}
                              checked={SEOBannerSelected || state.saveData.bannerLinkCheckBox}
                              onChange={(event) => {
                                const boxStatus = event.target.checked;
                                setState((prev) => ({
                                  ...prev,
                                  saveData: {
                                    ...state.saveData,
                                    bannerLinkCheckBox: boxStatus,
                                    link: boxStatus ? state.saveData.link : '',
                                  },
                                }));
                              }}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <TextField
                              fullWidth
                              disabled={SEOBannerSelected || !state.saveData.bannerLinkCheckBox}
                              label="Banner Button Link"
                              helperText="Use full URL ie. http://google.ca"
                              type="url"
                              value={SEOBannerSelected ? 'Banner 1 content is hard coded' : state.saveData.link}
                              onChange={(e) => {
                                //this e.persist is magic..withiout it the code fails. Thanks stack overflow!
                                e.persist();
                                setState((prev) => ({
                                  ...prev,
                                  saveData: {
                                    ...state.saveData,
                                    link: e.target.value,
                                  },
                                }));
                              }}
                            />
                          </ListItem>
                        </span>

                        {/*TEXT STYLES SECTION ================================================*/}

                        <FormControl
                          component="fieldset"
                          style={{
                            marginLeft: '50px',
                            marginRight: '50px',
                            marginTop: '10px',
                            marginBottom: '10px',
                          }}
                        >
                          {/* <FormLabel component="legend">Text Styling Options</FormLabel> */}
                          <FormGroup row>
                            <FormLabel component="legend">Text Position</FormLabel>
                            <div style={{ marginRight: '2rem' }}>
                              <ButtonGroup
                                color="primary"
                                aria-label="outlined primary button group"
                                label="Text Position"
                              >
                                <Button onClick={() => setTextPosn('ss')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(315deg)' }} />}
                                </Button>
                                <Button onClick={() => setTextPosn('sc')}>{<ArrowDropUpIcon />}</Button>
                                <Button onClick={() => setTextPosn('se')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(45deg)' }} />}
                                </Button>
                              </ButtonGroup>
                              <br />
                              <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={() => setTextPosn('cs')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(270deg)' }} />}
                                </Button>
                                <Button onClick={() => setTextPosn('cc')}>Ctr</Button>
                                <Button onClick={() => setTextPosn('ce')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(90deg)' }} />}
                                </Button>
                              </ButtonGroup>
                              <br />
                              <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={() => setTextPosn('es')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(225deg)' }} />}
                                </Button>
                                <Button onClick={() => setTextPosn('ec')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(180deg)' }} />}
                                </Button>
                                <Button onClick={() => setTextPosn('ee')}>
                                  {<ArrowDropUpIcon style={{ transform: 'rotate(135deg)' }} />}
                                </Button>
                              </ButtonGroup>
                            </div>
                            {/* [] ISSUE: Font size>Carousel can use the multiplier @ desktop however I am unsure how to apply it at theme breakpoints
                                 (since these are applied before props).  Disabling ATT*/}
                            {/*   <TextField
                                  style={{ zIndex: 1 }}
                                  disabled={!state.saveData.bannerTextCheckBox}
                                  label="Font size multiplier"
                                  value={state.saveData.fontSize ? state.saveData.fontSize : ''}
                                  onChange={(e) => {
                                    //this e.persist is magic..withiout it the code fails. Thanks stack overflow!
                                    e.persist();
                                    setState((prev) => ({
                                      ...prev,
                                      saveData: { ...state.saveData, fontSize: e.target.value },
                                    }));
                                  }}
                                /> */}
                            <FormControlLabel
                              value="end"
                              control={
                                <PopoverPicker
                                  color={state.saveData.fontColor}
                                  onChange={(event) =>
                                    setState((prev) => ({
                                      ...prev,
                                      saveData: {
                                        ...state.saveData,
                                        fontColor: event ? event : '#aabbcc',
                                      },
                                    }))
                                  }
                                />
                              }
                              label="Color"
                              labelPlacement="end"
                            />
                          </FormGroup>
                        </FormControl>
                      </List>
                    </div>
                  </span>

                  <AppBar position="static" style={{ zIndex: 1 }}>
                    <Tabs value={value} onChange={changeTab} aria-label="simple tabs example">
                      <Tab label="crop image" {...a11yProps(0)} />
                      <Tab label="upload image" {...a11yProps(1)} />
                      <Tab
                        // disabled={SEOBannerSelected}
                        label="Clear Banner"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0} label={'Crop Image'}>
                    <CroppingTool
                      imageSrc={state.saveData.file.url}
                      applyEdit={applyEdit}
                      setFiles={setBannerFiles}
                      undoCrop={undoCrop}
                      redoCrop={redoCrop}
                      disableUndoRedo={disableUndoRedo()}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1} label={'Upload Image'}>
                    <Typography variant="h4" align="center">
                      ↓ Upload Banner to {`${state.selectedPage}`} slot{' '}
                      {`${state.selectedBanner}`.charAt(state.selectedBanner.length - 1)} ↓
                    </Typography>

                    <div className={classes.InnerBox}>
                      <InputGroup noTitle>
                        <BannerUploader
                          disabled={false}
                          files={state.saveData.file}
                          setFiles={(e) => setBannerFiles(e, value)}
                          error={false}
                        ></BannerUploader>
                      </InputGroup>
                    </div>
                  </TabPanel>
                  {/* ao: beaut-167 - can change Banner1 image and clear it to use default */}
                  {/* {!SEOBannerSelected && ( */}
                  <TabPanel value={value} index={2} label={'Clear Banner'}>
                    <Typography variant="h4" align="center" className={classes.previewTitle}>
                      Clear this Banner? (Will Need to Save the banner after it is cleared)
                    </Typography>
                    <Button onClick={clearBannerInfo}>DELETE</Button>
                  </TabPanel>
                  {/* )} */}
                  <Divider variant="middle" />
                  <Button
                    variant="contained"
                    color="primary"
                    // JustifyContent="right"
                    disabled={false}
                    onClick={saveBanner}
                    className={classes.bannerButton}
                  >
                    {state.saveState.saving ? <CircularProgress size={15} color="secondary" /> : 'Save Banner'}
                  </Button>
                </Fragment>
              )}
            </Fragment>
          )}
        </div>
      </div>

      {/* =====================MODALS SECTION=====================================*/}
      <Modal
        className={classes.modal}
        open={
          state.saveState.saveError || state.saveState.saveSuccess || state.apiState.apiError || state.openManageModal
        }
        onClose={() => {
          setState((prev) => ({
            ...prev,
            apiState: { ...state.apiState, apiError: false, apiMessage: '' },
            saveState: {
              ...state.saveState,
              saveError: false,
              saveSuccess: false,
            },
          }));
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={
            state.saveState.saveError || state.saveState.saveSuccess || state.apiState.apiError || state.openManageModal
          }
        >
          <div className={classes.modalPaper}>
            {(state.apiState.apiError || state.saveState.saveError) && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  There was an issue {state.saveState.saveError ? 'saving' : 'retrieving'} data{' '}
                  {state.saveState.saveError ? 'to' : 'from'} the backend
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      saveState: { ...state.saveState, saving: false },
                      apiState: { ...state.apiState, apiError: false },
                    }));
                  }}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
                <ErrorDetails
                  error={state.apiState.error ? state.apiState.error : state.saveState.error}
                  data={state}
                ></ErrorDetails>
              </Fragment>
            )}
            {state.saveState.saveSuccess && (
              <Fragment>
                <Typography className={classes.statusMessage}>Successfully saved!</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      saveState: { ...state.saveState, saveSuccess: false },
                      hasChanged: state.hasChanged + 1,
                    }))
                  }
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
              </Fragment>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ManageBannersView;
