import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FeaturedListItem from './FeaturedListItem';
import SkeletonFeaturedListItem from './SkeletonFeaturedListItem';
import { Typography, Button, List, ListItem, useMediaQuery, ButtonGroup } from '@material-ui/core';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import BrandProductItem from "./BrandProductItem";
// import FeaturedProductItem from "./FeaturedProductItem";
// import { FEATURED_TYPES } from "../utils/constants";
// import { parseIdFromLink } from "../utils/helpers";
// import { ListCaption } from './ListCaption';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  storeListContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  list: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflowX: 'scroll',
    },
  },
  listCentered: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      // justifyContent: "space-between",
      height: 'fit-content',
      marginBottom: '2px',
    },
  },
  spacer: {
    width: '14rem',
    [theme.breakpoints.down('md')]: {
      width: '8rem',
    },
    [theme.breakpoints.down('xmd')]: {
      // xmd = 794px
      width: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: 0,
    },
  },
  // titleTop: {
  //   marginLeft: theme.spacing(1.5),
  //   marginTop: theme.spacing(1.5),
  //   flexGrow: 1,
  //   fontSize: "26pt",
  //   fontWeight: "900",
  //   color: theme.palette.secondary.main,
  //   [theme.breakpoints.down("md")]: {
  //     marginLeft: theme.spacing(1),
  //     marginTop: theme.spacing(1),
  //     fontSize: "22pt",
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     marginLeft: theme.spacing(0.5),
  //     marginTop: theme.spacing(0.5),
  //     fontSize: "14pt",
  //   },
  // },
  title: {
    // marginTop: theme.spacing(1.5),
    // flexGrow: 1,
    // fontSize: "26pt",
    fontSize: '1.75rem',
    textTransform: 'uppercase',
    letterSpacing: '5px',
    color: theme.palette.common.font,
    [theme.breakpoints.down('md')]: {
      // marginLeft: theme.spacing(1),
      // marginTop: theme.spacing(1),
      // fontSize: "22pt",
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      // marginLeft: 0,
      // marginTop: theme.spacing(0.5),
      // fontSize: "14pt",
      fontSize: '0.80rem',
      fontWeight: 'bold',
    },
  },
  titleSection: {
    display: 'flex',
    textAlign: 'center',
    width: 'fit-content',
    maxWidth: '75%', //To prevent overlap with button group (absolute positioning)
    // paddingBottom: '1rem',
    // paddingLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      // paddingBottom: 0,
      // fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%',
      // marginLeft: theme.spacing(0.5),
      // display: "flex",
      // alignItems: "center",
      // position: "inherit",
      // transform: 'none',
      // marginTop: "0px",
      // left: '5%',
      // position: "relative",
      // marginRight: 'auto',
      // transform: "translateX(-75%)",
      // paddingBottom: 0,
    },
  },
  titleSectionCategory: {
    flex: '0 1 auto',
    // marginLeft: theme.spacing(0.5),
    // display: "flex",
    // alignItems: "center",
    // width: "100%",
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    // transform: "translate(-50%, -50%)",
    // marginTop: "50px",
    paddingBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
      fontWeight: 'bold',
    },
    [theme.breakpoints.down('sm')]: {
      height: '20px',
      // marginLeft: theme.spacing(0.5),
      // display: "flex",
      // alignItems: "center",
      // position: "inherit",
      // transform: "none",
      // // marginTop: "0px",
      // left: "5%",
      // // position: "relative",
      // marginRight: "auto",
      // transform: "translateX(-75%)",
      paddingBottom: 0,
    },
  },
  titleContainerCategory: {
    flex: '0 1 auto',
    width: 'max-content',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    textAlign: 'center',
  },
  buttonGroup: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    // flex: '0 0.33 auto',
    // marginLeft: 'auto',
    // marginTop: theme.spacing(0.5),
  },
  viewAllButton: {
    backgroundColor: theme.palette.common.characters,
    // textShadow: `1px 1px ${theme.palette.common.font}`,
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
    height: '95%',
    width: 110,
    // color: 'blue',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {
      height: '85%',
      width: 80,
      fontSize: '0.75em',
      padding: theme.spacing(0.5, 1, 0.5, 1),
    },
  },
  pageButton: {
    height: '95%',
    width: 40,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  pageButtons: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  storeListCard: {
    // width: 392,
    width: 250,
    [theme.breakpoints.down('md')]: {
      // width: 352,
      width: 160,
      paddingRight: 0,
    },
  },
  emptyList: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 210,
    [theme.breakpoints.down('md')]: {
      height: 170,
    },
  },
  symbol: {
    width: '6em',
    [theme.breakpoints.down('md')]: {
      width: '5em',
    },
    [theme.breakpoints.down('sm')]: {
      width: '4em',
    },
  },
  directionalButton: {
    height: '100%',
    width: '100%',
  },
  leftIndicator: {
    position: 'absolute',
    left: -10,
    top: '50%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  rightIndicator: {
    position: 'absolute',
    right: -10,
    top: '50%',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

function FeaturedList(props) {
  const cardWidth = useMediaQuery('(max-width:1023px)') ? 352 : 354;
  const [listWidth, setListWidth] = useState(window.innerWidth > 1200 ? 1200 : window.innerWidth);

  window.addEventListener('resize', function () {
    setListWidth(window.innerWidth > 1200 ? 1200 : window.innerWidth);
  });

  const { loading, listData, title, type, viewAllPath, index, location, categoryMode } = props; //Rmv'd caption

  // const mobile = useMediaQuery("(max-width:1024px)");
  const mobile = useMediaQuery('(max-width:767px)');
  // const smallTablet = useMediaQuery('(max-width:794px)');
  // const tablet = useMediaQuery('(max-width:1024px)');

  const centered = mobile ? listData.length < 3 : listData.length < 5;

  const [endReached, setEndReached] = useState(listData.length > 2 ? false : true);
  const [startReached, setStartReached] = useState(true);

  useEffect(() => {
    if (listData.length > 2) {
      setEndReached(false);
    }
  }, [listData]);

  const handleScroll = (e) => {
    const end = e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth;

    if (end && listData.length > 0) {
      setEndReached(true);
    } else {
      setEndReached(false);
    }

    const start = e.target.scrollLeft <= 0;

    if (start && listData.length > 0) {
      setStartReached(true);
    } else {
      setStartReached(false);
    }
  };

  const classes = useStyles();

  const [page, setPage] = useState(0);

  const count = listWidth / cardWidth;

  const pageUp = () => {
    setPage(page + 1);
    $(`#store-list-${index}`).animate({
      scrollLeft: Math.floor((cardWidth * (page + 1) * count) / cardWidth) * cardWidth,
    });
  };

  const pageDown = () => {
    setPage(page - 1);
    $(`#store-list-${index}`).animate({
      scrollLeft: Math.floor((cardWidth * (page - 1) * count) / cardWidth) * cardWidth,
    });
  };

  // let highlighted = title.split(" ")[0].toUpperCase();
  // let nonHighlighted = title
  //   .split(" ")
  //   .slice(1, title.length)
  //   .join(" ")
  //   .toUpperCase();
  return (
    <div className={classes.root}>
      <div className={classes.storeListContainer}>
        <div className={classes.header}>
          {/* [] TODO - Clean up this mess - why is it nested list this?*/}
          <div className={categoryMode && mobile ? classes.titleSectionCategory : classes.titleSection}>
            <div
              className={categoryMode && mobile ? classes.titleContainerCategory : null}
              style={{ display: 'flex', flexWrap: 'wrap' }}
            >
              <Typography variant="h2" className={classes.title}>
                {title}
              </Typography>
            </div>
          </div>

          <div className={classes.buttonGroup}>
            {!categoryMode && (
              <Button
                disabled={loading}
                variant="contained"
                className={classes.viewAllButton}
                color="primary"
                component={Link}
                to={viewAllPath}
                //style={{ color: "#ffffff", textDecoration: "#ffffff" }}
              >
                View All
              </Button>
            )}
            <ButtonGroup className={classes.pageButtons}>
              <Button variant="text" onClick={() => pageDown()} disabled={page === 0} className={classes.pageButton}>
                {page === 0 && (
                  <img
                    src={require(`../assets/navigation-icons/leftArrowUnSelected.svg`)}
                    alt={`left-arrow`}
                    className={classes.directionalButton}
                  />
                )}
                {page !== 0 && (
                  <img
                    src={require(`../assets/navigation-icons/leftArrowSelected.svg`)}
                    alt={`left-arrow`}
                    className={classes.directionalButton}
                  />
                )}
              </Button>
              <Button
                variant="text"
                onClick={() => pageUp()}
                disabled={page === Math.floor(listData.length / count)}
                className={classes.pageButton}
              >
                {page === Math.floor(listData.length / count) && (
                  <img
                    src={require(`../assets/navigation-icons/rightArrowUnSelected.svg`)}
                    alt={`right-arrow`}
                    className={classes.directionalButton}
                  />
                )}
                {page !== Math.floor(listData.length / count) && (
                  <img
                    src={require(`../assets/navigation-icons/rightArrowSelected.svg`)}
                    alt={`right-arrow`}
                    className={classes.directionalButton}
                  />
                )}
              </Button>
            </ButtonGroup>
          </div>
        </div>

        {/* <ListCaption text={caption} /> */}

        {loading && (
          <List
            className={classes.list}
            // id={`store-list-${index}`}
            disablePadding
            // onScroll={e => handleScroll(e)}
          >
            {/* Loop to place 6x skeleton items */}
            {[...Array(6)].map((item, index) => (
              <ListItem key={`skeleton-${index}`} className={classes.storeListCard}>
                <SkeletonFeaturedListItem />
              </ListItem>
            ))}
          </List>
        )}
        {!loading && listData.length === 0 && (
          <Typography className={classes.emptyList}>Currently not available</Typography>
        )}
        {!loading && listData.length > 0 && (
          <List
            // className={(centered && !mobile) ? classes.listCentered : classes.list}
            className={centered ? classes.listCentered : classes.list}
            id={`store-list-${index}`}
            disablePadding
            onScroll={(e) => handleScroll(e)}
          >
            {listData
              .filter((item) => {
                //Filter out admin message store
                const adminMessageStoreId = process.env.REACT_APP_MSG_STORE_ID;
                const itemId = item.store?._links.self.href.split('/').pop().replace('{?projection}', ''); //id parsed from link
                return itemId !== adminMessageStoreId;
              })
              .map((item, index) => {
                const isLastItem = index === listData.length - 1;
                return (
                  <ListItem
                    className={classes.storeListCard}
                    key={index}
                    style={isLastItem ? { paddingRight: 16 } : null}
                  >
                    <FeaturedListItem
                      item={item.store}
                      key={index}
                      type={type}
                      location={location}
                      // mobile={mobile}
                    ></FeaturedListItem>
                  </ListItem>
                );
              })}
          </List>
        )}
        {!startReached && <ChevronLeftIcon fontSize="large" className={classes.leftIndicator} />}
        {!endReached && <ChevronRightIcon fontSize="large" className={classes.rightIndicator} />}
      </div>
    </div>
  );
}

export default FeaturedList;
