// *** FROM SDAW - MODIFY AS NEEDED ****
import React, { Children } from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(1)
  },
  inputFieldGroup: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "space-around"
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  inputField: {
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    width: "100%"
  }
}));

function InputGroup(props) {
  const { title, noTitle } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="body1">
        {title ? title : noTitle ? "" : <br></br>}
      </Typography>
      <div className={classes.inputFieldGroup}>
        {Children.map(props.children, child => (
          <div className={classes.inputField}>{child}</div>
        ))}
      </div>
    </div>
  );
}

export default InputGroup;
