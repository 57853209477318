/* Form to create an appointment
Can be used to create or edit an appointment
Takes in service items for the business to list in a drop down, but also allows for custom services 
- on the api appointment services will be strings rather than links to the service table 
  - should make link if available but allow for custom service - revisit later
Takes in a date and time for the appointment - assumes business does not work overnight
  - need to account for server UTC time vs local time and timezones (with utc may go over curr day...)
Takes in customer name and phone number
Takes in notes for the appointment

Server uses ISO 8601 format for dates and times

-[ ] Handle timezones properly - convert on initialization so form and selectors are in local time
-[ ] Handle submission to api - create or update

Future additions (when booking is fully implemented):
- Add customer email / link to customer account
- customers phone / link to account
- Add customer payment information
- payment amount (total)
- deposit
- payment method
- payment status
- payment date
- payment notes
- Checkin status / also no show note

STRETCH
 - on create - have start time default to current time (upcoming option), and end time default to +30min from current time
 - make time options more flexible ie 5pm gives 05:00 PM as value, 530pm gives 05:30 PM and 05:30 AM as options

 -[ ]  Prevent double bookings - check if time is available before submitting. Have a toast/ snackbar message if not available
  -[ ]  In future maybe indicate on time drop down or remove unavailable times?
  -[ ] Longer term - will allow concurrent appointments as staff / resources will be added. (ie x wash & dry as y gets a trim -   just info at that point
*/

import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppointmentTimeSelectors from './AppointmentTimeSelectors';

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  let formattedNumber = phoneNumber.replace(/[^0-9]/g, ''); //Only accept numbers
  if (phoneNumber.length >= 3 && phoneNumber.length <= 6) {
    formattedNumber = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  } else if (phoneNumber.length > 6) {
    formattedNumber = `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  return formattedNumber;
};

const doesAppointmentOverlap = (newStart, newEnd, appointments, currApptId) => {
  //Check if new appointment overlaps with any existing appointments
  //Return true if there is an overlap, false if not
  //Also take in ID of current appt form IOT prevent false positive (so it doesn't count itself as an overlap)
  for (let i = 0; i < appointments.length; i++) {
    const existingStart = new Date(appointments[i].startDateTime);
    const existingEnd = new Date(appointments[i].endDateTime);
    if (newStart < existingEnd && newEnd > existingStart) {
      const isApptItself = currApptId === appointments[i].id;
      return isApptItself ? false : true;
    }
  }
  return false;
};

export default function AppointmentForm({ appointmentToEdit, actions, serviceList, selectedDate, appointments }) {
  const apptToEditIdLink = appointmentToEdit ? appointmentToEdit.id : null;
  /* ======================================================================================== */
  const [customerName, setCustomerName] = useState(appointmentToEdit?.customerName);
  const [customerPhone, setCustomerPhone] = useState(formatPhoneNumber(appointmentToEdit?.customerPhone));
  const [notes, setNotes] = useState(appointmentToEdit?.notes);
  const [service, setService] = useState(appointmentToEdit?.service);
  const [formTime, setFormTime] = useState({ start: null, end: null }); //time from selectors
  const [overlapError, setOverlapError] = useState(false); //Error if appointment overlaps with existing appointment
  /* ======================================================================================== */

  const error = actions?.error;
  const status = actions?.status;

  //================================================================================================
  useEffect(() => {
    //Check if appointment overlaps with any existing appointments
    if (formTime.start && formTime.end) {
      const newStart = new Date(formTime.start);
      const newEnd = new Date(formTime.end);
      const overlap = doesAppointmentOverlap(newStart, newEnd, appointments, apptToEditIdLink);
      setOverlapError(overlap);
    }
  }, [formTime.start, formTime.end, overlapError, appointments, apptToEditIdLink]);
  //================================================================================================
  const handleSubmit = async (event) => {
    event.preventDefault();
    //Format the form values for the data hook & api
    const formValues = {
      service,
      startDateTime: new Date(formTime.start).toISOString(),
      endDateTime: new Date(formTime.end).toISOString(),
      customerName,
      customerPhone: customerPhone.replaceAll('-', ''), //xxx-xxx-xxxx Need to remove dashes before saving to api
      notes,
      id: appointmentToEdit?.id,
    };

    // Perform form submission logic here
    //Wait for update or create (api call) to finish before closing modal
    const apiAction = !!appointmentToEdit ? actions?.editAppointment : actions?.createAppointment;
    await apiAction(formValues); // Call api via hook
  };

  const handleErrorAck = () => {
    //Go back to form to try again
    //Clear Error
    actions && actions.setError(null);
    //Reset status
    actions && actions.setStatus('idle');
  };

  const isTimeValid = (time) => {
    //Check if time is valid //Time given as string, need to convert to date to check if valid
    //Return true if valid, false if not
    time = new Date(time);
    return time instanceof Date && !isNaN(time);
  };

  const allowSave =
    service &&
    isTimeValid(formTime.start) &&
    isTimeValid(formTime.end) &&
    !overlapError &&
    customerName &&
    customerPhone.length === 12;

  const appointmentForm = (
    <>
      <h2>{appointmentToEdit ? 'Edit Appointment' : 'New Appointment'}</h2>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="service-picker"
            freeSolo
            fullWidth
            value={service}
            options={serviceList ? serviceList.map((option) => option.name) : []} //Must have an array of somekind for the options
            renderInput={(params) => {
              return <TextField {...params} label="Service" margin="normal" variant="outlined" />;
            }}
            onChange={(event, newValue) => setService(newValue)} //User selected a value
            onInputChange={(event, newInputValue) => setService(newInputValue)} //User typed in a value (not setup under services list)
          />
        </Grid>
        <Grid item xs={12}>
          <AppointmentTimeSelectors
            existingAppointment={appointmentToEdit}
            setFormTime={setFormTime}
            viewSelectedDate={selectedDate}
            overlapError={overlapError}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Name"
            variant="outlined"
            fullWidth
            value={customerName}
            onChange={(event) => {
              setCustomerName(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Phone Number"
            variant="outlined"
            fullWidth
            value={customerPhone}
            onChange={(event) => {
              event.target.value = event.target.value.replace(/[^0-9]/g, ''); //Only accept numbers, need this here to prevent extra dashes during format
              event.target.value = formatPhoneNumber(event.target.value);
              setCustomerPhone(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Notes"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={notes}
            onChange={(event) => {
              setNotes(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" disabled={!allowSave}>
            Submit
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            style={{ marginLeft: '1rem' }}
            onClick={actions?.handleCloseAddEditModal}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );

  const appointmentFormSaving = <div>Saving...</div>;
  const saveSuccess = (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography>Successfully saved</Typography>
      <br />
      <Button variant="contained" color="primary" onClick={() => actions.handleCloseAddEditModal()}>
        OK
      </Button>
    </div>
  );
  const appointmentFormError = (
    <div>
      Error occured: <br />
      {`${error}`}
      <br />
      <Button variant="contained" color="primary" onClick={handleErrorAck}>
        OK
      </Button>
    </div>
  );
  const isSaving = status === 'CREATE' || status === 'EDIT';
  return (
    <form onSubmit={handleSubmit} style={{ padding: '1.5rem' }}>
      {error && appointmentFormError}
      {isSaving && appointmentFormSaving}
      {status === 'succeeded' && saveSuccess}
      {status === 'idle' && appointmentForm}
    </form>
  );
}
