import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { FEATURED_TYPES, SCREEN_STATES } from '../utils/constants';
import { Link } from 'react-router-dom';
import { parseIdFromLink } from '../utils/helpers';
import PreviewContext from '../PreviewContext';

const useStyles = makeStyles((theme) => ({
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 240,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
    [theme.breakpoints.down('sm')]: {
      height: 160,
    },
    [theme.breakpoints.down('xs')]: {
      height: 120,
    },
  },
  cardActionArea: {
    position: 'relative',
  },
  dealStoreImage: {
    height: 80,
    width: 80,
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(1.5),
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    backgroundColor: '#ebebeb',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      width: 60,
    },
  },
}));

function NewestDealImage(props) {
  const { item, itemPath, type } = props;

  const classes = useStyles();

  // let dealStatus = "";
  // if (type === FEATURED_TYPES.DEAL) {
  //   if (new Date() > convertToDate(item.endDate)) {
  //     dealStatus = "Expired";
  //   } else if (new Date() < convertToDate(item.startDate)) {
  //     dealStatus = "Upcoming";
  //   } else {
  //     dealStatus = "Active";
  //   }
  // }

  const preview = useContext(PreviewContext);

  return (
    <Card
      component={!preview.state.screen ? Link : undefined}
      to={!preview.state.screen ? itemPath.replace('{?projection}', '') : undefined}
      style={{ color: '#000000', textDecoration: 'none', borderRadius: '0px' }}
      onClick={
        !preview.state.screen
          ? undefined
          : () =>
              preview.changeScreen(
                SCREEN_STATES.DEAL_DETAILS,
                parseIdFromLink(item._links.self.href, 0, '{?projection}')
              )
      }
    >
      <CardActionArea className={classes.cardActionArea}>
        {/* {type === FEATURED_TYPES.DEAL && (
          <img
            src={item.storeImage}
            className={classes.dealStoreImage}
            alt={item.storeName}
          ></img>
        )}
        {type === FEATURED_TYPES.DELIVERY && item.nationwide && (
          <img
            src={require(`../assets/navigation-icons/canadawideSticker.svg`)}
            alt={`navigation-icon-canada-wide`}
            className={classes.mailOutBadge}
          />
        )} */}
        <CardMedia
          className={classes.media}
          image={
            type === FEATURED_TYPES.DEAL
              ? item.dealImage
              : type === FEATURED_TYPES.BRAND
              ? item.brandImage
              : item.storeImage
          }
          title={item.name}
        />
        {/* <CardContent>
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="caption">
              {dealStatus}
            </Typography>
          )}
          <Typography
            noWrap
            variant={type === FEATURED_TYPES.DEAL ? "body2" : "body1"}
            className={
              [
                FEATURED_TYPES.STORE,
                FEATURED_TYPES.DELIVERY,
                FEATURED_TYPES.BRAND,
              ].includes(type)
                ? classes.cardTextTitle
                : classes.cardText
            }
          >
            {type === FEATURED_TYPES.DEAL ? item.storeName : item.name}
          </Typography>
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="body1" className = {classes.cardTextTitle}>
              {item.name}
            </Typography>
          )}
          {[
            FEATURED_TYPES.STORE,
            FEATURED_TYPES.DELIVERY,
            FEATURED_TYPES.BRAND,
          ].includes(type) && (
            <div className={classes.ratingContainer}>
              <Rating
                name="read-only"
                value={item.avgRating ? Number(item.avgRating) : 0}
                precision={0.5}
                size="small"
                className={classes.stars}
                readOnly
              />
              <Typography variant="body2" className={classes.ratingValue}>
                {`${item.avgRating ? Number(item.avgRating.toFixed(1)) : 0} (${
                  item.numOfReviews ? item.numOfReviews : 0
                })`}
              </Typography>
            </div>
          )}
        </CardContent> */}
      </CardActionArea>
    </Card>
  );
}

export default NewestDealImage;
