import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FormRow from './FormRow';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import LinearProgress from '@material-ui/core/LinearProgress';
import {
  Backdrop,
  Modal,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
  AppBar,
  Tabs,
  Tab,
  Box,
  Button,
  FormHelperText,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ImageUploader from './ImageUploader';
import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
// import CancelIcon from '@material-ui/icons/Cancel';
import Select from '@material-ui/core/Select';
// import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import ServiceItemsManager from './ServiceItemsManager/Index';
import {
  STATES,
  PROVINCES,
  CANADA_CITIES,
  USA_CITIES,
  CATEGORIES,
  DAYS_OF_THE_WEEK,
  // HOURS_OF_THE_DAY,
  STATUS,
  ROLES,
} from '../utils/constants';
import {
  validatePhoneNumber,
  validateURL,
  validateFacebook,
  validateTwitter,
  validateInstagram,
  validateNonEmptyString,
  validateEmail,
  timezones,
  validatePostalCode,
  parseIdFromLink,
  parseHour,
  buildHour,
  determineMobileServiceType,
} from '../utils/helpers';
import logoPlaceholder from '../assets/images/logoPlaceholder.png';
import bannerPlaceholder from '../assets/images/bannerPlaceholder.png';
import api from '../utils/api';
import { CircularProgress } from '@material-ui/core';
import { getUserID } from '../cookies';
import ErrorDetails from './ErrorDetails copy';
import CroppingTool from '../components/CroppingTool/Index';
import BannerUploader from '../components/CroppingTool/BannerUploader';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useAdminLogsData from './AdminLogs/hooks/useAdminLogsData';
import BusinessHoursFields from './AddEditStore/BusinessHoursFields';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   margin: theme.spacing(2),
  //   paddingTop: theme.spacing(4),
  //   paddingBottom: theme.spacing(4),
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   // border: '2px solid blue'
  // },

  contentContainer: {
    // paddingTop: theme.spacing(6),
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    paddingBottom: theme.spacing(4),
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      letterSpacing: '3px',
      paddingBottom: theme.spacing(2),
    },
  },

  paper: {
    textAlign: 'left',
    padding: theme.spacing(3),
    // boxShadow: 'none',
    // border: '2px purple solid'
  },
  saveButton: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  cancelButton: {
    fontWeight: 700,
  },
  inputLabel: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    textAlign: 'right',
  },
  asterisk: {
    color: theme.palette.common.red,
    fontWeight: 700,
  },
  errorMessage: {
    color: theme.palette.common.red,
  },
  formControl: {
    // margin: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  // cancelServiceItemIcon: {
  //   color: theme.palette.secondary.main,
  //   cursor: 'pointer',
  //   "&:hover": {
  //     color: theme.palette.secondary.dark,
  //   }
  // },
  buttonContainer: {
    marginTop: theme.spacing(4),
    //small screen margin top 2
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  statusCancelButton: {
    // color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  bannerPreview: {
    maxHeight: '500px',
    width: 'auto',
    aspectRatio: '1245/500',
    display: 'flex',
  },
  uploadTab: {
    border: 'solid #FF6347',
    borderRadius: '0.5rem',
    [theme.breakpoints.down('md')]: {
      width: '80px',
    },
  },
  tabs: {
    [theme.breakpoints.down('md')]: {
      width: '80px',
    },
  },
  tabsContainer: {
    [theme.breakpoints.down('md')]: {},
  },
  bannerAdvice: {
    color: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'large',
  },

  formContainer: {
    // padding: theme.spacing(4),
    // border: '1px solid',
    // borderColor: theme.palette.common.gray,
    // borderRadius: '5px',
  },
  storeForm: {
    position: 'relative',
  },
  saveSection: {
    /* Save section will start under the essentials sections and will float and always be on top of the form 
    until the bottom of the form is reached where the section will set in as the last element */
    //Unable to make it start after essentials, so it just stays on the bottom of the viewport (within the form)
    position: 'sticky',
    bottom: '0px',
    zIndex: '1',
    // backgroundColor: theme.palette.common.white,
    //slightly transparent background
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
  publishSummary: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
}));

// API functions
const manageLocations = async (province, city) => {
  return api
    .getLocationBySearch(province, city)
    .then((res) => {
      if (res.data._embedded.locations.length === 0) {
        //Create location when it does not already exist
        return api
          .createLocation(province, city)
          .then((res) => {
            return res._links.self.href;
          })
          .catch((error) => {
            throw error;
          });
      }
      return res.data._embedded.locations[0]._links.self.href;
    })
    .catch((error) => {
      throw error;
    });
};

const uploadImage = async (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return api
    .uploadImage(formData)
    .then((res) => res._links.self.href)
    .catch((err) => {
      throw err;
    });
};

const createStore = async (data) => {
  const body = {
    ...data,
    owner: `/users/${getUserID()}`,
  };
  return api
    .createStore(body)
    .then((res) => res.data._links.self.href)
    .catch((error) => {
      throw error;
    });
};

const updateStore = async (storeId, data) => {
  return api
    .updateStore(storeId, data)
    .then((res) => res.data._links.self.href)
    .catch((error) => {
      throw error;
    });
};

const uploadImages = async (data, storeId) => {
  const uploads = [];
  data.forEach((file) => {
    const formData = new FormData();
    formData.append('file', file.file);
    if (storeId) {
      formData.append('store', storeId);
      formData.append('rank', file.rank);
    }

    uploads.push(
      api
        .uploadImage(formData, storeId)
        .then((res) => {
          return api.updateImage(parseIdFromLink(res._links.self.href, 0, '{?projection}'), { store: storeId });
        })
        .catch((error) => {
          throw error;
        })
    );
  });

  return Promise.all(uploads)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const deleteImages = async (ids) => {
  return Promise.all(
    ids.map((id) =>
      api
        .deleteImage(id)
        .then((res) => res)
        .catch((err) => {
          throw err;
        })
    )
  );
};

const updateImages = async (data) => {
  return Promise.all(
    data.map((image) =>
      api
        .updateImage(image.id, { rank: image.rank })
        .then((res) => res)
        .catch((err) => {
          throw err;
        })
    )
  );
};

const getUserData = async (userId) => {
  return api
    .getUser(getUserID())
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const buildHoursObject = (hours) => {
  const builtHours = {};
  DAYS_OF_THE_WEEK.forEach((day) => {
    builtHours[`${day.toLowerCase()}Open`] = buildHour(hours[`${day}`].open);
    builtHours[`${day.toLowerCase()}Close`] =
      hours[`${day}`].open === '24 Hours' ? buildHour('24 Hours') : buildHour(hours[`${day}`].close);
  });

  return builtHours;
};

function AddEditStore(props) {
  let { storeId, editMode, addMode, previewMode } = props;

  // Variables based on initial states
  let data = null;
  let initial = useRef({});
  const history = useHistory();
  // const previewMode = window.location.href.includes('preview');

  const savedProvince = data ? data.address.province : '';
  const savedCity = data ? data.address.city : '';

  const FORM_SECTIONS = {
    //Form state fields grouped by sections only used for progress bar and required fields
    ESSENTIAL_INFO: [
      'contactFirstName',
      'contactLastName',
      'name', //of the store
      'category',
      'email',
      'phone',
      'aboutUs',
      'city',
      'province',
    ],
    SERVICES: ['serviceItems'],
    ANNOUNCEMENT: ['announcementTitle', 'announcement'],
    HOURS: ['hours'],
    SOCIAL_MEDIA: ['website', 'instagram', 'facebook', 'twitter', 'snapchat'],
    IMAGES: ['logoAvatar', 'bannerAvatar', 'galleryAvatar'],
  };

  const MOBILE_SERVICE_TYPE = {
    STORE_ONLY: 'storeOnly',
    MOBILE_ONLY: 'mobileOnly',
    STORE_WITH_MOBILE: 'storeWithMobileService',
  };

  // Set states based on props if Store Entity (if exist)

  // States to display errors
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [facebookError, setFacebookError] = useState(false);
  const [twitterError, setTwitterError] = useState(false); //eslint-disable-line
  const [instagramError, setInstagramError] = useState(false);
  // const [snapchatError, setSnapchatError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [logoImageError, setLogoImageError] = useState(false);
  const [provinceError, setProvinceError] = useState(false);
  const [formState, setFormState] = useState({});
  const [mobileServiceType, setMobileServiceType] = useState(null); //Set by useEffect
  // const [storeHref, setStoreHref] = useState("");
  const [dataReady, setDataReady] = useState(false);
  // const [loadingError, setLoadingError] = useState(false);
  // const [logoImageReady, setLogoImageReady] = useState(false);
  // const [bannerImageReady, setBannerImageReady] = useState(false);
  const [formError, setFormError] = useState(false);
  const [saving, setSaving] = useState(false);
  // const [submitted, setSubmitted] = useState(false);
  // States
  const [status, setStatus] = useState('');
  const [APIError, setAPIError] = useState('');
  const [openServiceItemsModal, setOpenServiceItemsModal] = useState(false);
  const [storeIdForServiceItems, setStoreIdForServiceItems] = useState(''); //Used to allow add service Items on store creation
  const [svcItemCount, setSvcItemCount] = useState(0); //Used to indicate # of service items, is updated by service Item manager.

  // For independent businesses (hair stylists and makeup artists), some fields are not required
  // const isIndependent =  ['Hair Stylist', 'Makeup Artist'].includes(formState.category); // For mobile only the test would be mobile service with no street address
  const statesAndProvinces = [...PROVINCES];
  const CITIES = { ...USA_CITIES, ...CANADA_CITIES };

  const isNewStore = !editMode;
  const showLegacyServiceItems = editMode && formState.serviceItems;

  const [currUser, setCurrUser] = useState(null);
  useEffect(() => {
    currUser && setCurrUser(null);
    getUserData()
      .then((userData) => {
        const username = userData.username;
        setCurrUser(username);
        // const isTCAccepted = userData.tcAccepted;
        //Whats the difference b/w initial and terms accepted?
        // setInitialAccepted(isTCAccepted ? true : false); // From API, current setting
        // setTermsAccepted(isTCAccepted ? 'accepted' : false); //Input by user? but if string will hide option
      })
      .catch((error) => setStatus(error));
    //eslint-disable-next-line
  }, []);

  const { createAndSendChangeLog } = useAdminLogsData({ mode: 'client' }); //Hook to trigger admin logs

  // Validation Function
  const validate = () => {
    let isValid = true;

    // Validate Account Phone Number
    if (!validatePhoneNumber(formState.phone)) {
      setPhoneError(true);
      isValid = false;
    }

    if (!validateEmail(formState.email)) {
      setEmailError(true);
      isValid = false;
    }
    // validate postalcode
    let country = '';
    if (STATES.includes(formState.address.province)) {
      country = 'USA';
    } else {
      country = 'Canada';
    }
    if (!validatePostalCode(country, formState.address.postalCode)) {
      isValid = false;
      setPostalCodeError(true);
    }

    if (!validateNonEmptyString(formState.address.province)) {
      setProvinceError(true);
      isValid = false;
    }
    if (!validateNonEmptyString(formState.address.city)) {
      setProvinceError(true);
      isValid = false;
    }
    if (!validateURL(formState.website)) {
      setWebsiteError(true);
      isValid = false;
    }
    if (!validateFacebook(formState.facebook)) {
      setFacebookError(true);
      isValid = false;
    }
    if (!validateTwitter(formState.twitter)) {
      setTwitterError(true);
      isValid = false;
    }
    if (!validateInstagram(formState.instagram)) {
      setInstagramError(true);
      isValid = false;
    }
    // if (!validateNonEmptyArray(formState.logoAvatar)) {
    // 	setLogoImageError(true);
    // 	isValid = false;
    // }
    // if (!validateNonEmptyArray(formState.bannerAvatar)) {
    // 	setBannerImageError(true);
    // 	isValid = false;
    // }

    return isValid;
  };

  const deleteFeaturedRanks = async () => {
    const featuredRank = await api.getFeaturedStoresRanks(storeId);
    let featuredId = '';
    featuredRank.data._embedded.featuredRanks.length > 0
      ? (featuredId = featuredRank.data._embedded.featuredRanks[0]._links.self.href)
      : (featuredId = '');
    if (featuredId) {
      return api.deleteRank(featuredId);
    } else {
      return null;
    }
  };

  const deleteAllRanks = async () => {
    const allRank = await api.getAllStoresRanks(storeId);
    let allId = '';
    allRank.data._embedded.allRanks.length > 0
      ? (allId = allRank.data._embedded.allRanks[0]._links.self.href)
      : (allId = '');
    if (allId) {
      return api.deleteRank(allId);
    } else {
      return null;
    }
  };

  const manageImages = async (storeId) => {
    if (!editMode) {
      const data = formState.galleryImages.map((img, index) => ({
        file: img,
        rank: index,
      }));
      return uploadImages(data, storeId)
        .then(() => {
          return STATUS.SUCCESS;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      const prev = initial.current.galleryImages;
      const curr = formState.galleryImages;

      let added = [];

      curr.forEach((currImg, index) => {
        if (!prev.find((prevImg) => prevImg.preview === currImg.preview)) {
          added.push({ ...currImg, file: currImg, rank: index });
        }
      });

      let removed = [];

      prev.forEach((prevImg) => {
        if (!curr.find((currImg) => prevImg.preview === currImg.preview)) {
          removed.push(prevImg);
        }
      });

      let updated = [];

      curr.forEach((currImg, index) => {
        prev.forEach((prevImg) => {
          if (prevImg.preview === currImg.preview && index !== prevImg.rank) {
            updated.push({ ...currImg, rank: index });
          }
        });
      });

      const promises = [];

      if (added.length > 0) {
        promises.push(
          uploadImages(added, storeId)
            .then()
            .catch((error) => {
              throw error;
            })
        );
      }
      if (removed.length > 0) {
        const ids = removed.map((removing) =>
          parseIdFromLink(
            initial.current.images.find((file) => file.url === removing.preview)._links.self.href,
            0,
            '{?projection}'
          )
        );

        promises.push(
          deleteImages(ids)
            .then()
            .catch((error) => {
              throw error;
            })
        );
      }

      if (updated.length > 0) {
        const data = updated.map((updating) => ({
          id: parseIdFromLink(
            initial.current.images.find((file) => file.url === updating.preview)._links.self.href,
            0,
            '{?projection}'
          ),
          rank: updating.rank,
        }));

        promises.push(
          updateImages(data)
            .then()
            .catch((error) => {
              throw error;
            })
        );
      }

      return Promise.all(promises)
        .then(() => STATUS.SUCCESS)
        .catch((error) => {
          throw error;
        });
    }
  }; // End of manageImages
  const getStore = async (id) => {
    return api
      .getStore(id)
      .then((res) => res.data)
      .catch(() => STATUS.ERROR);
  };
  const [value, setValue] = useState(0); //[] If the variable name is changed to tabs they stop working, not sure why
  const [state, setState] = useState({
    //TODO: we should probably use a constant from utils that is self building whenever something gets added to the nav bar in the future so this list auto updates...
    pages: ['Home', 'Deliverys', 'Mailouts', 'Brands', 'StoreFronts', 'Deals'],
    selectedPage: '',
    selectedBanner: '',
    bannerMatch: {},
    banners: {
      formattedBanners: {},
    },
    loading: false,
    apiState: {
      apiError: false,
      error: false,
      apiMessage: '',
    },
    openManageModal: false,
    hasChanged: 0,
    saveState: {
      saving: false,
      error: false,
      saveError: false,
      saveSuccess: false,
    },

    saveData: {
      id: '',
      bannerText: '',
      bannerTextCheckBox: false,
      bannerLinkCheckBox: false,
      fontColor: '#aabbcc',
      fontSize: null,
      bannerTextPosition: '',
      link: '',
      file: [],
    },
    history: [],
    currHistoryIndex: 0,
  });
  const setBannerFiles = (value, tabIndex) => {
    let newHistory = state.history;
    const lastIndex = state.history.length - 1;
    let currIndex = state.currHistoryIndex;

    if (currIndex === lastIndex) {
      /* On Last Item */
      newHistory.push(value);
    } else {
      /* Create a new history from current index */
      newHistory = newHistory.slice(0, currIndex + 1);
      newHistory.push(value);
    }

    currIndex++;
    setState((prev) => ({
      ...prev,
      saveData: { ...state.saveData, file: value, newImage: true },
      history: newHistory,
      currHistoryIndex: currIndex,
    }));
    if (tabIndex === 1) {
      setValue(0);
    }
  };

  const disableUndoRedo = () => {
    const historyLength = state.history.length;
    const disableButtons = {
      undo: false,
      redo: false,
    };
    if (state.currHistoryIndex <= 0) {
      disableButtons.undo = true;
    }

    if (state.currHistoryIndex >= historyLength - 1) {
      disableButtons.redo = true;
    }

    return disableButtons;
  };

  const undoCrop = () => {
    const indexOfLastFile = state.currHistoryIndex - 1;
    const lastImage = state.history[indexOfLastFile];
    setState((prev) => ({
      ...prev,
      saveData: { ...state.saveData, file: lastImage, newImage: true },
      currHistoryIndex: indexOfLastFile,
    }));
  };
  const redoCrop = () => {
    const historyLength = state.history.length;
    if (state.currHistoryIndex < historyLength) {
      const indexOfNextFile = state.currHistoryIndex + 1;
      const lastImage = state.history[indexOfNextFile];
      setState((prev) => ({
        ...prev,
        saveData: { ...state.saveData, file: lastImage, newImage: true },
        currHistoryIndex: indexOfNextFile,
      }));
    }
  };

  const clearBannerInfo = () => {
    const emptyBanner = {
      id: state.saveData.id,
      page: state.selectedPage,
      bannerLinkCheckBox: false,
      link: null,
      bannerTextCheckBox: false,
      bannerText: null,
      bannerTextPosition: null,
      file: [],
      fontColor: null,
      fontSize: null,
      mobile: null,
      newImage: false,
      seo: false,
    };
    setState((prev) => {
      return {
        ...prev,
        saveData: { ...emptyBanner },
      };
    });
    setValue(1);
  };

  const changeTab = (e, value) => {
    setValue(value);
  };
  const applyEdit = (value) => {
    setFormState((prev) => ({
      ...prev,
      bannerAvatar: {
        ...formState.bannerAvatar,
        file: value,
        newImage: true,
      },
    }));
  };
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  /* TODO - SAVING should take place in a useEffect */
  const save = async () => {
    const determineFormProgress = () => {
      // Resource Intensive to use - need to have a more efficient form before this can be used

      //Iterate through all sections and determine if they are complete
      //If they are complete, add them to the completedSections array
      //If they are not complete, add them to the incompleteSections array
      //Return an object with completedSections and incompleteSections arrays
      let completedSections = [];
      let incompleteSections = [];
      Object.keys(FORM_SECTIONS).forEach((section) => {
        //Service Items are now stores in their own state, check with svcItemCount
        const isComplete =
          section === 'SERVICES' ? !!svcItemCount : isSectionComplete(FORM_SECTIONS[section], formState);
        if (isComplete) {
          completedSections.push(section);
        } else {
          incompleteSections.push(section);
        }
      });

      // Make a string that will be saved to the database, will be used by admin to see what sections are complete and incomplete
      const progressString = `${completedSections.join(',')}|${incompleteSections.join(',')}`;

      return {
        completedSections,
        incompleteSections,
        totalSections: Object.keys(FORM_SECTIONS).length,
        percentComplete: Math.round((completedSections.length / Object.keys(FORM_SECTIONS).length) * 100),
        progressString,
      };
    };

    const formProgress = determineFormProgress(); // Slows down the form a lot - run on save only?
    // setSaving(true);
    try {
      const ids = await manageLocations(formState.address.province, formState.address.city).catch((err) => {
        throw err;
      });

      if (
        (initial.current.category !== formState.category ||
          initial.current.address.city !== formState.address.city ||
          initial.current.publish !== formState.publish) &&
        editMode
      ) {
        //TODO - can be a promise all
        //Want to keep the api interaction b/c a rank may change while the edit screen is open (admin and client saves are separate)
        //Remove Ranks if category or city has changed or unpublished
        await deleteAllRanks().catch((err) => {
          throw err;
        });
        await deleteFeaturedRanks().catch((err) => {
          throw err;
        });
      }

      let logoLink = '';
      let bannerLink = '';
      let logoChanged = false;
      let bannerChanged = false;
      let body = { ...formState, locations: [ids] }; //Add location ID and Form State to body

      if (!editMode) {
        //===================Create new Store===================
        //=====Handle Images ======
        //Leave blank if no image is uploaded by user, front end will use placeholders when no image is present
        const hasLogoImage = !!formState.logoAvatar[0];
        const hasBannerImage = !!state.saveData.file.url;
        logoLink = hasLogoImage && (await uploadImage(formState.logoAvatar[0]));
        bannerLink = hasBannerImage && (await uploadImage(state.saveData.file));

        //=====Update Info======
        //Add image links as new store, and location IDs
        if (hasLogoImage) body['file'] = logoLink;
        if (hasBannerImage) body['bannerImageFile'] = bannerLink;
      } else {
        //Assign image links to body variables - will need to refactor to leave blank if no image is uploaded by client
        const logoImageChanged = initial.current.logoAvatar?.[0]?.preview !== formState.logoAvatar?.[0]?.preview;
        const bannerImageChanged = initial.current.bannerAvatar?.url !== state.saveData?.file?.url;
        if (logoImageChanged) {
          //Update Image in /file api
          logoChanged = true;
          logoLink = formState.logoAvatar?.[0] ? await uploadImage(formState.logoAvatar[0]) : null;
        }
        // /* TODO - why is there a 'state', and a 'formState' - this seems confusing and redundant */
        if (bannerImageChanged) {
          //banner image had changed - uploading new banner (prev was image)
          bannerChanged = true;
          bannerLink = state.saveData.file.url ? await uploadImage(state.saveData.file) : null;
        }

        if (logoChanged) body['file'] = logoLink;
        if (bannerChanged) body['bannerImageFile'] = bannerLink;
      }

      //=======  For either new or existing================

      /* Format hours for API, update fields for mobile services (clears street address if necessary)  */
      body = {
        ...body,
        hours: buildHoursObject(formState.hours),
        mobileService: formState.mobileService, //TODO: change to mobileService after api update
        detailsCompleted: formProgress.progressString,
      };
      /* TODO - refactor form handling of mobileService - if below funct sets it based on mobileServiceType, it doesn't need to exist in formstate? */
      const formatMobileServicesForAPI = (storeInfo) => {
        /* Depending on mobileServiceType may clear street field (if it exists) */
        switch (mobileServiceType) {
          case MOBILE_SERVICE_TYPE.STORE_ONLY:
            storeInfo.mobileService = false;
            //Will need street address to publish, but can save as 'in progress' without street address
            break;
          case MOBILE_SERVICE_TYPE.MOBILE_ONLY:
            //Clear the street field (may exist if client changes from store to mobile only)
            storeInfo.mobileService = true;
            storeInfo.address.street = '';
            break;
          case MOBILE_SERVICE_TYPE.STORE_WITH_MOBILE:
            storeInfo.mobileService = true;
            //Will need street address to publish, but can save as 'in progress' without street address
            break;
          default:
            break;
        }
        return storeInfo;
      };
      // Update mobile servies - based on mobileServiceType, may clear street address - see function for rules
      body = formatMobileServicesForAPI(body);

      //TODO - refactor below - only the call changes based on the mode, all following actions are the same
      if (editMode) {
        //===============Store Exists  - EDIT MODE ================
        updateStore(storeId, body)
          .then(async (res) => {
            return manageImages(res).then((status) => {
              setSaving(false);
              setStatus(status);
            });
            //we need to post to pinAddresses
            //console.log("after store update: ", status)
            // setSaving(false);
            //setStatus(status);
          })
          .catch((error) => {
            // setLogoImageReady(false);
            // setBannerImageReady(false);
            setSaving(false);
            setStatus(STATUS.ERROR);
            setAPIError(error ? error : {});
          });
      } else {
        createStore(body)
          .then(async (newStoreIdLink) => {
            const storeIdFromAPI = parseIdFromLink(newStoreIdLink, 0, '{?projection}');
            setStoreIdForServiceItems(storeIdFromAPI);

            await createAndSendChangeLog({
              storeID: storeIdFromAPI,
              storeName: body.name,
              // oldFormData: ref.current,
              // newFormData: formState,
              // oldPinData: preExistingPins,
              // newPinData: cityStreetTable,
              changedBy: currUser,
            });

            return manageImages(newStoreIdLink)
              .then((status) => {
                setSaving(false);
                setStatus(status);
              })
              .catch((err) => {
                console.log(err);
                throw err;
              });
            //we need to post to pinAddresses
            // setSaving(false);
            //setStatus(status);
          })
          .catch((error) => {
            // setLogoImageReady(false);
            // setBannerImageReady(false);
            setSaving(false);
            setStatus(STATUS.ERROR);
            setAPIError(error ? error : {});
          });
      }
    } catch (error) {
      setAPIError(error ? error : {});
      setStatus(STATUS.ERROR);
    }
  };
  // End save function

  useEffect(() => {
    const determineMobileServiceType = (storeInfo) => {
      /* Currently not saved as enum in store object but determined by state of two varables from the stores record (storeInfo)
  'storeOnly' storeInfo.mobileService === false && and street address is present (this will also require the street field to save)
  'mobileOnly' storeInfo.mobileService === true && street address is not present (this will empty and clear the field on save if one was present)
  'storeWithMobileService' storeInfo.mobileService ===  true && street address is present (this will also require the street field to save)
  */
      const savedMobileServiceBool = storeInfo.mobileService;

      let mobileServiceType = MOBILE_SERVICE_TYPE.STORE_ONLY; //Default to store only if no data is present
      if (savedMobileServiceBool === false && storeInfo.address.street) {
        mobileServiceType = MOBILE_SERVICE_TYPE.STORE_ONLY;
      } else if (savedMobileServiceBool === true && !storeInfo.address.street) {
        mobileServiceType = MOBILE_SERVICE_TYPE.MOBILE_ONLY;
      } else if (savedMobileServiceBool === true && storeInfo.address.street) {
        mobileServiceType = MOBILE_SERVICE_TYPE.STORE_WITH_MOBILE_SERVICE;
      }
      return mobileServiceType;
    };

    if (editMode) {
      //Store Already Exists on API
      getStore(storeId)
        .then((data) => {
          // if (data._links.self.href) {
          // 	setStoreHref(data._links.self.href);
          // }

          //===========================Format Store Hours ====================================
          //[] TODO Move ot a helper function
          let hours = {};
          if (data.hours === null) {
            DAYS_OF_THE_WEEK.forEach((day) => {
              hours[`${day}`] = {
                open: 'Closed',
                close: 'Closed',
              };
            });
          } else {
            DAYS_OF_THE_WEEK.forEach((day) => {
              hours[`${day}`] = {
                open: parseHour(data.hours[`${day.toLowerCase()}Open`]),
                close: parseHour(data.hours[`${day.toLowerCase()}Close`]),
              };

              if (hours[`${day}`].open[0] === '0') {
                hours[`${day}`].open = hours[`${day}`].open.slice(1);
              }
              if (hours[`${day}`].close[0] === '0') {
                hours[`${day}`].close = hours[`${day}`].close.slice(1);
              }
            });
          }

          //=========Parse Gallary Images================
          const galleryArray = [];
          data._embedded.imageFiles &&
            data._embedded.imageFiles.forEach((file) => {
              galleryArray.push({ preview: file.url });
            });
          // state.saveData.file = setState(prev =>) data._embedded.bannerImageFile
          // 	? data._embedded.bannerImageFile
          // 	: [];
          // setState((prev) => ({
          // 	...prev,
          // 	saveData: { ...state.saveData, file: lastImage, newImage: true },
          // 	currHistoryIndex: indexOfLastFile,
          // }));

          /* 
[]TODO - Seems to be multiple form states, need to clean up
Where is each of them used? If at all...
- setState  << ???
- useRef > initial.current  << initial implied its used to detect changes / revert but not sure that this feat exists.
- formState  << Seems to be primarly used for the form.

*/

          setState((prev) => ({
            ...prev,
            saveData: {
              ...state.saveData,
              file: data._embedded.bannerImageFile ? data._embedded.bannerImageFile : [],
            },
          }));
          initial.current = {
            id: parseIdFromLink(data._links.self.href),
            baseUrl: data._links.self.href.split('/').slice(0, 5).join('/'),
            name: data.name,
            allowBooking: data.allowBooking,
            category: data.category.charAt(0).toUpperCase() + data.category.slice(1),
            contactFirstName: data.contactFirstName,
            contactLastName: data.contactLastName,
            logoAvatar: data._embedded.file ? [{ preview: data._embedded.file.url }] : [],
            bannerAvatar: data._embedded.bannerImageFile ? data._embedded.bannerImageFile : [],
            galleryAvatar: data._embedded.imageFiles ? galleryArray : [],
            galleryImages: data._embedded.imageFiles
              ? data._embedded.imageFiles
                  .sort((x, y) => x.rank - y.rank)
                  .map((file) => ({
                    preview: file.url,
                    rank: file.rank,
                  }))
              : [],
            images: data._embedded.imageFiles ? data._embedded.imageFiles : [],
            file: data._embedded.file ? data._embedded.file._links.self.href : null, //Needs to be null rather than empty array - as it breaks api call
            address: data.address,
            email: data.email,
            phone: data.phone,
            serviceItems: data.serviceItems ? data.serviceItems : '',
            website: data.website ? data.website : '',
            instagram: data.instagram ? data.instagram : '',
            facebook: data.facebook ? data.facebook : '',
            snapchat: data.snapchat ? data.snapchat : '',
            twitter: data.twitter ? data.twitter : '',
            aboutUs: data.aboutUs,
            announcementTitle: data.announcementTitle ? data.announcementTitle : '',
            announcement: data.announcement ? data.announcement : '',
            hours,
            published: data.published,
            maxLocations: data.maxLocations > 2 ? data.maxLocations : 3,
            maxActiveDeals: data.maxActiveDeals > 2 ? data.maxActiveDeals : 3,
            locations: data._embedded.locations ? data._embedded.locations : [],
            pinAddress: data._embedded.pinAddresses ? data._embedded.pinAddresses : [],
            locIds: [],
            displayMap: data.displayMap === null || data.displayMap === true ? true : false,
            adminOwned:
              data._embedded.owner.role.name === ROLES.ROLE_SUPER_ADMIN ||
              data._embedded.owner.role.name === ROLES.ROLE_ADMIN
                ? true
                : false,
            timezone: data.timezone,
            mobileService: data.mobileService,
          };

          //======= Parse Address ===============
          // Beautimap doesn't use pins (only single location, so this is not really needed)
          // Where is long lat stored?, might be under this section, so its a redundancy from the API
          let cityStreet = [];
          //for each city in our pinAddress return data, add to our citystreet table
          if (data._embedded.pinAddresses) {
            data._embedded.pinAddresses.forEach((pin) => {
              pin.address.street
                ? cityStreet.push({
                    city: pin.address.city,
                    street: pin.address.street,
                  })
                : cityStreet.push({ city: pin.address.city, street: '' });
            });
          }
          //also add the main city and street IF it doesnt exist
          setFormState(initial.current);
          const initMobileServiceLevel = determineMobileServiceType(data);
          setMobileServiceType(initMobileServiceLevel);

          setDataReady(true);
        })
        .catch((err) => {
          console.log('error: ', err);
          // setLoadingError(true);
        });
    } else {
      //!EDITMODE, so its assumed to be a new store (doesn't exist on API)
      const hours = {};

      DAYS_OF_THE_WEEK.forEach(
        (day) =>
          (hours[`${day}`] = {
            open: '',
            close: '',
          })
      );

      setFormState({
        name: '',
        category: '',
        contactFirstName: '',
        contactLastName: '',
        logoAvatar: [],
        galleryAvatar: [],
        bannerAvatar: [],
        galleryImages: [],
        bannerImageFile: '',
        file: '',
        imageFiles: [],
        address: {
          street: '',
          city: '',
          province: '',
          postalCode: '',
        },
        email: '',
        phone: '',
        website: '',
        instagram: '',
        facebook: '',
        snapchat: '',
        serviceItems: '',
        twitter: '',
        aboutUs: '',
        announcementTitle: '',
        announcement: '',
        hours,
        published: false,
        locations: [],
        displayMap: true,
        nationwide: false,
        timezone: 'America/Toronto',
        owner: {},
        mobileService: false,
      });
      setMobileServiceType(MOBILE_SERVICE_TYPE.STORE_ONLY);
      setDataReady(true);
    }
    // eslint-disable-next-line
  }, [props.match, editMode, storeId]);

  const validateSubmission = () => {
    let valid = true;
    valid = validate();
    if (!valid) {
      setFormError(true);
    }
    return valid;
  };

  const closeErrorDialog = () => {
    setFormError(false);
  };
  const classes = useStyles();

  const isSectionComplete = (requiredFields, currentData) => {
    /* Exit - if current data is an empty object ({} in initial state), return false */
    if (Object.keys(currentData).length === 0) {
      console.warn("currentData is an empty object, can't determine save button status");
      return null;
    }
    /* Exit if required fields is empty or not found */
    if (!requiredFields || requiredFields.length === 0) {
      return null;
    }

    //Are all fields of the section within currentData populated?
    const requiredFieldsFilled = requiredFields.every((field) => {
      //handle nested fields (address, hours)
      const ADDRESS_FIELDS = ['street', 'city', 'province', 'postalCode'];
      if (ADDRESS_FIELDS.includes(field)) {
        return !!currentData.address[field];
      }
      if (field === 'hours') {
        //[]TODO - skipping for now, needs extra handling
        return true;
      }
      if (field === 'galleryImages' || field === 'logoAvatar' || field === 'bannerAvatar') {
        //handle images, need to check length of the array for galleryImages, logoAvatar, bannerAvatar
        return !!currentData[field].length;
      }
      //handle all other fields (flat data in formState)
      return !!currentData[field];
    });

    return requiredFieldsFilled;
  };

  const isSaveAllowed = isSectionComplete(FORM_SECTIONS.ESSENTIAL_INFO, formState);

  //If mobileServiceType is STORE_ONLY, or STORE_WITH_MOBILE, address.street is required to publish
  const isPublishAllowed =
    isSaveAllowed && mobileServiceType !== MOBILE_SERVICE_TYPE.MOBILE_ONLY ? !!formState.address?.street : true;

  const isBookingDisabled = determineMobileServiceType(formState) === MOBILE_SERVICE_TYPE.MOBILE_ONLY;
  return (
    <div id="formDiv" className={classes.formContainerAAA}>
      {!dataReady && <CircularProgress style={{ display: 'flex' }} />}

      {dataReady && (
        <form id="storeForm" className={classes.storeForm}>
          <section id="essentialInfo">
            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Essential Information
            </Typography>
            {/* Contact Info Input */}
            <FormRow title="Account Contact" asterisk>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} md={6} style={{ paddingTop: '8px' }}>
                  <TextField
                    required
                    size="small"
                    id="contact-first-name"
                    name="contactFirstName"
                    label="First Name"
                    fullWidth
                    value={formState.contactFirstName}
                    variant="outlined"
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        contactFirstName: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    size="small"
                    id="contact-last-name"
                    name="contactLastName"
                    label="Last Name"
                    fullWidth
                    variant="outlined"
                    value={formState.contactLastName}
                    onChange={(e) =>
                      setFormState({
                        ...formState,
                        contactLastName: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </FormRow>
            {/* End Contact Info Input */}
            {/* Store Name Input */}
            <FormRow title="Store Name" asterisk>
              <TextField
                size="small"
                required
                fullWidth
                id="store-name-input"
                name="name"
                variant="outlined"
                value={formState.name}
                onChange={(e) => setFormState({ ...formState, name: e.target.value })}
              />
            </FormRow>
            {/* End Store Name Input */}
            {/* category Type Input */}
            <FormRow title="Category" asterisk>
              <FormControl margin="dense" fullWidth variant="outlined" className={classes.formControl}>
                {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  multiple
                  value={formState.category?.split(',').filter((cat) => cat)} //String to array, remove empty strings
                  onChange={(e) => {
                    setFormState({ ...formState, category: e.target.value.join(',') }); //Array to string
                  }}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {CATEGORIES.map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {/* <MenuItem key={item} value={item} selected={item === formState.category}> */}
                        {/* {item} */}
                        <Checkbox checked={formState.category.split(',').indexOf(item) > -1} />
                        <ListItemText primary={item} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </FormRow>
            {/* End Service Type Input */}

            {/* Phone Input */}
            <FormRow title="Phone Number" asterisk>
              <TextField
                size="small"
                required
                fullWidth
                id="phone-number-input"
                name="phone"
                variant="outlined"
                error={phoneError}
                placeholder="(555) 555 5555"
                helperText={phoneError ? 'Please enter a valid phone number' : ''}
                value={formState.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 $3')}
                onChange={(e) => {
                  //Use regex to limit to 10 digits and save as xxxxxxxxxx
                  const newPhoneNum = e.target.value.replace(/\D/g, '').slice(0, 10);
                  setFormState({ ...formState, phone: newPhoneNum });
                  setPhoneError(false);
                }}
              />
              <FormHelperText id="phoneNum-helper-text">Phone number for customers to call your store</FormHelperText>
            </FormRow>
            {/* End Phone Input */}
            {/* Email Input */}
            <FormRow title="Email" asterisk>
              <TextField
                size="small"
                required
                fullWidth
                id="email-input"
                name="email"
                variant="outlined"
                value={formState.email}
                error={emailError}
                placeholder="info@mystore.com"
                helperText={emailError ? 'Please enter a email address' : ''}
                onChange={(e) => {
                  setFormState({ ...formState, email: e.target.value });
                  setEmailError(false);
                }}
              />
              <FormHelperText id="email-helper-text">Email for customers to contact your store</FormHelperText>
            </FormRow>
            {/* End Email Input */}

            {/**About us Input */}
            <FormRow title="About Us" asterisk>
              <TextField
                size="small"
                fullWidth
                id="aboutUs"
                name="aboutUs"
                required
                multiline
                rows={4}
                variant="outlined"
                value={formState.aboutUs}
                onChange={(e) => setFormState({ ...formState, aboutUs: e.target.value })}
              />
              {/* Label for textFieldAboutUs */}
              <FormHelperText id="aboutUs-helper-text">
                Tell us about your business - not sure what to write, that's ok, you can always come back and fill this
                in later
              </FormHelperText>
            </FormRow>
            {/**End About us input */}
          </section>

          <section id="storeInfo">
            {/* <Divider className={classes.divider} /> */}
            <br />
            {/* Location Information */}
            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Service Area
            </Typography>

            {/* State/Province Input */}
            <FormRow title="Province" asterisk>
              {/* <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel> */}
              <Autocomplete
                noOptionsText="No options available"
                disableClearable
                options={statesAndProvinces}
                style={{ width: '100%' }}
                value={formState.address.province}
                onChange={(event, newValue) => {
                  setProvinceError(false);
                  if (newValue === savedProvince) {
                    setFormState({
                      ...formState,
                      address: {
                        ...formState.address,
                        province: newValue,
                        city: savedCity,
                      },
                    });
                  } else {
                    setFormState({
                      ...formState,
                      address: {
                        ...formState.address,
                        province: newValue,
                        city: '',
                      },
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    error={provinceError}
                    helperText={provinceError ? 'Please choose a state or province' : ''}
                    // onChange={setCityError(false)}
                    // className={classes.locationSearchBox}
                  />
                )}
              />
            </FormRow>
            {/* End State/Province Input */}
            {/* City Input */}
            {
              <FormRow title="City" asterisk>
                <Autocomplete
                  noOptionsText="No options available"
                  disableClearable
                  disabled={!formState.address.province}
                  options={CITIES[formState.address.province]}
                  style={{ width: '100%' }}
                  value={formState.address.city}
                  onChange={(event, newValue) => {
                    setCityError(false);
                    setFormState({
                      ...formState,
                      address: { ...formState.address, city: newValue },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      variant="outlined"
                      error={cityError}
                      helperText={cityError ? 'Please choose a city' : 'Contact admin if your city is not on the list'}
                      // onChange={setCityError(false)}
                      // className={classes.locationSearchBox}
                    />
                  )}
                />
              </FormRow>
            }
            {/* End City Input */}

            {/* Mobile only option */}
            <FormRow title="Mobile Service">
              <FormGroup>
                <FormHelperText id="mobileSvc-helper-text">Do you offer mobile services?</FormHelperText>
                <FormControlLabel
                  control={
                    /* 3 options, store only, store with mobile svc, mobile only.  
                  Will set a state variable which affect fields displayed and how the form is saved.
                  [] TODO: consider putting this into its own component because it is more complex than a standard select
                    */
                    <Select
                      style={{ marginLeft: 12 }}
                      labelId="mobileServiceSelectLabel"
                      id="mobileServiceSelect"
                      value={mobileServiceType}
                      onChange={(e) => {
                        setMobileServiceType(e.target.value);
                      }}
                    >
                      <MenuItem value={MOBILE_SERVICE_TYPE.STORE_ONLY}>
                        No - we only offer brick and mortar service
                      </MenuItem>
                      <MenuItem value={MOBILE_SERVICE_TYPE.STORE_WITH_MOBILE}>
                        Yes - we offer both brick and mortar and mobile service
                      </MenuItem>
                      <MenuItem value={MOBILE_SERVICE_TYPE.MOBILE_ONLY}>Yes - we only travel to clients</MenuItem>
                    </Select>
                  }
                />
              </FormGroup>
            </FormRow>
            {/* End of mobile only */}
            {/* Street Address Input */}
            {mobileServiceType !== MOBILE_SERVICE_TYPE.MOBILE_ONLY && (
              <FormRow title="Street Address">
                {/* asterisk={!isIndependent} */}
                <TextField
                  size="small"
                  fullWidth
                  id="sttess-address-input"
                  name="street"
                  variant="outlined"
                  value={formState.address.street}
                  // required={!isIndependent} // Certain setting require a street to be saved however because of
                  // in progress cleints can save without the field present, but in some situations it would be required to published
                  // error={}
                  // helperText={streetError ? "Please enter a valid address" : ""}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      address: { ...formState.address, street: e.target.value },
                    });
                  }}
                />
                <FormHelperText id="street-helper-text">Required to publish your store</FormHelperText>
              </FormRow>
            )}
            {/* End Street Address */}
            {/* Postal/Zip Code Input */}
            <FormRow title="Postal Code">
              <TextField
                size="small"
                fullWidth
                id="postal-code-input"
                name="postal-code"
                variant="outlined"
                value={formState.address.postalCode}
                error={postalCodeError}
                helperText={postalCodeError ? 'Please enter a valid postal code for your country' : ''}
                onChange={(e) => {
                  const newPostalCode = e.target.value
                    .replace(/[^a-z0-9]/gi, '')
                    .slice(0, 6)
                    .toUpperCase();

                  setPostalCodeError(false);
                  setFormState({
                    ...formState,
                    address: {
                      ...formState.address,
                      postalCode: newPostalCode,
                    },
                  });
                }}
              />
            </FormRow>
            {/* Postal/Zip Code Input */}

            {/* Categories */}
            {/* As of Nov 2023
            Will conitionally show legacy service items if they exist and new service items have not been created yet
            Once new service items are created, the legacy service items will be removed 
            or if the user removes the legacy service items text the textbox will be removed
            After appropriate time, the legacy service items will be removed from rendering
            */}
            {editMode && (
              /* Only display line and title in edit mode 
              When creating this section will not be shown , the modal will be offered after the store is created. */
              <>
                <Divider className={classes.divider} />
                <Typography variant="h5" color="textPrimary" className={classes.title}>
                  {`Service Menu ${svcItemCount > 0 ? `(${svcItemCount})` : ''}`}
                </Typography>
              </>
            )}
            {svcItemCount <= 0 && showLegacyServiceItems && (
              /* LEGACY - Only renders if the old service items exist and new items have NOT been created yet  */
              /* How to check if they have been created? - hook or state? */
              <>
                <Alert severity="warning">
                  UPDATE: Service Items will be movings to a new section, found with the button below. There you can
                  better manage description, duration, and pricing. &#10; &#10; Once you start using the new system, the
                  textbox system will be removed.
                </Alert>
                <br />
                <FormRow title="Service Items">
                  <TextField
                    size="small"
                    multiline
                    rows={15}
                    fullWidth
                    id="serviceItems"
                    name="service-Items"
                    variant="outlined"
                    value={formState.serviceItems}
                    placeholder="Enter service items and prices, for example:&#10;HAIRCUT $29&#10;PERM $49+&#10;HIGHLIGHTS BY CONSULTATION"
                    onChange={(e) => {
                      setFormState({ ...formState, serviceItems: e.target.value });
                    }}
                  />
                </FormRow>
                <br />
              </>
            )}

            <ServiceItemsManager
              storeId={isNewStore ? storeIdForServiceItems || 'NEW' : storeId}
              name={'your store'}
              openModalOveride={openServiceItemsModal}
              setOpenModalOveride={setOpenServiceItemsModal}
              setParentSvcItemCount={setSvcItemCount}
            />

            {/* Toggle to allow bookings */}
            <FormGroup row style={{ marginTop: 16 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isBookingDisabled ? false : formState.allowBooking}
                    onChange={(e) => {
                      setFormState({ ...formState, allowBooking: e.target.checked });
                    }}
                    disabled={isBookingDisabled}
                    name="allowBooking"
                    color="primary"
                  />
                }
                label={
                  <>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>{`Allow bookings (${
                      !isBookingDisabled && formState.allowBooking ? 'ON' : 'OFF'
                    })`}</Typography>
                    {isBookingDisabled && (
                      <Typography>
                        A brick and mortar location with an address is required to enable booking.{' '}
                      </Typography>
                    )}
                    <Typography>Allow your customers to book appointments online through Beautimap.</Typography>
                    <Typography>
                      You will receive notifications when appoitments are made. This can be turned off at anytime
                      without affecting existing appointments.
                    </Typography>
                  </>
                }
              />
            </FormGroup>
            {/* End Services */}

            <Divider className={classes.divider}></Divider>
            {/**Announcements */}
            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Announcement
            </Typography>
            <FormRow title="Title">
              <TextField
                size="small"
                fullWidth
                id="announcement-title"
                name="announcement-title"
                variant="outlined"
                value={formState.announcementTitle}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    announcementTitle: e.target.value,
                  });
                }}
              />
            </FormRow>
            <FormRow title="Text">
              <TextField
                size="small"
                multiline
                fullWidth
                id="announcement"
                name="announcement"
                variant="outlined"
                value={formState.announcement}
                onChange={(e) => {
                  setFormState({ ...formState, announcement: e.target.value });
                }}
              />
            </FormRow>
            {/**End Announcment Title */}
          </section>
          <Divider className={classes.divider} />
          <section id="socials">
            {/**Website & Social Media */}
            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Website & Social Media
            </Typography>
            {/* []TODO - DRY by having socials iterate over an array of objects () */}
            {/* Website input */}
            <FormRow title="Website">
              <TextField
                size="small"
                fullWidth
                id="website-input"
                name="website"
                variant="outlined"
                value={formState.website}
                placeholder="Enter URL, e.g. www.mySalon.com"
                error={websiteError}
                helperText={websiteError ? 'Please enter a valid web address' : ''}
                onChange={(e) => {
                  setFormState({ ...formState, website: e.target.value });
                  setWebsiteError(false);
                }}
              />
            </FormRow>
            {/* End Website input */}
            {/* Facebook input */}
            <FormRow title="Facebook">
              <TextField
                size="small"
                fullWidth
                id="facebook-input"
                name="facebook"
                variant="outlined"
                value={formState.facebook}
                placeholder="Enter URL, e.g. www.facebook.com/mySalon"
                error={facebookError}
                helperText={facebookError ? 'Please enter a valid facebook link' : ''}
                onChange={(e) => {
                  setFormState({ ...formState, facebook: e.target.value });
                  setFacebookError(false);
                }}
              />
            </FormRow>
            {/* End Facebook input */}

            {/* Twitter input */}
            {/*  <FormRow title="Twitter">
              <TextField
                size="small"
                fullWidth
                id="twitter-input"
                name="twitter"
                variant="outlined"
                value={formState.twitter}
                placeholder="Enter URL (e.g. www.twitter.com/mySalon) or handle (e.g. @mySalon)"
                // helperText="Enter URL or handle (e.g. @mySalon)"
                error={twitterError}
                helperText={twitterError ? 'Please enter a valid twitter link or username' : ''}
                onChange={(e) => {
                  setTwitterError(false);
                  setFormState({ ...formState, twitter: e.target.value });
                }}
              />
            </FormRow> */}
            {/* End Twitter input */}
            {/* Instagram input */}
            {/*   <Typography variant="body1" style={{ marginTop: '10px', marginBottom: '10px', width: '80%' }}>
              <span style={{ fontWeight: 700 }}>Note:</span>Your Instagram information can be inputted as the profile
              URL (ex. www.instagram.com/mySalon/) or using your username preceded with the `@` symbol (ex. @mySalon)
            </Typography> */}

            <FormRow title="Instagram">
              <TextField
                size="small"
                fullWidth
                id="instagram-input"
                name="instagram"
                variant="outlined"
                value={formState.instagram}
                placeholder="Enter URL (e.g. www.instagram.com/mySalon) or handle (e.g. @mySalon)"
                // helperText="Enter URL or handle (e.g. @mySalon)"
                error={instagramError}
                helperText={
                  instagramError
                    ? 'Please enter a valid instagram link or username'
                    : 'Enter URL or handle (e.g. @mySalon)'
                }
                onChange={(e) => {
                  setInstagramError(false);
                  setFormState({ ...formState, instagram: e.target.value });
                }}
              />
            </FormRow>
            {/* End Instagram input */}
            {/* Snapchat input */}
            {/* <FormRow title="Snapchat">
              <TextField
                size="small"
                fullWidth
                id="snapchat-input"
                name="snapchat"
                variant="outlined"
                value={formState.snapchat}
                placeholder="Enter URL (e.g. www.snapchat.com/mySalon) or handle (e.g. @mySalon)"
                // helperText="Enter URL or handle (e.g. @mySalon)"
                error={snapchatError}
                helperText={snapchatError ? 'Please enter a valid snapchat link or username' : ''}
                onChange={(e) => {
                  setSnapchatError(false);
                  setFormState({ ...formState, snapchat: e.target.value });
                }}
              />
            </FormRow> */}
            {/* End snapchat input */}
            {/**End Website & Social Media */}
          </section>

          <Divider className={classes.divider}></Divider>
          <section id="storeDetails">
            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Business Hours
            </Typography>

            <BusinessHoursFields classes={classes} formState={formState} setFormState={setFormState} />

            {/* Time zone start */}
            <FormRow title="Timezone Preference" asterisk>
              <FormControl margin="dense" fullWidth variant="outlined" className={classes.formControl}>
                <Select
                  value={formState.timezone}
                  onChange={(event) => {
                    setFormState({ ...formState, timezone: event.target.value });
                  }}
                >
                  {timezones.map((zone) => (
                    <MenuItem key={zone.name} value={zone.name}>
                      {zone.abbr} {zone.utcOffset}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormRow>
            {/* End Time Zone */}
          </section>

          <section id="images">
            <Divider className={classes.divider} />

            <Typography variant="h5" color="textPrimary" className={classes.title}>
              Images
            </Typography>
            {/**Logo Input */}
            <FormRow title="Logo Upload">
              {formState.logoAvatar.length === 0 && (
                <Card style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={logoPlaceholder} alt="logo placeholder" />
                </Card>
              )}
              <ImageUploader
                imageType={'logo'}
                files={formState.logoAvatar}
                setFiles={(value) => {
                  setLogoImageError(false);
                  setFormState({ ...formState, logoAvatar: value });
                }}
                error={logoImageError}
                errorText="An logo image for your business is required"
              ></ImageUploader>
            </FormRow>
            {/**End Logo Input */}
            {/**Banner Image Input */}
            <FormRow title="Banner Image Upload">
              {/* <ImageUploader
							imageType={"banner"}
							files={state.saveData.file}
							setFiles={(e) => setBannerFiles(e, value)}
							// error={submitted && !validate(formFields.file)}
							error={bannerImageError}
							errorText="An banner image for your business is required"
						></ImageUploader> */}
              <span
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4" align="center" className={classes.previewTitle}>
                  Banner Preview
                  <Typography className={classes.bannerAdvice}>
                    *** Please ensure to click the crop button and the dimensions are atleast 1245 pixels wide and 500
                    pixels high or the banner might get distorted ***
                  </Typography>
                </Typography>
                {/*[] Add flag for changes to show unsaved */}
                {/*        {true && (
                      <Typography align="center" className={classes.previewTitle}>
                        (Unsaved changes)
                      </Typography>
                    )} */}
              </span>
              {state.saveData.file.url ? (
                <Card className={classes.bannerPreview}>
                  <img
                    src={state.saveData.file.url}
                    alt="banner preview"
                    style={{
                      objectFit: 'fill',
                      width: '100%',
                      maxHeight: '500px',
                    }}
                  />
                  {
                    // console.log("banner====", state.saveData.file)
                  }
                  {state.saveData.file.size > 10000000 && (
                    <Typography>
                      File Size: {state.saveData.file.size / 10000000} MB. Please ensure size is below 10MB.
                    </Typography>
                  )}
                </Card>
              ) : (
                <Card className={classes.bannerPreview}>
                  <img
                    src={bannerPlaceholder}
                    alt="banner preview"
                    style={{
                      objectFit: 'cover',
                      maxWidth: '100%',
                      maxHeight: '500px',
                    }}
                  />
                </Card>
              )}
              <AppBar position="static" style={{ zIndex: 1 }}>
                <Tabs
                  value={value}
                  onChange={changeTab}
                  aria-label="simple tabs example"
                  centered
                  wrapped
                  className={classes.tabsContainer}
                >
                  <Tab label="crop image" className={classes.tabs} {...a11yProps(0)} />
                  <Tab
                    label="upload image"
                    className={!state.saveData.file.url && classes.uploadTab}
                    {...a11yProps(1)}
                  />
                  <Tab label="Clear Banner" className={classes.tabs} {...a11yProps(2)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} label={'Crop Image'}>
                <CroppingTool
                  imageSrc={state.saveData.file.url}
                  applyEdit={applyEdit}
                  setFiles={setBannerFiles}
                  undoCrop={undoCrop}
                  redoCrop={redoCrop}
                  disableUndoRedo={disableUndoRedo()}
                />
              </TabPanel>
              <TabPanel value={value} index={1} label={'Upload Image'}>
                <Typography variant="h4" align="center">
                  ↓ Upload Banner ↓
                </Typography>

                <div className={classes.InnerBox}>
                  <div>
                    <BannerUploader
                      disabled={false}
                      files={state.saveData.file}
                      setFiles={(e) => setBannerFiles(e, value)}
                      error={false}
                    ></BannerUploader>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2} label={'Clear Banner'}>
                <Typography variant="h4" align="center" className={classes.previewTitle}>
                  Clear this Banner? (Will Need to Save the banner after it is cleared)
                </Typography>
                <Button
                  variant="filled"
                  style={{
                    backgroundColor: '#FFB4A4',
                    color: 'white',
                    height: '2rem',
                    width: '5rem',
                  }}
                  onClick={clearBannerInfo}
                >
                  DELETE
                </Button>
              </TabPanel>
            </FormRow>
            {/**End Banner Image Input */}
            {/**Gallery Image Input */}
            <FormRow title="Gallery Images Upload">
              <ImageUploader
                multi
                imageLimit={24}
                hideAddPlaceholder
                files={formState.galleryImages}
                setFiles={(value) => setFormState({ ...formState, galleryImages: value })}
                // error={submitted && !validate(formFields.file)}
                error={false}
              ></ImageUploader>
            </FormRow>
            {/**End Banner Image Input */}
          </section>

          <section id="saveArea" className={classes.saveSection}>
            <Divider className={classes.divider} />

            {/* =============================================PUBLISHED ============================ */}
            {/* <Divider className={classes.divider} /> */}
            {/* Floating progress */}
            {/* <div style={{ margin: '32px 80px' }}>
              <Typography>{`Progress: ${formProgress.completedSections.length}/${formProgress.totalSections} sections - you can save but more is needed to publish your store.`}</Typography>
              <LinearProgress variant="determinate" value={formProgress.percentComplete} />
            // Change to have a "Allowed to save line" - 3 colors (or sections) no save / save / 100% complete 
            </div> */}

            {/**Published */}
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {formState.published && (
                  <Typography className={classes.publishSummary}>
                    <span role="img" aria-label="Green check">
                      ✅
                    </span>{' '}
                    <strong>Publish</strong> / Unpublish when saved
                  </Typography>
                )}
                {!formState.published && (
                  <Typography className={classes.publishSummary}>
                    <span role="img" aria-label="Red X">
                      ❌
                    </span>{' '}
                    Publish / <strong>Unpublish</strong> when saved
                  </Typography>
                )}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ paddingTop: 16 }}>
                <FormRow title="Save / Publish">
                  <FormGroup row style={{ backgroundColor: 'white' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!isPublishAllowed} //[] TODO: is anything beyond min save needed to publish? (Actually required)
                          checked={formState.published}
                          onChange={() =>
                            setFormState({
                              ...formState,
                              published: !formState.published,
                            })
                          }
                          name="checked-published"
                        />
                      }
                      label="By publishing a store you agree that all information is correct and ready to be put on the live site."
                    />
                  </FormGroup>
                </FormRow>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {/**End Published */}

            {/* Button Container */}
            <Grid container alignItems="center" item xs={12} spacing={3} className={classes.buttonContainer}>
              <Grid item xs={2} />
              <Grid item xs={4}>
                {/* <Paper className={classes.paper}> */}
                <Button
                  color="secondary"
                  fullWidth
                  variant="contained"
                  className={classes.cancelButton}
                  component={Link}
                  to={previewMode ? `/client/preview/stores/${initial.current.id}` : '/admin/stores'}
                >
                  Cancel
                </Button>
                {/* </Paper> */}
              </Grid>
              <Grid item xs={4}>
                {saving ? (
                  <Button color="primary" variant="contained" fullWidth className={classes.saveButton} disabled>
                    <CircularProgress size={24} color="inherit" />{' '}
                  </Button>
                ) : (
                  <Button
                    // type="submit"
                    color="primary"
                    fullWidth
                    variant="contained"
                    className={classes.saveButton}
                    disabled={!isSaveAllowed}
                    onClick={() => {
                      setSaving(true);
                      if (validateSubmission()) {
                        save();
                      } else {
                        setSaving(false);
                      }
                    }}
                  >
                    Save
                  </Button>
                )}
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            {/* End Button Container */}
          </section>
        </form>
      )}

      {/* ===============================DIALOGS & MODALS =================================== */}

      <Dialog open={formError} onClose={closeErrorDialog}>
        <DialogTitle>{'Missing or Incorrect Data'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Please ensure all required fields are filled correctly</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        className={classes.modal}
        open={status !== ''}
        onClose={(event, reason) => {
          const IGNORED_REASONS = ['backdropClick', 'escapeKeyDown'];
          if (IGNORED_REASONS.includes(reason)) return event.preventDefault();
          setStatus('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{ zIndex: 1500 }}
      >
        <Fade in={status !== ''}>
          <div className={classes.modalPaper}>
            {status === STATUS.SUCCESS && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  {isNewStore ? 'Sucessfully created your store!' : 'Successfully saved!'}
                </Typography>
                {isNewStore && (
                  <Typography className={classes.statusMessage}>
                    Would you like to add services that you offer? You can always add or change them later.
                  </Typography>
                )}
                {/* TODO - What is add mode? */}
                {!addMode && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      // setSaving(false);
                      setStatus('');
                      history.push('/');
                      window.location.reload();
                    }}
                    style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                    className={classes.statusAcceptButton}
                  >
                    Ok
                  </Button>
                )}
                {isNewStore && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpenServiceItemsModal(true);
                    }}
                    style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                    className={classes.statusAcceptButton}
                  >
                    Add Services
                  </Button>
                )}

                {addMode && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      history.push('/');
                      setTimeout(() => {
                        window.location.reload();
                      }, 200);
                    }}
                    style={{ color: '#FFFFFF', textDecoration: 'inherit' }}
                    className={classes.statusAcceptButton}
                  >
                    {isNewStore ? 'Go home' : 'Ok'}
                  </Button>
                )}
              </Fragment>
            )}
            {status === STATUS.ERROR && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  There was an issue while saving your data. Please try again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setSaving(false);
                    setStatus('');
                  }}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
                <ErrorDetails error={APIError} data={formState}></ErrorDetails>
              </Fragment>
            )}
          </div>
        </Fade>
      </Modal>
      {/* =========================End of Modals and Dialog */}
    </div>
  );
}

export default AddEditStore;
