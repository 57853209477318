import React, { useState, useEffect, Fragment } from 'react';
// import useBookingSystemMgmt from '../components/Bookings/hooks/useBookingSystemMgmt';
// import BookingSystemIndex from '../components/Bookings/AdminModule/AdminIndex';

import {
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Backdrop,
  Fade,
  useMediaQuery,
} from '@material-ui/core';
import { PROVINCES, STATUS } from '../utils/constants';
import api from '../utils/api';
import { parseIdFromLink, convertToDate, filterFeaturedList } from '../utils/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactSortable } from 'react-sortablejs';
import SortItem from '../components/SortItem';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileContent: {
    margin: '2px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  subheader: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 0),
  },
  note: {
    width: '100%',
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  table: {
    zIndex: '50!important',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
    },
  },
  actionButtons: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButton: {
    margin: theme.spacing(1),
    width: 100,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  sharedLists: {
    display: 'flex',
  },
  leftList: {
    margin: theme.spacing(1),
  },
  rightList: {
    margin: theme.spacing(1),
  },
  pinnedItems: {
    padding: theme.spacing(1),
    border: '1px solid #cbcbcb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    marginTop: theme.spacing(2),
  },
  unpinnedItems: {
    padding: theme.spacing(1),
    border: '1px solid #cbcbcb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    marginTop: theme.spacing(2),
  },
  mobilePinnedItems: {
    padding: '2px',
    border: '1px solid #cbcbcb',
    borderRadius: '2px',
    backgroundColor: '#ebebeb',
    marginTop: '2px',
  },
  mobileUnpinnedItems: {
    padding: '2px',
    border: '1px solid #cbcbcb',
    borderRadius: '2px',
    backgroundColor: '#ebebeb',
    marginTop: '2px',
  },
}));

const getLocationBySearch = async (province, city) => {
  return api
    .getLocationBySearch(province, city, true)
    .then((res) => res.data._embedded.locations.sort((first, second) => first.city.localeCompare(second.city)))
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getAllDeals = async (id) => {
  const publishedOnly = true;
  return await api
    .getDealsByLocation(id, publishedOnly)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getListbyLocation = async (locID, endpoint) => {
  return await api
    .getListByLocation(locID, endpoint)
    .then((res) => res.data._embedded[endpoint])
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const createRanks = async (type, data, location) => {
  return api.createRanks(null, data, location, type).catch((error) => {
    throw error;
  });
};

const updateRanks = async (type, data) => {
  return api.updateRanks(type, data).catch((error) => {
    throw error;
  });
};

const deleteRanks = async (type, ids) => {
  return api.deleteRanks(type, ids).catch((error) => {
    throw error;
  });
};

const STATUS_OPTIONS = {
  INVALID: 'INVALID',
  FETCH_FAIL: 'FETCH_FAIL',
  SAVING: 'SAVING',
  SAVE_FAIL: 'SAVE_FAIL',
  SUCCESS: 'SUCCESS',
  CONFIRM_SAVE: 'CONFIRM_SAVE',
  NO_CHANGES: 'NO_CHANGES',
};

function ManageFeaturedDealsView(props) {
  const classes = useStyles();

  const [type, setType] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const [data, setData] = useState([]); //Ranked deals (legacy system)
  const [allActiveDeals, setAllActiveDeals] = useState([]);
  const [previous, setPrevious] = useState([]);

  const [locations, setLocations] = useState('');

  const [locationsReady, setLocationsReady] = useState(false);
  const [rankingsReady, setRankingsReady] = useState(false);
  const [allListReady, setAllListReady] = useState(false);

  const [unpinned, setUnpinned] = useState([]);

  const [status, setStatus] = useState('');

  const cityID = city && parseIdFromLink(locations.find((location) => location.city === city)._links.self.href);
  // const {
  //   // fetchBookingsByDates,
  //   // fetchSettings,
  //   bookingSettings,
  //   bookingSummary,
  //   // isBookingPermitted,
  //   // updateHookSelectedInfo,
  //   setSelectedInfo,
  //   countSummary,
  //   setCityID,
  //   bookingMode,
  // } = useBookingSystemMgmt();

  const mobile = useMediaQuery('(max-width:500px)');

  useEffect(() => {
    /* Populate drop downs */
    setLocationsReady(false);
    setRankingsReady(false);
    if (state && !city) {
      getLocationBySearch(state)
        .then((res) => {
          setLocations(res);
          setLocationsReady(true);
        })
        .catch((error) => {
          setStatus(STATUS_OPTIONS.FETCH_FAIL);
        });
    }
    // setSelectedInfo({ province: state, city, list: type, type: 'deal' });
    // eslint-disable-next-line
  }, [state, city]);

  useEffect(() => {
    /* Any Drop down changes (location, ) */
    setRankingsReady(false);
    setAllListReady(false);
    // setSelectedInfo({ province: state, city, list: type, type: 'deal' });
    if (city) {
      // setCityID(cityID);
      setLocationsReady(true);
    }

    if (!type) return; //Exit if `type` isn't set yet

    const fetchAndSetLists = async (locationID, type) => {
      const data = await Promise.all([getListbyLocation(locationID, type), getAllDeals(locationID)]);
      const [initialFeatList, allDeals] = data;
      const featuredList = filterFeaturedList('deal', initialFeatList, allDeals);
      const allCurrentDeals = allDeals.filter(
        (value) => new Date() <= convertToDate(value.endDate) && new Date() >= convertToDate(value.startDate)
      );
      const unPinnedDeals = allCurrentDeals.filter(
        (currDeal) =>
          !featuredList.some(
            (featDeal) =>
              featDeal.deal._links.self.href.replace('{?projection}', '') ===
              currDeal._links.self.href.replace('{?projection}', '')
          )
      );

      setPrevious(initialFeatList);
      setData(featuredList);
      setRankingsReady(true);
      setUnpinned(unPinnedDeals);
      setAllActiveDeals(allCurrentDeals); //Where is this used?
      setAllListReady(true);
    };

    const LISTENDPOINT = type === 'FeaturedDeal' ? 'featuredDealRanks' : 'recommendedDealRanks'; //[]  location api endpoints > Set in a constant
    try {
      fetchAndSetLists(cityID, LISTENDPOINT);
    } catch (err) {
      setStatus(STATUS_OPTIONS.FETCH_FAIL);
    }

    // eslint-disable-next-line
  }, [state, city, type, locations]);
  //========================================================

  const provinceList = PROVINCES.map((province) => {
    // const bookingsInProv = 2;
    // const bookingsInProv =
    //   bookingSummary &&
    //   bookingSummary.filter(
    //     (booking) => booking.deal && booking.location.province === province && booking.status !== 'cancelled'
    //   );
    // const approvedInProv = bookingsInProv.filter((booking) => booking.status === 'approved');
    return (
      <MenuItem key={province} value={province}>
        {/* {bookingsInProv.length
          ? `${province} - ${approvedInProv.length}/${bookingsInProv.length} bookings approved`
          : province} */}
        {province}
      </MenuItem>
    );
  });
  const cityList =
    locationsReady &&
    locations.map((location) => {
      const currCity = location.city;
      // const currProvince = location.province;

      // const isCityAllowedToBook = Object.values(bookingSettings.permittedCities).filter((x) => {
      //   return x.province === location.province && x.city === currCity;
      // }).length;
      // if (isCityAllowedToBook) {
      //   const bookingsInCity =
      //     bookingSummary &&
      //     bookingSummary.filter(
      //       (booking) =>
      //         booking.deal &&
      //         booking.status !== 'cancelled' &&
      //         booking.location.province === currProvince &&
      //         booking.location.city === currCity
      //     );
      //   const approvedInCity = bookingsInCity.filter((booking) => booking.status === 'approved');
      //   return (
      //     <MenuItem key={currCity} value={currCity}>
      //       {isCityAllowedToBook
      //         ? `${currCity} - ${approvedInCity.length}/${bookingsInCity.length} bookings approved`
      //         : { currCity }}
      //     </MenuItem>
      //   );
      // }
      return (
        <MenuItem key={currCity} value={currCity}>
          {currCity}
        </MenuItem>
      );
    });

  const buildTypeList = () => {
    // if (state && city && bookingMode === 'bookings') {
    //   const currCity = city;
    //   const currProvince = state;
    //   const bookingsInCity =
    //     bookingSummary &&
    //     bookingSummary.filter(
    //       (booking) => booking.deal && booking.location.province === currProvince && booking.location.city === currCity
    //     );
    //   const count = {
    //     popularDeal: bookingsInCity.filter((x) => x.status !== 'cancelled' && x.list.includes('popular')),
    //     platinumDeal: bookingsInCity.filter((x) => x.status !== 'cancelled' && x.list.includes('platinum')),
    //   };

    //   const approved = {
    //     popularDeal: count.popularDeal.filter((booking) => booking.status === 'approved'),
    //     platinumDeal: count.platinumDeal.filter((booking) => booking.status === 'approved'),
    //   };

    //   return (
    //     <Select
    //       value={type}
    //       onChange={(event) => {
    //         setPrevious([]);
    //         setData([]);
    //         setUnpinned([]);
    //         setType(event.target.value);
    //       }}
    //     >
    //       {/* <MenuItem value={'FeaturedDeal'}>Platinum Deals</MenuItem>
    //           <MenuItem value={'RecommendedDeal'}>Most Popular Deals</MenuItem> */}
    //       <MenuItem
    //         value={'FeaturedDeal'}
    //       >{`Platinum Deals - ${approved.platinumDeal.length}/${count.platinumDeal.length} bookings approved`}</MenuItem>
    //       <MenuItem
    //         value={'RecommendedDeal'}
    //       >{`Most Popular Deals - ${approved.popularDeal.length}/${count.popularDeal.length} bookings approved`}</MenuItem>
    //     </Select>
    //   );
    // }
    //Default menu (Non Booking system)
    return (
      <Select
        value={type}
        onChange={(event) => {
          setPrevious([]);
          setData([]);
          setUnpinned([]);
          setType(event.target.value);
        }}
      >
        <MenuItem value={'FeaturedDeal'}>Platinum Deals</MenuItem>
        <MenuItem value={'RecommendedDeal'}>Most Popular Deals</MenuItem>
      </Select>
    );
  };

  //========================================================
  const submitChanges = () => {
    setStatus(STATUS_OPTIONS.SAVING);

    //Compare previous and data and:
    // 1. Find all removed ranks (where previous has the rank link but data does not)

    let removed = [];

    previous.forEach((prev) => {
      if (!data.find((curr) => curr._links && prev._links && curr._links.self.href === prev._links.self.href))
        removed.push(parseIdFromLink(prev._links.self.href, 0, '{?projection}'));
    });

    // 2. Find all changed ranks (where previous and data have the same link but with different ranks)
    let changed = [];

    previous.forEach((prev) => {
      data.forEach((curr, index) => {
        if (
          curr._links &&
          prev._links &&
          curr._links.self.href === prev._links.self.href &&
          index !== Number(prev.rank)
        ) {
          curr.rank = index;
          changed.push({
            ...curr,
            id: parseIdFromLink(curr._links.self.href, 0, '{?projection}'),
          });
        }
      });
    });

    // 3. Find all added ranks (where previous does not have the store link but data does)
    let added = [];

    data.forEach((curr, index) => {
      if (!curr.deal) {
        added.push({ deal: { ...curr }, rank: index });
      } else if (!previous.some((rank) => rank._links.self.href === curr._links.self.href)) {
        added.push({ ...curr, rank: index });
      }
    });

    if (removed.length === 0 && changed.length === 0 && added.length === 0) {
      setStatus(STATUS_OPTIONS.NO_CHANGES);
    } else {
      //Simultaneously make all DELETE, PATCH and POST calls to the /featuredStoreRanks or /featuredDeliveryRanks endpoints
      const location = locations.find((location) => location.province === state && location.city === city)._links.self
        .href;
      Promise.all([createRanks(type, added, location), deleteRanks(type, removed), updateRanks(type, changed)])
        .then(() => {
          //[] state should be updated with current data rather then fetching everything again
          setRankingsReady(false);

          const LISTENDPOINT = type === 'FeaturedDeal' ? 'featuredDealRanks' : 'recommendedDealRanks'; //[]  location api endpoints > Set in a constant
          getListbyLocation(cityID, LISTENDPOINT)
            .then((latestFeatList) => {
              const filteredList = filterFeaturedList('deal', latestFeatList, allActiveDeals);
              setPrevious(latestFeatList);
              setData(filteredList);
              setRankingsReady(true);
              setStatus(STATUS_OPTIONS.SUCCESS);
            })
            .catch((error) => setStatus(STATUS_OPTIONS.FETCH_FAIL));
        })
        .catch((error) => {
          setStatus(STATUS_OPTIONS.SAVE_FAIL);
        });
    }
  };

  const columnManagment = (
    <div className={classes.tableWrapper}>
      <div className={classes.sharedLists}>
        <div className={classes.leftList}>
          <Typography variant="body">
            Items in this list will be pinned to the top of their respective sections in the order they appear.
            {type === 'FeaturedDeal'
              ? ' Also, the TOP 3 items will appear in the NEWEST DEALS section of the Deals Page.'
              : ''}{' '}
            <strong>Drag and drop</strong> to add, remove and change the order of items.
          </Typography>
          <div className={mobile ? classes.mobilePinnedItems : classes.pinnedItems}>
            <ReactSortable
              list={data}
              setList={setData}
              animation={150}
              group="setting-pinned"
              style={{
                minHeight: '100px',
                height: '100%',
                width: '100%',
              }}
            >
              {data.map((item) => (
                <SortItem
                  key={item._links.self.href}
                  item={item.store ? item.store : item.delivery ? item.delivery : item.deal ? item.deal : item}
                  mobile={mobile}
                  type={type}
                  pinned
                ></SortItem>
              ))}
            </ReactSortable>
          </div>
        </div>
        <div className={classes.rightList}>
          <Typography variant="body">
            Items in this list will be ordered alphabetically in their "All" section after all the pinned items. You can{' '}
            <strong>drag items </strong>
            from this list to the pinned section.
          </Typography>
          <div className={mobile ? classes.mobileUnpinnedItems : classes.unpinnedItems}>
            <ReactSortable
              list={unpinned}
              setList={setUnpinned}
              animation={150}
              group="setting-pinned"
              style={{
                height: '100%',
                width: '100%',
                minHeight: '100px',
              }}
            >
              {unpinned.map((item) => (
                <SortItem
                  key={item._links.self.href}
                  item={item.store ? item.store : item.delivery ? item.delivery : item.deal ? item.deal : item}
                  mobile={mobile}
                  type={type}
                ></SortItem>
              ))}
            </ReactSortable>
          </div>
        </div>
      </div>

      <div className={classes.actionButtons}>
        <Button
          className={classes.formButton}
          variant="contained"
          onClick={() => {
            setData([]);
            setPrevious([]);
            setType('');
            setState('');
            setCity('');
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.formButton}
          variant="contained"
          color="primary"
          onClick={() => setStatus(STATUS_OPTIONS.CONFIRM_SAVE)}
        >
          Save
        </Button>
      </div>
    </div>
  );

  // const adminBookingSystem = (
  //   <div>
  //     <h2>{`${type === 'RecommendedDeal' ? 'Most Popular' : 'Platinum'} Deal Bookings`}</h2>

  //     <BookingSystemIndex
  //       locId={cityID}
  //       province={state}
  //       city={city}
  //       type={'deal'}
  //       list={type === 'RecommendedDeal' ? 'popular' : 'platinum'}
  //       storesInLocation={allActiveDeals}
  //       bookingWeeks={countSummary}
  //     />
  //   </div>
  // );

  //============================================================== RENDERING

  return (
    <div
      className={classes.root}
      onDragCapture={() => {
        $('#nav-bar-client-admin').hide();
      }}
      onDropCapture={() => {
        $('#nav-bar-client-admin').show();
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
    >
      <div className={mobile ? classes.mobileContent : classes.content}>
        <div className={classes.contentInnerContainer}>
          <Typography variant="h4" className={classes.title}>
            Manage Deals
          </Typography>
          <Typography variant="body2" className={classes.subheader}>
            Here you can control the ordering of Stores, Deliveries or Deals in the "All" section for a specific
            location.
          </Typography>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>Province</InputLabel>
            <Select
              value={state}
              onChange={(event) => {
                setPrevious([]);
                setData([]);
                setUnpinned([]);
                setCity('');
                setType('');
                setState(event.target.value);
              }}
            >
              {provinceList}
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl} disabled={!locationsReady}>
            <InputLabel>City</InputLabel>
            <Select
              value={city}
              onChange={(event) => {
                setPrevious([]);
                setData([]);
                setUnpinned([]);
                setType('');
                setCity(event.target.value);
              }}
            >
              {cityList}
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl} disabled={!state || !city}>
            <InputLabel>Type</InputLabel>
            {buildTypeList()}
          </FormControl>
          <Typography className={classes.note} variant="caption">
            Note: Only cities that have existing stores are shown.
          </Typography>
          {type && state && city && rankingsReady && allListReady && columnManagment}
          {state && city && type && rankingsReady && !allListReady && <h2>Loading...</h2>}
          {/* {allListReady && bookingMode === 'columns' && columnManagment}
          {allListReady && bookingMode === 'bookings' && adminBookingSystem} */}
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={status !== '' && status !== STATUS_OPTIONS.SAVING}
        onClose={() => {
          setStatus('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={status !== ''}>
          <div className={classes.modalPaper}>
            {status === STATUS_OPTIONS.SUCCESS && (
              <Fragment>
                <Typography className={classes.statusMessage}>Successfully saved!</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.SAVE_FAIL && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  There was an issue while saving your data. Please try again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.FETCH_FAIL && (
              <Fragment>
                <Typography className={classes.statusMessage}>There was an issue fetching data.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
            {(status === STATUS_OPTIONS.CONFIRM_SAVE || status === STATUS_OPTIONS.SAVING) && (
              <Fragment>
                <Typography className={classes.statusMessage}>Are you sure you want to save your changes?</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitChanges()}
                  className={classes.statusAcceptButton}
                >
                  {status === STATUS_OPTIONS.SAVING && <CircularProgress size={24} color="inherit" />}
                  {status !== STATUS_OPTIONS.SAVING && 'Save Changes'}
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.NO_CHANGES && (
              <Fragment>
                <Typography className={classes.statusMessage}>There are no changes to save.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ManageFeaturedDealsView;
