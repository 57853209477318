import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import api from "../utils/api";
import {
  InputAdornment,
  Tooltip,
  Modal,
  Backdrop,
  Fade,
  // FormControlLabel,
  // Checkbox
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import { ROLES } from "../utils/constants";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  endAdornment: {
    color: theme.palette.grey.A700,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  registerMsg: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButtons: { display: "flex" },
  confirmationMessage: {
    margin: theme.spacing(2),
  },
  confirmationButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
}));

export default function ChangePasswordView(props) {
  const { history } = props;

  // if (cookies.getJWT() && cookies.getUserID() && cookies.getRole()) {
  //   history.goBack();
  // }

  const classes = useStyles();

  const [password, setPassword] = useState("");
  const [passwordMask, setPasswordMask] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [changeError, setChangeError] = useState(false);
  const [changed, setChanged] = useState(false);

  const validate = () => {
    let valid = validatePassword() && password === passwordConfirm;

    return valid;
  };

  // const validateEmail = () => {
  //   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   return re.test(String(email).toLowerCase());
  // };

  const validatePassword = () => {
    //Length 8-50, 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character
    const containsUpperCase = password.match(/[A-Z]/);
    const containsLowerCase = password.match(/[a-z]/);
    const containsDigit = password.match(/\d/);
    const containsSpecial = password.match(/[^a-zA-Z\d]/);

    return (
      password.length >= 8 &&
      password.length <= 50 &&
      containsDigit &&
      containsSpecial &&
      containsUpperCase &&
      containsLowerCase
    );
  };

  const changePassword = async () => {
    return api
      .changePassword(password, token)
      .then(() => {
        setChanged(true);
      })
      .catch((error) => {
        setChangeError(true);
      });
  };

  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Change Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            value={password}
            onChange={(event) => {
              setChangeError(false);
              setPassword(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={passwordMask ? "password" : "text"}
            id="password"
            autoComplete="current-password"
            error={(submitted && !validatePassword()) || changeError}
            helperText={
              submitted && !validatePassword()
                ? "Password does not match requirements"
                : changeError
                ? "Something went wrong"
                : ""
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordMask && (
                    <Tooltip
                      title="Show password"
                      placement="bottom"
                      className={classes.endAdornment}
                    >
                      <VisibilityIcon
                        onClick={() => setPasswordMask(!passwordMask)}
                      />
                    </Tooltip>
                  )}
                  {!passwordMask && (
                    <Tooltip title="Hide password" placement="bottom">
                      <VisibilityOffIcon
                        onClick={() => setPasswordMask(!passwordMask)}
                        className={classes.endAdornment}
                      />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography variant="caption" className={classes.formNote}>
            Password Requirements: Length 8-50, 1 Uppercase, 1 Lowercase, 1
            Digit, 1 Special Character
          </Typography>
          <TextField
            value={passwordConfirm}
            onChange={(event) => {
              setChangeError(false);
              setPasswordConfirm(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Confirm Password"
            type="password"
            // id="password"
            autoComplete="current-password"
            error={(password && password !== passwordConfirm) || changeError}
            helperText={
              password && password !== passwordConfirm
                ? "Passwords do not match"
                : ""
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(event) => {
              event.preventDefault();
              setSubmitted(true);
              if (validate()) {
                changePassword();
              }
            }}
          >
            Change Password
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" to="/">
            Beautimap
          </Link>{" "}
          2021.
        </Typography>
      </Box>
      <Modal
        className={classes.modal}
        open={changed}
        onClose={() => {
          history.push("/");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={changed}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              Password was successfully changed. Please login using your new
              password.
            </Typography>
            <div className={classes.actionButtons}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/");
                }}
                className={classes.confirmationButton}
              >
                Ok
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
