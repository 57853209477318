import hair1 from './Hair01.jpeg';
import hair2 from './Hair02.jpeg';
import hair3 from './Hair03.jpeg';
import brows1 from './Brow01.jpeg';
import brows2 from './Brow02.jpeg';
import brows3 from './Brow03.jpeg';
import hairRemoval1 from './HairRemoval01.jpeg';
import hairRemoval2 from './HairRemoval02.jpeg';
import lashes1 from './Lashes01.jpeg';
import lashes2 from './Lashes02.jpeg';
import makeup1 from './Makeup01.jpeg';
import makeup2 from './Makeup02.jpeg';
import makeup3 from './Makeup03.jpeg';
import makeup4 from './Makeup04.jpeg';
import nails1 from './Nails01.jpeg';
import nails2 from './Nails02.jpeg';
import skin1 from './Skin01.jpeg';
import skin2 from './Skin02.jpeg';
import skin3 from './Skin03.jpeg';
import skin4 from './Skin04.jpeg';
import wellness1 from './Wellness01.jpeg';
import wellness2 from './Wellness02.jpeg';
import wellness3 from './Wellness03.jpeg';
import wellness4 from './Wellness04.jpeg';
import wellness5 from './Wellness05.jpeg';

export const banners = {
  hair: [hair1, hair2, hair3],
  brows: [brows1, lashes1, brows2, lashes2, brows3],
  nails: [nails1, nails2],
  makeup: [makeup1, makeup2, makeup3, makeup4],
  hairRemoval: [hairRemoval1, hairRemoval2],
  skin: [skin1, skin2, skin3, skin4],
  wellness: [wellness1, wellness2, wellness3, wellness4, wellness5],

  // lashes: [lashes1, lashes2, lashes3],
  // stylists: [stylists1, stylists2, stylists3],
  // aesthetics: [botox1, botox2, botox3],
  // waxing: [waxing1, waxing2, waxing3],
  // facial: [facial1, facial2, facial3],
  // selfcare: [selfcare1, selfcare2, selfcare3],
  // skincare: [skincare1, skincare2, skincare3],
  // tanning: [tanning1, tanning2, tanning3]
};
