import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: { display: "flex", flexDirection: "column" },
	headerElement: {
		fontWeight: "bold",
		margin: theme.spacing(2, 4, 2, 4),
		alignSelf: "center",
		textDecoration: "underline",
		fontSize: "14pt",
	},
	subHeaderElement: {
		fontWeight: "bold",
		margin: theme.spacing(2, 4, 2, 4),
		textDecoration: "underline",
	},
	element: {
		margin: theme.spacing(2, 4, 2, 4),
		fontSize: "10pt"
	},
	section: {
		margin: theme.spacing(2, 4, 2, 14),
		fontSize: "10pt"
	},
	bulletpoint: {
		margin: theme.spacing(2, 4, 2, 6),
		fontSize: "10pt"
	},
}));

function TermsConditionsContent() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.headerElement} variant="h1">
				WEBSITE TERMS AND CONDITIONS
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Acceptance of the Website Terms and Conditions of Use
			</Typography>

			<Typography className={classes.element}>
				These website terms and conditions of use for{" "}
				<a
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.beautimap.com"
				>
					www.beautimap.com
				</a>{" "}
				constitute a legal agreement and are entered into by and between you and
				GigEco Inc., operating as Beautimap (”Company,” “we,” “us,” “our”). The
				following terms and conditions, together with any documents and/or
				additional terms they expressly incorporate by reference (collectively,
				these “Terms and Conditions”), govern your access to and use of,
				including any content, functionality, and services offered on or through{" "}
				<a
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.beautimap.com"
				>
					www.beautimap.com
				</a>{" "}
				(the “Website”).
			</Typography>

			<Typography className={classes.element}>
				BY USING THE WEBSITE, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH
				THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY, FOUND AT{" "}
				<a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
					www.beautimap.com/privacy-policy
				</a>
				, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS
				AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE
				WEBSITE.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Geographic and Age Restrictions
			</Typography>

			<Typography className={classes.element}>
				The owner of the Website is based in Ontario in Canada. We provide this
				Website for use only by persons located in Canada. This Website is not
				intended for use in any jurisdiction where its use is not permitted. If
				you access the Website from outside Canada, you do so at your own risk
				and you are responsible for compliance with local laws of your
				jurisdiction.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Modifications to the Terms and Conditions and to the Website
			</Typography>

			<Typography className={classes.element}>
				We reserve the right in our sole discretion to revise and update these
				terms and conditions from time to time. Any and all such modifications
				are effective immediately upon posting and apply to all access to and
				continued use of the Website. You agree to periodically review the terms
				and conditions in order to be aware of any such modifications and your
				continued use shall be your acceptance of any such modifications.
			</Typography>

			<Typography className={classes.element}>
				The information and material on this Website, and the Website, may be
				changed, withdrawn or terminated at any time in our sole discretion
				without notice. We will not be liable if, for any reason, all or any
				part of the Website is restricted to users or unavailable at any time or
				for any period.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Your Use of the Website and Account Set-Up and Security
			</Typography>

			<Typography className={classes.element}>
				The security of your personal information is very important to us. We
				use physical, electronic, and administrative measures designed to secure
				your personal information from accidental loss and from unauthorized
				access, use, alteration, and disclosure.
			</Typography>

			<Typography className={classes.element}>
				The safety and security of your information also depends on you. Users
				are responsible for obtaining their own access to the Website. Users are
				required to ensure that all persons who access the Website through a
				user’s internet connection are aware of these Terms and Conditions and
				comply with them. The Website, including content or areas of the
				Website, may require user registration. It is a condition of your use of
				the Website that all the information you provide on the Website is
				correct, current, and complete.
			</Typography>

			<Typography className={classes.element}>
				Unfortunately, the transmission of information via the Internet is not
				completely secure. Although we do our best to protect your personal
				information, we cannot guarantee the security of your personal
				information transmitted to our Website. Any transmission of personal
				information is at your own risk. We are not responsible for
				circumvention of any privacy settings or security measures contained on
				the Website.
			</Typography>

			<Typography className={classes.element}>
				Your provision of registration information and any submissions you make
				to the Website through any functionality such as applications, chat
				rooms, e-mail, message boards, personal or interest group web pages,
				profiles, forums, bulletin boards and other such functions
				(collectively, “Interactive Functions”) constitutes your consent to all
				actions we take with respect to such information consistent with our
				Privacy Policy, found at{" "}
				<a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
					www.beautimap.com/privacy-policy
				</a>
				.
			</Typography>

			<Typography className={classes.element}>
				Any username, password, or any other piece of information chosen by you,
				or provided to you as part of our security procedures, must be treated
				as confidential, and you must not disclose it to any other person or
				entity. You must exercise caution when accessing your account from a
				public or shared computer so that others are not able to view or record
				your password or other personal information. You understand and agree
				that should you be provided an account, your account is personal to you
				and you agree not to provide any other person with access to this
				Website or portions of it using your username, password, or other
				security information. You agree to notify us immediately of any
				unauthorized access to or use of your username or password or any other
				breach of security. You also agree to ensure that you logout from your
				account at the end of each session. You are responsible for any password
				misuse or any unauthorized access.
			</Typography>

			<Typography className={classes.element}>
				We reserve the right at any time and from time to time, to disable or
				terminate your account, any username, password, or other identifier,
				whether chosen by you or provided by us, in our sole discretion for any
				or no reason, including any violation of any provision of these Terms
				and Conditions.
			</Typography>

			<Typography className={classes.element}>
				You are prohibited from attempting to circumvent and from violating the
				security of this Website, including, without limitation: (a) accessing
				content and data that is not intended for you; (b) attempting to breach
				or breaching the security and/or authentication measures which are not
				authorized; (c) restricting, disrupting or disabling service to users,
				hosts, servers or networks; (d) illicitly reproducing TCP/IP packet
				header; (e) disrupting network services and otherwise disrupting Website
				owner’s ability to monitor the Website; (f) using any robot, spider, or
				other automatic device, process, or means to access the Website for any
				purpose, including monitoring or copying any of the material on the
				Website; (g) introducing any viruses, trojan horses, worms, logic bombs,
				or other material that is malicious or technologically harmful; (h)
				attacking the Website via a denial-of-service attack, distributed
				denial-of-service attack, flooding, mailbombing or crashing; and (i)
				otherwise attempting to interfere with the proper working of the
				Website.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Intellectual Property Rights and Ownership
			</Typography>

			<Typography className={classes.element}>
				You understand and agree that the Website and its entire contents,
				features, and functionality, including, but not limited to, all
				information, software, code, text, displays, graphics, photographs,
				video, audio, design, presentation, selection, and arrangement, are
				owned by the Company, its licensors, or other providers of such material
				and are protected in all forms by intellectual property laws including
				without limitation, copyright, trademark, patent, trade secret, and any
				other proprietary rights. Use of any such property, except as expressly
				authorized, shall constitute an infringement or violation of the rights
				of the property owner and may be a violation of federal or other laws
				and could subject the infringer to legal action.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Conditions of Use and User Submissions
			</Typography>

			<Typography className={classes.element}>
				As a condition of your access and use, you agree that you may use the
				Website only for lawful purposes and in accordance with these Terms and
				Conditions.
			</Typography>

			<Typography className={classes.element}>
				The following content standards apply to any and all content, material,
				and information a user submits, posts, publishes, displays, or transmits
				(collectively, “submit”) to the website, to other users or other persons
				(collectively, “User Submissions”) and any and all Interactive
				Functions. Any and all User Submissions must comply with all applicable
				federal, provincial, local, and international laws, regulations and
				terms of service.
			</Typography>

			<Typography className={classes.element}>
				Without limiting the foregoing, you warrant and agree that your use of
				the Website and any User Submissions shall not:
			</Typography>
			<Typography className={classes.section}>
				(a) In any manner violate any applicable federal, provincial, local, or
				international law or regulation including, without limitation, any laws
				regarding the export of data or software, patent, trademark, trade
				secret, copyright, or other intellectual property, legal rights
				(including the rights of publicity and privacy of others) or contain any
				material that could give rise to any civil or criminal liability under
				applicable laws or regulations or that otherwise may be in conflict with
				these Terms and Conditions and our Privacy Policy found at{" "}
				<a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
					www.beautimap.com/privacy-policy
				</a>
				.
			</Typography>

			<Typography className={classes.section}>
				(b) In any manner violate the terms of use of any third-party website
				that is linked to the Website, including but not limited to, any
				third-party social media website.
			</Typography>

			<Typography className={classes.section}>
				(c) Include or contain any material that is exploitive, obscene,
				harmful, threatening, abusive, harassing, hateful, defamatory, sexually
				explicit or pornographic, violent, inflammatory, or discriminatory based
				on race, sex, religion, nationality, disability, sexual orientation, or
				age or other such legally prohibited ground or be otherwise
				objectionable, such determination to be made in Company’s sole
				discretion.
			</Typography>

			<Typography className={classes.section}>
				(d) Involve stalking, attempting to exploit or harm any individual
				(including minors) in any way by exposing them to inappropriate content
				or otherwise or ask for personal information as prohibited under
				applicable laws, regulations or code.
			</Typography>

			<Typography className={classes.section}>
				(e) Involve, provide or contribute any false, inaccurate or misleading
				information.
			</Typography>

			<Typography className={classes.section}>
				(f) Impersonate or attempt to impersonate the Company, a Company
				employee, another user, or any other person or entity (including,
				without limitation, by using email addresses, or screen names associated
				with any of the foregoing).
			</Typography>

			<Typography className={classes.section}>
				(g) Transmit, or procure the sending of, any advertisements or
				promotions (without our prior written consent), sales, or encourage any
				other commercial activities, including, without limitation, any “spam”,
				“junk mail”, “chain letter”, contests, sweepstakes and other sales
				promotions, barter, or advertising or any other similar solicitation.
			</Typography>

			<Typography className={classes.section}>
				(h) Encourage any other conduct that restricts or inhibits anyone’s use
				or enjoyment of the Website, or which, as determined by us, may harm the
				Company or users of the Website or expose them to liability.
			</Typography>

			<Typography className={classes.section}>
				(i) Cause annoyance, inconvenience, or needless anxiety or be likely to
				upset, embarrass, or alarm any other person.
			</Typography>

			<Typography className={classes.section}>
				(j) Promote any illegal activity, or advocate, promote, or assist any
				unlawful act.
			</Typography>

			<Typography className={classes.section}>
				(k) Give the impression that they originate from or are endorsed by us
				or any other person or entity, if this is not the case.
			</Typography>

			<Typography className={classes.element}>
				By submitting User Submissions, you represent, warrant, and covenant
				that you own, or have the necessary licenses, rights, consents, and/or
				permissions, and authorize the Company, its applicable affiliates, the
				Website, and all users thereof, to use such User Submissions as
				necessary.
			</Typography>

			<Typography className={classes.element}>
				By submitting User Submissions, you represent and warrant that you are
				at least of the legal age of majority under applicable law and in
				accordance with applicable provincial and/or territorial legal
				restrictions, and you acknowledge that persons under the legal age may
				not submit User Submissions.
			</Typography>

			<Typography className={classes.element}>
				You understand and agree that you, not the Company, nor Company’s
				parent, subsidiaries, affiliates nor their respective directors,
				officers, employees, agents, service providers, contractors, licensors,
				suppliers or successors, are fully responsible for any User Submissions
				you submit or contribute, and you are fully responsible and legally
				liable, including to any third party, for such content and its accuracy.
				We are not responsible or legally liable to any third party for the
				content or accuracy of any User Submissions submitted by you or any
				other user of the Website.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Site Monitoring and Enforcement, Suspension and Termination.
			</Typography>

			<Typography className={classes.element}>
				Company has the right, without provision of notice to:
			</Typography>

			<Typography className={classes.bulletpoint}>
				• &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remove or refuse to post on
				the Website any User Submissions for any or no reason in our sole
				discretion.
			</Typography>
			<Typography className={classes.bulletpoint}>
				• &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;At all times, take such
				actions with respect to any User Submission deemed necessary or
				appropriate in our sole discretion, including, without limitation, for
				violating these Terms and Conditions.
			</Typography>
			<Typography className={classes.bulletpoint}>
				• &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Take appropriate legal
				action, including, without limitation, referral to law enforcement or
				regulatory authority, or notifying the harmed party of any illegal or
				unauthorized use of the Website. Without limiting the foregoing, we have
				the right to fully cooperate with any law enforcement authorities or
				court order requesting or directing us to disclose the identity or other
				information of anyone posting any materials on or through the Website.
			</Typography>
			<Typography className={classes.bulletpoint}>
				• &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terminate or suspend your
				access to all or part of the Website for any or no reason, including,
				without limitation, any violation of these Terms and Conditions.
			</Typography>
			<Typography className={classes.element}>
				YOU WAIVE AND HOLD HARMLESS THE COMPANY, AND ITS PARENT, SUBSIDIARIES,
				AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
				SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS AND
				SUCCESSORS, FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY
				THE COMPANY, AND ANY OF THE FOREGOING PARTIES, RELATING TO ANY
				INVESTIGATIONS BY EITHER THE COMPANY, OR SUCH FOREGOING PARTIES, OR BY
				LAW ENFORCEMENT AUTHORITIES.
			</Typography>

			<Typography className={classes.element}>
				We have no obligation, nor any responsibility to any party to monitor
				the Website or its use, and do not and cannot undertake to review
				material that you or other users submit to the Website. We cannot ensure
				prompt removal of objectionable material after it has been posted and we
				have no liability for any action or inaction regarding transmissions,
				communications, or content provided by any user or third party, subject
				to applicable laws.
			</Typography>

			<Typography className={classes.subHeaderElement}>No Reliance</Typography>

			<Typography className={classes.element}>
				The content on our Website is provided for general information purposes
				only. Although we make reasonable efforts to update the information on
				our Website, we make no representations, warranties or guarantees,
				whether express or implied, that the content on our Website is accurate,
				complete or up to date. Your use of the Website is at your own risk and
				neither the Company, nor its parent, subsidiaries, affiliates, and their
				respective directors, officers, employees, agents, service providers,
				contractors, licensors, licensees, suppliers or successors, has any
				responsibility or liability whatsoever for your use of this Website.
			</Typography>

			<Typography className={classes.element}>
				This Website includes content provided by third parties, including from
				other users and third-party licensors. All statements and/or opinions
				expressed in any such third-party content, other than the content
				provided by the Company, are solely the opinions and the responsibility
				of the person or entity providing those materials. Such materials do not
				necessarily reflect the opinion of the Company. Neither the Company, nor
				its parent, subsidiaries, affiliates, and their respective directors,
				officers, employees, agents, service providers, contractors, licensors,
				licensees, suppliers or successors, has any responsibility or liability
				whatsoever to you, or any third party, for the content or accuracy of
				any third-party materials.
			</Typography>

			<Typography className={classes.subHeaderElement}>Privacy</Typography>

			<Typography className={classes.element}>
				By submitting your personal information and using our Website, you
				consent to the collection, use, reproduction, hosting, transmission and
				disclosure of any such user content submissions in compliance with our
				Privacy Policy, found at{" "}
				<a rel="noopener noreferrer" target="_blank" href="/privacy-policy">
					www.beautimap.com/privacy-policy
				</a>
				, as we deem necessary for use of the Website.
			</Typography>

			<Typography className={classes.element}>
				By using this Website you are consenting to the use of cookies which
				allow a server to recall previous requests or registration and/or IP
				addresses to analyze website use patterns. You can set your browser to
				notify you before you receive a cookie, giving you the chance to decide
				whether to accept it. You can also set your browser to turn off cookies.
				If you do, however, some areas of the Website may not function
				adequately.{" "}
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Third Party Websites
			</Typography>

			<Typography className={classes.element}>
				For your convenience, this Website may provide links or pointers to
				third-party sites. We make no representations about any other websites
				that may be accessed from this Website. If you choose to access any such
				sites, you do so at your own risk. We have no control over the contents
				of any such third-party sites and accept no responsibility for such
				sites or for any loss or damage that may arise from your use of them.
				You are subject to any terms and conditions of such third-party sites.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Disclaimer of Warranties
			</Typography>

			<Typography className={classes.element}>
				YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND
				ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR
				OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR
				ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS
				AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
				IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
				MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
				THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
				LIMITED UNDER APPLICABLE LAW.
			</Typography>

			<Typography className={classes.element}>
				NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR
				RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS,
				CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS OR SUCCESSORS MAKE ANY
				WARRANTY, REPRESENTATION OR ENDORSEMENT WITH RESPECT TO THE
				COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY OR
				AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE
				FOREGOING, NEITHER THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES
				OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
				PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS OR SUCCESSORS
				REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR
				ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
				ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR
				WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR
				OTHER HARMFUL COMPONENTS.
			</Typography>

			<Typography className={classes.element}>
				WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE
				FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES
				OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR
				YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET AND DATA SECURITY.
				TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
				LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED
				DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING OR
				CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER
				TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
				EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
				YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED
				THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT,
				OR ON ANY WEBSITE LINKED TO IT.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Limitation on Liability
			</Typography>

			<Typography className={classes.element}>
				EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO
				CIRCUMSTANCE WILL THE COMPANY NOR ITS PARENT, SUBSIDIARIES, AFFILIATES
				OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
				PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS
				BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
				FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
				INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
				PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
				AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
				LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
				LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
				OF CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF THE PARTY WAS
				ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION
				WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY
				LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE
				CONTENT, MATERIALS, POSTING OR INFORMATION THEREON EVEN IF THE PARTY WAS
				ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Indemnification
			</Typography>

			<Typography className={classes.element}>
				To the maximum extent permitted by applicable law, you agree to defend,
				indemnify, and hold harmless the Company, its parent, subsidiaries,
				affiliates, and their respective directors, officers, employees, agents,
				service providers, contractors, licensors, suppliers, successors, and
				assigns from and against any claims, liabilities, damages, judgments,
				awards, losses, costs, expenses, or fees (including reasonable legal
				fees) arising out of or relating to your breach of these Terms and
				Conditions or your use of the Website, including, but not limited to,
				your User Submissions, third-party sites, any use of the Website’s
				content, services, and products other than as expressly authorized in
				these Terms and Conditions.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Governing Law and Jurisdiction
			</Typography>

			<Typography className={classes.element}>
				The Website and these Terms and Conditions will be governed by and
				construed in accordance with the laws of the province of Ontario and the
				federal laws of Canada applicable therein, without giving effect to any
				choice or conflict of law provision, principle or rule, and
				notwithstanding your domicile, residence or physical location.
			</Typography>

			<Typography className={classes.element}>
				Any action or proceeding arising out of or relating to this Website and
				under these Terms and Conditions will be instituted in the courts of the
				province of Ontario and/or the Federal Court of Canada, and each party
				irrevocably submits to the exclusive jurisdiction of such courts in any
				such action or proceeding. You waive any and all objections to the
				exercise of jurisdiction over you by such courts and to the venue of
				such courts.
			</Typography>
			<Typography className={classes.subHeaderElement}>Waiver</Typography>
			<Typography className={classes.element}>
				No failure to exercise, or delay in exercising, any right, remedy, power
				or privilege arising from these Terms and Conditions operates, or may be
				construed, as a waiver thereof. No single or partial exercise of any
				right, remedy, power or privilege hereunder precludes any other or
				further exercise thereof or the exercise of any other right, remedy,
				power or privilege.
			</Typography>

			<Typography className={classes.subHeaderElement}>Severability</Typography>

			<Typography className={classes.element}>
				If any term or provision of these Terms and Conditions is invalid,
				illegal or unenforceable in any jurisdiction, such invalidity,
				illegality or unenforceability shall not affect any other term or
				provision of these Terms and Conditions or invalidate or render
				unenforceable such term or provision in any other jurisdiction.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Entire Agreement
			</Typography>

			<Typography className={classes.element}>
				The Terms and Conditions and our Privacy Policy constitute the sole and
				entire agreement between you and GigEco Inc. regarding the Website and
				supersedes all prior and contemporaneous understandings, agreements,
				representations and warranties, both written and oral, regarding such
				subject matter.
			</Typography>

			<Typography className={classes.subHeaderElement}>
				Reporting and Contact
			</Typography>

			<Typography className={classes.element}>
				This website is operated by GigEco Inc.
			</Typography>

			<Typography className={classes.element}>
				Should you become aware of misuse of the website including libelous or
				defamatory conduct, you must report it to the Company at{" "}
				<a href="mailto: info@beautimap.com">info@beautimap.com</a>.
			</Typography>

			<Typography className={classes.element}>
				All other feedback, comments, requests for technical support, and other
				communications relating to the Website should be directed to{" "}
				<a href="mailto: info@beautimap.com">info@beautimap.com</a>.
			</Typography>

			<Typography className={classes.element}>
				<strong>DATE: </strong>&nbsp;&nbsp;&nbsp;September 14, 2021
			</Typography>
		</div>
	);
}

export default TermsConditionsContent;
