import Cookies from 'js-cookie';
import ReactGA from 'react-ga';

export const setJWT = (value) => {
  Cookies.set('jwt', value, { expires: 5 });
};

export const setUserID = (value) => {
  Cookies.set('userID', value, { expires: 5 });
};

export const setRole = (value) => {
  Cookies.set('role', value, { expires: 5 });
};

export const setFirstName = (value) => {
  Cookies.set('firstName', value, { expires: 5 });
};

export const setLocation = (value) => {
  ReactGA.set({
    dimension1: value.city,
  });

  ReactGA.set({
    dimension2: value.province,
  });

  Cookies.set('location', JSON.stringify(value), { expires: 30 });
};

export const setRegion = (value) => {
  Cookies.set('region', value, { expires: 30 });
};

// export const setRemember = () => {
//   return Cookies.get("jwt");
// };

export const getJWT = () => {
  return Cookies.get('jwt');
};

export const getUserID = () => {
  return Cookies.get('userID');
};

export const getRole = () => {
  return Cookies.get('role');
};

export const getFirstName = () => {
  return Cookies.get('firstName');
};

export const getLocation = () => {
  return Cookies.get('location') ? JSON.parse(Cookies.get('location')) : undefined;
};

export const getRegion = () => {
  return Cookies.get('region');
};

// export const getRemember = () => {
//   return Cookies.get("jwt");
// };

const removeJWT = () => {
  Cookies.remove('jwt');
};

export const removeUserID = () => {
  Cookies.remove('userID');
};

export const removeFirstName = () => {
  Cookies.remove('firstName');
};

export const removeRole = () => {
  Cookies.remove('role');
};
export const removeLocation = () => {
  Cookies.remove('location');
};
export const removeJWTAndUserID = () => {
  removeJWT();
  removeUserID();
  Cookies.remove('role');
};

export const removeAll = () => {
  removeJWT();
  removeUserID();
  removeFirstName();
  removeRole();
  //Location not included b/c ideally it would be the same
  //[] TODO update name of function
};

export const setSignUpPrompt = (duration = 1) => {
  Cookies.set('hideWelcomeModal', true, { expires: duration });
};

export const getSignUpPrompt = () => {
  return Cookies.get('hideWelcomeModal');
};

export default {
  setJWT,
  setUserID,
  setRole,
  setFirstName,
  setLocation,
  setRegion,
  getJWT,
  getUserID,
  getRole,
  getFirstName,
  getLocation,
  getRegion,
  removeJWTAndUserID,
  removeLocation,
  removeAll,
  setSignUpPrompt,
  getSignUpPrompt,
};
