import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import api from '../utils/api';
import cookies from '../cookies';
import { Fade, Backdrop, Modal } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  loginPopover: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.background,
    maxWidth: 250,
    color: theme.palette.common.font,
  },
  loginTitle: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.common.font,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 700,
  },
  loginInput: {
    backgroundColor: theme.palette.common.white,
    marginTop: theme.spacing(1),
    borderRadius: 4,
  },
  loginText: {
    textAlign: 'center',
  },
  forgotPassLink: {
    color: theme.palette.common.font,
  },
  loginButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.common.font,
    textAlign: 'center',
    border: `${theme.palette.common.characters} solid`,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButtons: { display: 'flex' },
  confirmationMessage: {
    margin: theme.spacing(2),
  },
  confirmationButton: {
    color: theme.palette.common.white,

    margin: theme.spacing(2),
  },
  okconfirmationButton: {
    color: theme.palette.common.black,
    backgroundColor: '#DDDDDD',
    margin: theme.spacing(2),
  },
}));

export default function LoginPopover(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // const [submitted, setSubmitted] = useState(false);
  const [resendSubmitted, setResendSubmitted] = useState(false);
  const [resendEmail, setResendEmail] = useState('');
  const [resendModal, setResendModal] = useState(false);
  const { anchorEl, closeProfileMenu } = props;
  const [resendErrorMsg, setResendErrorMsg] = useState(''); //Red Error text
  const [resendError, setResendError] = useState(false);
  const [timer, setTimer] = useState(false);
  const [notVerif, setNotVerif] = useState(false);

  const [buttonText, setButtonText] = useState('Resend Email');

  const handleLogin = (e) => {
    e.preventDefault();
    return api
      .login(email.toLowerCase().trim(), password, setLoginError)
      .then((res) => {
        setLoginError(false);
        cookies.setJWT(res.data.token);
        cookies.setUserID(res.data.userID);
        api
          .getUser(cookies.getUserID())
          .then((res) => {
            cookies.setFirstName(res.firstName);
            cookies.setRole(res._embedded.role.name);
            closeProfileMenu();
            window.location.reload();
          })
          .catch((error) => {
            setLoginError(true);
            console.log(error.status);
          });
      })
      .catch((error) => {
        if (error.message.includes('User')) {
          setNotVerif(true);
          setResendModal(true);
          setErrorMsg('Please verify your email address before attempting to login');
        }
        if (error.message.includes('Unauthorized')) {
          setErrorMsg('Please enter valid credentials');
          // console.log("****",errorMsg)
        }
        //setErrorMsg(error.message)
        setLoginError(true);
      });
  };

  // const validate = () => {
  //   let valid = validateEmail() && validatePassword();

  //   return valid;
  // };

  const validateEmail = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // const validatePassword = () => {
  //   return password !== "";
  // };

  const resendConfirmEmail = async (email) => {
    return api
      .sendConfirmation(email)
      .then((res) => {
        setResendError(false);
        // setResendMsg("Email Confirmation resent")
        setResendErrorMsg('');
      })
      .catch((error) => {
        setResendError(true);
        if (error.message.includes('User')) {
          //setErrorMsg("There is no account associated to this email address.");
          setResendError(true);
          setResendErrorMsg('There is no account associated to this email address.');
          setTimer(false);
          setButtonText('Resend Email');
          //setSubmitted(false);
        }
        //setMsg("Please enter a valid email address to resend a verification email.")
      });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'login-popover' : undefined;

  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={closeProfileMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Container className={classes.loginPopover}>
        <Typography variant="h5" className={classes.loginTitle}>
          welcome back!
        </Typography>
        <form onSubmit={(e) => handleLogin(e)}>
          <TextField
            id="email"
            name="email"
            type="email"
            required
            fullWidth
            variant="outlined"
            margin="dense"
            value={email}
            className={classes.loginInput}
            onChange={(e) => {
              setLoginError(false);
              setEmail(e.target.value);
            }}
            placeholder="Your email"
            // error={(submitted && !validateEmail()) || loginError}
            // helperText={
            //   submitted && !validateEmail() ? "Enter a valid email address" : ""
            // }
          />
          <TextField
            id="password"
            name="password"
            type="password"
            required
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.loginInput}
            value={password}
            placeholder="Your password"
            onChange={(e) => {
              setLoginError(false);
              setPassword(e.target.value);
            }}
          />
          <Modal
            className={classes.modal}
            open={notVerif && resendModal}
            onClose={() => {
              setResendModal(false);
              //window.location.assign("/login");
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={notVerif && resendModal}>
              <div className={classes.modalPaper}>
                <Typography className={classes.confirmationMessage}>Please verify your email address.</Typography>
                <TextField
                  value={resendEmail}
                  onChange={(event) => {
                    setResendEmail(event.target.value);
                  }}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type={'text'}
                  id="email"
                  autoComplete="current-email"
                  error={(!validateEmail(resendEmail) && resendSubmitted) || resendError}
                  helperText={resendErrorMsg}
                />
                <div className={classes.actionButtons}>
                  <Button
                    variant="contained"
                    color="primary"
                    //disabled={notVerif && resendError}
                    onClick={() => {
                      setResendModal(false);
                    }}
                    className={classes.okconfirmationButton}
                  >
                    Ok
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    //hidden = {true ? notVerif : false}
                    className={classes.confirmationButton}
                    disabled={!validateEmail(resendEmail) || timer}
                    onClick={() => {
                      resendConfirmEmail(resendEmail);
                      setResendSubmitted(true);
                      //if(!error){
                      setTimer(true);
                      setButtonText('60s for next Resend');
                      setTimeout(() => {
                        setTimer(false);
                        setButtonText('Resend Email');
                      }, 60000);
                      // }
                    }}
                  >
                    {buttonText}
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
          <Link href="/forgotPassword">
            <Typography variant="body2" className={classes.forgotPassLink}>
              Forgot Password?
            </Typography>
          </Link>
          <Button type="submit" className={classes.loginButton} color="secondary" fullWidth variant="contained">
            <Typography>LOGIN</Typography>
          </Button>
        </form>
        {loginError && (
          <Typography className={classes.errorText} variant="body1">
            {errorMsg}
          </Typography>
        )}
        <Typography className={classes.loginText} variant="body2">
          Don't have an account?
        </Typography>
        <Link href="/signup" className={classes.loginText}>
          <Button className={classes.loginButton} color="secondary" fullWidth variant="contained">
            <Typography variant="body2">Register / Signup Here</Typography>
          </Button>
        </Link>
      </Container>
    </Popover>
  );
}
