import React, { useState, Fragment } from "react";
import {
  makeStyles,
  Typography,
  Button,
  ClickAwayListener,
  Tooltip,
} from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  detailsButton: {
    marginBottom: theme.spacing(2),
  },
  expandable: {
    maxWidth: "40vw",
    maxHeight: "20vh",
    padding: theme.spacing(2),
    overflow: "auto",
    border: "1px solid #ebebeb",
    marginBottom: theme.spacing(2),
  },
  errorSection: {
    display: "flex",
  },
  errorHeadings: {
    display: "flex",
    flexDirection: "column",
    // width: "18%",
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  errorContent: {
    display: "flex",
    flexDirection: "column",
    // width: "80%",
    flexGrow: 3,
  },
  dataSection: {
    display: "flex",
    marginTop: theme.spacing(5),
  },
  dataHeadings: {
    display: "flex",
    flexDirection: "column",
    // width: "18%",
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  dataContent: {
    display: "flex",
    flexDirection: "column",
    // width: "80%",
    flexGrow: 3,
  },
  copyButton: {},
}));

function ErrorDetails(props) {
  const classes = useStyles();

  const { error, data } = props;

  const [expand, setExpand] = useState(false);

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="default"
        onClick={() => setExpand(!expand)}
        className={classes.detailsButton}
      >
        Details
      </Button>
      {expand && (
        <Fragment>
          <div className={classes.expandable}>
            {(typeof error !== "string" || !error.includes("timeout of")) && (
              <div className={classes.errorSection}>
                <div className={classes.errorHeadings}>
                  <Typography variant="caption">
                    <strong>Timestamp:</strong>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Status:</strong>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Error:</strong>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Error Message:</strong>
                  </Typography>
                  <Typography variant="caption">
                    <strong>Path:</strong>
                  </Typography>
                </div>
                <div className={classes.errorContent}>
                  <Typography variant="caption">{error.timestamp}</Typography>
                  <Typography variant="caption">{error.status}</Typography>
                  <Typography variant="caption">{error.error}</Typography>
                  <Typography variant="caption">{error.message}</Typography>
                  <Typography variant="caption">{error.path}</Typography>
                </div>
              </div>
            )}
            {(typeof error !== "string" || !error.includes("timeout of")) && (
              <div className={classes.dataSection}>
                <div className={classes.dataHeadings}>
                  <Typography variant="caption">
                    <strong>Data:</strong>
                  </Typography>
                </div>
                <div className={classes.dataContent}>
                  <Typography variant="caption">
                    {JSON.stringify(data)}
                  </Typography>
                </div>
              </div>
            )}
            {typeof error === "string" && error.includes("timeout of") && (
              <Typography variant="caption">{error}</Typography>
            )}
          </div>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Fragment>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
                // bottom
                arrow
              >
                <CopyToClipboard
                  className={classes.actionButton}
                  text={`Error: ${JSON.stringify(
                    error
                  )}, Data: ${JSON.stringify(data)}`}
                >
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={handleTooltipOpen}
                  >
                    Copy Details
                  </Button>
                </CopyToClipboard>
              </Tooltip>
            </Fragment>
          </ClickAwayListener>
        </Fragment>
      )}
    </div>
  );
}

export default ErrorDetails;
