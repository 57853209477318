/* Component to group appointment items together by day
Itent is to provide separation between days 

[]TODO : need to decide if this will be a wrapper, or if data will be drilled and passed in
  Leaning towards wrapper as all data should be handled higher becuase there will eventually be other view options (Day/ Week/ Month)
*/
import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { isBefore, isSameDay } from 'date-fns';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
  dayRoot: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    padding: '8px',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  dateText: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
    width: '15%',
  },
  appointmentItemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '100%',
  },
  passedDay: {
    opacity: 0.5,
  },
}));

export default function Day({ children, date }) {
  const classes = useStyles();
  const today = new Date();
  const itemDate = new Date(`${date} 00:00`); //`date` prop '2023-12-22' is treated as UTC when date is created, converts to local when time is added (00:00)
  const isDayPassed = isBefore(itemDate, today) && !isSameDay(itemDate, today);

  return (
    <div className={isDayPassed ? `${classes.dayRoot} ${classes.passedDay}` : classes.dayRoot}>
      <Typography className={classes.dateText}>{format(itemDate, 'dd MMM, EEE')}</Typography>
      <div className={classes.appointmentItemsContainer}>{children}</div>
    </div>
  );
}
