// *** FROM SDAW - MODIFY AS NEEDED ****
import React, { useState, useEffect } from 'react';
//================
import cookies from '../cookies';
import { useHistory } from 'react-router-dom';
//================
import { makeStyles } from '@material-ui/styles';
import MUIDataTable from 'mui-datatables';
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  Fade,
  Backdrop,
  useMediaQuery,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import api from '../utils/api';
import { STATUS, DISCOUNT_TYPES } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
import StoreButton from '../components/StoreButton';
import AddIcon from '@material-ui/icons/Add';
import theme from '../theme';
import ErrorDetails from '../components/ErrorDetails';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth + 300,
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeading: {
    display: 'flex',
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
  },
  title: {
    fontWeight: 'bold',
  },
  createButton: {},
  table: {
    zIndex: '50!important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalMessage: {
    margin: theme.spacing(2),
  },
  confirmationActionButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100,
  },
  deleteConfirmation: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100,
  },
  portalNavigation: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down('sm')]: { flexDirection: 'column' },
  },
  createNewText: { [theme.breakpoints.down('sm')]: { display: 'none' } },
  createNewIcon: { [theme.breakpoints.up('md')]: { display: 'none' } },
}));

// const getDeals = async (id) => {
//   return api
//     .getDealsForStore(id)
//     .then((res) => res)
//     .catch(() => {
//       throw STATUS.ERROR;
//     });
// };

const getStoreInfo = async (id) => {
  return api
    .getStore(id)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const deleteDeal = async (id) => {
  return api
    .deleteDeal(id)
    .then(() => STATUS.SUCCESS)
    .catch((err) => {
      throw err;
    });
};

function MyDealsView(props) {
  const classes = useStyles();

  const { adminMode } = props;
  const [deals, setDeals] = useState([]);
  const [dealLimit, setDealLimit] = useState(3);
  const [dealSummary, setSummary] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleted, setDeleted] = useState(0);

  const [APIError, setAPIError] = useState('');
  const history = useHistory();
  const storeID = props.match.params.id;
  //  sdaw-548 Limit store deals=================================================
  const checkCanActivate = () => {
    if (dealSummary.active) {
      return dealSummary.active < dealLimit ? true : false;
    }
    return true;
  };

  const canActivate = checkCanActivate();

  const addStatusToDeal = (deal) => {
    //Rx Deal Object > Add a Status based on today's date
    if (typeof deal !== 'object') {
      return deal;
    }
    const updatedDeal = { ...deal, status: 'Active' };
    const startDate = new Date(deal.startDate);
    const endDate = new Date(deal.endDate);
    const today = new Date();
    if (startDate > today) {
      updatedDeal.status = 'Upcoming';
    } else if (endDate < today) {
      updatedDeal.status = 'Expired';
    }
    return updatedDeal;
  };

  const statusStyling = (status) => {
    if (status === 'Active') {
      return { color: '#599e57', border: '2px solid #599e57' };
    } else if (status === 'Upcoming') {
      return { color: '#ff9500', border: '2px solid #ff9500' };
    } else {
      return { color: '#ed685f', border: '2px solid #ed685f' };
    }
  };

  const propsToEditForm = {
    existingDeals: deals,
    activeDealLimit: dealLimit,
    dealSummary,
  };

  useEffect(() => {
    const addStatusToDeals = (allDeals) => {
      //This function will add a "Status" Key pair value to the all deals (Upcoming, Active, or Expired)
      //Then will rebuild the deals Array for rendering
      const summary = { active: 0, expired: 0, upcoming: 0 };
      const updatedDeals = [];
      allDeals.forEach((deal) => {
        const nDeal = addStatusToDeal(deal);
        if (nDeal.status === 'Upcoming') {
          summary.upcoming += 1;
        } else if (nDeal.status === 'Expired') {
          summary.expired += 1;
        } else {
          summary.active += 1;
        }
        if (summary) {
          setSummary(summary);
        }
        updatedDeals.push(nDeal);
      });
      return updatedDeals;
    };

    const isOwner = (storeOwnerID, adminMode) => {
      if (adminMode) return true;
      return storeOwnerID === cookies.getUserID() ? true : false;
    };
    //====================

    setLoading(true);
    getStoreInfo(storeID) //Switch get When backend dealLimit is in place
      .then((storeInfo) => {
        //====If clinic re route
        //==========Check is store belongs to the user or user is an admin
        const storeType = storeInfo.data.storeType;
        const ownerID = parseIdFromLink(storeInfo.data._embedded.owner._links.self.href, 0, '{?projection}');
        // console.log('STORE INFO', adminMode, ownerID, cookies.getUserID(), isOwner(ownerID, adminMode));
        if (!isOwner(ownerID, adminMode) || storeType === 'Clinic') return history.push('/client/stores');
        //===========================
        const initDeals = storeInfo.data._embedded.deals.sort((elem1, elem2) => {
          // storeInfo.sort((elem1, elem2) => {
          const first = parseIdFromLink(elem1._links.self.href);
          const second = parseIdFromLink(elem2._links.self.href);
          return second - first;
        });
        const dealsWithStatus = addStatusToDeals(initDeals);
        setDeals(dealsWithStatus);

        setDealLimit(storeInfo.data.maxActiveDeals ? storeInfo.data.maxActiveDeals : 3); // pre-feature stores have null 'maxActiveDeals'
        setLoading(false);
      })
      .catch((e) => {
        if (e === 'ERROR') return history.push('/client/stores');
        setLoadingError(true);
      });
  }, [storeID, deleted, adminMode, history]);
  //========================================================================
  function ActionMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const { id, storeId, showConfirmation, adminMode /*, published*/ } = props;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <Button variant="contained" onClick={handleClick}>
          <MoreHorizIcon />
        </Button>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem
            onClick={handleClose}
            component={Link}
            to={{
              pathname: `/${adminMode ? 'admin/stores' : 'client/stores'}/${storeId}/deals/${id}`,
              state: propsToEditForm,
            }}
            style={{ color: '#000000', textDecoration: 'none' }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            // disabled={!published}
            component={Link}
            // to={`/deals/${id}`}
            to={{
              pathname: `/client/preview/stores/${storeId}`,
              query: {
                dealId: id,
              },
            }}
            style={{ color: '#000000', textDecoration: 'none' }}
          >
            Preview
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              showConfirmation(id);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </div>
    );
  }

  const columns = [
    {
      name: '_links.self.href',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => parseIdFromLink(value, 0, '{?projection}'),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    // {
    //   name: "createDateTime",
    //   label: "Created At",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: value => moment.utc(value).local().format("MMMM Do YYYY, h:mm a")
    //   }
    // },
    // {
    //   name: "published",
    //   label: "Published",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: value => (value ? "Yes" : "No")
    //   }
    // },
    {
      name: '_links.self.href',
      label: 'Discount',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (link) => {
          const discountType = data.find((deal) => deal._links.self.href === link).discount.type;

          if (discountType === DISCOUNT_TYPES[0] || discountType === DISCOUNT_TYPES[1]) {
            return data.find((deal) => deal._links.self.href === link).discount.amount + ' ' + discountType;
          } else if (discountType === DISCOUNT_TYPES[2]) {
            return `Buy ${data.find((deal) => deal._links.self.href === link).buy} Get ${
              data.find((deal) => deal._links.self.href === link).get
            } Free`;
          } else {
            return discountType;
          }
        },
      },
    },
    {
      name: 'startDate',
      label: 'Starts On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => moment(value).format('MMMM Do YYYY'),
      },
    },
    {
      name: 'endDate',
      label: 'Ends On',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => moment(value).format('MMMM Do YYYY'),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Button
              size="small"
              className={classes.storeButton}
              variant="outlined"
              disabled={true}
              style={statusStyling(value)}
            >
              {value}
            </Button>
          );
        },
      },
    },
    {
      name: 'appliesTo',
      label: 'Applies To',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: '_links.self.href',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <ActionMenu
              adminMode={adminMode}
              id={parseIdFromLink(value, 0, '{?projection}')}
              storeId={storeID}
              showConfirmation={() => setDeleteConfirm(parseIdFromLink(value, 0, '{?projection}'))}
              published={data.find((store) => store._links.self.href === value).published}
              type={data.find((store) => store._links.self.href === value).storeType}
            ></ActionMenu>
          );
        },
      },
    },
    {
      name: '_links.self.href',
      label: 'Preview',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            // to={`/deals/${parseIdFromLink(value)}`}
            to={{
              pathname: `/client/preview/stores/${storeID}`,
              query: {
                dealId: parseIdFromLink(value, 0, '{?projection}'),
              },
            }}
            style={{ color: '#ffffff', textDecoration: 'none' }}
          >
            <ArrowRightAltIcon />
          </Button>
        ),
      },
    },
  ];

  const data = deals;

  const mobile = useMediaQuery('(max-width:767px)');

  const options = {
    filterType: 'checkbox',
    download: adminMode ? true : false,
    print: false,
    viewColumns: false,
    elevation: 2,
    responsive: mobile ? 'scrollMaxHeight' : 'stacked',
    selectableRows: 'none',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getMuiTheme = () =>
    createMuiTheme({
      ...theme,
      overrides: {
        MUIDataTableToolbar: {
          root: {
            display: 'flex !important',
          },
          left: {
            flexGrow: '1',
          },
          actions: {
            flexGrow: '0',
            display: 'flex',
          },
        },
        MUIDataTablePagination: {
          root: {
            padding: '0px !important',
          },
          selectRoot: {
            margin: '2px',
          },
        },
        MuiToolbar: {
          regular: {
            paddingLeft: '24px',
          },
          gutters: {
            // paddingLeft: "0px !important"
          },
        },
        MuiTablePagination: {
          actions: {
            marginLeft: '5px',
          },
        },
        MuiIconButton: {
          root: {
            padding: '4px',
          },
        },
      },
    });

  return (
    <div className={classes.root}>
      {loading && !loadingError && <Typography>Loading...</Typography>}
      {(!loading || loadingError) && (
        <div className={classes.contentContainer}>
          <div className={classes.tableHeading}>
            <Typography variant="h4" className={classes.title}>
              {adminMode ? '' : 'My'} Deals
            </Typography>
            {/* {!adminMode && ( */}
            {/* sdaw-548 - 
            limitReached ? Modal Message : usual Button
            */}
            <Typography variant="h4" className={classes.title}>
              {' '}
              Active Deals: {dealSummary.active || 0} / {dealLimit}{' '}
            </Typography>
            {!canActivate ? (
              <Button
                variant="contained"
                color="primary"
                className={classes.createButton}
                component={Link}
                to={{
                  pathname: `/${adminMode ? 'admin/stores' : 'client/stores'}/${storeID}/deals/new`,
                  state: propsToEditForm,
                }}
                style={{ color: '#FFFFFF', textDecoration: 'none' }}
              >
                <Typography className={classes.createNewText}>Add Upcoming Deal</Typography>
                <AddIcon className={classes.createNewIcon}></AddIcon>
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                className={classes.createButton}
                component={Link}
                to={{
                  pathname: `/${adminMode ? 'admin/stores' : 'client/stores'}/${storeID}/deals/new`,
                  state: propsToEditForm,
                }}
                style={{ color: '#FFFFFF', textDecoration: 'none' }}
              >
                <Typography className={classes.createNewText}>Add New Deal</Typography>
                <AddIcon className={classes.createNewIcon}></AddIcon>
              </Button>
            )}
            {/* )} */}
          </div>
          <div className={classes.portalNavigation}>
            <StoreButton
              disabled
              label="Viewing Store: "
              id={storeID}
              path={`/${adminMode ? `admin/stores/` : `client/`}${storeID}/deals`}
            ></StoreButton>
            <StoreButton
              overrideLabel
              label="Back to Stores"
              id={storeID}
              // path={`/${adminMode ? `admin/stores` : `client/stores`}`}
              // beaut-126: ao: *** MAY NEED TO FIX FOR ADMIN MODE TOO ****
              path={`/${adminMode ? `admin/stores` : `client/preview/stores/${storeID}`}`}
            ></StoreButton>
          </div>
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable className={classes.table} data={data} columns={columns} options={options} />
          </MuiThemeProvider>
        </div>
      )}
      <Modal
        className={classes.modal}
        open={deleteConfirm !== null}
        onClose={() => {
          setDeleteConfirm(null);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteConfirm !== null}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>Are you sure you want to delete this deal?</Typography>
            <div>
              <Button
                variant="contained"
                color="default"
                className={classes.confirmationActionButton}
                onClick={() => {
                  setDeleteConfirm(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.deleteConfirmation}
                onClick={() => {
                  setDeleting(true);
                  deleteDeal(deleteConfirm)
                    .then((status) => {
                      setDeleting(false);
                      setDeleteConfirm(null);
                      setDeleteMessage(status);
                      setDeleted(deleted + 1);
                    })
                    .catch((err) => {
                      setDeleting(false);
                      setDeleteConfirm(null);
                      setAPIError(err ? err : {});
                      setDeleteMessage(STATUS.ERROR);
                    });
                }}
              >
                {deleting && <CircularProgress size={24} color="inherit" />}
                {!deleting && 'Delete'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={deleteMessage !== ''}
        onClose={() => {
          setDeleteMessage('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteMessage !== ''}>
          <div className={classes.modalPaper}>
            <Typography className={classes.modalMessage}>
              {deleteMessage === STATUS.SUCCESS
                ? 'Deal successfully deleted.'
                : deleteMessage === STATUS.ERROR
                ? 'Something went wrong when deleting the deal. Please try again.'
                : ''}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                color="default"
                className={classes.confirmationActionButton}
                onClick={() => {
                  setDeleteMessage('');
                }}
                style={{ marginBottom: theme.spacing(1) }}
              >
                Ok
              </Button>
              {deleteMessage !== STATUS.SUCCESS && <ErrorDetails error={APIError} data={{ NA: 'NA' }}></ErrorDetails>}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default MyDealsView;
