import React from 'react';
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
// import MUIDataTable from "mui-datatables";
import { makeStyles } from '@material-ui/core/styles';
import AddEditStore from '../components/AddEditStore';
// import {data} from '../data';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(4, 0, 4, 0),
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // color:'red',
    width: '100%',
    marginBottom: theme.spacing(4),
    alignItems: 'center',
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.dark,
    },
  },
  formContainer: {
    padding: theme.spacing(2),
    width: '100%',
  },
  bold: {
    fontWeight: 700,
  },

  // title: {
  //   marginLeft: theme.spacing(2),
  //   flex: 1,
  // },
}));

function AddEditStoreView(props) {
  const { previewMode, editMode, previewId, data, storesData, setStoresData, handleClose } = props;

  let storeId;

  if (previewMode) {
    storeId = previewId;
  } else {
    storeId = props.match ? props.match.params.storeId : null;
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.contentInnerContainer}>
        <div className={classes.title}>
          {editMode ? (
            <Typography variant="h4" className={classes.bold}>
              Edit Details
            </Typography>
          ) : (
            <Typography variant="h4" className={classes.bold}>
              Create Store
            </Typography>
          )}
          {/* <CancelIcon color='secondary' className={classes.closeIcon} onClick={handleClose}></CancelIcon> */}
        </div>
        <Paper id="formBorder" elevation={5} className={classes.formContainer}>
          <AddEditStore
            data={data}
            storesData={storesData}
            setStoresData={setStoresData}
            adminMode
            handleClose={handleClose}
            editMode={editMode}
            previewMode={previewMode}
            storeId={storeId}
          />
        </Paper>
      </Container>
    </div>
  );
}

export default AddEditStoreView;
