import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import DeleteDialog from './DeleteDialog';

const useStyles = makeStyles((theme) => ({
  svcItemRoot: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    // backgroundColor: 'blue',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px',
    flexWrap: 'wrap',
  },
  publishedSwitch: {}, //Color set in component props
  checkedItem: {
    '& .Mui-disabled': {
      color: theme.palette.primary.main,
    },
  },
}));

const INPUT_FIELDS = [
  { label: 'Name', key: 'name' },
  { label: 'Description (optional)', key: 'description' },
  { label: 'Duration (min)', key: 'durationInMinutes', type: 'number' }, //API saves under durationInMinutes
  { label: 'Price', key: 'price', type: 'number' },
  { label: 'Published', key: 'isArchived', type: 'checkbox' }, //API saves under isArchived so user side flips the value
];

export default function ServiceItem({ service, actions }) {
  const classes = useStyles();
  const [mode, setMode] = useState(service.id ? 'view' : 'edit'); // 'view' | 'edit' | 'create' | 'delete'
  const [serviceState, setServiceState] = useState(service || {});
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleInputChange = (fieldKey, inputValue) => {
    if (fieldKey === 'durationInMinutes' || fieldKey === 'price') {
      //Only numbers allowed, no negatives.  Input type sends "" if NaN
      if (inputValue === '' || inputValue < 0) {
        return;
      }
    }

    setServiceState((prevState) => ({
      ...prevState,
      [fieldKey]: inputValue,
    }));
  };

  const handleCheckboxChange = (key, value) => {
    setServiceState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSave = () => {
    // TODO: Implement save functionality
    actions.saveServiceItem(serviceState);
    setMode('view');
  };

  const handleDelete = () => {
    actions.removeServiceItem(service.id);
    setConfirmDelete(false);
  };

  const handleCancel = () => {
    // Reset to initial State then set mode to view
    setServiceState(service);
    setMode('view');
  };

  return (
    <Card style={{ margin: '16px', padding: '16px' }}>
      <form className={classes.svcItemRoot} noValidate autoComplete="off">
        <TextField label="ID:" value={`${serviceState.id ?? 'NEW'}`} disabled size="small" />

        {/* ===========================Inputs ========================== */}
        {INPUT_FIELDS.map(({ label, key, type }) => {
          if (type === 'checkbox') {
            const isItemPublished = !serviceState[key];
            let classToUse = classes.publishedSwitch;
            if (isItemPublished) classToUse += ` ${classes.checkedItem}`;
            return (
              <FormControlLabel
                control={
                  <Switch
                    id={`service${key}`}
                    className={classToUse}
                    checked={isItemPublished}
                    onChange={(e) => handleCheckboxChange(key, !e.target.checked)}
                    name={key}
                    color="primary" //Pink
                    disabled={mode === 'view'}
                    style={{}}
                  />
                }
                label={isItemPublished ? label : 'Unpublished'}
              />
            );
          }

          return (
            <React.Fragment key={key}>
              <TextField
                id={`service${key}`}
                label={label}
                variant="outlined"
                value={serviceState[key] ?? ''}
                type={type ?? 'text'}
                onChange={(e) => handleInputChange(key, e.target.value)}
                disabled={mode === 'view'}
              />
            </React.Fragment>
          );
        })}

        {/* ===========================Buttons ========================== */}
        {mode === 'view' && (
          <ButtonGroup>
            <Button onClick={() => setMode('edit')}>Edit</Button>
            {/* <Button onClick={() => setConfirmDelete(true)} style={{ borderColor: 'red', color: 'red' }}>
              Delete
            </Button> */}
            {/* TODO - Delete will be admin only */}
            {/* Delete should be an X icon - only visible during edit mode
            - Do we even want users to be able to delete services?
              As it will affect appointments eventually - just archive?
           */}
          </ButtonGroup>
        )}
        {mode === 'edit' && (
          <ButtonGroup>
            <Button onClick={handleSave} disabled={!serviceState.name || !serviceState.durationInMinutes}>
              Save
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ButtonGroup>
        )}
      </form>
      {/* Dialog to confirm delete */}
      <DeleteDialog
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        handleConfirm={handleDelete}
        name={serviceState.name}
      />
    </Card>
  );
}
