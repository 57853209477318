import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      //Pink
      light: '#ffc1b3',
      main: '#FFB4A4',
      dark: '#ff9780',
      contrastText: '#ffffff',
    },
    secondary: {
      //Grey
      main: '#636363',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
    warning: {
      //Redish - Pink
      light: ' #f4717a',
      main: '#EE333F',
      dark: '#ec1322',
      contrastText: '#ffffff',
    },

    common: {
      black: '#000000',
      lightGray: '#F2F2F2',
      gray: '#D3D3D3',
      white: '#FFFFFF',
      red: '#EE333F',
      //Below are new brand colors will move copy to primary and secondary
      background: '#FEEBE4', //Pale Pink
      characters: '#F7BEB0', //Pink primary
      font: '#473C38',
    },
    legacy: {
      Primary: {
        //Pink
        light: '#ffc1b3',
        main: '#FFB4A4',
        dark: '#ff9780',
        contrastText: '#ffffff',
      },
      secondary: {
        //Grey
        main: '#636363',
        dark: '#000000',
        contrastText: '#FFFFFF',
      },
      warning: {
        //Redish - Pink
        light: ' #f4717a',
        main: '#EE333F',
        dark: '#ec1322',
        contrastText: '#ffffff',
      },
    },
  },

  screen: {
    maxWidth: 1245,
    // maxWidth: 1450,
    drawer: {
      width: 250,
    },
    aboutContent: {
      width: 420,
    },
  },
  breakpoints: {
    values: {
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Montserrat, Quicksand, Roboto, Helvetica, Arial, sans-serif',
  },
});

// // From sdaw (kept for reference)
// export default createMuiTheme({
//   mixins: {
//     toolbar: {
//       minHeight: 96
//     },
//     largeToolbar: {
//       minHeight: 96 + 60
//     }
//   },

//   overrides: {
//     MuiBreadcrumbs: {
//       separator: {
//         margin: 0
//       }
//     }
//   },
//   screen: {
//     maxWidth: 1245,
//     drawer: {
//       width: 250
//     },
//     aboutContent: {
//       width: 420
//     }
//   },
//   palette: {
//     common: {
//       darkGrey: "#3c3f3d",
//       darkerGrey: "#0c0f0d"
//     },
//     primary: {
//       light: "#4e652e",
//       main: "#4e652e",
//       dark: "#4e652e",
//       contrastText: "#ffffff"
//     },
//     secondary: {
//       light: "#9ea137",
//       main: "#9ea137",
//       dark: "#9ea137",
//       contrastText: "#ffffff"
//     }
//   },
//   typography: {
//     fontFamily: `"Lato", "Roboto", "Helvetica", "Arial", sans-serif`
//   }
// });
