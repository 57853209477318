/* This version of carousel uses /topBanners from the API
Theses are the managed by the admin team */
import React, { Fragment, useState, useEffect } from 'react';
import { Carousel } from 'antd';
import 'antd/dist/antd.css';
import './styles/Carousel.css';
import { makeStyles, Typography, Button, Divider } from '@material-ui/core';
import api from '../utils/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import theme from '../theme';

const useStyles = makeStyles((theme) => ({
  /* overall banner height is controlled by  `import './styles/Carousel.css';` [] TODO Refactor into this file, classname should be on <Carousel> */
  root: {
    width: '100%',
    height: '100%',
  },

  bannerSlide: {
    //Heights need to match .Carousel.css Heights
    display: 'flex!important',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: '45vh',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '38vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '30vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '22vh',
    },
  },
  bannerImage: {
    height: '100%',
    width: '100%',
    maxWidth: '1700px',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    zIndex: 2,
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  contentInnerContainer: {
    maxWidth: '1245px',
    position: 'relative',
  },

  //NOTE: any styling with banner_____ is referencing the HOME page banners. All other banners are specified as: storeFrontBanner, delvieryBanner etc
  bannerText: {
    textShadow: '1px 1px #4e652e',
    wordBreak: 'break-word',
    fontSize: '7vh',
    [theme.breakpoints.down('lg')]: {
      fontSize: '6vh',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '5vh',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.5vh',
    },
    fontWeight: '600',
    margin: '1rem',
  },

  bannerButton: {
    boxShadow: '0px 0px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
    },
  },

  bannerCaption: {
    fontWeight: 'bold',
    fontSize: '2.75vh',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75vh',
    },
  },

  divider: {
    height: 2,
  },
}));

const fetchBanners = async () => {
  return await api.getTopBanners();
};

function CarouselPage({ banners, type, override, previewBannerName, previewBannerData, bannerImages }) {
  const classes = useStyles();

  const [state, setState] = useState({
    //TODO: we should probably use a constant from utils that is self building whenever something gets added to the nav bar in the future so this list auto updates...
    banners: {
      formattedBanners: [],
    },
    loading: true,
    apiState: {
      apiError: false,
      error: false,
      apiMessage: '',
    },
    hasChanged: 0, //Cfm if this is used
  });

  const getTextPosition = (val) => {
    /*
    Val will be a 2 char code (sce) that will be used to determine flex position
    If no input rtn null > CSS will default to center left (CSS)
     */
    if (!val) {
      return null;
    }
    const options = {
      s: 'flex-start',
      c: 'center',
      e: 'flex-end',
    };
    const vertical = val[0];
    const horizontal = val.slice(-1);

    const flexPosns = {
      alignItems: options[vertical],
      justifyContent: options[horizontal],
    };

    return flexPosns;
  };

  //we get the backend banners and now we need to stitch together the differnce between how we used to provide data to the caroosel and the new banner system..
  useEffect(() => {
    let mounted = true;
    /* TODO - Need to dry up code b/c there are 2x set states that do the exact same thing, setState should go into its own function */

    setState((prev) => ({ ...prev, loading: true }));
    const parsedInfo = {};
    if (previewBannerName) {
      const { bannerText, bannerTextPosition, link, fontSize, fontColor, seo, mobile, file, page, bannerTextCheckBox } =
        previewBannerData;
      const bannerIndex = previewBannerName.slice(-1);
      //set banner using preview prop
      parsedInfo[bannerIndex] = {
        name: 'newBanner',
        text: bannerIndex === '1' ? `SEO Text, ie  ${page} <City>` : bannerTextCheckBox && bannerText,
        textPosition: getTextPosition(bannerTextPosition),
        fontSize,
        fontColor,
        seo,
        link,
        file,
        mobile,
        page,
        // id: pageInfo._links.self.href,
      };
    } else {
      fetchBanners()
        .then((res) => {
          // console.log("***At Carousel - res: ", res);
          const bannersForAllPages = res.data._embedded.topBanners;
          const pageInfo = bannersForAllPages.find((x) => x.page === type);
          if (!pageInfo) return; //Stops no banners found from breaking the app
          //Parse bannerInfo (1 big flat object need to parse)
          // ao: beaut-118 for the first 2 default banners:
          for (let i = 1; i < 3; i++) {
            parsedInfo[i] = {
              name: `Banner${i}`,
              file: { url: bannerImages[i - 1] },
            };
          }
          for (let i = 1; i <= 5; i++) {
            //Banner Object is fixed to 5 banners (API)
            // ao: beaut-167 if there's an uploaded image for banner1 or banner2 use it instead of default.
            if (pageInfo[`file${i}`])
              parsedInfo[i] = {
                name: pageInfo[`name${i}`],
                text: pageInfo[`bannerText${i}`],
                textPosition: getTextPosition(pageInfo[`textStylePos${i}`]),
                fontSize: pageInfo[`textStyleFontSize${i}`] ? pageInfo[`textStyleFontSize${i}`] : 1,
                fontColor: pageInfo[`textStyleColor${i}`] ? pageInfo[`textStyleColor${i}`] : '#FFFFFF',
                seo: pageInfo[`seo${i}`] ? pageInfo[`seo${i}`] : false,
                link: pageInfo[`link${i}`],
                file: pageInfo[`file${i}`],
                mobile: pageInfo[`mobileFile${1}`],
                id: pageInfo._links.self.href,
                page: `${pageInfo.page}`,
              };
          }
          if (mounted)
            setState((prev) => ({
              ...prev,
              banners: {
                ...state.banners,
                formattedBanners: Object.values(parsedInfo),
                // topBanners: copy.data._embedded.topBanners, //[] What does this do? is it needed
              },
              loading: false,
              // apiState: { ...state.apiState, apiMessage: res?.status || 'Success', apiError: false },
              apiState: {
                ...state.apiState,
                apiMessage: 'Success',
                apiError: false,
              },
            }));
        })
        .catch((e) => {
          console.log('API ERROR', e);
          throw e;
        });
    }
    if (Object.values(parsedInfo)?.length > 0) {
      if (mounted)
        setState((prev) => ({
          ...prev,
          banners: {
            ...state.banners,
            formattedBanners: Object.values(parsedInfo),
            // topBanners: copy.data._embedded.topBanners, //[] What does this do? is it needed
          },
          loading: false,
          // apiState: { ...state.apiState, apiMessage: res?.status || 'Success', apiError: false },
          apiState: {
            ...state.apiState,
            apiMessage: 'Success',
            apiError: false,
          },
        }));
    } else {
      //means the backend call had no topBanners
      if (mounted)
        setState((prev) => ({
          ...prev,
          loading: false,
          // apiState: { ...state.apiState, apiMessage: res.status, apiError: true },
          apiState: {
            ...state.apiState,
            apiMessage: 'Success',
            apiError: true,
          },
        }));
    }
    return () => {
      mounted = false;
    };
    // .catch((err) => setState((prev) => ({ ...prev, apiState: { ...state.apiState, apiError: true, error: err } })));
    // eslint-disable-next-line
  }, [previewBannerData]);

  // We need to display banners 1-5. IF a banner has a non null file attribute, we display it.
  // Considerations: SEO banners will be manually text styled and set by devs. All other regular banners are admin manageable
  // Text styling is also going to be a part of the formattedBanners.
  return (
    <div className={classes.root} id="carouselWrapper">
      {state.loading && <CircularProgress size={50} style={{ marginLeft: '50%', marginTop: '5%' }}></CircularProgress>}
      {state.APIError && <Typography>There was an issue fetching banner information from the server.</Typography>}
      {!state.loading && !state.APIError && (
        <Fragment>
          <Carousel
            autoplay
            animation="slide"
            navButtonsAlwaysInvisible={true}
            stopAutoPlayOnHover={false}
            indicatorIconButtonProps={{
              style: {
                color: theme.palette.common.gray,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: theme.palette.primary.main,
              },
            }}
            indicatorContainerProps={{
              style: {
                position: 'absolute',
                bottom: 20,
              },
            }}
          >
            {/* {state[type] &&
              state[type].length > 0 &&
            state[type] */}
            {state?.banners?.formattedBanners
              .filter((ban) => ban.file?.url)
              .map((banner, index) => {
                return (
                  <div className={classes.bannerSlide} key={index}>
                    <img src={banner.file.url} alt={banner.name} className={classes.bannerImage} />
                    {/* <img src={banner.file.url} alt={banner.name} className={classes.mobilebannerImage} /> */}
                    <div className={classes.content} style={{ ...banner.textPosition }}>
                      <div className={classes.contentInnerContainer}>
                        <Typography
                          className={classes.bannerText}
                          //style={{ "background-color" : "rgba(0, 0, 0, 0.09)", "border-radius": "5px"} }
                          variant={banner.name.slice(-1) === '1' ? 'h1' : 'h2'} //1st Banner will always be the H1 for the page (ref SEO)
                          style={{
                            color: `${banner.fontColor ? banner.fontColor : 'white'}`,
                            // fontSize: '4.75vw',
                            // fontSize: `${7 * 1}vh`,
                            // fontSize: `clamp(3.5vh, 15%, 10vh)`,
                          }}
                        >
                          {/* {banner.text} */}
                          {override && index === 0 ? override : banner.text}
                        </Typography>
                        {/*  //If there is a link make the whole banner slide a clickable link */}
                        {banner.link && (
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.bannerButton}
                            style={{
                              color: 'inherit',
                              textDecoration: 'inherit',
                              zIndex: 99,
                            }}
                          >
                            <Typography className={classes.bannerCaption}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={banner.link}
                                style={{ color: 'white' }}
                              >
                                SEE DETAILS
                              </a>
                            </Typography>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
          <Divider className={classes.divider}></Divider>
        </Fragment>
      )}
    </div>
  );
}

export default CarouselPage;
