import React, { useState } from 'react';

export default function ChangeTable({ title, logs, tableActions, logErrors }) {
  const { archiveLog, deleteLog } = tableActions;

  const [searchQuery, setSearchQuery] = useState('');
  const [sortDateAsc, setSortDateAsc] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);

  const handleSearchInput = (e) => {
    setSearchQuery(e.target.value);
  };

  const sortedLogs = logs?.sort((a, b) => {
    const aDate = new Date(a.createDateTime);
    const bDate = new Date(b.createDateTime);
    return sortDateAsc ? aDate - bDate : bDate - aDate;
  });

  //Filter logs by searchQuery
  const filteredLogs = sortedLogs?.filter((log) => {
    const changeString = log.changes.join(', ').toLowerCase();
    //Disregard case
    const query = searchQuery.toLowerCase();

    return (
      log.storeId.includes(query) ||
      log.storeName.toLowerCase().includes(query) ||
      changeString.includes(query) ||
      log.createDateTime.includes(query)
    );
  });

  const LogActions = ({ log }) =>
    confirmDelete === log.messageId ? (
      <>
        <div>Are you sure?</div>
        <button style={{ margin: '0 4px 0 4px' }} onClick={() => setConfirmDelete(null)}>
          No
        </button>
        <button onClick={() => deleteLog(log.messageId)}>Yes</button>
      </>
    ) : (
      <>
        {log.isRead === false && (
          <button style={{ margin: '0 4px 0 0' }} onClick={() => archiveLog(log.messageId)}>
            Archive
          </button>
        )}
        <button onClick={() => setConfirmDelete(log.messageId)}>Delete</button>
      </>
    );
  const isMobileView = window.innerWidth < 768; // [] TODO - Not responsive change to use media query

  const logTable = isMobileView ? (
    <>
      {filteredLogs?.map((log, i) => {
        return (
          <div style={{ border: '1px solid lightgrey', margin: '4px 0 4px 0' }}>
            <h3>{`${log.storeName} (${log.storeId})`}</h3>
            <figure>
              <figcaption>Changes</figcaption>
              <ul>
                {log.changes.map((change, i) => {
                  /* With custom hooks - Add helper / enum to convert change keys to human readable  
                  ie firstName to First Name*/
                  return <li key={`${i}-change`}>{change}</li>;
                })}
              </ul>
            </figure>
            <span>
              <p>{`Create Date Time: ${log.createDateTime}`}</p>
              <p>{`Message ID: ${log.messageId}`}</p>
            </span>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LogActions log={log} />
            </div>
          </div>
        );
      })}
    </>
  ) : (
    <table style={{ width: '100%' }}>
      <thead style={{ border: '1px solid lightgrey' }}>
        <tr>
          {/* <th></th> */}
          <th colspan={2} style={{ borderRight: '1px solid lightgrey', borderLeft: '1px solid lightgrey' }}>
            Store
          </th>
        </tr>
        <tr>
          {/* <th>
            <input type="checkbox" />
          </th> */}
          <th>ID</th>
          <th>Current Name</th>
          <th>Change(s)</th>
          <th>Made By (username):</th>
          <th onClick={() => setSortDateAsc(!sortDateAsc)} style={{ cursor: 'pointer' }}>{`Change Date ${
            sortDateAsc ? ' ⬆' : ' ⬇'
          }`}</th>
          <th>Msg ID</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredLogs?.map((log, i) => {
          return (
            <tr key={log.messageId} style={{ border: '1px solid grey' }}>
              {/*   <td>
                <input type="checkbox" />
              </td> */}
              <td>{log.storeId}</td>
              <td>{log.storeName}</td>
              <td>
                <ul>
                  {log.changes.map((change, i) => {
                    /* With custom hooks - Add helper / enum to convert change keys to human readable  
                  ie firstName to First Name*/
                    return <li key={`${i}-change`}>{change}</li>;
                  })}
                </ul>
              </td>
              <td>{log.changedBy}</td>
              <td>{log.createDateTime}</td>
              <td>{log.messageId}</td>
              <td>
                <LogActions log={log} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <>
      <h1>{title}</h1>
      <br />

      <div style={{ marginBottom: '4px' }}>
        {/* Input search */}
        <label htmlFor="search" style={{ marginRight: '4px' }}>
          Search:
        </label>
        <input type="text" placeholder="Enter ID, store name, or change type" onChange={handleSearchInput} />
      </div>

      {!logErrors && logTable}
      {logErrors && (
        <div>
          <hr />
          <h3>There was an error loading the logs:</h3>
          <div>{logErrors?.toString()}</div>
        </div>
      )}
    </>
  );
}
