import { Fragment } from 'react';
import React from 'react';
import { Tabs, Tab, makeStyles, Button } from '@material-ui/core';
import { CATEGORIES } from '../utils/constants';
import { BiFilterAlt } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { categoryShorthand } from '../utils/helpers';

export default function MapFilter({
  handleFilterSettings,
  filterSettings,
  showFilter,
  handleTabChange,
  value,
  displayFilterSettings,
  mobile,
  filterOpen,
}) {
  const useStyles = makeStyles((theme) => ({
    tabRoot: {
      marginBottom: mobile ? '0px' : '30px',
    },
    indicator: {
      position: 'absolute',
      height: 0,
      top: 0,
      transition: 'none',
    },

    storeTab: {
      backgroundColor: '#FFFF',
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      borderRadius: '10px',
      minWidth: 4,
      opacity: 1,
      height: 3,
    },
    storeTabOpen: {
      backgroundColor: '#FFFF',
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      borderRadius: '10px',
      minWidth: 4,
      opacity: 1,
    },

    storeTabSelected: {
      backgroundColor: '#ebebeb',
      borderRadius: '10px',
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      minWidth: 0,
      opacity: 1,
      height: 3,
    },
    storeTabSelectedOpen: {
      backgroundColor: '#ebebeb',
      borderRadius: '10px',
      borderColor: '#e6e6e6',
      border: 'solid 1px',
      minWidth: 0,
      opacity: 1,
    },
    checkboxContainer: {
      width: '100%',
      flexDirection: 'row',
      paddingTop: theme.spacing(5),
      paddingHorizontal: theme.spacing(5),
      alignItems: 'center',
      justifyContent: 'center',
      borderBottomWidth: theme.spacing(1),
      borderBottomColor: '#f0f0f0',
    },
    filterButton: {
      flexDirection: 'row',
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      alignItems: 'center',
      marginTop: 3,
      marginHorizontal: 3,
      paddingHorizontal: theme.spacing(5),
      paddingVertical: theme.spacing(5),
      justifyContent: 'center',
    },
    filterPopupButton: {
      backgroundColor: '#FFB4A4',
      color: 'white',
      height: '2rem',
      width: '250px',
      margin: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    label: {},
    buttonContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: 'lightgreen',
    },
    icon: {
      height: 30,
      width: 30,
    },
  }));

  const classes = useStyles();
  const numberOfFilters = Object.values(displayFilterSettings).filter((x) => x).length;
  return (
    <Fragment>
      {!mobile && (
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="tabs"
          variant="fullWidth"
          centered
          classes={{ indicator: classes.indicator, root: classes.tabRoot }}
          style={{ alignItems: 'end' }}
        >
          {CATEGORIES.map((cat) => (
            <Tab
              indicatorColor="primary"
              value={cat}
              className={displayFilterSettings[categoryShorthand(cat)] ? classes.storeTabSelected : classes.storeTab}
              onClick={() => handleFilterSettings(categoryShorthand(cat))}
              label={cat}
            ></Tab>
          ))}
          <Tab
            value={'open'}
            icon={
              <img
                alt="open"
                src={require('../assets/icons-for-map-feature/OpenNowicon.svg')}
                style={{ height: mobile ? '50px' : '70px' }}
              />
            }
            //aria-controls={`tabpanel-menu`}
            className={displayFilterSettings.open ? classes.storeTabSelectedOpen : classes.storeTabOpen}
            onClick={() => handleFilterSettings('open')}
            label="Open Now"
          ></Tab>
        </Tabs>
      )}
      {mobile && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant="filled" className={classes.filterPopupButton} onClick={filterOpen}>
            <IconContext.Provider value={{ color: 'white', className: 'global-class-name' }}>
              <BiFilterAlt />
            </IconContext.Provider>
            {`Filters` + (numberOfFilters > 0 ? ` (${numberOfFilters} applied)` : '')}
          </Button>
        </div>
      )}
    </Fragment>
  );
}
