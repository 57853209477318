import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import MUIDataTable from 'mui-datatables';
import {
  Typography,
  Button,
  Menu,
  MenuItem,
  Modal,
  Fade,
  Backdrop,
  useMediaQuery,
  createMuiTheme,
  MuiThemeProvider,
  IconButton,
  Input,
  Paper,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import SearchIcon from '@material-ui/icons/Search';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import moment from "moment";
import api from '../utils/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CATEGORIES, STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
import AddIcon from '@material-ui/icons/Add';
import theme from '../theme';
import ErrorDetails from '../components/ErrorDetails';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
// import CancelIcon from '@material-ui/icons/Cancel';
// import CreateEditBusinessForm from "../components/CreateEditBusinessForm";
// import { brandsData } from "../utils/example-data";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth + 300,
    display: 'flex',
    flexDirection: 'column',
  },
  tableHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
  createButton: {},
  table: {
    zIndex: '50!important',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  adminMessageModalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  modalMessage: {
    margin: theme.spacing(2),
  },
  unpublishMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  confirmationMessage: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  confirmationActionButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100,
  },
  deleteConfirmation: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100,
  },
  copyConfirmation: {
    color: '#ffffff !important',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: 100,
  },
  createNewText: { [theme.breakpoints.down('sm')]: { display: 'none' } },
  createNewIcon: { [theme.breakpoints.up('md')]: { display: 'none' } },
  browseButton: { margin: theme.spacing(0, 0, 0, 2) },
  adminMessageContainer: {
    maxHeight: '90vh',
    width: '70vw',
    position: 'relative',
  },
  headerContainer: {
    display: 'flex',
  },

  messageHeader: {
    height: '20vh',
    width: '100%',
    objectFit: 'cover',
  },
  messageLogo: {
    position: 'absolute',
    top: '1vh',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    height: '20vh',
  },
  cancelButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    //transform: "translate(0%, -40%)",
    padding: '5px',
    marginTop: theme.spacing(2),
    height: 5,
    width: 5,
  },
  messageContentContainer: {
    padding: theme.spacing(1, 4, 4, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  messageHeaderText: {
    fontSize: '22pt',
    fontWeight: 'bold',
    color: '#000',
    [theme.breakpoints.down('md')]: {
      fontSize: '18pt',
    },
  },
  messageStoreName: {
    fontSize: '16pt',
    fontWeight: 'bold',
    color: '#000',
    margin: theme.spacing(2, 0, 1, 0),
  },
  messageTitle: {
    fontSize: '14pt',
    fontWeight: 'bold',
    color: '#000',
    margin: theme.spacing(2, 0, 2, 0),
  },
  messageBody: {
    fontSize: '12pt',
    margin: theme.spacing(1, 0, 1, 0),
    maxHeight: '20vh',
    overflowY: 'auto',
  },
  messageActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  messageOkButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    width: theme.spacing(20),
  },
  searchField: {
    // display: "flex",
    // justifyContent: "space-between",
    margin: theme.spacing(0, 0, 2, 2),
    // margin: theme.spacing(1),
    // marginBottom: theme.spacing(4),
    // margin: theme.spacing(1),
    // marginBottom: theme.spacing(4),
  },
}));

const getStores = async (page, size) => {
  return api
    .getEnhancedStoresForAdmin(page, size)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

// ao: get store by name
const getStoreByName = async (name) => {
  return api
    .getStoreByName(name)
    .then((res) => {
      return res;
    })
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const deleteStore = async (id, brand) => {
  return api
    .deleteStore(id)
    .then(() => STATUS.SUCCESS)
    .catch((err) => {
      throw err;
    });
};

const unpublishStore = async (id, brand) => {
  if (!brand) {
    return api
      .updateStore(id, { published: false })
      .then((res) => STATUS.SUCCESS)
      .catch((err) => {
        throw err;
      });
  } else {
    return api
      .updateBrand(id, { published: false })
      .then((res) => STATUS.SUCCESS)
      .catch((err) => {
        throw err;
      });
  }
};

const publishStore = async (id, brand) => {
  if (!brand) {
    return api
      .updateStore(id, { published: true })
      .then((res) => STATUS.SUCCESS)
      .catch((err) => {
        throw err;
      });
  } else {
    return api
      .updateBrand(id, { published: true })
      .then((res) => STATUS.SUCCESS)
      .catch((err) => {
        throw err;
      });
  }
};

const deleteRanks = async (type, ids) => {
  return api.deleteRanks(type, ids).catch((error) => {
    throw error;
  });
};

const removeRanks = async (id, type) => {
  let featuredIds = [];
  let allIds = [];
  let recommendedIds = [];

  if (type === 'Storefront') {
    featuredIds = await api
      .getFeaturedStoresRanks(id)
      .then((res) => res.data._embedded.featuredStoreRanks.map((rank) => rank._links.self.href))
      .catch((err) => {
        throw err;
      });

    recommendedIds = await api
      .getRecommendedStoresRanks(id)
      .then((res) => res.data._embedded.recommendedRanks.map((rank) => rank._links.self.href))
      .catch((err) => {
        throw err;
      });

    allIds = await api
      .getAllStoresRanks(id)
      .then((res) => res.data._embedded.allStoreRanks.map((rank) => rank._links.self.href))
      .catch((err) => {
        throw err;
      });
  } else throw STATUS.ERROR;

  const deletes = [
    deleteRanks(
      type,
      featuredIds.map((link) => parseIdFromLink(link))
    ),
    deleteRanks(
      'Recommended',
      recommendedIds.map((link) => parseIdFromLink(link))
    ),
    deleteRanks(
      type + 'Ordering',
      allIds.map((link) => parseIdFromLink(link))
    ),
  ];

  return Promise.all(deletes)
    .then(() => {
      return STATUS.SUCCESS;
    })
    .catch((err) => {
      throw err;
    });
};

function ActionMenu(props) {
  const { id, href, showConfirmation, published, adminMode, unpublish, publish, updating, setCopyConfirm, brand } =
    props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const copyLink = window.location

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        <MoreHorizIcon />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={handleClose}
          component={Link}
          // to={`/${type === "Storefront" ? "stores" : "deliveries"}/${id}`}
          to={`/client/preview/${brand ? 'brands' : 'stores'}/${id}`}
          style={{ color: '#000000', textDecoration: 'none' }}
        >
          Preview
        </MenuItem>
        {adminMode && (
          <CopyToClipboard
            text={`${window.location.href.replace('/admin/stores', '')}/client/preview/${
              brand ? 'brands' : 'stores'
            }/${id}`}
          >
            <MenuItem>Copy Preview Link</MenuItem>
          </CopyToClipboard>
        )}
        <MenuItem
          onClick={() => {
            handleClose();
          }}
          component={Link}
          to={`/admin/edit-store/${id}`}
          style={{ color: '#000000', textDecoration: 'none' }}
        >
          Edit
        </MenuItem>
        {/* <MenuItem
					onClick={() => {
						handleClose();
					}}
					component={Link}
					to={`/${adminMode ? "admin" : "client"}${
						brand ? "/brands" : "/stores"
					}/${id}/products`}
					style={{ color: "#000000", textDecoration: "none" }}
				>
					Manage Products
				</MenuItem>
				{!brand && (
					<MenuItem
						onClick={() => {
							handleClose();
						}}
						component={Link}
						to={`/${adminMode ? "admin/stores" : "client/stores"}/${id}/deals`}
						style={{ color: "#000000", textDecoration: "none" }}
					>
						Manage Deals
					</MenuItem>
				)} */}
        {adminMode && published && (
          <MenuItem
            onClick={() => {
              handleClose();
              unpublish(id);
            }}
          >
            {updating && <CircularProgress size={24} color="inherit" />}
            {!updating && 'Unpublish'}
          </MenuItem>
        )}

        {adminMode && !published && (
          <MenuItem
            onClick={() => {
              handleClose();
              publish(id);
            }}
          >
            {updating && <CircularProgress size={24} color="inherit" />}
            {!updating && 'Publish'}
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            handleClose();
            showConfirmation(id);
          }}
        >
          Delete
        </MenuItem>
        {/*clients have the ability to copy a store up to 5 stores total*/}
        <MenuItem
          //CHANGE
          // button={storeCount ? true : false}
          onClick={() => {
            handleClose();
            setCopyConfirm(href);
          }}
        >
          {/* {storeCount ? ( */}
          Copy Store
          {/* ) : ( */}
          {/* <Typography style={{ color: "gray" }}>
								Copy Store (5 stores max)
							</Typography>
						)} */}
        </MenuItem>

        {/*!storeCount && (
          <MenuItem
            button={false}
            onClick={() => {
              handleClose();
            }}
          >
            <Typography style={{ "color": "gray" }}>Copy Store (5 stores max)</Typography>
          </MenuItem>
          )*/}
      </Menu>
    </div>
  );
}

const getDeals = async (id) => {
  return api
    .getDealsForStore(id)
    .then((res) => res)
    .catch((err) => {
      throw err;
    });
};

const getDeal = async (id) => {
  return api
    .getDeal(id)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const deleteDealRanks = async (id) => {
  return getDeals(id)
    .then(async (res) => {
      if (res.length > 0) {
        return Promise.all(
          res.map(async (deal) => {
            return getDeal(parseIdFromLink(deal._links.self.href))
              .then(async (dealRes) => {
                if (dealRes._embedded.allDealRanks) {
                  const ids = dealRes._embedded.allDealRanks.map((rank) =>
                    parseIdFromLink(rank._links.self.href, 0, '{?projection}')
                  );

                  await deleteRanks('DealOrdering', ids)
                    .then()
                    .catch((err) => {
                      throw err;
                    });
                }

                if (dealRes._embedded.featuredDealRanks) {
                  const ids = dealRes._embedded.featuredDealRanks.map((rank) =>
                    parseIdFromLink(rank._links.self.href, 0, '{?projection}')
                  );

                  await deleteRanks('FeaturedDeal', ids)
                    .then()
                    .catch((err) => {
                      throw err;
                    });
                }
                if (dealRes._embedded.recommendedDealRanks) {
                  const ids = dealRes._embedded.recommendedDealRanks.map((rank) =>
                    parseIdFromLink(rank._links.self.href, 0, '{?projection}')
                  );

                  await deleteRanks('RecommendedDeal', ids)
                    .then()
                    .catch((err) => {
                      throw err;
                    });
                }
              })
              .catch((err) => {
                throw err;
              });
          })
        )
          .then()
          .catch((err) => {
            throw err;
          });
      }
    })
    .catch((err) => {
      throw err;
    });
};

// const markReadStoreMessage = async (id, data) => {
//   return api
//     .markStoreMessageRead(id, data)
//     .then((res) => res)
//     .catch((err) => {
//       throw err;
//     });
// };

// const markReadBrandMessage = async (id, data) => {
//   return api
//     .markBrandMessageRead(id, data)
//     .then((res) => res)
//     .catch((err) => {
//       throw err;
//     });
// };

const cloneStore = async (storeHref) => {
  //console.log(storeHref)
  return api
    .cloneStore(storeHref)
    .then((res) => res)
    .catch((err) => err);
};

function ManageCategoryStoresView(props) {
  const { adminMode, brand, search, nameText } = props;
  const classes = useStyles();

  const [totalRecords, setTotalRecords] = useState(0);
  const [maxPage, setmaxPage] = useState(0);

  // const [pageSize, setPageSize] = useState(25); // from 1000 to speed-up intial load
  const [pageSize, setPageSize] = useState(1000); //SDAW-1024

  const [pageData, setPageData] = useState([]);
  const [searchState, setSearchState] = useState('');
  const [flatData, setFlatData] = useState([]);
  const [pagesLoaded, setPagesLoaded] = useState([0]);
  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState('');
  const [deleted, setDeleted] = useState(0);
  const [unpublished, setUnpublished] = useState(0);
  //promotion, enable, disable are updates that dont shift the table data
  const [updateID, setUpdateID] = useState(null);
  //user deletion shifts page data so its special
  const [deleteID, setDeleteID] = useState(null);

  const [boxText, setBoxText] = useState('');

  const [updating, setUpdating] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');

  const [copyConfirm, setCopyConfirm] = useState(null);
  const [copying, setCopying] = useState(false);
  const [copyMessage, setCopyMessage] = useState('');
  const [confirmUnpublish, setConfirmUnpublish] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  // const [messages, setMessages] = useState([]);

  const [APIError, setAPIError] = useState('');

  const publish = (id) => {
    // setUpdating(true);
    // ao: display Progress circle and scroll to top of screen.
    setLoading(true);
    window.scroll(0, 0);
    setUpdateID(id);
    if (brand) {
      publishStore(id, true)
        .then((res) => {
          //setLoading(true);
          setUnpublished(unpublished + 1);
        })
        .catch((err) => {
          setAPIError(err ? err : {});
          setUpdateMessage(STATUS.ERROR);
          setUpdating(false);
        });
    } else {
      publishStore(id)
        .then((res) => {
          setUnpublished(unpublished + 1);
          //setLoading(true);
        })
        .catch((err) => {
          setAPIError(err ? err : {});
          setUpdateMessage(STATUS.ERROR);
          setUpdating(false);
        });
    }
  };

  const unpublish = (id) => {
    // setUpdating(true);
    // ao: display Progress circle and scroll to top of screen.
    setLoading(true);
    window.scroll(0, 0);
    setUpdateID(id);
    let type;
    for (let page of pageData) {
      for (let row of page.data) {
        let rid = parseIdFromLink(row._links.self.href);
        if (rid === id) {
          type = row.storeType;
          break;
        }
      }
      removeRanks(id, type)
        .then(() => {
          deleteDealRanks(id)
            .then(() => {
              unpublishStore(id)
                .then((res) => {
                  setUnpublished(unpublished + 1);
                  //setLoading(true);
                })
                .catch((err) => {
                  setAPIError(err ? err : {});
                  setUpdateMessage(STATUS.ERROR);
                  setUpdating(false);
                });
            })
            .catch((err) => {
              setAPIError(err ? err : {});
              setUpdateMessage(STATUS.ERROR);
              setUpdating(false);
            });
        })
        .catch((err) => {
          setAPIError(err ? err : {});
          setUpdateMessage(STATUS.ERROR);
          setUpdating(false);
        });
    }
  };

  // const removeMessage = (messageId, storeId) => {
  //   setMessages((state) =>
  //     state.filter((message) => !(message.messageId === messageId && message.storeId === storeId))
  //   );
  // };

  // const markRead = (messageId, storeId, type) => {
  //   if (type === 'store') {
  //     markReadStoreMessage(parseIdFromLink(messageId, 0, '{?projection}'), storeId.replace('{?projection}', ''))
  //       .then(() => {
  //         removeMessage(messageId, storeId);
  //         setReadDisabled(false);
  //       })
  //       .catch(() => {
  //         removeMessage(messageId, storeId);
  //         setReadDisabled(false);
  //       });
  //   } else if (type === 'brand') {
  //     markReadBrandMessage(parseIdFromLink(messageId, 0, '{?projection}'), storeId.replace('{?projection}', ''))
  //       .then(() => {
  //         removeMessage(messageId, storeId);
  //         setReadDisabled(false);
  //       })
  //       .catch(() => {
  //         removeMessage(messageId, storeId);
  //         setReadDisabled(false);
  //       });
  //   } else {
  //     removeMessage(messageId, storeId);
  //     setReadDisabled(false);
  //   }
  // };

  const handleBlur = (event) => {
    setBoxText(event.target.value);
  };

  const handleSubmit = (event) => {
    // ao: disable <enter> on search box.
    event.preventDefault();
  };
  const [categoryName, setCategoryName] = useState(window.location.href.split('/')[5]);

  const history = useHistory();

  const handleCategoryClick = (category) => {
    history.push(`/admin/stores/${category}`);
    setCategoryName(category);
  };

  useEffect(() => {
    setLoading(true);
    // ao: if search is active, display store name search results.
    if (adminMode && search && nameText) {
      setPageData([]);
      getStoreByName(nameText)
        .then((res) => {
          // setTotalRecords(res.data._embedded.stores.length);
          setTotalRecords(res.data.page.totalElements);
          let initialData = [{ page: page, data: res.data._embedded.stores }];
          setPageData(initialData);
          // let data = initialData[0].data; :
          let data = [];
          for (let dataset of initialData) {
            data = [...dataset.data];
          }
          data = data.map((rows) => ({
            ...rows,
            locations: !Array.isArray(rows.locations)
              ? rows.locations
              : rows.locations.map((location) => location.city).join(', '),
          }));
          //set flatData array for the table
          setFlatData(data);
          setLoading(false);
          // ao: handle update & delete cases when action taken from search results.
          if (updateID) {
            setUpdateMessage(STATUS.SUCCESS);
            setUpdating(false);
            setUpdateID(null);
            setLoading(false);
          }
          if (deleteConfirm) {
            setDeleting(false);
            setDeleteConfirm(null);
            setDeleteMessage(STATUS.SUCCESS);
            setDeleteID(null);
          }
          // setSearchBox(false);
        })
        .catch(() => setLoadingError(true));
    }
    // ao: end of search else if
    else {
      if (pageData === undefined || pageData.length === 0) {
        getStores(adminMode, maxPage, 100) //sdaw-1024
          .then((res) => {
            adminMode ? setTotalRecords(res.page.totalElements) : setTotalRecords(res._embedded.stores.length);
            let initialData = [{ page: page, data: res._embedded.stores }];
            setPageData(initialData);

            const totalPages = res.page?.totalPages || 1;
            //DL OTHER PAGES
            const queryRemainder = [];
            for (let page = 1; page < totalPages; page++) {
              queryRemainder.push(getStores(adminMode, page, 100)); //sdaw-1024
            }
            Promise.all(queryRemainder)
              .then((remData) => {
                // Build flat data
                const allData = {};
                remData.forEach((pageOfData) => {
                  //Iterate the page of data, adding key value to allData
                  for (let store of pageOfData._embedded.stores) {
                    const currID = parseIdFromLink(store._links.self.href);
                    allData[currID] = store;
                  }
                });
                //Add initial Page
                for (let store of res._embedded.stores) {
                  const currID = parseIdFromLink(store._links.self.href);
                  allData[currID] = store;
                }
                //Prep AllData for dataTable (flatData)  results.sort((first, second) => first.name.localeCompare(second.name))
                let data = Object.values(allData).sort((first, second) => first.name.localeCompare(second.name));
                // console.log(data.length)

                data = data.map((rows) => ({
                  ...rows,
                  locations: !Array.isArray(rows.locations)
                    ? rows.locations
                    : rows.locations.map((location) => location.city).join(', '),
                }));
                data = data.filter((item) => item.category.toLowerCase().includes(categoryName.toLowerCase()));
                //set flatData array for the table
                setFlatData(data);
                // ao: set pageData with all data results
                setPageData([{ page: page, data: data }]);
                setLoading(false);
              })
              .catch((e) => console.log('Error all fetching...', e));
            //===================================================================================

            let data = [];
            for (let dataset of initialData) {
              data = [...flatData, ...dataset.data];
            }
            data = data.map((rows) => ({
              ...rows,
              locations: !Array.isArray(rows.locations)
                ? rows.locations
                : rows.locations.map((location) => location.city).join(', '),
            }));
            //set flatData array for the table
            setFlatData(data);
            setLoading(false);
          })
          .catch(() => setLoadingError(true));
        // } else if ( !deleteID && !updateID ) {
      } else if (!deleteID && !updateID && maxPage) {
        // ao: when maxPage === 0 all records are loaded, don't run getStores again - fixes the double records but it's causing issues when updating/deleting with display all records.
        getStores(adminMode, maxPage, pageSize)
          .then((res) => {
            adminMode ? setTotalRecords(res.page.totalElements) : setTotalRecords(res._embedded.stores.length);
            let newPageData = [...pageData, { page: page, data: res._embedded.stores }];
            setPageData(newPageData);
            let data = [];
            for (let dataset of newPageData) {
              data = [...flatData, ...dataset.data];
            }
            data = data.map((rows) => ({
              ...rows,
              locations: !Array.isArray(rows.locations)
                ? rows.locations
                : rows.locations.map((location) => location.city).join(', '),
            }));
            setFlatData(data);
            setLoading(false);
          })
          .catch(() => setLoadingError(true));
      } else {
        let updateFrom = null;
        let copy = null;
        if (deleteID !== null && maxPage > 0) {
          for (let page of pageData) {
            for (let row of page.data) {
              let id = parseIdFromLink(row._links.self.href);
              if (id === deleteID) {
                updateFrom = page.page;
                break;
              }
            }
          }
          copy = pageData;
          for (let marker = updateFrom; marker <= pagesLoaded.length - 1; marker++) {
            getStores(adminMode, marker, pageSize)
              //eslint-disable-next-line
              .then((res) => {
                adminMode ? setTotalRecords(res.page.totalElements) : setTotalRecords(res._embedded.stores.length);
                copy[marker].page = marker;
                copy[marker].data = res._embedded.stores;

                setPageData(copy);
                let data = [];
                for (let dataset of copy) {
                  data = [...data, ...dataset.data];
                }

                data = data.map((rows) => ({
                  ...rows,
                  locations: !Array.isArray(rows.locations)
                    ? rows.locations
                    : rows.locations.map((location) => location.city).join(', '),
                }));
                setFlatData(data);
                setDeleting(false);
                setDeleteConfirm(null);
                setDeleteMessage(STATUS.SUCCESS);
                setDeleteID(null);
                setLoading(false);
              })
              .catch(() => setLoadingError(true));
          }
        } else if (deleteID !== null && maxPage === 0) {
          // ao: if displaying all records - clear pageData to trigger reload from the top when deleting store
          setPageData(undefined);
          setDeleted(deleted + 1);
          setDeleting(false);
          setDeleteConfirm(null);
          setDeleteMessage(STATUS.SUCCESS);
          setDeleteID(null);
          setLoading(false);
        } else {
          // ao: if displaying all records - clear pageData to trigger reload from the top when updating store.
          if (maxPage === 0) {
            setPageData(undefined);
            setUnpublished(unpublished + 1);
            setUpdateMessage(STATUS.SUCCESS);
            setUpdating(false);
            setUpdateID(null);
            setLoading(false);
          } else {
            copy = pageData;
            for (let page of pageData) {
              for (let row of page.data) {
                let id = parseIdFromLink(row._links.self.href);
                if (id === updateID) {
                  updateFrom = page.page;
                  break;
                }
              }
            }
            getStores(adminMode, updateFrom, pageSize)
              .then((res) => {
                adminMode ? setTotalRecords(res.page.totalElements) : setTotalRecords(res._embedded.stores.length);
                copy[updateFrom].page = updateFrom;
                copy[updateFrom].data = res._embedded.stores;
                let data = [];
                for (let dataset of copy) {
                  data = [...data, ...dataset.data];
                }
                data = data.map((rows) => ({
                  ...rows,
                  locations: !Array.isArray(rows.locations)
                    ? rows.locations
                    : rows.locations.map((location) => location.city).join(', '),
                }));
                setFlatData(data);
                setPageData(copy);
                setUpdateMessage(STATUS.SUCCESS);
                setUpdating(false);
                setUpdateID(null);
                setLoading(false);
              })
              .catch(() => setLoadingError(true));
          }
        }
      }

      // if (!adminMode) {
      //Get all stores for messages - beutimap doesn't use this yet.  But initial iteration should be refactored anyway (highliy inefficient)
      //   getStores(adminMode, maxPage, pageSize)
      //     .then((res) => {
      //       const messages = res._embedded.stores
      //         .filter((store) => store.adminMessages.length > 0)
      //         .map((store) => ({
      //           storeName: store.name,
      //           storeId: store._links.self.href,
      //           messages: store.adminMessages,
      //         }));
      //       const individualMessages = [];
      //       messages.forEach((store) =>
      //         store.messages.forEach((message) => {
      //           individualMessages.push({
      //             title: message.title,
      //             body: message.body,
      //             storeName: store.storeName,
      //             storeId: store.storeId,
      //             messageId: message._links.self.href,
      //             type: 'store',
      //           });
      //         })
      //       );
      //       // setMessages(individualMessages);
      //     })
      //     .catch(() => setLoadingError(true));
      // }
    }
    // eslint-disable-next-line
  }, [adminMode, maxPage, pageSize, brand, deleted, unpublished, search, nameText, categoryName]);

  const columns = [
    {
      name: '_links.self.href',
      label: 'ID',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => parseIdFromLink(value, 0, '{?projection}'),
      },
    },
    {
      name: 'createDateTime',
      label: 'Time Created (EST)',
      options: {
        filter: false,
        sort: true,
        //display: adminMode ? false : true,
        customBodyRender: (value) => moment(moment.utc(value).local().toDate()).format('YYYY-MM-DD-hh:mm'),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          //Add space after comma of multi category items
          return value.replaceAll(',', ', ');
        },
      },
    },
    // {
    //   name: "lastMenuUpdate",
    //   label: "Products Last Updated",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => {
    //       return value ? moment.utc(value).local().fromNow() : "N/A";
    //     },
    //   },
    // },
    // {
    //   name: "nationwide",
    //   label: "Canada Wide Delivery",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => {
    //       return value ? "Yes" : "No";
    //     },
    //     display: brand ? false : true,
    //   },
    // },
    // {
    //   //Pull owner details here
    //   name: "_links.self.href",
    //   label: "Owner",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value) => {
    //       const store = flatData.find(
    //         (store) => store._links.self.href === value
    //       );

    //       return store.ownerFirstName + " " + store.ownerLastName;
    //     },

    //     display: adminMode ? true : false,
    //   },
    // },
    // {
    //   //Pull Email here
    //   name: "ownerUsername",
    //   label: "Email",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     display: adminMode ? true : false,
    //   },
    // },
    // {
    //   name: "owner",
    //   label: "Owner",
    //   filter: true,
    //   sort: true,
    //   display: adminMode ? true : false,
    // },
    // {
    //   name: "storeType",
    //   label: "Type",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     display: (value) => (value ? true : false),
    //   },
    // },
    // {
    //   name: "createDateTime",
    //   label: "Created At",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: value =>
    //       moment
    //         .utc(value)
    //         .local()
    //         .format("MMMM Do YYYY, h:mm a")
    //   }
    // },
    {
      name: 'address.city',
      label: 'City',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address.province',
      label: 'Province',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'published',
      label: 'Published',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <Button
              size="small"
              className={classes.storeButton}
              variant="outlined"
              disabled={true}
              style={{
                color: value ? '#599e57' : '#ed685f',
                border: value ? '2px solid #599e57' : '2px solid #ed685f',
              }}
            >
              {value ? 'Yes' : 'No'}
            </Button>
          );
        },
      },
    },
    // {
    //   name: "menuItems",
    //   label: "# of Menu Items",
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "avgRating",
    //   label: "Rating",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     display: adminMode ? false : true,
    //     customBodyRender: (value) =>
    //       value ? value.toFixed(2) : (0).toFixed(2),
    //   },
    // },
    // {
    //   name: "numOfReviews",
    //   label: "# of Reviews",
    //   options: {
    //     filter: false,
    //     sort: true,
    //     display: adminMode ? false : true,
    //   },
    // },
    {
      name: '_links.self.href',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <ActionMenu
            adminMode={adminMode}
            storeCount={adminMode ? true : flatData.length <= 5 ? true : false}
            id={parseIdFromLink(value, 0, '{?projection}')}
            href={value}
            published={flatData.find((store) => store._links.self.href === value).published}
            showConfirmation={() => {
              setDeleteConfirm(
                parseIdFromLink(value, 0, '{?projection}'),
                setDeleteID(parseIdFromLink(value, 0, '{?projection}'))
              );
            }}
            // type={
            // 	flatData.find((store) => store._links.self.href === value)
            // 		.category
            // }
            unpublish={(id) => {
              setConfirmUnpublish(id);
              setIsPublished(true);
            }}
            publish={(id) => {
              setConfirmUnpublish(id);
              setIsPublished(false);
            }}
            updating={updating}
            setCopyConfirm={(val) => setCopyConfirm(val)}
            brand={brand}
          ></ActionMenu>
        ),
      },
    },
    {
      name: '_links.self.href',
      label: 'Preview',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Button
            // disabled={brand}
            variant="contained"
            color="primary"
            href={`/client/preview/stores/${parseIdFromLink(value, 0, '{?projection}')}`}
            target="_blank"
            style={{ color: '#ffffff', textDecoration: 'none' }}
          >
            <ArrowRightAltIcon />
          </Button>
        ),
      },
    },
  ];

  const mobile = useMediaQuery('(max-width:767px)');

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    download: adminMode ? true : false,
    print: false,
    viewColumns: false,
    elevation: 2,
    pagination: true,
    responsive: mobile ? 'scrollMaxHeight' : 'stacked',
    rowsPerPage: pageSize,
    rowsPerPageOptions: [25, 50, 100, 500, 1000],
    searchText: searchState ? searchState : '',
    page: page,
    count: totalRecords,
    onTableChange: (action, tableState) => {
      if (action === 'changePage') {
        setmaxPage(tableState.page > maxPage ? tableState.page : maxPage);
        setPage(tableState.page);
        if (!pagesLoaded.includes(tableState.page)) setPagesLoaded([...pagesLoaded, tableState.page]);
      }

      if (action === 'changeRowsPerPage') {
        setPageSize(tableState.rowsPerPage);
        // setmaxPage(0);
        // setPageData([]);
        // setFlatData([]);
      }
      if (action === 'search') {
        setSearchState(tableState.searchText);
      }
    },
  };

  // const [readDisabled, setReadDisabled] = useState(false);

  const getMuiTheme = () =>
    createMuiTheme({
      ...theme,
      overrides: {
        MUIDataTableToolbar: {
          root: {
            display: 'flex !important',
          },
          left: {
            flexGrow: '1',
          },
          actions: {
            flexGrow: '0',
            display: 'flex',
          },
        },
        MUIDataTablePagination: {
          root: {
            padding: '0px !important',
          },
          selectRoot: {
            margin: '2px',
          },
        },
        MuiToolbar: {
          regular: {
            paddingLeft: '24px',
          },
          gutters: {
            // paddingLeft: "0px !important"
          },
        },
        MuiTablePagination: {
          actions: {
            marginLeft: '5px',
          },
        },
        MuiIconButton: {
          root: {
            padding: '4px',
          },
        },
      },
    });

  return (
    <div className={classes.root}>
      {loading && !loadingError && <CircularProgress size={50} color="inherit" />}
      {(!loading || loadingError) && (
        <div className={classes.contentContainer}>
          <div className={classes.tableHeading}>
            {/* <Typography variant="h4" className={classes.title}>
              {`${adminMode ? "All" : "My"} ${brand ? "Brands" : "Stores"}`}
              {(adminMode || cookies.getRole() === ROLES.ROLE_SUPER_ADMIN) && (
                <Button
                  variant="outlined"
                  // onClick={() => setbrand(!brand)}
                  component={Link}
                  to={`/${adminMode ? "admin" : "client"}${brand ? "/stores" : "/brands"
                    }`}
                  className={classes.browseButton}
                >
                  {brand ? "Browse Stores" : "Browse Brands"}
                </Button>
              )}
            </Typography> */}

            <Typography variant="h4" className={classes.tittle}>
              {categoryName.charAt(0).toUpperCase() + categoryName.slice(1)}

              <Button
                variant="outlined"
                // onClick={() => setbrand(!brand)}
                component={Link}
                to={`/admin/stores/`}
                className={classes.browseButton}
              >
                All Stores
              </Button>
              {CATEGORIES.filter((cat) => {
                return cat.toLowerCase() !== categoryName;
              }).map((category) => (
                <Button
                  variant="outlined"
                  onClick={() => handleCategoryClick(category)}
                  component={Link}
                  // to={`/admin/stores/${category.toLowerCase()}`}
                  className={classes.browseButton}
                >
                  {category}
                </Button>
              ))}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.createButton}
              component={Link}
              to={`/admin/new-store`}
            >
              <Typography className={classes.createNewText}>Create New</Typography>
              <AddIcon className={classes.createNewIcon}></AddIcon>
            </Button>
            {/* )} */}
          </div>

          {/* ao: store name searchbox */}

          <Paper component="form" elevation={0} className={classes.searchField} onSubmit={handleSubmit}>
            <Input
              className={classes.input}
              placeholder="Find store by name"
              onBlur={handleBlur}
              // onBlur={(event) => {
              //   setBoxText(event.target.value);
              // }}
              // inputProps={{ 'aria-label': 'search google maps' }}
            />
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              component={Link}
              to={`/admin/stores/${categoryName}/search/${boxText}`}
            >
              <SearchIcon />
            </IconButton>
          </Paper>

          {/* // <div className={classes.formContainer}>
              // <FormControl variant="filled" className={classes.formControl} >
              //   <InputLabel htmlFor="store-name">Find store by name</InputLabel>
              //   <Input 
                  id="store-name" 
                  onBlur={(event) => {
                    setBoxText(event.target.value);
                  }}
                  // onChange={(event) => {
                  //     setBoxText(event.target.value);
                  // }}
                />
                </FormControl>
                <Button 
                  variant="outlined"
                  component={Link}
                  // onClick={() => setSearchBox(true)}
                  to={`/admin/stores/search/${boxText}`}
                  className={classes.browseButton}
                >
                  Search Store
                </Button>
              </div> */}

          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable className={classes.table} data={flatData} columns={columns} options={options} />
          </MuiThemeProvider>
        </div>
      )}
      <Modal
        className={classes.modal}
        open={updateMessage !== ''}
        onClose={() => {
          setDeleteConfirm(null);
          setUpdateMessage('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            // timeout: 500,
          }
        }
      >
        <Fade in={updateMessage !== ''}>
          <div className={classes.modalPaper}>
            {updateMessage === STATUS.SUCCESS && (
              <Typography className={classes.confirmationMessage}>
                {`${brand ? 'Brand' : 'Store'} was successfully updated.`}
              </Typography>
            )}
            {updateMessage === STATUS.ERROR && (
              <Typography className={classes.confirmationMessage}>There was an issue updating this store.</Typography>
            )}
            <Button
              variant="contained"
              color="default"
              style={{ marginBottom: theme.spacing(1) }}
              className={classes.confirmationActionButton}
              onClick={() => {
                setUpdateMessage('');
              }}
            >
              Ok
            </Button>
            {updateMessage !== STATUS.SUCCESS && <ErrorDetails error={APIError} data={{ NA: 'NA' }}></ErrorDetails>}
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={deleteConfirm !== null}
        onClose={() => {
          setDeleteConfirm(null);
          setUpdateMessage('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            //timeout: 500,
          }
        }
      >
        <Fade in={deleteConfirm !== null}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              Are you sure you want to delete this store?`
            </Typography>
            <Typography className={classes.confirmationMessage} variant="body2" color="error"></Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                color="default"
                className={classes.confirmationActionButton}
                onClick={() => {
                  setDeleteConfirm(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.deleteConfirmation}
                onClick={() => {
                  setDeleting(true);
                  // ao: display Progress circle and scroll to top of screen.
                  setLoading(true);
                  window.scroll(0, 0);
                  deleteStore(deleteConfirm)
                    .then((status) => {
                      setDeleted(deleted + 1);
                    })
                    .catch((err) => {
                      if (err.message[0].includes('banner')) {
                        setDeleteMessage(STATUS.BANNER_ATTACHED);
                      } else {
                        setDeleteMessage(STATUS.ERROR);
                      }
                      setDeleting(false);
                      setDeleteConfirm(null);
                      setAPIError(err ? err : {});
                    });
                }}
              >
                {deleting && <CircularProgress size={24} color="inherit" />}
                {!deleting && 'Delete'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={deleteMessage !== ''}
        onClose={() => {
          setDeleteMessage('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            //timeout: 500,
          }
        }
      >
        <Fade in={deleteMessage !== ''}>
          <div className={classes.modalPaper}>
            {deleteMessage === STATUS.SUCCESS && (
              <Typography className={classes.modalMessage}>
                {`${brand ? 'Brand' : 'Store'} successfully deleted.`}
              </Typography>
            )}
            {deleteMessage === STATUS.ERROR && (
              <Typography className={classes.modalMessage}>
                Something went wrong when deleting the store. Please try again.
              </Typography>
            )}
            {deleteMessage === STATUS.BANNER_ATTACHED && (
              <Typography className={classes.modalMessage}>
                This store is associated with a banner. Contact an administrator to remove the banner association.
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                color="default"
                style={{ marginBottom: theme.spacing(1) }}
                className={classes.confirmationActionButton}
                onClick={() => {
                  setDeleteMessage('');
                }}
              >
                Ok
              </Button>
              {deleteMessage !== STATUS.SUCCESS && <ErrorDetails error={APIError} data={{ NA: 'NA' }}></ErrorDetails>}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={copyConfirm !== null}
        onClose={() => {
          setCopyConfirm(null);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            //timeout: 500,
          }
        }
      >
        <Fade in={copyConfirm !== null}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              <strong>Are you sure you want to copy this store?</strong>
            </Typography>
            <Typography className={classes.confirmationMessage} variant="body2">
              You are now about to create a unpublished duplicate store with the same menu items as the store you have
              selected. Please make sure to go in and edit the STORE NAME and Listing AREAS and publish it when you are
              ready.
              <strong>This may take a few minutes, please do not exit this window until completion.</strong>
            </Typography>
            <div>
              <Button
                variant="contained"
                color="default"
                className={classes.confirmationActionButton}
                onClick={() => {
                  setCopyConfirm(null);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.copyConfirmation}
                onClick={() => {
                  setCopying(true);
                  //we need the store data, if brand, and store ID
                  cloneStore(copyConfirm)
                    .then((res) => {
                      setCopying(false);
                      setCopyConfirm(null);
                      setCopyMessage(STATUS.SUCCESS);
                    })
                    .catch((err) => {
                      setAPIError(err ? err : {});
                      setCopyMessage(STATUS.ERROR);
                    });
                }}
              >
                {copying ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={copyMessage !== ''}
        onClose={() => {
          setCopyMessage('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            //timeout: 500,
          }
        }
      >
        <Fade in={copyMessage !== ''}>
          <div className={classes.modalPaper}>
            {copyMessage === STATUS.SUCCESS && (
              <Typography className={classes.modalMessage}>Copy Succesful.</Typography>
            )}
            {copyMessage === STATUS.ERROR && (
              <Typography className={classes.modalMessage}>
                Something went wrong when deleting the store. Please try again.
              </Typography>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="contained"
                color="default"
                style={{ marginBottom: theme.spacing(1) }}
                className={classes.confirmationActionButton}
                onClick={() => {
                  setCopyMessage('');
                  window.location.reload();
                }}
              >
                Ok
              </Button>
              {copyMessage !== STATUS.SUCCESS && <ErrorDetails error={APIError} data={{ NA: 'NA' }}></ErrorDetails>}
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        className={classes.modal}
        open={confirmUnpublish !== null}
        onClose={() => {
          // setConfirmUnpublish(null);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={
          {
            // timeout: 500,
          }
        }
      >
        <Fade in={confirmUnpublish !== null}>
          <div className={classes.modalPaper}>
            <Typography className={classes.confirmationMessage}>
              {`Are you sure you want to ${isPublished ? 'unpublish' : 'publish'} this ${brand ? 'brand' : 'store'}?`}
            </Typography>

            {isPublished && (
              <Typography className={classes.unpublishMessage} variant="body2">
                {`The ${brand ? 'brand' : 'store'} will not be viewable on the
              live site and any featured spots, recommended spots and business listing orders will be lost`}
              </Typography>
            )}

            {!isPublished && (
              <Typography className={classes.unpublishMessage} variant="body2">
                It will be available for the public to view. This action can be undone.
              </Typography>
            )}

            <div>
              <Button
                variant="contained"
                color="default"
                className={classes.confirmationActionButton}
                onClick={() => {
                  setConfirmUnpublish(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.deleteConfirmation}
                onClick={() => {
                  setUpdating(true);
                  isPublished ? unpublish(confirmUnpublish) : publish(confirmUnpublish);
                  setConfirmUnpublish(null);
                }}
              >
                {/* ao: add circular progress when updating */}
                {updating && <CircularProgress size={24} color="inherit" />}
                {!updating && isPublished ? 'unpublish' : 'publish'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ManageCategoryStoresView;

// const MessagesModal = ({ messages, setMessages }) => {
/* message modal from SDAW had lived at bottom of stores view for rendering, but beautimap doesn't use it.
  Removed to allow deleting of unused assets */
//   return (
//     {messages.map((message) => {
//       const lines = message.body.split(/[\n]/);

//       return (
//         <Modal
//           className={classes.modal}
//           open={true}
//           onClose={() => {}}
//           closeAfterTransition
//           BackdropComponent={Backdrop}
//           BackdropProps={
//             {
//               //timeout: 500,
//             }
//           }
//           key={`${message.messageId}-${message.storeId}`}
//         >
//           <Fade in={true}>
//             <div className={classes.adminMessageModalPaper}>
//               <div className={classes.adminMessageContainer}>
//                 <div className={classes.headerContainer}>
//                   <img
//                     src={require(`../assets/messages/header.png`)}
//                     alt={`messages-header`}
//                     className={classes.messageHeader}
//                   />
//                   {/*asdasd */}
//                   <img
//                     src={require(`../assets/messages/sdaw-logo.svg`)}
//                     alt={`sdaw-main-logo`}
//                     className={classes.messageLogo}
//                   />
//                   <IconButton
//                     edge="start"
//                     className={classes.cancelButton}
//                     color="inherit"
//                     aria-label="notification"
//                     onClick={() => {
//                       setReadDisabled(true);
//                       markRead(message.messageId, message.storeId, message.type);
//                     }}
//                   >
//                     <CancelIcon />
//                   </IconButton>
//                 </div>
//                 <div className={classes.messageContentContainer}>
//                   <Typography className={classes.messageHeaderText}>Message from S7DAW!</Typography>
//                   <Typography className={classes.messageStoreName}>For your business: {message.storeName}</Typography>
//                   <Typography className={classes.messageTitle}>{message.title}</Typography>
//                   {/* <Typography className={classes.messageBody}>
//                     {message.body}
//                   </Typography> */}
//                   <div className={classes.messageBody}>
//                     {lines.map((line, index) => (
//                       <Typography /*className={classes.line}*/ key={index}>
//                         {line}
//                         {line === '' && <Typography /*className={classes.emptyLineHelper}*/>&nbsp;</Typography>}
//                       </Typography>
//                     ))}
//                   </div>
//                   <div className={classes.messageActionButtons}>
//                     <Button
//                       color="primary"
//                       className={classes.messageOkButton}
//                       disabled={readDisabled}
//                       onClick={() => {
//                         setReadDisabled(true);
//                         markRead(message.messageId, message.storeId, message.type);
//                       }}
//                     >
//                       Ok
//                     </Button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Fade>
//         </Modal>
//       );
//     })}
//   )
// }
