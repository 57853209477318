import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
// import Rating from "@material-ui/lab/Rating";
// import { FEATURED_TYPES, NAV_OPTIONS } from "../utils/constants";
import { Link } from 'react-router-dom';
// import { parseIdFromLink, abbreviate } from "../utils/helpers";
import { NAV_OPTIONS } from '../utils/constants';
import { handleImage, navbarTabLink, parseIdFromLink } from '../utils/helpers';
import api from '../utils/api';
import { STATUS } from '../utils/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '80%',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.font,
    padding: '1rem',
    // marginRight: "20px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      // width: 125,
    },
    [theme.breakpoints.down('sm')]: {
      // padding: "1rem"
    },
    // display: "relative",
    // marginLeft: theme.spacing(5),
  },
  header: {
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
    // height: 200,
  },
  cardHeaderTitle: {
    // fontWeight: '400',
    fontSize: '1.5rem',
    color: theme.palette.common.font,
    // backgroundColor: theme.palette.common.white,
    textTransform: 'uppercase',

    textAlign: 'center',
    borderBottom: '1px solid #fff',
    lineHeight: '0.1em',
    margin: '0.5em 0 0.5em',
    [theme.breakpoints.down('md')]: {
      // letterSpacing: "0px",
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      // letterSpacing: "0px",
      fontSize: '1rem',
    },
  },
  cardHeaderTitleSpan: {
    padding: '0 0.2em 0 0.5em',
    // background: '#000',
    letterSpacing: '0.3em',
  },
  cardActionArea: {
    // position: "relative",
    // border: '1px solid black',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '0.75rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',
    [theme.breakpoints.down('md')]: {
      // paddingBottom: "0.5rem",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.4rem',
      paddingRight: '0.5rem',
      paddingBottom: '0.75rem',
      paddingLeft: '0.5rem',
    },
  },
  media: {
    backgroundRepeat: 'no-repeat',
    // backgroundSize: "contain",
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    // backgroundPositionX: "left",
    // backgroundPositionY: "center",
    width: '42%',
    minHeight: 250,
    marginBottom: 'auto',
    marginTop: '0.25rem',
    // marginLeft: "1.5rem",
    // paddingTop: 10,
    // border: '1px solid black',
    [theme.breakpoints.down('md')]: {
      minHeight: 190,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 125,
      // marginLeft: "0.75rem",
    },
  },
  cardText: {
    // maxWidth: "50%",
    width: '50%',
    marginBottom: 'auto',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      // fontSize: "1rem",
    },
    // color: "#EE333F",
  },
  cardTextTitle: {
    color: theme.palette.common.font,
    fontWeight: 'bold',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      // fontSize: "small",
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  cardTextAboutUs: {
    color: theme.palette.common.font,
    // fontWeight: "bold",
    marginBottom: '4rem',
    [theme.breakpoints.down('md')]: {
      lineHeight: '1.25em',
      // fontSize: "small",
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  checkUsButton: {
    // maxWidth: '48%',
    // fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: theme.palette.common.white, //'#FFFFFF',
    backgroundColor: theme.palette.common.characters, //'#EE333F',
    borderRadius: '0px',
    [theme.breakpoints.down('md')]: {
      // maxWidth: '48%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 'x-small',
    },
  },
  // rating: {
  //   display: "flex",
  // },
  stars: {
    [theme.breakpoints.down('md')]: {
      fontSize: 'small',
    },
  },
  // dealStoreImage: {
  //   height: 60,
  //   width: 60,
  //   position: "absolute",
  //   bottom: theme.spacing(10),
  //   right: theme.spacing(1),
  //   objectFit: "contain",
  //   border: "1px solid #ebebeb",
  //   borderRadius: "5px",
  //   backgroundColor: "#ebebeb",
  //   [theme.breakpoints.down("sm")]: {
  //     height: 50,
  //     width: 50,
  //   },
  // },
}));

const getStore = async (id) => {
  return api
    .getStore(id)
    .then((res) => res)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function FocusThisWeekCard(props) {
  const { item, type, location } = props;

  if (item.name.length > 16) {
    item.name = item.name.slice(0, 14) + '...';
  }
  const classes = useStyles();

  const itemPath = `${navbarTabLink(
    NAV_OPTIONS.find((option) => option.name === type).path,
    location.province,
    location.city
  )}/${parseIdFromLink(item._links.self.href, 0, '{?projection}')}`;

  const [store, setStore] = useState(null);

  useEffect(() => {
    getStore(parseIdFromLink(item._links.self.href, 0, '{?projection}')).then((res) => {
      setStore(res.data);
    });
  }, [item]);

  const imageUrl = handleImage(item.storeImage, { placeholderType: 'storeLogo' });

  return (
    <Card
      className={classes.card}
      component={Link}
      to={itemPath}
      style={{
        color: '#000000',
        textDecoration: 'none',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.1)',
        transition: ' 0.3s',
        borderRadius: '0px',
      }}
    >
      <CardHeader
        className={classes.header}
        title={'FOCUS THIS WEEK'}
        // titleColor={"black"}
        // titleStyle={{textAlign: "center"}}
      >
        <Typography className={classes.cardHeaderTitle}>
          <span className={classes.cardHeaderTitleSpan}>Focus This Week</span>
        </Typography>
      </CardHeader>
      <CardActionArea className={classes.cardActionArea}>
        <CardMedia className={classes.media} image={imageUrl} title={item.name} />
        <CardContent className={classes.cardText}>
          <Typography noWrap variant="h6" className={classes.cardTextTitle}>
            {item.name}
          </Typography>
          <Typography className={classes.cardTextAboutUs}>{store && store.aboutUs}</Typography>
          {/* <div className={classes.itemDealsButton}> */}
          <Button
            variant="contained"
            // color="warning"
            textDecoration="inherit"
            key={'Hot Deals'}
            // href={navbarTabLink("deals", location.province, location.city)}
            onClick={() => window.location.assign(itemPath)}
            // href="/admin"
            className={classes.checkUsButton}
          >
            Check Us Out
          </Button>
          {/* </div> */}
          {/* <div
						style={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							width: "11rem",
						}}
					>
						<Typography
							noWrap
							variant="body1"
							color="secondary"
							className={classes.cardText}
						>
							{item.address && (
								<img
									src={require("../assets/icons/beautimap.jpg")}
									alt={`beautimap-logo`}
									style={{ height: "15px", marginRight: "10px" }}
									className={classes.appLogo}
								/>
							)}
							{item.address && item.address.street.length > 12
								? `${item.address.street.slice(0, 9)}...`
								: `${item.address.street}`}
						</Typography>
					</div> */}
          {/* <div className={classes.rating}>
						<Rating
							name="read-only"
							value={item.avgRating ? Number(item.avgRating) : 0}
							precision={0.5}
							size="small"
							className={classes.stars}
							readOnly
						/>
						<Typography variant="caption" className={classes.ratingText}>
							{`${item.avgRating ? Number(item.avgRating.toFixed(1)) : 0} (${
								item.numOfReviews ? item.numOfReviews : 0
							})`}
						</Typography>
					</div> */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default FocusThisWeekCard;
