import React, { useState, useEffect, Fragment } from 'react';
// import LinkBar from '../components/LinkBar';
// import CarouselComponent from "../components/CarouselComponent";
import Carousel from '../components/Carousel';
// import Carousel from 'react-material-ui-carousel'
// import ProductRow from '../components/ProductRow';
// import StylistItem from '../components/StylistItem';
// import Container from '@material-ui/core/Container';
// import { hairCategoryData } from '../utils/example-data';
import Typography from '@material-ui/core/Typography';
import { Tooltip, makeStyles } from '@material-ui/core';
import FeaturedList from '../components/FeaturedList';
import FeaturedListItem from '../components/FeaturedListItem';
import FocusThisWeekCard from '../components/FocusThisWeekCard';
// import CategoryList from "../components/CategoryList";
import { useStateValue } from '../state';
import api from '../utils/api';
import { STATUS } from '../utils/constants';
import { parseIdFromLink } from '../utils/helpers';
// import { banners } from '../assets/banners/index';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ListItem } from '@material-ui/core';

import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  content: {
    // paddingTop: theme.spacing(6),
    marginTop: '10px',
    paddingBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiExpansionPanelSummary-content': {
      flexDirection: 'column',
      textAlign: 'center',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
    '& .MuiExpansionPanel-root::before': {
      backgroundColor: 'transparent',
    },
  },
  bannerImage: {
    width: '100%',
    maxHeight: '500px',
    minHeight: '200px',
    backgroundRepeat: `no-repeat`,
    backgroundPosition: `center`,
    backgroundSize: `cover`,
    aspectRatio: '1245/500',
  },

  carousel: {
    width: '100%',
    maxHeight: 500,
    aspectRatio: '1245/500',
  },
  subLinkBar: {
    height: '66px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(8),
    textTransform: 'capitalize',
    fontWeight: 700,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
  },

  bannerContainer: {
    backgroundColor: theme.palette.common.black,
    width: '100%',
    height: '140px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: theme.spacing(12, 0, 12, 0),
    marginBottom: '20px',

    // textAlign: 'center'

    // padding: theme.spacing(5,5,5,5)
  },
  location: {
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    letterSpacing: '5px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      // paddingTop: "1rem",
      letterSpacing: '0px',
      fontSize: 'larger',
    },
  },

  title: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    letterSpacing: '5px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      letterSpacing: '0px',
      fontSize: 'large',
    },
  },

  quote: {
    color: theme.palette.primary.contrastText,
    fontSize: 'medium',
    marginLeft: '5%',
    marginRight: '5%',
  },

  author: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      // paddingBottom: "1rem",
      fontSize: 'large',
    },
  },

  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  stylistContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewAll: {
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const getStores = async (id) => {
  return api.getStoreRanks(id).then((res) => res.data._embedded.stores.filter((store) => store.published));
};

const getStoreRanks = async (id) => {
  return api
    .getStoreRanks(id)
    .then((res) => res.data._embedded)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

function CategoryView(props) {
  const { category } = props;
  const [allStores, setAllStores] = useState([]);
  const [allRanks, setAllRanks] = useState([]);
  const [featuredStores, setFeaturedStores] = useState([]);
  const [unrankedStores, setUnrankedStores] = useState([]);
  const [topRatedStores, setTopRatedStores] = useState([]);
  const [featuredStylists, setFeaturedStylists] = useState([]);
  const title = `Featured ${category}`;

  const [loadingStores, setLoadingStores] = useState(true);
  const [{ location }] = useStateValue();
  const classes = useStyles();

  /* Work around to get default banners from banner index - whole banner system need refactoring */
  // let bannerKey = '';
  // switch (category) {
  //   case 'Lashes & Brows':
  //     bannerKey = 'brows';
  //     break;
  //   case 'Hair Removal':
  //     bannerKey = 'hairRemoval';
  //     break;
  //   case 'Aesthetics & Skin':
  //     bannerKey = 'skin';
  //     break;
  //   case 'Wellness':
  //     bannerKey = 'wellness';
  //     break;
  //   // case 'Hair':
  //   // case 'Nails':
  //   // case 'Makeup':
  //   default:
  //     bannerKey = category.toLowerCase();
  //     break;
  // }

  const bannerImages = []; //banners[bannerKey];

  // -TODO  consolidate the two useEffects into one - can use single call api call (ranks should have everything needed) - worst case use promise.all
  useEffect(() => {
    let mounted = true;
    //Update all stores and top rated stores
    setLoadingStores(true);
    getStores(parseIdFromLink(location.link))
      .then((res) => {
        const parsedStoreList = res
          .filter((store) => store.category.includes(category))
          .concat()
          .sort((first, second) => first.name.localeCompare(second.name));

        const topRanked = res
          .concat()
          .filter((store) => store.category.includes(category) && store.numOfReviews > 0)
          .sort((first, second) =>
            second.avgRating - first.avgRating !== 0
              ? second.avgRating - first.avgRating
              : second.numOfReviews - first.numOfReviews !== 0
              ? second.numOfReviews - first.numOfReviews
              : first.name.localeCompare(second.name)
          )
          .slice(0, 15);

        const topStores = topRanked.map((store) => {
          return { store };
        });

        if (mounted) {
          setAllStores(parsedStoreList);
          setTopRatedStores(topStores);
        }
        setLoadingStores(false);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => {
      mounted = false;
    };
  }, [location]); //eslint-disable-line

  useEffect(() => {
    //Update feat lists
    let mounted = true;
    if (!loadingStores) {
      /*[] TODO - What is fetching legacy??? What does this actually do, and why is it different from fetching stores? */
      getStoreRanks(parseIdFromLink(location.link)).then((res) => {
        const featuredRanks = res.featuredRanks
          ? res.featuredRanks
              .filter((store) => store.category === category)
              .sort((store1, store2) => store1.rank - store2.rank)
              .map((store) => {
                return store;
              })
          : [];

        const featuredStylistsRanks = res.featuredRanks
          ? res.featuredRanks
              .filter((store) => store.category.toLowerCase() === 'stylists')
              .sort((store1, store2) => store1.rank - store2.rank)
              .map((store) => {
                return store;
              })
          : [];

        const allRanks = res.allRanks
          ? res.allRanks
              .filter((store) => store.category === category)
              .sort((store1, store2) =>
                store1.rank - store2.rank !== 0
                  ? store1.rank - store2.rank
                  : store1.store.name.localeCompare(store2.store.name)
              )
              .map((store) => {
                return store;
              })
          : [];
        if (mounted) setFeaturedStores(featuredRanks);
        if (mounted) setAllRanks(allRanks);
        if (mounted) setFeaturedStylists(featuredStylistsRanks);
        let remainingStoreIds = [];
        let allRanksIds = [];
        allRanksIds = allRanks.map((rank) => parseIdFromLink(rank.store._links.self.href, 0, '{?projection}'));
        let allStoresIds = [];
        allStoresIds = allStores.map((store) => parseIdFromLink(store._links.self.href, 0, '{?projection}'));
        remainingStoreIds = allStoresIds.filter((id) => !allRanksIds.includes(id));
        let remainingStores = allStores.filter((store) => {
          return remainingStoreIds.includes(parseIdFromLink(store._links.self.href, 0, '{?projection}'));
        });

        if (mounted) setUnrankedStores(remainingStores);
      });
    }
    return () => {
      mounted = false;
    };
  }, [loadingStores, category, location, allStores]);

  //allStoresWithRanks is an array of objects with allRank first, then unranked stores
  const allStoresWithRanks = allRanks.map((x) => x.store).concat(unrankedStores);
  const firstFive = allStoresWithRanks.slice(0, 5);
  const remainingRanks = allStoresWithRanks.slice(5);

  const pageTitle = `${category} services in ${location?.city} ${location?.province} | Beautimap`;
  const metaDescription = `Browse ${category} services in ${location?.city} ${location?.province} and make appointments. Learn more by visiting our site.`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name={pageTitle} content={metaDescription} />
      </Helmet>
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <Carousel
            id="topBanners"
            type={category}
            bannerImages={bannerImages}
            // previewBannerName={state.selectedBanner}
            // previewBannerData={{
            // 	...state.saveData,
            // }}
          />
          {/* End carousel */}

          {featuredStores.length > 0 && (
            <Fragment>
              {/* TODO - Below typography should be an h1 for SEO */}
              <Typography variant="h4" className={classes.heading}>
                {`${category} Salons in ${location?.city} ${location?.province}`}
              </Typography>
              <FocusThisWeekCard
                // item={allRanks[0].store}
                item={featuredStores[0].store}
                type={category}
                location={location}
              ></FocusThisWeekCard>
            </Fragment>
          )}
          {/* Featured salon */}
          {featuredStores && featuredStores.length > 0 && (
            <FeaturedList
              listData={featuredStores}
              title={title}
              type={category}
              location={location}
              style={{
                alignItems: 'center',
              }}
              categoryMode={true}
            />
          )}
          {topRatedStores && topRatedStores.length > 0 && (
            <FeaturedList
              listData={topRatedStores}
              title={'Top Rated ' + category}
              type={category}
              location={location}
              style={{
                alignItems: 'center',
              }}
              categoryMode={true}
            />
          )}
          {category.toLowerCase() === 'hair' && featuredStylists && featuredStylists.length > 0 && (
            <FeaturedList
              listData={featuredStylists}
              title="Featured Hair Stylists"
              type={category}
              location={location}
              style={{
                alignItems: 'center',
              }}
              categoryMode={true}
            />
          )}
          {/* End Featured salon */}

          {remainingRanks.length > 0 ? (
            <ExpansionPanel
              // expanded={expanded === 'panel1'}
              // onChange={handleChange('panel1')}
              className={classes.allStoreAccordion}
              id="allRanksAccordion"
            >
              <ExpansionPanelSummary
                expandIcon={
                  <Tooltip title="See All">
                    <ExpandMoreIcon />
                  </Tooltip>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="h4" className={classes.heading}>
                  All {category}
                </Typography>
                {allRanks.length === 0 && allStores.length === 0 && (
                  <Typography className={classes.emptyList}>Currently not available</Typography>
                )}
                <div
                  className="allStoresContainer"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '8px',
                  }}
                >
                  {firstFive.map((item, index) => (
                    <ListItem style={{ width: '200px', padding: 0 }}>
                      <FeaturedListItem key={index} item={item} type={category} location={location} />
                    </ListItem>
                  ))}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div
                  className="allStoresContainer"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: '8px',
                  }}
                >
                  {remainingRanks.map((item, index) => (
                    <FeaturedListItem key={index} item={item} type={category} location={location} />
                  ))}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ) : (
            <>
              <Typography variant="h4" className={classes.heading}>
                All {category}
              </Typography>
              <div
                className="allStoresContainer"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {firstFive.map((item, index) => (
                  <FeaturedListItem key={index} item={item} type={category} location={location} />
                ))}
              </div>
            </>
          )}

          {!loadingStores && firstFive.length === 0 && (
            <div>
              No {category} services are listed in {location?.city} {location?.province} at the moment. Please check
              back later.
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CategoryView;

// {/* Featured stylists */}
// <Typography variant='h4' className={classes.heading}>featured hair stylists</Typography>
// <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a>
// <Container className={classes.stylistContainer}>
//     {hairCategoryData.featuredStylists.map((item, index) => (
//       <StylistItem key={index} item={item} location={{city: "Toronto", province: 'ON'}}/>
//     ))}
// </Container>
// {/* End featured stylists */}
// {/* Popular stylists */}
// <Typography variant='h4' className={classes.heading}>top hair stylists</Typography>
// <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a>
// <Container className={classes.stylistContainer}>
//     {hairCategoryData.popularStylists.map((item, index) => (
//       <StylistItem key={index} item={item} location={{city: "Toronto", province: 'ON'}}/>
//     ))}
// </Container>
// {/* End popular stylists */}
// {/* Products */}
// <Typography variant='h4' className={classes.heading}>view all</Typography>
// {/* <a href="#" style={{textDecoration: 'none'}}>
//   <Typography color="primary" variant='body1' className={classes.viewAll}>View All</Typography>
// </a> */}
// <ProductRow
//     listData={hairCategoryData.products}
//     loading={false}
//   />
// {/* End products*/}
