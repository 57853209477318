import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column" },
  headerElement: {
    fontWeight: "bold",
    margin: theme.spacing(2, 4, 2, 4),
    alignSelf: "center",
    textDecoration: "underline",
    fontSize: "18pt",
  },
  headerCaption: {
    fontWeight: "bold",
    margin: theme.spacing(2, 4, 2, 4),
    alignSelf: "center",
    textDecoration: "underline",
  },
  subHeaderElement: {
    fontWeight: "bold",
    margin: theme.spacing(2, 4, 2, 4),
    textDecoration: "underline",
  },
  element: {
    margin: theme.spacing(2, 4, 2, 4),
  },
  bulletpoint: {
    margin: theme.spacing(2, 4, 2, 8),
  },
}));

function PrivacyPolicyContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.headerElement} variant="h1">
        Privacy Policy
      </Typography>
      <Typography className={classes.headerCaption}>
        Revised: September 2021
      </Typography>
      <Typography className={classes.element}>
        Your privacy is very important to us. We strive to ensure the privacy of
        everyone who visits this site. This privacy policy sets out what
        information we track and how we use it. By using this site, you consent
        to the collection of information in accordance with the terms of this
        privacy policy.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Collection & Use of Information
      </Typography>
      <Typography className={classes.element}>
        We do not collect any personally identifying information about users
        unless it has been knowingly and specifically supplied to us by the
        user.
      </Typography>
      <Typography className={classes.element}>
        Personal data, (name, address, telephone numbers, credit card details,
        etc.) which has been provided by website visitors for product purchase
        and registration is controlled by beautimap.com for the purposes indicated.
      </Typography>
      <Typography className={classes.element}>
        We will not disclose any personal information about users to any outside
        party, unless the user has been advised of this possibility before
        providing the personal information.
      </Typography>
      <Typography className={classes.element}>
        We collect general information on what pages users visit and information
        volunteered by users.
      </Typography>
      <Typography className={classes.element}>
        Information collected from individual site visitors is never shared,
        rented or sold.
      </Typography>
      <Typography className={classes.element}>
        We reserve the right to access and disclose individually identifiable
        information in order to comply with applicable laws and lawful
        governmental requests, to operate our systems properly or to protect our
        rights and property.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        How We Use Personal Data
      </Typography>
      <Typography className={classes.element}>
        In addition to data required to provide Services to registered users and
        as outlined in our Terms of Use, we also process personal data for
        certain legitimate business purposes, all of which have been carefully
        considered and whose principle aim is to deliver an enhanced customer
        experience
      </Typography>
      <Typography className={classes.element}>
        For example, some business purposes for data processing include:
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Service Monitoring and Improvements
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To deliver relevant and
        sometimes urgent communication regarding your use of the Services,
        including but not limited to transactional notices regarding:
        maintenance, downtime, new or updated features, and other product and
        policy updates
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To monitor and understand
        visitor interest in particular features, products, themes, and topics
        for the purpose of improving user experience and the performance and
        relevance of our Services{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To identify and prevent
        fraud or violations of our Terms of Use
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Performing any other
        function that we believe in good faith is necessary to protect the
        security or proper functioning of our Site or Services
      </Typography>
      <Typography className={classes.subHeaderElement}>Product Data</Typography>
      <Typography className={classes.element}>
        In addition to Usage Data collected on public-facing pages of our Site,
        we also collect data required to provide the services requested by
        Clients who have registered to access beautimap.com’s password restricted
        services. By creating an account with beautimap.com, users also accept
        beautimap.com’s Term and Conditions.
      </Typography>
      <Typography className={classes.element}>
        In order to provide the agreed-upon services, the following data is
        collected through the beautimap.com application and client extensions.
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information about the device
        used to access our service{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information about how our
        service was used (eg. the number of requests to our service) as well as
        events such as device events like crashes etc{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Other log information (which
        may include Internet Protocol Address or other information or
        identifiers that may uniquely identify your account or browser or
        device){" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information about your
        location (eg. used to identify your time zone settings){" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Information about your
        beautimap.com application version. When you install/uninstall or use one of
        our applications the service may send version information{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Email subject lines,
        Recipient email address and/or distribution list names entered in the To
        and CC fields{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Destination of Hyperlinks in
        order to uniquely track them
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cookies or other anonymous
        identifiers may be sent to your devices and may be used when you
        interact with our site or service
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Use by children
      </Typography>
      <Typography className={classes.element}>
        If you are under 18, be sure to obtain your parents or guardians
        permission before you send any personal information (for example, your
        name, address, e-mail address, etc.) to us or anyone else over the
        Internet. We encourage parents to get involved with their children’s
        online usage and to be aware of the activities in which they are
        participating.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Questions About our Privacy Policy
      </Typography>
      <Typography className={classes.element}>
        In compliance with the EU-US and Swiss-US Privacy Shield Principles,
        beautimap.com commits to resolve complaints about your privacy and our
        collection or use of your Personal Data. Individuals with inquiries or
        complaints regarding this privacy policy should first contact beautimap.com
        by emailing{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="mailto: support@beautimap.com"
        >
          support@beautimap.com
        </a>
        .
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Changes to Policy
      </Typography>
      <Typography className={classes.element}>
        We may change this privacy policy from time to time by updating this
        posting. Please check the privacy policy page of our website
        periodically for changes. Your continued use of this site following the
        posting of changes constitutes your acceptance of those changes.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Single Sign-On
      </Typography>
      <Typography className={classes.element}>
        You can log in to our Site using sign-in services such as Google’s OAuth
        and Microsoft 365 OAuth. These services will authenticate your identity
        and provide you the option to share certain Personal Information with us
        such as your name and email address to prepopulate our sign up form or
        create your account.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Customer Testimonials
      </Typography>
      <Typography className={classes.element}>
        We post customer testimonials on our web site which may contain Personal
        Data. We do obtain the customer’s consent prior to posting the
        testimonial to post their name along with their testimonial. To request
        removal of your Personal Data from Testimonials please contact us by
        contacting beautimap.com via email at{" "}
        <a
          style={{ textDecoration: "underline" }}
          href="mailto: support@beautimap.com"
        >
          support@beautimap.com
        </a>
        .
      </Typography>
      <Typography className={classes.subHeaderElement}>Cookies</Typography>
      <Typography className={classes.element}>
        A cookie is a small piece of information that is sent to your browser
        from a web server and stored on your computer’s hard drive. Like most
        major websites, we use cookies to better understand how our site is
        used, to make sure our ads and dynamic content are served correctly and
        to improve the performance of our site for our users.
      </Typography>
      <Typography className={classes.element}>
        You may disable cookies on your browser, however this may limit your use
        of some website features.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Information security
      </Typography>
      <Typography className={classes.element}>
        We take appropriate security measures to protect against unauthorized
        access to or unauthorized alteration, disclosure or destruction of data.
        These include internal reviews of our data collection, storage and
        processing practices and security measures, as well as physical security
        measures to guard against unauthorized access to systems where we store
        personal data.
      </Typography>
      <Typography className={classes.element}>
        We restrict access to personal information to beautimap.com employees,
        contractors and agents who need to know that information in order to
        operate, develop or improve our services. These individuals are bound by
        confidentiality obligations.
      </Typography>
      <Typography className={classes.element}>
        Data with sensitive information such as user passwords are transmitted
        over secure network connections using SSL/TLS technology.
      </Typography>
      <Typography className={classes.subHeaderElement}>
        Additional Limits on Use of Your Google User Data
      </Typography>
      <Typography className={classes.element}>
        Notwithstanding anything else in this Privacy Policy, if you provide the
        App access to the following types of your Google data, the App’s use of
        that data will be subject to these additional restrictions:
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The App will only use access
        to read, write, modify, or control Gmail message bodies (including
        attachments), metadata, headers, and settings to provide a web email
        client that allows users to compose, send, read, and process emails and
        will not transfer this Gmail data to others unless doing so is necessary
        to provide and improve these features, comply with applicable law, or as
        part of a merger, acquisition, or sale of assets.{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The App will not use this
        Gmail data for serving advertisements.{" "}
      </Typography>
      <Typography className={classes.bulletpoint}>
        • &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The App will not allow
        humans to read this data unless we have your affirmative agreement for
        specific messages, doing so is necessary for security purposes such as
        investigating abuse, to comply with applicable law, or for the App’s
        internal operations and even then only when the data have been
        aggregated and anonymized.
      </Typography>
      <Typography className={classes.element}>
        We welcome any questions regarding our privacy policy. We can be reached
        via{" "}
        <a
          href="mailto: support@beautimap.com"
          style={{ textDecoration: "underline" }}
        >
          email
        </a>
        .
      </Typography>
    </div>
  );
}

export default PrivacyPolicyContent;
