import React, { useState, useEffect, Fragment } from 'react';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { makeStyles, Modal, Typography, Backdrop, useMediaQuery, Tab } from '@material-ui/core';
import api from '../utils/api';
import { useStateValue } from '../state';
import { CATEGORIES, STATUS } from '../utils/constants';
import { categoryShorthand, parseIdFromLink } from '../utils/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import MapsStoreList from '../components/MapsStoreList';
import MapsStoreProfile from '../components/MapsStoreProfile';
import axios from 'axios';
import { openStatus } from '../utils/helpers';
import SwipeableTemporaryDrawer from '../components/Drawer';
import MapFilter from '../components/MapFilter';
import CloseIcon from '@material-ui/icons/Close';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mapTitle: {
    textAlign: 'left',
    color: 'rgb(74, 74, 74)',
    [theme.breakpoints.down('sm')]: {
      marginTop: '70px',
      display: 'none',
      //marginLeft: "50%",
    },

    marginTop: '30px',

    marginBottom: '30px',
    fontSize: 'clamp(1.5rem, 2.6vw, 2rem)',
  },
  filtertext: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginBottom: '30px',
    fontSize: 'clamp(1.5rem, 2.6vw, 2rem)',
  },
  storeWindow: {
    position: 'absolute',
    left: 8,
    top: 365,
    width: '350px',
    height: '500px',
    backgroundColor: 'white',
    border: '2px solid #E3E3E3',
    overflowY: 'scroll',

    [theme.breakpoints.down('md')]: {
      position: 'relative',
      display: 'flex',
      left: 'initial',
      top: 'initial',
      flexDirection: 'column',
      width: '100%',
      height: '500px',
      backgroundColor: 'white',
      border: '2px solid #E3E3E3',
      overflowY: 'scroll',
    },

    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      display: 'flex',
      left: 'initial',
      top: 'initial',
      flexDirection: 'column',
      width: '100%',
      height: '500px',
      backgroundColor: 'white',
      border: '2px solid #E3E3E3',
      overflowY: 'scroll',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },
  categoryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  storeTab: {
    backgroundColor: '#FFFF',
    borderColor: '#e6e6e6',
    border: 'solid 1px',
    borderRadius: '10px',
    opacity: 1,
    height: 3,
    margin: theme.spacing(1),
    width: '5px',
  },
  storeTabOpen: {
    backgroundColor: '#FFFF',
    borderColor: '#e6e6e6',
    border: 'solid 1px',
    borderRadius: '10px',
    opacity: 1,
    width: '5px',
    margin: theme.spacing(1),
  },

  storeTabSelected: {
    backgroundColor: '#ebebeb',
    borderRadius: '10px',
    borderColor: '#e6e6e6',
    border: 'solid 1px',
    opacity: 1,
    width: '5px',
    margin: theme.spacing(1),
    height: 3,
  },
  storeTabSelectedOpen: {
    backgroundColor: '#ebebeb',
    borderRadius: '10px',
    borderColor: '#e6e6e6',
    border: 'solid 1px',
    width: '5px',
    margin: theme.spacing(1),
    opacity: 1,
  },
  checkboxContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: theme.spacing(5),
    paddingHorizontal: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    borderBottomWidth: theme.spacing(1),
    borderBottomColor: '#f0f0f0',
  },
}));

//mapbox
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; //Fix to allow mapbox to work with create-react-app deployments
const accessToken = process.env.REACT_APP_MAP_BOX_TOKEN;

const getStores = async (type, id) => {
  return api
    .getStoresForAdmin()
    .then((res) => res.filter((store) => store.published))
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getGeocode = async (address) => {
  return axios
    .get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address.city}${address.province}&key=${process.env.REACT_APP_MAPS_API_KEY}`
    )
    .then((res) => res.data.results[0]?.geometry.location)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

let currentMarker = {};

export default function MapsPageView(props) {
  const { admin } = props;
  const [{ location }] = useStateValue();

  const [allStores, setAllStores] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCoordinates, setLoadingCoordinates] = useState(true);
  const [markerSelect, setMarkerSelect] = useState(false);
  const [previousMarker, setPreviousMarker] = useState(false);
  const [clickedStoreData, setClickedStoreData] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);

  //Getters are used dynamically in eval function thus the eslint-disable-next-line
  //When refactoring this component, all of these state's will be removed
  //Will filter based on 'allStores' at time of rendering.
  const [hair, setHair] = useState([]); //eslint-disable-line
  const [nails, setNails] = useState([]); //eslint-disable-line
  const [makeup, setMakeup] = useState([]); //eslint-disable-line
  const [lashes, setLashes] = useState([]); //eslint-disable-line
  const [aesthetics, setAesthetics] = useState([]); //eslint-disable-line
  const [waxing, setWaxing] = useState([]); //eslint-disable-line
  const [wellness, setWellness] = useState([]); //eslint-disable-line

  // const [featuredStores, setFeaturedStores] = useState([]);
  const [loadingStores, setLoadingStores] = useState(true);

  const [value, setValue] = useState(0);

  const [displayFilter, setDisplayFilter] = useState({
    settings: {
      hair: false,
      nails: false,
      makeup: false,
      lashes: false,
      aesthetics: false,
      waxing: false,
      wellness: false,
      open: false,
      // stylists: false,
      // brows: false,
      // facial: false,
      // selfcare: false,
      // skincare: false,
      // tanning: false,
      // mailout: false,
    },
    message: 'All Locations in Area',
    showFilter: false,
  });

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const mobile = useMediaQuery('(max-width:1024px)');
  const [viewport, setViewport] = useState({
    //marginTop: "50%",
    zoom: 3,
    // pitch: 50,
  });

  const gobackReset = () => {
    setClickedStoreData([]);
  };
  useEffect(() => {
    //console.log("Location : ", location.city)
    //console.log("admin? : ", admin)
    getStores(false, location.link)
      .then((res) => {
        // console.log("get stores: ", res);
        //heres were we need to do some calcs. we need to get the coordinates of the city that matches the location.link
        // this routine finds the pinaddress that matches the selected city, and returns the store with lat and long cordinates of the matching city
        // as [{...store, latitude: matching city latitude, longitude: matching city longitude}]

        //Remove stores with no lat or long

        res = res.filter((store) => {
          const hasCoordinates = store.latitude && store.longitude;
          const isPublished = store.published;
          const isSameCity = store.address.province === location.province && store.address.city === location.city;
          return hasCoordinates && isPublished && isSameCity;
        });
        setAllStores(res);
        let val = res;

        /* 
          export const NAV_OPTIONS = [
          { name: 'Hair', path: '/hair', index: 0 },
          { name: 'Lashes & Brows', path: '/lashes-brows', index: 1 },
          { name: 'Nails', path: '/nails', index: 2 },
          { name: 'Makeup', path: '/makeup', index: 3 },
          { name: 'Hair Removal', path: '/hair-removal', index: 4 },
          { name: 'Aesthetics & Skin', path: '/aesthetics-skin', index: 5 },
          { name: 'Wellness & Self Care', path: '/wellness-selfcare', index: 6 },
          ];
          export const CATEGORIES = NAV_OPTIONS.map((option) => option.name);
        */
        let hair = val.filter((store) => store.category.includes('Hair'));
        let nails = val.filter((store) => store.category.includes('Nails'));
        let makeup = val.filter((store) => store.category.includes('Makeup'));
        let lashes = val.filter((store) => store.category.includes('Lashes & Brows'));
        let aesthetics = val.filter((store) => store.category.includes('Aesthetics & Skin'));
        let waxing = val.filter((store) => store.category.includes('Hair Removal'));
        let wellness = val.filter((store) => store.category.includes('Wellness & Self Care'));

        // let stylists = val.filter((store) => store.category.includes('Stylists'));
        // let brows = val.filter((store) => store.category.includes('Brows'));
        // let facial = val.filter((store) => store.category.includes('Facial'));
        // let selfcare = val.filter((store) => store.category.includes('Selfcare'));
        // let tanning = val.filter((store) => store.category.includes('Tanning'));

        setCoordinates(val);
        setHair(hair);
        // setStylists(stylists);
        setLashes(lashes);
        // setBrows(brows);
        setNails(nails);
        setMakeup(makeup);
        setAesthetics(aesthetics);
        setWaxing(waxing);
        setWellness(wellness);
        // setFacial(facial);
        // setSelfcare(selfcare);
        // setSkincare(skincare);
        // setTanning(tanning);

        setLoading(false);
        setClickedStoreData({});
        setLoadingStores(false);
        return val;
      })
      .then((allStoreRes) => {
        getGeocode(location).then((res) => {
          if (res) {
            res.zoom = 10;
          }
          if (!res) {
            // //Get 1st store coords else
            if (allStoreRes.length) {
              res = {
                //Got to first store zoomed out, or Hard Code Ctr TO.
                lat: allStoreRes[0].latitude || 43.423004,
                lng: allStoreRes[0].longitude || -80.160943,
                zoom: 10,
              };
            } else {
              res = {
                //Hard Code Ctr Canada
                lat: 49.296472,
                lng: -90.117444,
                zoom: 3,
              };
            }
          }
          setViewport((previousState) => ({
            ...previousState,
            latitude: res.lat,
            longitude: res.lng,
            transitionDuration: 300,
            zoom: res.zoom,
            transitionInterpolator: new FlyToInterpolator({ curve: 1.5 }),
          }));
          setLoadingCoordinates(false);
        });
      });
  }, [location.link, location]);
  // console.log("tanning", tanning);
  const handleMarkerPosition = (event, store) => {
    if (event.currentTarget.id !== previousMarker) {
      currentMarker[event.currentTarget.id] = true;
      currentMarker[previousMarker] = false;
    }

    setClickedStoreData(store);

    setViewport((previousState) => ({
      ...previousState,
      latitude: mobile ? store.latitude : store.latitude,
      longitude: store.longitude,
      transitionDuration: 300,
      transitionInterpolator: new FlyToInterpolator({ curve: 1.3 }),
    }));
    setMarkerSelect(currentMarker);
    setPreviousMarker(event.currentTarget.id);
  };

  const handleFilterSettings = (input) => {
    const shortHandCategory = categoryShorthand(input); //To make multi word categories compatible

    const oldSettings = displayFilter.settings;
    const newSettings = {
      ...oldSettings,
      [shortHandCategory]: !displayFilter['settings'][shortHandCategory],
    };
    const filterMessage = (settings) => {
      //Return a string of what is being shown on the map
      if (!settings) {
        return null;
      }
      const showing = [];
      for (const [key, value] of Object.entries(settings)) {
        if (value) {
          key === 'open' ? showing.push('Open Now') : showing.push(key.charAt(0).toUpperCase() + key.slice(1));
        }
      }
      if (showing.length === 0) {
        return 'All Locations in Area';
      }
      return showing.join(' ');
    };
    setDisplayFilter((prev) => {
      return {
        ...prev,
        settings: newSettings,
        message: filterMessage(newSettings),
      };
    });
  };
  const handleFilterClick = () => {
    setFilterOpen(true);
  };

  const handleFilters = () => {
    if (
      !displayFilter.settings.hair &&
      !displayFilter.settings.stylists &&
      !displayFilter.settings.lashes &&
      !displayFilter.settings.brows &&
      !displayFilter.settings.nails &&
      !displayFilter.settings.makeup &&
      !displayFilter.settings.aesthetics &&
      !displayFilter.settings.waxing &&
      !displayFilter.settings.facial &&
      !displayFilter.settings.selfcare &&
      !displayFilter.settings.skincare &&
      !displayFilter.settings.tanning &&
      !displayFilter.settings.open
    ) {
      //console.log("passed in coords: ", coordinates)
      return coordinates;
    } else {
      //console.log("StoreFronts: " , storeFronts)
      //console.log("deliveries: " , deliveries)
      //console.log("openNow: " , openNow)
      //console.log("mailOuts: " , mailouts)
      //console.log("SF: ", storeFrontCheckBox, "D:", deliveryCheckBox, "O:", openCheckBox, "M:", mailoutsCheckBox)

      if (
        displayFilter.settings.open &&
        !displayFilter.settings.hair &&
        !displayFilter.settings.brows &&
        !displayFilter.settings.nails &&
        !displayFilter.settings.makeup &&
        !displayFilter.settings.aesthetics &&
        !displayFilter.settings.waxing &&
        !displayFilter.settings.facial &&
        !displayFilter.settings.selfcare &&
        !displayFilter.settings.skincare &&
        !displayFilter.settings.tanning
      ) {
        return coordinates.filter((store) => openStatus(store.hours));
      }
      const uniqueStoresToDisplay = new Map(); //Map used to prevent duplicates
      Object.entries(displayFilter.settings).forEach(([filterName, isActive]) => {
        if (isActive) {
          if (filterName === 'open') return; //Filters open store after unique list is complete
          //eslint-disable-next-line
          const currList = eval(`${filterName}`); //eval is used to get the list of stores based on the filterName, but can be harmful accorind to eslint
          //Opens to injection and harder to debug. Ref injection not a thread as filters are hardcoded but will refactor later
          //Full refactor required to remove eval will rebuild this component and data handling later
          currList.forEach((store) => {
            const storeId = parseIdFromLink(store._links.self.href);
            uniqueStoresToDisplay.set(storeId, store);
          });
        }
      });
      const dataToDisplay = [...uniqueStoresToDisplay.values()];
      const storesOpenNow = dataToDisplay.filter((store) => openStatus(store.hours));

      return displayFilter.settings.open ? storesOpenNow : dataToDisplay; // data;

      /* []TODO 
      Refactor the whole filter to clean it up and all stores can be utilized with filters in this function rather than having a dozen states
      */
    }
  };

  const classes = useStyles();

  const pageTitle = `Beautimap for ${location?.city} ${location?.province}`;
  return (
    <Fragment>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name={pageTitle} content="Find beauty services near you" />
      </Helmet>

      {(loading || loadingCoordinates || loadingStores) && (
        <CircularProgress size={90} color="inherit" style={{ display: 'flex', marginLeft: '50%', marginTop: '20%' }} />
      )}

      {!mobile && !loading && !loadingCoordinates && !loadingStores && (
        <div
          style={{
            margin: 'auto',
            maxWidth: '1400px',
            marginBottom: '25px !important',
          }}
        >
          {/* <Typography variant="h4" className={classes.mapTitle}>
						{`Beauty Services in ${location.city}, ${location.province}`}
					</Typography> */}
          <div>
            <Typography variant="h5" className={classes.filtertext}>
              Map Filters
            </Typography>
          </div>

          <MapFilter
            handleFilterSettings={handleFilterSettings}
            filterSettings={displayFilter.settings}
            showFilter={displayFilter.showFilter}
            value={value}
            handleTabChange={handleTabChange}
            displayFilterSettings={displayFilter.settings}
            mobile={mobile}
            filterOpen={filterOpen}
          />
        </div>
      )}

      {!loading && !loadingCoordinates && !loadingStores && (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
          <ReactMapGL
            {...viewport}
            width="100vw"
            height={mobile ? '30vh' : '70vh'}
            attributionControl={false}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={(viewport) => {
              const { width, height } = viewport;
              setViewport({ ...viewport, width, height });
            }}
            mapboxApiAccessToken={accessToken}
          >
            {handleFilters(coordinates).map((city, index) => (
              <Marker key={index} latitude={city.latitude} longitude={city.longitude} offsetLeft={-20} offsetTop={-10}>
                <img
                  id={parseIdFromLink(city._links.self.href)}
                  src={
                    markerSelect[parseIdFromLink(city._links.self.href)]
                      ? require('../assets/icons-for-map-feature/Pin-Selected.svg')
                      : require('../assets/icons-for-map-feature/Pin-Regular.svg')
                  }
                  alt={city.name}
                  style={{
                    zIndex: markerSelect[parseIdFromLink(city._links.self.href)] ? '9999' : '1',
                    height: markerSelect[parseIdFromLink(city._links.self.href)] ? '50px' : '35px',
                    width: markerSelect[parseIdFromLink(city._links.self.href)] ? '40px' : '28px',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    handleMarkerPosition(e, city);
                  }}
                />

                {/*
my next idea is to put the store filtering code in here. 
Now the idea is that im going to have a matrix value put on top of the index value.
That way we can store the .res in the return matrix. Otherwise it will not fit in the storage requirments.
*/}
              </Marker>
            ))}
          </ReactMapGL>
        </div>
      )}

      {mobile && !loading && !loadingCoordinates && !loadingStores && (
        <div
          style={{
            marginBottom: '20px',
            maxWidth: '800px',
            margin: 'auto' /*width: "50%"*/,
          }}
        >
          <MapFilter
            handleFilterSettings={handleFilterSettings}
            filterSettings={displayFilter.settings}
            showFilter={displayFilter.showFilter}
            value={value}
            handleTabChange={handleTabChange}
            displayFilterSettings={displayFilter.settings}
            mobile={mobile}
            filterOpen={handleFilterClick}
          />
        </div>
      )}

      {!loading && !loadingCoordinates && !loadingStores && (
        <div className={classes.storeWindow}>
          {admin && (
            <SwipeableTemporaryDrawer
              fullStores={allStores.filter(
                (store) => !coordinates.find((val) => store._links.self.href === val._links.self.href)
              )}
              validStores={coordinates}
            />
          )}

          {Object.keys(clickedStoreData)?.length > 0 && !loading && !loadingCoordinates && !loadingStores && (
            <MapsStoreProfile
              mobile={mobile}
              store={clickedStoreData}
              setClickedStoreData={gobackReset}
              location={location}
            />
          )}
          {Object.keys(clickedStoreData)?.length === 0 && !loading && !loadingCoordinates && !loadingStores && (
            <MapsStoreList
              loading={loading}
              originalList={coordinates}
              storeList={handleFilters()}
              handleMarkerPosition={handleMarkerPosition}
              location={location}
            />
          )}
          <Modal
            //Mobile only modal - button to open is in MapFilter.js likely should live in there.
            //[] Ideally consolidate mobile and desktop filters into one component.
            className={classes.modal}
            open={filterOpen}
            onClose={() => {
              setFilterOpen(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.modalPaper}>
              <CloseIcon
                onClick={() => setFilterOpen(false)}
                style={{
                  position: 'absolute',
                  right: '16px',
                  top: '16px',
                  zIndex: '9999',
                }}
              />
              <Typography
                variant="h6"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Map Filters<br></br>
              </Typography>
              <div className={classes.categoryWrapper}>
                {CATEGORIES.map((cat) => (
                  <Tab
                    style={{ width: '100%' }}
                    indicatorColor="primary"
                    value={cat}
                    // className={displayFilter.settings[cat.toLowerCase()] ? classes.storeTabSelected : classes.storeTab}
                    className={
                      displayFilter?.settings[categoryShorthand(cat)] ? classes.storeTabSelected : classes.storeTab
                    }
                    onClick={() => handleFilterSettings(cat.toLowerCase())}
                    label={cat}
                  />
                ))}
                <Tab
                  style={{ width: '100%' }}
                  value={'open'}
                  icon={
                    <img
                      alt="open"
                      src={require('../assets/icons-for-map-feature/OpenNowicon.svg')}
                      style={{ height: mobile ? '50px' : '70px' }}
                    />
                  }
                  className={displayFilter.settings.open ? classes.storeTabSelectedOpen : classes.storeTabOpen}
                  onClick={() => handleFilterSettings('open')}
                  label="Open Now"
                />
              </div>
            </div>
          </Modal>
        </div>
      )}
    </Fragment>
  );
}
