import React from 'react';
import ReviewItem from './ReviewItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import ReviewForm from './ReviewForm';
import useReviewData from '../hooks/useReviewsData';

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: theme.palette.common.font,
    fontSize: '2rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      fontSize: '1.25rem',
      marginBottom: '0.5rem',
    },
  },
  noReviewsText: {
    fontSize: '1.5rem',
    color: theme.palette.common.font,
    margin: theme.spacing(4, 0),
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      fontSize: '1.05rem',
    },
  },
}));

export default function ReviewSectionIndex({ storeId }) {
  // console.log('review props', props);
  const classes = useStyles();
  const { reviews, actions } = useReviewData(storeId);

  return (
    <section>
      <Typography variant="h2" className={classes.headingText}>
        Word on the street
      </Typography>
      {/* Input form - allows users to leave a post - requires login */}
      <ReviewForm actions={actions} />
      {/* Display exisiting reviews */}
      {reviews?.map((review) => (
        <ReviewItem key={review.id} review={review} actions={actions} />
      ))}
      {reviews?.length === 0 && (
        <Typography variant="body1" className={classes.noReviewsText}>
          No reviews yet. Be the first!
        </Typography>
      )}
    </section>
  );
}
