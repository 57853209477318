import axios from "axios";
import cookies from "./cookies";
import * as Sentry from "@sentry/browser";

const instance = axios.create({
  baseURL: `https://${process.env.REACT_APP_ENV}.beautimap.com:8080/api/v1`,
  // baseURL: `https://api.dev.beautimap.com:8080/api/v1`,
});

instance.defaults.timeout = 30000;

// Add/configure any interceptors
instance.interceptors.request.use(
  (config) => {
    if (cookies.getJWT()) {
      config.headers.common["Authorization"] = `Bearer ${cookies.getJWT()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      // Sentry.captureException({
      //   data: error.response.config.data,
      //   error: error.response.data,
      //   user: cookies.getUserID(),
      // });
      return Promise.reject(error.response.data);
    } else if (error.message) {
      Sentry.captureException({
        error: error.message,
        user: cookies.getUserID(),
      });
    }
    return Promise.reject(error.message);
  }
);

export default instance;
