import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core'; //Typography, Button, IconButton,useMediaQuery
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import LandingHero from '../components/LandingHero';
// import LinkBar from '../components/LinkBar';
import FeaturedList from '../components/FeaturedList';
// import SimpleCard from '../components/SimpleCard';

import { CATEGORIES, NAV_OPTIONS } from '../utils/constants';
import { Helmet } from 'react-helmet';
import { useStateValue } from '../state';
import api from '../utils/api';
import { parseIdFromLink } from '../utils/helpers'; //allStoresRedirect, abbreviate
// import cookies from '../cookies';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    marginTop: '10px',
    paddingBottom: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1.5),
    },
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& :last-child': {
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
  },
  hotDealsContainer: {
    width: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: 0,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  hotDealsTitle: {
    color: theme.palette.common.white,
    marginTop: 0,
    textTransform: 'uppercase',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  categoryHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 0,
  },
  scrollArrows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '-webkit-fill-available',
    height: '80%',
    // marginBottom: theme.spacing(0.75),
    [theme.breakpoints.down('sm')]: {
      // marginBottom: theme.spacing(0.25),
    },
  },
  leftIndicator: {
    color: '#EE333F',
    padding: 0,
    fontSize: '4rem',
  },
  rightIndicator: {
    color: '#EE333F',
    padding: 0,
    fontSize: '4rem',
  },
  hiddenIndicator: {
    color: '#000000',
    padding: 0,
    fontSize: '4rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  gridListContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: theme.screen.maxWidth,
    margin: '0 auto',
    justifyContent: 'center',
    overflow: 'hidden',
    // height: '100%',
    // '&::-webkit-scrollbar': {
    // 	display: 'none',
    // },
  },
  gridList: {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    width: '-webkit-fill-available',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  gridListCentred: {
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    width: '-webkit-fill-available',
    justifyContent: 'center',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  dealGrid: {
    margin: theme.spacing(2),
    marginBottom: theme.spacing(5),
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    overflowY: 'scroll',
    gridGap: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  hotDealsLocation: {
    color: '#EE333F',
    font: 'Quicksand',
    fontWeight: 400,
    // marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.3),
      // marginTop: theme.spacing(0.5),
      fontSize: '14pt',
    },
  },
  dealsButton: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#EE333F',
    borderRadius: '0px',
    paddingTop: theme.spacing(0.125),
    paddingBottom: theme.spacing(0.125),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    alignSelf: 'center',
  },
}));
export default function NewLandingPage() {
  const classes = useStyles();
  const [{ location }] = useStateValue();

  const [allFeatureLists, setAllFeatureLists] = useState([]);

  useEffect(() => {
    const getAndSetFeatureLists = async () => {
      const locationId = parseIdFromLink(location.link);
      const locationRanksRes = await api.getFeaturedRanks(locationId);
      const featListsForLocation = locationRanksRes.data._embedded.featuredRanks; //Gives indv Ranks with a category value
      const featLists = [];
      //Iterate categories and get the list of featured businesses for each category
      CATEGORIES.forEach((listCategory) => {
        //Put the list of featured businesses into the featLists array
        const featListForCategory = featListsForLocation
          .filter((item) => item.category === listCategory)
          .sort((a, b) => a.rank - b.rank);
        //Sort each group by rank
        featListForCategory.length > 0 && featLists.push(featListForCategory);
      });
      setAllFeatureLists(featLists);
    };

    location.link && getAndSetFeatureLists();
  }, [location]);

  const pageTitle = `Beautimap - ${location?.city} ${location?.province}`;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name={pageTitle}
          content="Browse beauty services near you and make appointments. Learn more by visiting our site."
        />
      </Helmet>

      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <LandingHero />
          {/* Iterate over feature lists the display */}
          {allFeatureLists?.map((categoryGroup, index) => {
            const category = categoryGroup[0].category;
            return (
              <FeaturedList
                key={`landingFeatList-${index}`}
                listData={categoryGroup}
                title={`Featured ${category}`}
                viewAllPath={NAV_OPTIONS.find((option) => option.name === category).path}
                type={category}
                location={location}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
