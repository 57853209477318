import React, { Fragment } from "react";
import {
  makeStyles,
  Modal,
  Fade,
  Backdrop,
  Typography,
  Button
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  actionButtons: { display: "flex" },
  confirmationMessage: {
    margin: theme.spacing(2)
  },
  cancelButton: {
    margin: theme.spacing(2)
  },
  confirmationButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2)
  }
}));

function PublishConfirmModal(props) {
  const classes = useStyles();

  const {
    item,
    status,
    setStatus,
    current,
    changePublish,
    initialAccepted
  } = props;

  return (
    <div className={classes.root}>
      <Modal
        className={classes.modal}
        open={status}
        onClose={() => {
          setStatus(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={status}>
          <div className={classes.modalPaper}>
            {current === false && (
              <Fragment>
                <Typography className={classes.confirmationMessage}>
                  {`Are you sure you want to publish this ${item}? It will be available for the public to view. This action can be undone.`}
                </Typography>
                {!initialAccepted && (
                  <Typography>
                    You will need to accept the terms and conditions.
                  </Typography>
                )}
              </Fragment>
            )}
            {current === true && (
              <Typography className={classes.confirmationMessage}>
                {`Are you sure you want to unpublish this ${item}? It will be no longer be available for the public to view. This action can be undone.`}
              </Typography>
            )}
            <div className={classes.actionButtons}>
              <Button
                variant="contained"
                onClick={() => setStatus(false)}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setStatus(false);
                  changePublish();
                }}
                className={classes.confirmationButton}
              >
                Ok
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default PublishConfirmModal;
