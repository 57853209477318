/* 
Popup to appear on the home page asking a user to sign up for an account
Affermative action will take user to the sign up page
No action will close the popup and hide for 1 day
Will have checkbox option to not show again (local storage)
If user is signed in, popup will not appear
*/

import React, { useState } from 'react';
import { makeStyles, Card, CardContent, Typography, Button, Fade, Dialog } from '@material-ui/core';
import beautimapLogo from '../../assets/logos/Beautimap-logo.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  contentContainer: {
    padding: 16,
    paddingBottom: '0 !important',
  },
  confirmationCard: {
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'center',
  },
  logoAndTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
  },
  question: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
  },
  signUpButton: {
    width: '100%',
    color: theme.palette.common.font,
    fontWeight: 'bold',
    fontSize: '1.25rem',
  },
  buttonsContainer: {
    display: 'flex',
    fontSize: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  extendedHidePrompt: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 500,
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.9rem',
      },
    },
  },
  button: {
    textTransform: 'capitalize',
    boxShadow: '0px 0px',
    color: theme.palette.common.font,
    fontWeight: 500,
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  logoHeader: {
    [theme.breakpoints.down('sm')]: {
      width: 180,
    },
  },
}));

export default function Index({ showWelcomePrompt, closeAction }) {
  const classes = useStyles();

  // const isLoggedIn = !!cookies.getJWT() && !!cookies.getUserID();
  // const showPrompt = !isLoggedIn && cookies.getSignUpPrompt() !== 'true';
  // const [showSignUpPrompt, setShowSignUpPrompt] = useState(showPrompt);
  const [checkedHide, setCheckedHide] = useState(false);

  const closeModal = () => {
    closeAction && closeAction({ hidePrompt: checkedHide });
    //   checkedHide && cookies.setSignUpPrompt();
    //   setShowSignUpPrompt(false);
  };
  const toggleCheckHide = () => {
    setCheckedHide((prev) => !prev);
  };

  const goToSignUp = async () => {
    closeAction && (await closeAction({ hidePrompt: false }));
    window.location.href = '/signup';
  };

  return (
    <Dialog open={showWelcomePrompt} onClose={null}>
      <Fade in={true}>
        <Card className={classes.confirmationCard} variant="outlined">
          <CardContent className={classes.contentContainer}>
            <div className={classes.logoAndTitle}>
              <img src={beautimapLogo} alt={`Beautimap Logo`} className={classes.logoHeader} />
            </div>
            <Typography className={classes.question} variant="h6" align="center">
              Have you joined our beauty community yet?
            </Typography>
            <Button
              className={classes.signUpButton}
              variant="contained"
              color="primary"
              onClick={goToSignUp}
              size="large"
            >
              Sign up now
            </Button>
            <div className={classes.buttonsContainer}>
              <FormControlLabel
                control={<Checkbox checked={checkedHide} onChange={toggleCheckHide} name="checkedB" color="primary" />}
                label="Hide for 30 days"
                className={classes.extendedHidePrompt}
              />
              <Button id="noBtn" className={classes.button} onClick={closeModal}>
                Maybe Later
              </Button>
            </div>
          </CardContent>
        </Card>
      </Fade>
    </Dialog>
  );
}
