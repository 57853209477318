const parseIdFromLink = (link, offset, strip) => {
  if (!link) return link;
  let id;
  if (strip) {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)].replace(strip, '');
  } else {
    id = link.split('/')[link.split('/').length - 1 + (offset ? offset : 0)];
  }
  return id;
};

const determinePinChanges = (cityStreetData, currPin) => {
  let newCitys = [];
  let deleteCitys = [];
  let editPins = [];
  //PATCH if .street values for matching citys are different, POST new ones, DELETE the ones that dont exist in
  // these are the new pins [{city: "", street : ""}]
  newCitys = cityStreetData.filter((record) => !currPin.find((pin) => pin.address.city === record.city));
  //these are the pin href that have to be deleted
  deleteCitys = currPin
    .filter((pin) => !cityStreetData.find((record) => record.city === pin.address.city))
    .map((pin) => {
      return { ...pin, ...pin.address, id: parseIdFromLink(pin._links.self.href, 0, '{?projection}') };
    });

  currPin.forEach((pin) => {
    cityStreetData.forEach((val) => {
      if (pin.address.city === val.city) {
        if (pin.address.street !== val.street || pin.address.postalCode !== val.postalCode) {
          editPins.push({
            //Clear old coordinates and send new addr.
            longitude: null,
            latitude: null,
            street: val.street,
            postalCode: val.postalCode,
            id: parseIdFromLink(pin._links.self.href, 0, '{?projection}'),
          });
        }
      }
    });
  });
  return { newCitys, deleteCitys, editPins };
};

const isProtectFields = (isLocked, isPublished) => {
  //Used in AddEditStore, and useAdminLogData.  Standard bool for lock however because this is an added item
  //some store may not have a lock field set yet.  Thus the isPublished is used as a fallback.
  //Business rules as of 2023 - certain storefields lock AFTER the first time a store is published.

  //Clause to hand legacy stores that dont have a lock field set yet.
  if (isLocked === null) return isPublished;
  //Lock is the prefered field to use.
  if (isLocked) return true;
  return false;
};

export { isProtectFields, determinePinChanges };
