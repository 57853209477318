import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Divider, IconButton } from '@material-ui/core';
import { openStatus, formatPhoneNumber, handleImage } from '../utils/helpers';
import Rating from '@material-ui/lab/Rating';
import { useHistory } from 'react-router-dom';
import { parseIdFromLink } from '../utils/helpers';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import SimpleCard from "../components/SimpleCard";
import { Typography, Fab, Button } from '@material-ui/core';
import api from '../utils/api';
import { NAV_OPTIONS, STATUS } from '../utils/constants';
import { Call, Email, Share /*, Add*/ } from '@material-ui/icons';

const getStore = async (id) => {
  return api
    .getStore(id) //getStoreDetailedProjection
    .then((res) => res.data)
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  container: {
    width: '100%',
    marginTop: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    //cursor: "pointer",
  },
  storeImage: {
    width: '80px',
    height: '80px',
    marginRight: '10px',
    marginLeft: '10px',
  },
  storeInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flexStart',
    fontSize: '11px',
  },
  storeTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  storeActionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '10px',
  },
  storeActionButton: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 0px',
  },
  storeProfile: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #E3E3E3',
    paddingBottom: '10px',
  },
}));

export default function MapsStoreProfile(props) {
  const styles = useStyles();

  const { store, setClickedStoreData, location } = props;

  const history = useHistory();
  const [storeDetailsData, setStoreDetailsData] = useState({});
  const [openTooltip, setOpenTooltip] = useState(false);
  const [loading, setLoading] = useState(true);

  // here we need to pass the slug in to /store/:id/:storeSlug
  const itemPath = `${NAV_OPTIONS.find((option) => store.category.includes(option.name))?.path}/${location.province}/${
    location.city
  }/${parseIdFromLink(store._links.self.href, 0, '{?projection}')}/${store.slug}`;
  console.log('itempath', itemPath);

  const handleStoreRedirect = (event) => {
    history.push(itemPath);
  };

  useEffect(() => {
    getStore(parseIdFromLink(store._links.self.href))
      .then((res) => {
        if (res.published) {
          setStoreDetailsData(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [store]);

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 3000);
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'flex-row' }}>
        <IconButton
          style={{ marginLeft: '5px' }}
          onClick={() => {
            setClickedStoreData([]);
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h6" style={{ color: 'rgb(74,74,74)', marginTop: '9px' }}>
          {'Back'}
        </Typography>
      </div>
      <Divider variant="middle" />

      <div id={parseIdFromLink(store._links.self.href)} className={styles.container}>
        <div className={styles.storeProfile}>
          <img src={handleImage(store.storeImage)} alt="store-logo" className={styles.storeImage} />
          <div className={styles.storeInfo}>
            <span className={styles.storeTitle} style={{ cursor: 'pointer' }} onClick={(e) => handleStoreRedirect(e)}>
              {store.name}
            </span>
            <span>{store.category.replaceAll(',', ', ')}</span>
            <span>{`${location.city} , ${location.province}`}</span>
            {!loading && <span>{formatPhoneNumber(storeDetailsData.phone)}</span>}
            {openStatus(store.hours) && <span style={{ color: 'green' }}>Open </span>}
            {!openStatus(store.hours) && <span style={{ color: 'red' }}>Closed </span>}
            <Rating name="read-only" value={store.avgRating} precision={0.5} size="small" readOnly />
            {`${Number(store.avgRating).toFixed(2)} (${store.numOfReviews} reviews)`}
          </div>
        </div>
        {!loading && (
          <div className={styles.storeActionButtons} id="store-actions">
            <Tooltip title="Call" bottom="true" arrow>
              <Fab
                color="default"
                size="small"
                aria-label="call"
                className={styles.actionButton}
                href={`tel:${storeDetailsData.phone}`}
                style={{ color: '#000000', textDecoration: 'inherit' }}
              >
                <Call />
              </Fab>
            </Tooltip>
            <Tooltip title="Email" bottom="true" arrow>
              <Fab
                color="default"
                size="small"
                aria-label="email"
                className={styles.actionButton}
                href={`mailto:${storeDetailsData.email}`}
                style={{ color: '#000000', textDecoration: 'inherit' }}
              >
                <Email />
              </Fab>
            </Tooltip>

            <Tooltip title={openTooltip ? 'Copied Store URL' : 'Share URL'} bottom="true" arrow>
              <CopyToClipboard className={styles.actionButton} text={itemPath}>
                <Fab color="default" size="small" aria-label="share" onClick={handleTooltipOpen}>
                  <Share />
                </Fab>
              </CopyToClipboard>
            </Tooltip>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="primary" style={{ marginTop: '20px' }} onClick={(e) => handleStoreRedirect(e)}>
            Go to Store Page
          </Button>
        </div>
      </div>
    </div>
  );
}
