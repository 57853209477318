import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { DAYS_OF_THE_WEEK, HOURS_OF_THE_DAY } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  hoursDayOfWeek: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '0.5rem',
    },
  },
  hoursActionButton: {
    minWidth: '150px',
    height: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '0.5rem',
    },
  },
}));

export default function BusinessHoursPicker({ formState, setFormState }) {
  const classes = useStyles();
  const copyPreviousDaysHours = (index) => {
    const previousIndex = index === 0 ? 6 : index - 1;

    const currentDay = DAYS_OF_THE_WEEK[index];
    const previousDay = DAYS_OF_THE_WEEK[previousIndex];

    setFormState({
      ...formState,
      hours: {
        ...formState.hours,
        [`${currentDay}`]: {
          open: formState.hours[`${previousDay}`].open,
          close: formState.hours[`${previousDay}`].close,
        },
      },
    });
  };

  return (
    <div className="daysContainer">
      {DAYS_OF_THE_WEEK.map((day, index) => {
        const isClosedHidden =
          formState.hours[`${day}`]?.open === 'Closed' || formState.hours[`${day}`]?.open === '24 Hours';

        return (
          <div key={`picker-${day}`} className={classes.hoursDayOfWeek}>
            <FormControl fullWidth variant="filled" className={classes.formControl}>
              <InputLabel shrink id={`${day.toLowerCase()}-open-label`}>
                {`${day} Open`}
              </InputLabel>
              <Select
                labelId={`${day.toLowerCase()}-open-label`}
                id={`${day.toLowerCase()}-open-select`}
                value={formState.hours[`${day}`].open}
                onChange={(e) => {
                  const newOpeningTime = e.target.value;
                  const newTimes = {
                    open: newOpeningTime,
                  };
                  if (newOpeningTime === 'Closed') newTimes['close'] = ''; //Clear 2nd box if closed

                  setFormState({
                    ...formState,
                    hours: {
                      ...formState.hours,
                      [`${day}`]: {
                        ...formState.hours[`${day}`],
                        ...newTimes,
                      },
                    },
                  });
                }}
              >
                {HOURS_OF_THE_DAY.map((hour) => (
                  <MenuItem
                    key={hour}
                    label="open"
                    variant="outlined"
                    value={hour}
                    selected={formState.hours[`${day}`].open}
                  >
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!isClosedHidden && (
              <FormControl fullWidth variant="filled" className={classes.formControl}>
                <InputLabel shrink id={`${day.toLowerCase()}-close-label`}>
                  {`${day} Close`}
                </InputLabel>
                <Select
                  labelId={`${day.toLowerCase()}-close-label`}
                  id={`${day.toLowerCase()}-close-select`}
                  value={formState.hours[`${day}`].close}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      hours: {
                        ...formState.hours,
                        [`${day}`]: {
                          ...formState.hours[`${day}`],
                          close: e.target.value,
                        },
                      },
                    })
                  }
                >
                  {HOURS_OF_THE_DAY.slice(2).map((hour, index) => (
                    <MenuItem key={hour} label="close" variant="outlined" value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {index === 0 && (
              <Button
                className={classes.hoursActionButton}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  const openTime = formState.hours.Sunday.open;
                  const closingTime = formState.hours.Sunday.close;
                  const newHours = {};
                  DAYS_OF_THE_WEEK.forEach((day) => {
                    newHours[day] = {}; //Create Day first before assiging data.
                    newHours[day]['open'] = openTime;
                    newHours[day]['close'] = closingTime;
                  });
                  setFormState((prev) => {
                    return {
                      ...prev,
                      hours: newHours,
                    };
                  });
                }}
              >
                Copy to All
              </Button>
            )}
            {index > 0 && (
              <Button
                className={classes.hoursActionButton}
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => copyPreviousDaysHours(index)}
              >
                Copy Previous
              </Button>
            )}
          </div>
        );
      })}
    </div>
  );
}
