import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles /*, Checkbox*/ } from '@material-ui/core';
import api from '../utils/api';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Modal, Backdrop, Fade } from '@material-ui/core';
import cookies from '../cookies';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  endAdornment: {
    color: theme.palette.grey.A700,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  registerMsg: {
    margin: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  actionButtons: { display: 'flex' },
  confirmationMessage: {
    margin: theme.spacing(2),
  },
  confirmationButton: {
    color: theme.palette.common.white,

    margin: theme.spacing(2),
  },
  okconfirmationButton: {
    color: theme.palette.common.black,
    backgroundColor: '#DDDDDD',
    margin: theme.spacing(2),
  },
}));

export default function VerifiedEmailModal(props) {
  const [email, setEmail] = useState('');
  const [resendError, setResendError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [buttonText, setButtonText] = useState('resend');
  const [timer, setTimer] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const resendConfirmEmail = async (email) => {
    return api
      .sendConfirmation(email)
      .then((res) => {
        setResendError(false);
        setMsg('Email Confirmation resent');
        setErrorMsg('');
      })
      .catch((error) => {
        setResendError(true);
        if (error.message.includes('User')) {
          setErrorMsg('There is no account associated to this email address.');
          setTimer(false);
          setButtonText('Resend');
          //setSubmitted(false);
        }
        //setMsg("Please enter a valid email address to resend a verification email.")
      });
  };
  useEffect(() => {
    api
      .confirmEmail(token)
      .then(() => {
        setVerified(true);
        setMsg('Email verified. Please press OK to continue to the login screen.');
      })
      .catch((error) => {
        setMsg('Unfortunately there was an issue verifying your email.');
      });
  }, [token]);
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        setOpen(false);
        window.location.assign('/');
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <Typography className={classes.confirmationMessage}>{msg.toString()}</Typography>
          <TextField
            hidden={true ? verified : false}
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="email"
            label="Email"
            type={'text'}
            id="email"
            autoComplete="current-email"
            error={(!validateEmail(email) && submitted) || resendError}
            helperText={errorMsg}
          />
          <div className={classes.actionButtons}>
            <Button
              variant="contained"
              color="primary"
              disabled={!verified && resendError}
              onClick={() => {
                setOpen(false);
                cookies.removeJWTAndUserID();
                window.location.assign('/'); //no login page in beautimap send to home in all cases
              }}
              className={classes.okconfirmationButton}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              hidden={true ? verified : false}
              className={classes.confirmationButton}
              disabled={!validateEmail(email) || verified || timer}
              onClick={() => {
                resendConfirmEmail(email);
                setSubmitted(true);
                //if(!error){
                setTimer(true);
                setButtonText('60s for next Resend');
                setTimeout(() => {
                  setTimer(false);
                }, 60000);
                // }
              }}
            >
              {buttonText}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
