import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexDirection: 'column' },
  headerElement: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 4, 2, 4),
    alignSelf: 'center',
    fontSize: '24pt',
  },
  headerCaption: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 4, 2, 4),
    alignSelf: 'center',
  },
  subHeaderElement: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 4, 2, 4),
    fontSize: '16pt',
  },
  subSubHeaderElement: {
    fontWeight: 'bold',
    margin: theme.spacing(2, 4, 2, 4),
  },
  element: {
    margin: theme.spacing(2, 4, 2, 4),
  },
  bulletpoint: {
    margin: theme.spacing(2, 4, 2, 8),
  },
}));

function PrivacyPolicyContent() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.headerElement} variant="h1">
        About Us
      </Typography>

      <Typography className={classes.subHeaderElement}>Who Are We</Typography>
      <Typography className={classes.element}>
        Beautimap is a quality and reliable online business-listing portal founded with a mission, vision, and a strong
        purpose. Created in 2021, Beautimap, as the name suggests is a platform that was designed to build a directory
        of local beauty businesses. Our vision is to empower professionals, entrepreneurs, and business owners in the
        beauty sector and help build a strong community of supported local businesses. We strongly believe in fostering
        connections and support to the beauty businesses around us and have a mission to truly create a difference in
        their lives by helping to connect them with their dream clients.
      </Typography>
      <Typography className={classes.element}>
        “Get Connected To The Beauty Around You” is our tagline and paramount in our brand's culture and overall
        identity.
      </Typography>

      <Typography className={classes.subHeaderElement}>What Do We Do</Typography>
      <Typography className={classes.element}>
        Beautimap is your go-to place and the ultimate destination to find all your beauty solutions under one roof. It
        is a centralized beauty hub where customers can find and be connected to a wide range of beauty services. We
        help advertise and market local salons, freelance artists, entreprenuers and businesses. Our audience is then
        able to select the best verified and skilled spot that would best suit them.
      </Typography>
      <Typography className={classes.element}>
        As a customer, you can filter based on the location, types of services offered, a deal/price of the service,
        reviews of previous customers and friends and more. Our range of top beauty industry categories, makes it
        extremely easy for you to navigate and find exactly what you are looking for. Once you lock in all your desired
        preferences, Beautimap will show you the best matches that fit your exact requirements and that’s it, you’re
        ready to connect!
      </Typography>
      <Typography className={classes.element}>
        As a beauty business, this is your one stop shop to connect with your dream clients. Once you register your
        business, you can create a mini website and put your mark on the map. Tell the world what you’re about, what
        services you offer, your pricing, hours, showcase your work and much more. Future clients can instantly connect
        with you with our connection features and eventually, will be able to book directly through the site.
      </Typography>
      <Typography className={classes.element}>
        Beautimap has been designed keeping the owners, businesses and customers in mind. Everything revolves around
        what can make connecting both businesses and customers to each other as easy as possible. We have made the
        interface extremely user friendly and seamless to operate with. We may be starting in your local cities across
        Canada, we are operating in the entire North American region and we have our eyes set on bigger things.
      </Typography>

      {/*  <Typography className={classes.subHeaderElement}>Our Core Values</Typography>
      <Typography className={classes.element}>
        What is a business without its core and the most fundamental values- empty, absolutely nothing! Presenting to
        you our mantra and values that are etched in our hearts forever.
      </Typography> */}
      <Typography className={classes.subHeaderElement}>Accountability</Typography>
      <Typography className={classes.element}>
        At Beautimap, staying truthful in all the procedures and actions that we carry out is in the veins of not only
        our founder but every member of this company. We truly believe this as an uncompromisable core value that keeps
        us always true to our customers, authentic, and thereby accountable to them.
      </Typography>
      <Typography className={classes.subHeaderElement}>Customer Satisfaction</Typography>
      <Typography className={classes.element}>
        We at Beautimap often outshine ourselves and our previous moves when it comes to providing top-quality service.
        Yes, that’s the kind of effort we take to constantly deliver ear-to-ear smiles on our customer’s faces. We
        ensure to go that extra mile ahead in order to ensure customer satisfaction through service excellence.
      </Typography>
    </div>
  );
}

export default PrivacyPolicyContent;
