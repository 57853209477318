import React, { useEffect, useState } from "react";
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
import Typography from "@material-ui/core/Typography";
// import CloseIcon from '@material-ui/icons/Close';
// import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core/styles";
import AddEditStore from "../components/AddEditStore";
import { getUserID } from "../cookies";
// import {data} from '../data';
import api from "../utils/api";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",
		padding: theme.spacing(4, 0, 4, 0),
	},
	contentInnerContainer: {
		width: "100%",
		maxWidth: theme.screen.maxWidth,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	title: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		// color:'red',
		width: "100%",
		marginBottom: theme.spacing(4),
		alignItems: "center",
	},
	closeIcon: {
		"&:hover": {
			cursor: "pointer",
			color: theme.palette.secondary.dark,
		},
	},
	formContainer: {
		padding: theme.spacing(2),
		width: "100%",
	},
	bold: {
		fontWeight: 700,
	},

	// title: {
	//   marginLeft: theme.spacing(2),
	//   flex: 1,
	// },
}));

const userId = getUserID();
function AddEditStoreView(props) {
	const [user, setUser] = useState({});
  const [store, setStore] = useState(false);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		api.getUser(userId).then((res) => {
			setUser({ ...user, current: res });
      res._embedded.stores ? setStore(true) : setStore(false);
			setLoading(false);
		});
		// eslint-disable-next-line
	}, []);
	// const selectedData = businessesData.find(item => item.id === selectedId);
	// console.log('selectedData in form', selectedData);

	const classes = useStyles();
	return (
		<div>
			{loading && <CircularProgress />}
			{!loading && (
				<div className={classes.root}>
					{store && <Typography>Cannot create more than 1 store per account</Typography>}
					{!store && <Container className={classes.contentInnerContainer}>
						<div className={classes.title}>
							<Typography variant="h4" className={classes.bold}>
								Create Store
							</Typography>
							{/* <CancelIcon color='secondary' className={classes.closeIcon} onClick={handleClose}></CancelIcon> */}
						</div>
						<Paper elevation={5} className={classes.formContainer}>
							<AddEditStore addMode={true}></AddEditStore>
						</Paper>
					</Container>}
				</div>
			)}
		</div>
	);
}

export default AddEditStoreView;
