import React from 'react';
import { makeStyles, Card, Typography, useMediaQuery } from '@material-ui/core';
import { handleImage, parseIdFromLink } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mobileRoot: {
    fontSize: '9pt',
    display: 'flex',
    flexDirection: 'column',
    margin: '2px',
    padding: '2px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    height: 60,
    width: 60,
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      //display: "none",
    },
  },
  condensedText: {
    fontSize: '9pt',
  },
  mobileLogo: {
    height: 50,
    width: 50,
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
  },

  active: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  expired: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  upcoming: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
}));

function SortItem(props) {
  const classes = useStyles();

  const { item, type, pinned, brand } = props;

  let dealStatus = '';

  const mobile = useMediaQuery('(max-width:500px)');

  // if (["Deal", "RecommendedDeal", "FeaturedDeal"].includes(type)) {
  //   //console.log("TYPE: ", type)
  //   if (new Date() > convertToDate(item.endDate)) { // was previous
  //     dealStatus = "Expired";
  //   } else if (new Date() < convertToDate(item.startDate)) {
  //     dealStatus = "Upcoming";
  //   } else {
  //     dealStatus = "Active";
  //   }
  // }

  return (
    <Card className={mobile ? classes.mobileRoot : classes.root} style={{ backgroundColor: pinned ? '#f2d8bd' : '' }}>
      <div className={classes.details}>
        <Typography variant="body2" className={mobile ? classes.condensedText : ''}>
          {item.name}
        </Typography>

        <Typography color="textSecondary" className={mobile ? classes.condensedText : ''}>
          {item.storeName}
        </Typography>

        <Typography color="textSecondary" variant="body2" hidden={mobile}>
          Owned By: {item.contactFirstName} {item.contactLastName}
        </Typography>

        <Typography color="textSecondary" hidden={mobile}>
          {['Deal', 'RecommendedDeal', 'FeaturedDeal'].includes(type) ? 'Deal ' : ''}
          ID: {parseIdFromLink(item._links.self.href.replace('{?projection}', ''))}
        </Typography>
        <Typography
          variant="body2"
          // color="textSecondary"
          className={
            dealStatus === 'Active'
              ? classes.active
              : dealStatus === 'Expired'
              ? classes.expired
              : dealStatus === 'Upcoming'
              ? classes.upcoming
              : ''
          }
        >
          {/* {(["Deal", "RecommendedDeal", "FeaturedDeal"].includes(type)) && dealStatus === "Active"
            ? "Active"
            : (["Deal", "RecommendedDeal", "FeaturedDeal"].includes(type)) && dealStatus === "Expired"
              ? "Expired"
              : (["Deal", "RecommendedDeal", "FeaturedDeal"].includes(type)) && dealStatus === "Upcoming"
                ? "Upcoming"
                : ""} */}
        </Typography>
      </div>
      <img
        className={mobile ? classes.mobileLogo : classes.logo}
        src={handleImage(brand ? item.brandImage : item.storeImage, { placeholderType: 'storeLogo' })}
        alt={item.name}
      />
    </Card>
  );
}

export default SortItem;
