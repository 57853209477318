import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import LandingPage from './views/LandingPage';
import BusinessView from './components/BusinessView/Index';
import CategoryView from './views/CategoryView';
import DealsView from './views/DealsView';
import CustomerRegisterView from './views/CustomerRegisterView';
// import UserRegisterProfileView from './views/UserRegisterProfileView';
import UserProfileView from './views/UserProfileView';
// import LoginView from './views/LoginView';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { NAV_OPTIONS, ROLES } from './utils/constants';
import cookies from './cookies';
import AdminHomeView from './views/AdminHomeView';
import ManageUsersView from './views/ManageUsersView';
import ManageFeaturedPopularView from './views/ManageFeaturedPopularView';
import ManageStoresView from './views/ManageStoresView';
import AddEditStoreView from './views/AddEditStoreView';
import AddEditUserView from './views/AddEditUserView';
import AddEditDealView from './views/AddEditDealView';
import MyDealsView from './views/MyDealsView';
import ManageListingOrderView from './views/ManageListingOrderView';
import PreviewView from './views/PreviewView';
import ManageCategoryStoresView from './views/ManageCategoryStoresView';
import { StateProvider } from './state';
import VerifiedEmailModal from './components/VerifiedEmailModal';
import ClientAddStoreView from './views/ClientAddStoreView';
import ForgotPasswordView from './views/ForgotPasswordView';
import ChangePasswordView from './views/ChangePasswordView';
import PrivacyPolicyView from './views/PrivacyPolicyView';
import ScrollToTop from './components/ScrollToTop';
import MapsPageView from './views/MapsPageView';
import AboutUsView from './views/AboutUsView';
import ManageBannersView from './views/ManageBannersView';
import DealDetailsView from './views/DealDetailsView';
import ManageFeaturedDealsView from './views/ManageFeaturedDealsView';
import AppointmentsView from './views/AppointmentsView';
import AdminLogsProvider from './components/AdminLogs/context/AdminLogsContext';
import AdminLogsView from './components/AdminLogs/AdminLogsView.js';
import SignUpPrompt from './components/SignUpPrompt/Index.js';

// Create context for user data
// Create context for location data

function App(props) {
  // const classes = useStyles();
  const [JWT, setJWT] = useState(cookies.getJWT()); //eslint-disable-line
  const [userId, setUserId] = useState(cookies.getUserID()); //eslint-disable-line
  const [role, setRole] = useState(cookies.getRole()); //eslint-disable-line

  const isLoggedIn = !!JWT && !!userId;
  const hideSignUpPrompt = cookies.getSignUpPrompt() === 'true' || isLoggedIn;
  const [showSignUpPrompt, setShowSignUpPrompt] = useState(!hideSignUpPrompt);

  // const { location, updateUserLocation } = useClientLocation();

  // console.log('APP LOCATION', location);
  // if (!location) {
  //   console.log("location doesn't exist");
  //   // updateUserLocation();
  //   return <div>LOADING</div>; // return for new rerender
  // }
  // let location = cookies.getLocation();

  // if (!location) {
  //   location = {
  //     province: 'Ontario',
  //     city: 'London',
  //     link: '',
  //   };
  //   cookies.setLocation(location);
  // }

  // function geoSuccess(userPos) {
  //   console.log('success userPos', userPos);
  //   const crd = userPos.coords;
  //   console.log('getting city...');
  //   // Axios.get(
  //   //   `https://nominatim.openstreetmap.org/reverse/?lat=${crd.latitude}&lon=${crd.longitude}format=json&addressdetails=1`
  //   // )
  //   //   .then((res) => {
  //   //     // console.log('openstreet res', res);
  //   //     if (res.data[0] && res.data[0].address && res.data[0].address.state) {
  //   //     }
  //   //   })
  //   //   .catch((err) => {
  //   //     // console.log('failed to get city', err);
  //   //   });
  // }

  // function geoError(err) {
  //   console.log('err', err);
  //   console.warn(`ERROR(${err.code}): ${err.message}`);

  //   cookies.setRegion('');
  // }

  // if (!cookies.getRegion()) {
  //   try {
  //     navigator.geolocation.getCurrentPosition(geoSuccess, geoError);
  //   } catch {
  //     cookies.setRegion('');
  //   }
  // }

  let savedLocation = cookies.getLocation();

  // Context State - Is this actually used? << Seems to be called in nav bar - not sure where else
  const initialState = {
    location: savedLocation || { city: 'London', province: 'Ontario', link: '' }, //On first load use London, -[] TODO prompt user for location on first load
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeLocation':
        return {
          ...state,
          location: action.newLocation,
        };

      default:
        return state;
    }
  };

  // const [topLevelPath, setTopLevelPath] = useState(
  // 	"/" +
  // 		window.location.href.split("/")[3] +
  // 		(window.location.href.split("/")[4]
  // 			? "/" + window.location.href.split("/")[4]
  // 			: "")
  // );

  // const [previousTopLevel, setPreviousTopLevel] = useState("/");

  //Analytics - GA4 - only use in PROD Env
  if (process.env.REACT_APP_ENV === 'api.prod') ReactGA.initialize('G-M2ENPGYS5D');

  /* Refactor Category paths, be tweaking child to read the category prop and fixing the url to actually be a prop rather than a hard path
  should be able to minimize these
  ie ideal stores would be 
  /:category/:province/:city/:storeId/:storeName  where current is /${category}/:province/...  
  */
  const nav = NAV_OPTIONS.map((item, index) => {
    const url = item.path;
    return (
      <Route path={`${url}`} key={index} exact>
        <CategoryView category={item.name} />
      </Route>
    );
  });

  const navWithLocation = NAV_OPTIONS.map((item, index) => {
    const url = item.path;
    return (
      <Route
        key={index}
        path={`${url}/:province/:city`}
        component={(props) => <CategoryView category={item.name} {...props} />}
        exact
      />
    );
  });

  const stores = NAV_OPTIONS.map((category, index) => {
    const url = category.path;
    return (
      <Route
        key={index}
        path={`${url}/:province/:city/:storeId`}
        component={(props) => <BusinessView category={category.name} {...props} />}
        exact
      />
    );
  });

  const storesWithNames = NAV_OPTIONS.map((category, index) => {
    const url = category.path;
    return (
      <Route
        key={index}
        path={`${url}/:province/:city/:storeId/:storeName`}
        component={(props) => <BusinessView category={category.name} {...props} />}
        exact
      />
    );
  });

  const closeWelcomePrompt = async (options) => {
    const hidePrompt = options.hidePrompt;
    await cookies.setSignUpPrompt(hidePrompt ? 30 : 1);
    //Close welcome modal
    setShowSignUpPrompt(false);
  };

  const isSuperAdmin = JWT && userId && role === ROLES.ROLE_SUPER_ADMIN;

  return (
    <ThemeProvider theme={theme}>
      <StateProvider initialState={initialState} reducer={reducer}>
        <AdminLogsProvider mode={isSuperAdmin ? 'admin' : null}>
          <div id="appRoot" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Router>
              <ScrollToTop />
              <NavBar />
              <Switch>
                <Route exact path="/">
                  <LandingPage />
                </Route>
                {!JWT && (
                  <Route path="/signup">
                    <CustomerRegisterView />
                  </Route>
                )}
                {/* <Route path="/store-signup"><UserRegisterProfileView /></Route> */}
                {JWT && userId && (
                  <Route path="/user-profile">
                    <UserProfileView />
                  </Route>
                )}
                {JWT && userId && (
                  <Route path="/client/user-profile">
                    <UserProfileView />
                  </Route>
                )}
                {JWT && userId && (
                  <Route path="/admin/user-profile">
                    <UserProfileView />
                  </Route>
                )}
                <Route path={'/users/confirmEmail'}>
                  <VerifiedEmailModal></VerifiedEmailModal>
                </Route>
                {role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path={'/maps'}>
                    <MapsPageView admin={true}></MapsPageView>
                  </Route>
                )}
                <Route path={'/maps'}>
                  <MapsPageView admin={false}></MapsPageView>
                </Route>
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin" exact>
                    <AdminHomeView />
                  </Route>
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/users" exact>
                    <ManageUsersView />
                  </Route>
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/users/new" exact>
                    <AddEditUserView />
                  </Route>
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    path="/admin/users/search/:usernameText"
                    component={(props) => (
                      <ManageUsersView search={true} usernameText={props.match.params.usernameText} {...props} />
                    )}
                  />
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    path="/admin/users/search"
                    component={(props) => <ManageUsersView search={false} usernameText="" {...props} />}
                  />
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/users/:id" component={(props) => <AddEditUserView editMode {...props} />} />
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/featured-popular" exact>
                    <ManageFeaturedPopularView />
                  </Route>
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/banners" exact>
                    <ManageBannersView />
                  </Route>
                )}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/logs" component={(props) => <AdminLogsView />} />
                )}

                {/* moved admin/stores to the bottom of all admin/store... routes */}
                {/* {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
								<Route path="/admin/stores" exact>
									<ManageStoresView adminMode />
								</Route>
							)} */}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/search/:nameText"
                    component={(props) => (
                      <ManageStoresView search={true} nameText={props.match.params.nameText} adminMode {...props} />
                    )}
                  />
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/search"
                    component={(props) => <ManageStoresView search={false} nameText="" adminMode {...props} />}
                  />
                )}

                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:categoryName/search/:nameText"
                    component={(props) => (
                      <ManageCategoryStoresView
                        search={true}
                        nameText={props.match.params.nameText}
                        adminMode
                        {...props}
                      />
                    )}
                  />
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:categoryName/search"
                    component={(props) => <ManageCategoryStoresView search={false} nameText="" adminMode {...props} />}
                  />
                )}

                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:categoryName"
                    component={(props) => <ManageCategoryStoresView adminMode {...props} />}
                  />
                )}

                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/new-store" exact>
                    <AddEditStoreView />
                  </Route>
                )}

                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    path="/admin/edit-store/:storeId"
                    component={(props) => <AddEditStoreView editMode {...props} />}
                  />
                )}
                {JWT && (role === ROLES.ROLE_SUPER_ADMIN || role === ROLES.ROLE_STORE_ADMIN) && (
                  <Route path="/client/preview/stores/:storeId" component={(props) => <PreviewView {...props} />} />
                )}
                {JWT && role === ROLES.ROLE_STORE_ADMIN && (
                  <Route path="/client/new-store" exact>
                    <ClientAddStoreView />
                  </Route>
                )}
                {JWT && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/listing-order" exact>
                    <ManageListingOrderView />
                  </Route>
                )}
                {/* beaut-126 */}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:id/deals/new"
                    component={(props) => <AddEditDealView adminMode {...props} />}
                  />
                )}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:id/deals/:dealId"
                    component={(props) => <AddEditDealView adminMode editMode {...props} />}
                  />
                )}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    exact
                    path="/admin/stores/:id/deals"
                    component={(props) => <MyDealsView adminMode {...props} />}
                  />
                )}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route exact path="/admin/stores" component={(props) => <ManageStoresView adminMode {...props} />} />
                )}
                {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                  <Route
                    path="/client/edit/stores/:id"
                    component={(props) => {
                      const storeId = props.match.params.id;
                      return <AddEditStoreView previewMode={true} previewId={storeId} editMode {...props} />;
                    }}
                  />
                )}
                {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                  <Route path="/client/stores/:id/deals/new" component={(props) => <AddEditDealView {...props} />} />
                )}
                {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                  <Route
                    path="/client/stores/:id/deals/:dealId"
                    component={(props) => <AddEditDealView editMode {...props} />}
                  />
                )}
                {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                  <Route path="/client/stores/:id/deals" component={(props) => <MyDealsView {...props} />} />
                )}

                {/* beaut-151 */}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/featured-deals" component={(props) => <ManageFeaturedDealsView {...props} />} />
                )}

                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route
                    path="/admin/edit-store/:storeId"
                    component={(props) => <AddEditStoreView editMode {...props} />}
                  />
                )}
                {JWT && (role === ROLES.ROLE_SUPER_ADMIN || role === ROLES.ROLE_STORE_ADMIN) && (
                  <Route
                    exact
                    path="/client/preview/stores/:storeId"
                    component={(props) => <PreviewView {...props} />}
                  />
                )}
                {JWT && (role === ROLES.ROLE_SUPER_ADMIN || role === ROLES.ROLE_STORE_ADMIN) && (
                  <Route
                    exact
                    path="/client/preview/stores/:storeId/appointments"
                    component={(props) => <AppointmentsView {...props} />}
                  />
                )}

                {JWT && role === ROLES.ROLE_STORE_ADMIN && (
                  <Route path="/client/new-store" exact>
                    <ClientAddStoreView />
                  </Route>
                )}

                {/* beaut-120 DealsPage */}
                <Route path={'/deals/:province/:city'} component={(props) => <DealsView {...props} />} exact></Route>

                <Route
                  path={'/deals/:province/:city/:id/:slug'}
                  component={(props) => <DealDetailsView {...props} />}
                  exact
                ></Route>

                {JWT && userId && [ROLES.ROLE_SUPER_ADMIN, ROLES.ROLE_STORE_ADMIN].includes(role) && (
                  <Route
                    path="/client/stores/:storeId/appointments"
                    exact
                    component={(props) => <AppointmentsView {...props} />}
                  />
                )}

                {/* beaut-151 */}
                {JWT && userId && role === ROLES.ROLE_SUPER_ADMIN && (
                  <Route path="/admin/featured-deals" component={(props) => <ManageFeaturedDealsView {...props} />} />
                )}

                <Route path={'/privacy-policy'}>
                  <PrivacyPolicyView />
                </Route>
                <Route path={'/about-us'}>
                  <AboutUsView />
                </Route>

                <Route path={'/forgotPassword'} component={(props) => <ForgotPasswordView {...props} />} />
                <Route path={'/users/changePassword'} component={(props) => <ChangePasswordView {...props} />}></Route>

                {/* beaut-120 DealsPage */}
                <Route path={'/deals/:province/:city'} component={(props) => <DealsView {...props} />} exact></Route>

                <Route
                  path={'/deals/:province/:city/:id/:slug'}
                  component={(props) => <DealDetailsView {...props} />}
                  exact
                ></Route>

                {nav}
                {navWithLocation}
                {stores}
                {storesWithNames}

                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Router>
            <Footer />
            <SignUpPrompt showWelcomePrompt={showSignUpPrompt} closeAction={closeWelcomePrompt} />
          </div>
        </AdminLogsProvider>
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
