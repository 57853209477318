import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography, Button } from '@material-ui/core';
import { FEATURED_TYPES, SCREEN_STATES } from '../utils/constants';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import { parseIdFromLink } from '../utils/helpers';
import PreviewContext from '../PreviewContext';

const useStyles = makeStyles((theme) => ({
  media: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundSize: 'contain',
    height: 150,
    [theme.breakpoints.down('md')]: {
      height: 120,
    },
    [theme.breakpoints.down('sm')]: {
      height: 96,
    },
  },
  ratingContainer: {
    display: 'flex',
    justifyContent: 'center',
    // alignContent: "center",
  },
  ratingValue: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      fontSize: '0.75rem',
    },
  },
  stars: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
  cardActionArea: {
    position: 'relative',
    paddingTop: '1rem',
    paddingRight: '0.75rem',
    paddingBottom: '1rem',
    paddingLeft: '0.75rem',
    background: '#E5E5E5',
    alignContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem',
    },
  },
  cardContent: {
    padding: 0,
  },
  dealStoreImage: {
    height: 80,
    width: 80,
    position: 'absolute',
    bottom: theme.spacing(6.5),
    right: theme.spacing(1.5),
    objectFit: 'contain',
    border: '1px solid #ebebeb',
    backgroundColor: '#ebebeb',
    [theme.breakpoints.down('sm')]: {
      height: 60,
      width: 60,
    },
  },
  mailOutBadge: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    width: '65px',
    height: '65px',
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(0.5),
      right: theme.spacing(0.5),
      width: '55px',
      height: '55px',
    },
  },
  cardTextTitle: {
    font: 'Quicksand',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.25rem',
    marginBottom: '0.5rem',
  },
  cardText: {
    font: 'Quicksand',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  viewMoreButton: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#EE333F',
    textTransform: 'none',
    borderRadius: '0px',
    marginTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
    },
  },
}));

function SimpleCard(props) {
  const { item, itemPath, type } = props;

  const classes = useStyles();

  // let dealStatus = "";
  // if (type === FEATURED_TYPES.DEAL) {
  //   if (new Date() > convertToDate(item.endDate)) {
  //     dealStatus = "Expired";
  //   } else if (new Date() < convertToDate(item.startDate)) {
  //     dealStatus = "Upcoming";
  //   } else {
  //     dealStatus = "Active";
  //   }
  // }

  const preview = useContext(PreviewContext);

  return (
    <Card
      component={!preview.state.screen ? Link : undefined}
      to={!preview.state.screen ? itemPath.replace('{?projection}', '') : undefined}
      style={{ color: '#000000', textDecoration: 'none', borderRadius: '0px' }}
      onClick={
        !preview.state.screen
          ? undefined
          : () =>
              preview.changeScreen(
                SCREEN_STATES.DEAL_DETAILS,
                parseIdFromLink(item._links.self.href, 0, '{?projection}')
              )
      }
    >
      <CardActionArea className={classes.cardActionArea}>
        {type === FEATURED_TYPES.DELIVERY && item.nationwide && (
          <img
            src={require(`../assets/navigation-icons/canadawideSticker.svg`)}
            alt={`navigation-icon-canada-wide`}
            className={classes.mailOutBadge}
          />
        )}
        <CardMedia
          className={classes.media}
          image={
            type === FEATURED_TYPES.DEAL
              ? item.dealImage
              : type === FEATURED_TYPES.BRAND
              ? item.brandImage
              : item.storeImage
          }
          title={item.name}
        />
        <CardContent className={classes.cardContent}>
          {/* {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="caption">
              {dealStatus}
            </Typography>
          )} */}
          {type === FEATURED_TYPES.DEAL && (
            <Typography noWrap variant="body1" className={classes.cardTextTitle}>
              {item.name}
            </Typography>
          )}
          <Typography
            noWrap
            variant={type === FEATURED_TYPES.DEAL ? 'body2' : 'body1'}
            className={
              [FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.BRAND].includes(type)
                ? classes.cardTextTitle
                : classes.cardText
            }
          >
            {type === FEATURED_TYPES.DEAL ? item.storeName : item.name}
          </Typography>

          {[FEATURED_TYPES.STORE, FEATURED_TYPES.DELIVERY, FEATURED_TYPES.BRAND].includes(type) && (
            <div className={classes.ratingContainer}>
              <Rating
                name="read-only"
                value={item.avgRating ? Number(item.avgRating) : 0}
                precision={0.5}
                size="small"
                className={classes.stars}
                readOnly
              />
              <Typography variant="body2" className={classes.ratingValue}>
                {`${item.avgRating ? Number(item.avgRating.toFixed(1)) : 0} (${
                  item.numOfReviews ? item.numOfReviews : 0
                })`}
              </Typography>
            </div>
          )}
          {type === FEATURED_TYPES.DEAL && (
            <div className={classes.ratingContainer}>
              <Rating
                name="read-only"
                value={item.store.avgRating ? Number(item.store.avgRating) : 0}
                precision={0.5}
                size="small"
                className={classes.stars}
                readOnly
              />
              <Typography variant="body2" className={classes.ratingValue}>
                {`${item.store.avgRating ? Number(item.store.avgRating.toFixed(1)) : 0} (${
                  item.store.numOfReviews ? item.store.numOfReviews : 0
                })`}
              </Typography>
            </div>
          )}
        </CardContent>
        {/* {type === FEATURED_TYPES.DEAL && (
          <img
            src={item.storeImage}
            className={classes.dealStoreImage}
            alt={item.storeName}
          ></img>
        )} */}
        <Button variant="contained" key={'View More'} className={classes.viewMoreButton}>
          View More
        </Button>
      </CardActionArea>
    </Card>
  );
}

export default SimpleCard;
