import { Fab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
export default function ScrollToTop() {
	const { pathname } = useLocation();
	const [isVisible, setIsVisible] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		// Button is displayed after scrolling for 500 pixels
		const toggleVisibility = () => {
			if (window.pageYOffset > 500) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	const scrollTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<div>
			{isVisible && (
				<Fab
					style={{
						backgroundColor: "#505050",
						position: "fixed",
						bottom: "25px",
						right: "25px",
						zIndex: 100
					}}
					size="small"
					aria-label="scroll back to top"
				>
					<KeyboardArrowUpIcon style={{ color: "white" }} onClick={scrollTop} />
				</Fab>
			)}
		</div>
	);
}
