/* Card to show appointment details with action bar to handle CRUD */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import format from 'date-fns/format';

import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined';
import CancelIcon from '@material-ui/icons/CancelSharp';
import { formatPhoneNumber } from '../../../utils/helpers';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    padding: 12,
    borderRadius: 12,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
}));

export default function ApptDetailsCard({ appointment, actions }) {
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);

  if (!appointment) return null;

  const startDate = new Date(appointment.startDateTime);
  const endDate = new Date(appointment.endDateTime);
  const calendarDay = format(startDate, 'EEEE, dd MMMM');

  const apptDetails = (
    <>
      <CardActions className={classes.actionsContainer}>
        <EditIcon
          onClick={() => {
            actions.setAppointmentToEdit(appointment);
            actions.handleCloseDetailsPopever();
          }}
        />
        <DeleteForeverIcon
          onClick={() => {
            setConfirmDelete(true);
          }}
        />
        <CancelIcon
          onClick={() => {
            actions.handleCloseDetailsPopever();
          }}
        />
      </CardActions>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} gutterBottom>
          {`${appointment.service} with ${appointment.customerName}`}
        </Typography>
        <Typography>
          {calendarDay} - {format(startDate, 'hh:mm a')} - {format(endDate, 'hh:mm a')}
        </Typography>
        <Typography>Phone: {formatPhoneNumber(appointment.customerPhone)}</Typography>
        {appointment.notes && (
          <Typography style={{ marginTop: '8px' }}>
            Notes:
            <br />
            {appointment.notes}
          </Typography>
        )}
      </CardContent>
    </>
  );

  const deleteConfirmation = (
    <>
      <CardContent className={classes.cardContent}>Are you sure you want to delete this appointment?</CardContent>
      <CardActions className={classes.actionsContainer}>
        <Button variant="contained" onClick={() => setConfirmDelete(false)}>
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            const apptIdLink = appointment.id;
            actions.deleteAppointment(apptIdLink);
            actions.handleCloseDetailsPopever();
          }}
        >
          Delete
        </Button>
      </CardActions>
    </>
  );

  return <Card className={classes.root}>{!confirmDelete ? apptDetails : deleteConfirmation}</Card>;
}
