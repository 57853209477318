import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
// import SearchInput from './SearchInput';
import LoginPopover from './LoginPopover';
import ProfilePopover from './ProfilePopover';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MapIcon from '../assets/icons/location.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import cookies from '../cookies';
import { useStateValue } from '../state';
import api from '../utils/api';
import { NAV_OPTIONS, STATUS } from '../utils/constants';
import MenuIcon from '@material-ui/icons/Menu';
import { capitalizeFirstOfEach, navbarTabLink, parseIdFromLink } from '../utils/helpers';
import LinkBar from './LinkBar';
import LogNotification from './AdminLogs/LogNotification';
import useClientLocation from '../hooks/useClientLocation';
// import theme from '../theme';

const appBarTopHeight = 60;
const appBarBottomHeight = 56;

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    fontColor: theme.palette.common.font,
  },
  logoLink: {
    // marginLeft: '10px',
    // [theme.breakpoints.down('md')]: {
    // margin: 'auto',
    // paddingRight: '2px',
    // marginTop: '15px',
    // },
  },
  appLogo: {
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      marginLeft: '6px',
    },
  },
  appBar: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 0px',
    backgroundColor: theme.palette.common.background,
  },
  appBarTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: appBarTopHeight,
    backgroundColor: theme.palette.common.background,
    boxShadow: '0px 0px',
    padding: theme.spacing(4, 0),
  },
  appBarTopInner: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // [theme.breakpoints.down('md')]: {
    // justifyContent: 'center',
    // },
  },
  appBarBottom: {
    backgroundColor: theme.palette.common.white,
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    boxShadow: '0px 0px',
  },
  appBarBottomInner: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  drawerButton: {
    color: theme.palette.primary.dark,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  adminLink: {
    color: 'white',
    textDecoration: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    //On Hover remove underline, keep color the same
    '&:hover': {
      textDecoration: 'underline',
      color: 'white',
    },
  },
  bold: {
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  logo: {
    marginRight: '15px',
    width: '22px',
    height: '23px',
    fontWeight: 'bold',
  },
  profileIcon: {
    color: theme.palette.common.font,
  },
  firstItem: {
    flexGrow: 1,
  },
  locationItem: {
    display: 'flex',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    color: theme.palette.common.font,
  },
  itemDealsButton: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    // [theme.breakpoints.down('sm')]: {
    //   width: '50px',
    // },
  },
  dealsButton: {
    fontFamily: 'Quicksand',
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: '#FFFFFF',
    backgroundColor: '#EE333F',
    borderRadius: '0px',
  },
  spacer: {
    margin: theme.spacing(0, 1, 0, 1),
    color: theme.palette.common.font,
  },
  offset: {
    flexGrow: 1,
    height: appBarBottomHeight + appBarTopHeight,
  },

  searchToolbarInnerLocation: {
    display: 'flex',
    alignItems: 'center',
  },
  mapsLabel: {
    fontWeight: 'bold',
    fontSize: '8pt',
    color: theme.palette.common.font,
    marginRight: theme.spacing(2),
  },
  locationBoxLabel: {
    maxWidth: 75,
    fontWeight: 'bold',
    fontSize: '11pt',
    color: theme.palette.common.font,
    marginRight: theme.spacing(2),
    // ao: remove for mobile:
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mapsLink: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: '30px',
      marginTop: '4px',
    },
  },
  locationAutocomplete: {
    width: 350,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '45vw',
      marginRight: 0,
    },
    color: 'white',
  },
  globalSearchBox: {
    width: '100%',
    borderRadius: '5%',
    color: 'white',
  },
  searchBoxIcon: {
    color: theme.palette.common.characters,
  },
  locationSearchBox: {
    width: '100%',
    color: theme.palette.common.font,
    borderRadius: '5%',
    backgroundColor: theme.palette.common.white,
  },
  list: {
    width: theme.screen.drawer.width,
  },
}));

function AppBarOffset() {
  const classes = useStyles();
  return <div className={classes.offset} />;
}

function NavBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [locations, setLocations] = useState([]);
  const [{ location }, dispatch] = useStateValue();
  // eslint-disable-next-line
  const [locationChanged, setLocationChanged] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const history = useHistory();
  const role = cookies.getRole();
  // const locations = POPULAR_LOCATIONS;

  const mobile = useMediaQuery('(max-width:767px)');
  // const tablet = useMediaQuery('(max-width:1024px)');

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setAnchorEl(null);
    // setLocationEl(null)
  };
  const toggleDrawer = (side, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerState({ ...drawerState, [side]: open });
  };
  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button key="home" component={Link} to={'/'} style={{ color: 'inherit', textDecoration: 'inherit' }}>
          <ListItemText>Home</ListItemText>
        </ListItem>
        {NAV_OPTIONS.map((option) => (
          <ListItem
            button
            key={option.name}
            component={Link}
            to={navbarTabLink(option.path, location.province, location.city)}
            style={{ color: 'inherit', textDecoration: 'inherit' }}
          >
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const getLocationId = async (province, city) => {
    try {
      return api.getLocationBySearch(province, city).then((res) => {
        return res.data._embedded.locations[0]._links.self.href;
      });
    } catch (error) {
      throw error;
    }
  };

  const getLocations = async () => {
    return api
      .getLocations()
      .then((res) =>
        res._embedded.locations.sort((first, second) => {
          const allLocations = res._embedded.locations;
          setLocations(allLocations);
          if (first.province.localeCompare(second.province) === 0) {
            return first.city.localeCompare(second.city);
          } else return first.province.localeCompare(second.province);
        })
      )
      .catch((error) => {
        throw STATUS.ERROR;
      });
  };

  //======================================== Find Closest Location ========================================
  const { closestLocation, updateClostestLocation, loadingNearestLoc } = useClientLocation({ locationList: locations });
  const determineClosestLocation = () => {
    updateClostestLocation(); //Updates clostestLocation
  };
  //===================== use effect ========================
  useEffect(() => {
    //When closest location is found, update location
    if (closestLocation) {
      setLocationChanged(true);
      dispatch({
        type: 'changeLocation',
        newLocation: {
          province: closestLocation.province,
          city: closestLocation.city,
          link: closestLocation.link,
        },
      });
      cookies.setLocation({
        province: closestLocation.province,
        city: closestLocation.city,
        link: closestLocation.link,
      });
    }
  }, [dispatch, closestLocation]);

  useEffect(() => {
    if (!location.link) {
      //On very first load gets location of London ON, from app context (assigned in app.js)
      // Since id is environment specific, we need to get the id from the server rather than hardcoding it
      // But if we are featching ALL locations anyway, we can just get the default from there then update the context...
      getLocationId(location.province, location.city)
        .then((res) => {
          setLocationChanged(true);
          dispatch({
            type: 'changeLocation',
            newLocation: {
              province: location.province,
              city: location.city,
              link: res,
            },
          });
          cookies.setLocation({
            province: location.province,
            city: location.city,
            link: res,
          });
        })
        .catch((error) => console.error(error));
    }
    //Get and set location list for selector
    getLocations()
      .then((res) => {
        setLocations(res);
      })
      .catch((error) => console.error(error));
  }, [location, dispatch]);

  useEffect(() => {
    //If the location is not set, and the url has a location, set the location.  Also handles changes in url
    if (!locationChanged && locations.length > 1) {
      const prov = capitalizeFirstOfEach(window.location.href.split('/')[4]?.toLowerCase().replace('-', ' '));
      const city = capitalizeFirstOfEach(window.location.href.split('/')[5]?.toLowerCase().replace('-', ' '));

      const found = locations.find((loc) => loc.province === prov && loc.city === city);

      if (found) {
        setLocationChanged(true);
        const loc = {
          province: found.province,
          city: found.city,
          link: parseIdFromLink(found._links.location.href, 0, '{?projection}'),
        };
        dispatch({
          type: 'changeLocation',
          newLocation: loc,
        });

        cookies.setLocation(loc);
      }
    }
  }, [locations, locationChanged, dispatch]);

  //================================================================Rendering

  const classes = useStyles();
  return (
    <header className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        {/**Top Nav Bar */}
        <div className={classes.appBarTop}>
          <div className={classes.appBarTopInner}>
            <div className={classes.itemDealsButton}>
              {
                window.location.href.includes('admin') ? (
                  /* [] TODO - Make a proper bread crumb here */
                  <Link href="/admin" onClick={(e) => e.preventDefault()} className={classes.adminLink}>
                    ADMIN PORTAL
                  </Link>
                ) : (
                  <Link className={classes.logoLink} to="/">
                    <img
                      src={require(`../assets/logos/Beautimap-logo.svg`)}
                      alt={`beautimap-logo`}
                      style={{ height: '50px' }}
                      className={classes.appLogo}
                      onClick={() => history.push('/')}
                    />
                  </Link>
                )
                /*  <Button
                  variant="contained"
                  // color="warning"
                  textDecoration="inherit"
                  key={'Hot Deals'}
                  // href={navbarTabLink("deals", location.province, location.city)}
                  onClick={() =>
                    window.location.assign(`/deals/${location.province.toLowerCase()}/${location.city.toLowerCase()}`)
                  }
                  // href="/admin"
                  className={classes.dealsButton}
                >
                  {!mobile && 'Check Our'} Hot Deals
                </Button> */
              }
            </div>

            <div className={classes.locationItem}>
              {/* <div className={classes.item}>
                <img className={classes.logo} src={MapIcon} alt="map"></img>
                <Typography className={ classes.bold} variant='body1'>Your location, </Typography>
                <Typography className={ classes.bold} variant='body1' color='error'>{locationData && locationData.city}</Typography>
                <ExpandMoreOutlinedIcon onClick={e => openLocationMenu(e)} className={classes.expandIcon} /> 
              </div> */}
              <div className={classes.searchToolbarInnerLocation}>
                <div className={classes.mapsLink}>
                  <Link
                    to="/maps"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none' }}
                  >
                    <img className={classes.logo} src={MapIcon} alt="map"></img>

                    <Typography className={classes.mapsLabel}>Maps</Typography>
                  </Link>
                </div>
                <Typography className={classes.locationBoxLabel}>Browsing within:</Typography>
                <Autocomplete
                  noOptionsText="At this time we are working to bring on partnerships with your local retailers to service this area."
                  disableClearable
                  disabled={locations.length === 0 || loadingNearestLoc}
                  options={locations}
                  getOptionLabel={(option) => `${option.city}, ${option.province}`}
                  className={classes.locationAutocomplete}
                  value={location}
                  onChange={(event, newValue) => {
                    const province = newValue.province;
                    const city = newValue.city;
                    getLocationId(province, city)
                      .then((res) => {
                        setLocationChanged(true);
                        // navBarSelectRedirect(
                        //   window.location.href,
                        //   province,
                        //   city,
                        //   res,
                        //   cookies,
                        //   dispatch
                        // );

                        dispatch({
                          type: 'changeLocation',
                          newLocation: {
                            province,
                            city,
                            link: res,
                          },
                        });
                        cookies.setLocation({
                          province,
                          city,
                          link: res,
                        });
                      })
                      .catch((error) => console.error(error));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnIcon className={classes.searchBoxIcon} />
                          </InputAdornment>
                        ),
                      }}
                      margin="dense"
                      variant="outlined"
                      className={classes.locationSearchBox}
                    />
                  )}
                />

                <Button
                  onClick={determineClosestLocation}
                  variant="contained"
                  color="primary"
                  disabled={loadingNearestLoc}
                  style={{
                    width: '120px',
                    color: 'black',
                    padding: '4px',
                    margin: '6px 0px',
                    fontSize: '0.75rem',
                    height: '35px',
                  }}
                >
                  {loadingNearestLoc ? <CircularProgress color="secondary" size="25px" /> : 'Nearest to me'}
                </Button>
              </div>

              {!window.location.href.includes('admin') && <div className={classes.spacer}>|</div>}

              {window.location.href.includes('admin') && (
                <>
                  <LogNotification />
                  <div className={classes.spacer}>|</div>
                </>
              )}
              <div className={classes.item} onClick={(e) => openProfileMenu(e)}>
                <IconButton className={classes.logo} style={{ color: 'white' }}>
                  {/* <Badge badgeContent={4} color='error' overlap="circle">
                  </Badge> */}
                  <PersonOutlineIcon className={classes.profileIcon} fontSize="large" />
                </IconButton>
                <Typography className={classes.bold} variant="body1">
                  {role ? `My Account` : 'Login / SignUp'}
                </Typography>
                {!mobile && <ExpandMoreOutlinedIcon />}
              </div>
            </div>
          </div>
        </div>
        {/**End Top Nav Bar */}
        {/**Bottom Nav Bar */}
        <Toolbar className={classes.appBarBottom}>
          <div className={classes.appBarBottomInner}>
            <IconButton
              edge="start"
              className={classes.drawerButton}
              color="inherit"
              aria-label="drawer"
              onClick={toggleDrawer('left', true)}
            >
              <MenuIcon />
            </IconButton>
            <LinkBar />
            {/*   <Link className={classes.logoLink} to="/">
              <img
                src={require(`../assets/logos/Beautimap-logo.svg`)}
                alt={`beautimap-logo`}
                style={{ height: '50px' }}
                className={classes.appLogo}
                onClick={() => history.push('/')}
              />
            </Link> */}
            {/* {<SearchInput />} */}
          </div>
        </Toolbar>
        {/** End Bottom Nav Bar*/}
      </AppBar>
      <AppBarOffset />
      <SwipeableDrawer
        open={drawerState.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {sideList('left')}
      </SwipeableDrawer>

      {/* {locationEl && <LocationPopover anchorEl={anchorEl} closeProfileMenu={closeProfileMenu} />} */}
      {!role && <LoginPopover anchorEl={anchorEl} closeProfileMenu={closeProfileMenu} />}
      {role && <ProfilePopover anchorEl={anchorEl} closeProfileMenu={closeProfileMenu} />}
    </header>
  );
}

export default NavBar;
