import React, { useState } from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  selectorContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  starContainer: {
    display: 'flex',
    color: theme.palette.common.characters,
    height: '48px',
    width: '48px',
    position: 'relative',
  },
  halfSelector: {
    zIndex: 1,
    flexGrow: 0.5,
  },
  fullSelector: {
    zIndex: 1,
    flexGrow: 0.5,
  },
  starIcon: {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
  },
}));

export default function ReviewStarSelector({ rating, setRating, disabled }) {
  const classes = useStyles();
  const [hoverRating, setHoverRating] = useState(rating); // 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5
  const handleStarClick = () => {
    if (disabled) return;
    //Locks in rating when star is clicked
    setRating(hoverRating); //Assign rating that is being hovered on to rating state for saving
  };

  const handleHover = (ratingHoveredOn) => {
    if (disabled) return;
    setHoverRating(ratingHoveredOn);
  };

  return (
    <div className={classes.selectorContainer}>
      {[1, 2, 3, 4, 5].map((star) => {
        let starComponentToDisplay = <StarBorderIcon className={classes.starIcon} />;
        if (star <= hoverRating) {
          starComponentToDisplay = <StarIcon className={classes.starIcon} />;
        } else if (star - 0.5 === hoverRating) {
          starComponentToDisplay = <StarHalfIcon className={classes.starIcon} />;
        }
        return (
          <span
            key={star}
            className={classes.starContainer}
            onClick={() => handleStarClick()}
            // onMouseEnter={() => handleStarClick(star)}
            onMouseLeave={() => setHoverRating(rating)}
          >
            <div className={classes.halfSelector} onMouseEnter={() => handleHover(star - 0.5)} />
            <div className={classes.fullSelector} onMouseEnter={() => handleHover(star)} />
            {starComponentToDisplay}
          </span>
        );
      })}
    </div>
  );
}
