/* 
Section need s to be backwards compatible with old serviceItems (string from store object).
Latest version uses serviceItems array from /serviceItems endpoint. (will be sources from /stores/:id/serviceItems)
Priority is to use latest data. But if only the legacy data is available the legacy string will be displayed.
With latest data is should be an island and fetch its own data. 

TODO:
-[ ] fix wrap of UL and li, move to servieItem component
- [ ] clean up conditions to make it clear what is happening and there
- [ ] add a hook for data 
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import BookAction from './CallToAction/BookAction';
import cookies from '../../cookies';

import { makeStyles } from '@material-ui/core';
import useStoreServiceItems from './hooks/useServiceItems';
import { determineMobileServiceType } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  servicesContainer: {},
  headingText: {
    color: theme.palette.common.font,
    fontSize: '2rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      fontSize: '1.25rem',
      marginBottom: '0.5rem',
    },
  },
  serviceItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    [theme.breakpoints.down('sm')]: {
      gap: '8px',
      paddingLeft: 8,
    },
  },
  serviceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 16,
    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '15px',
    },
  },
}));

export default function ServiceList({ data, storeId }) {
  const classes = useStyles();
  const { serviceItems } = useStoreServiceItems(storeId);

  const legacyServiceItemsString = data?.serviceItems;
  const noDataToDisplay = serviceItems?.length === 0 && !legacyServiceItemsString;
  const displayLegacyText = serviceItems?.length === 0 && legacyServiceItemsString;
  const displayServiceItems = serviceItems?.length > 0;

  const isLoggedIn = cookies.getJWT() && cookies.getUserID(); //TODO - should use auth context to check if user is logged in.
  const hasStoreFront = data && determineMobileServiceType(data) !== 'mobileOnly';
  const allowBooking = data?.allowBooking && hasStoreFront;

  return (
    <section className={classes.servicesContainer}>
      <Typography variant="h2" className={classes.headingText}>
        Services & Pricing
      </Typography>

      {displayLegacyText && (
        /* Will eventually be phased out */
        <div id="simpleServiceItems" className={classes.servicesContainer}>
          <div
            style={{
              whiteSpace: 'pre-wrap',
              fontSize: '19',
            }}
          >
            {data?.serviceItems}
          </div>
        </div>
      )}

      {displayServiceItems && (
        <div id="serviceItemsContainer">
          <ul className={classes.serviceItems}>
            {serviceItems?.map((item) => (
              <li key={item.id} className={classes.serviceItem}>
                <div style={{ width: '100%', marginRight: 8 }}>
                  <span style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <Typography variant="h6">{`${item.name}`}</Typography>
                    <Typography
                      variant="p"
                      style={{ whiteSpace: 'nowrap' }}
                    >{`(${item.durationInMinutes} min)`}</Typography>
                  </span>
                  {item.description && <Typography variant="body1">{`${item.description}`}</Typography>}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <Typography variant="h6">{`$${item.price}`}</Typography>
                  {allowBooking && (
                    <BookAction serviceItem={item} storeInfo={{ ...data, id: storeId }} isLoggedIn={isLoggedIn} />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {noDataToDisplay && <Typography>No services listed</Typography>}
      {/* TODO: Add error state */}
    </section>
  );
}
