import React, { useState, Fragment } from "react";
import MapsStoreCard from "./MapsStoreCard"
import {  Typography } from "@material-ui/core";
import { Divider } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";


export default function MapsStoreList(props) {
  const { storeList, handleMarkerPosition, originalList,loading,location} = props;
  const [selected, setSelected] = useState(null)



  return (
  
    <Fragment>

  {  loading  && (
        <CircularProgress
          size={80}
          color="inherit"
          style={{ display: "flex", "marginLeft": "40%", "marginTop": "20%" }}
        />
  )}
  {!loading && (

  <div>
    <div>
    {
    originalList.length === 0 && 
    (
      <Typography variant="h6" style={{ margin: "5%", color: "rgb(74,74,74)" }}>
      {"Please check back soon. We are working hard to ensure you can see all the best stores near you!"}
    </Typography>
    )
    }
      </div>

    {storeList.length >= 0 && originalList.length > 0 &&(

      <div>
        <Typography variant="h6" style={{ margin: "5px", color: "rgb(74,74,74)", marginLeft: "35%" }}>
          {'Total Stores: ' 
          + `${storeList.length}`}
        </Typography>


        <Divider variant="middle" />

        {storeList.sort((store1, store2) => store1.rank - store2.rank)
        .sort((x, y) => !!y.rank - !!x.rank)
        .map((store, index) => {

          //console.log("store: ", store)
          return (

            <div
              key={index}
              onClick={(e) => {
                setSelected(store)
              }}
            >
              <MapsStoreCard
                key={index}
                selected={selected}
                location={location}
                store={store}
                handleMarkerPosition={handleMarkerPosition}
              />
            </div>

          );
        })}

      </div>
    )}
    </div>
)}
    </Fragment>
  );
}
