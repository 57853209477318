import axios from '../axios';
// import { setJWT, setUserID, removeJWTAndUserID } from "./cookies";
import { ROLES } from './constants';
import { parseIdFromLink } from './helpers';

const login = async (email, password, setJWT, setUserId, setError) => {
  try {
    return await axios
      .post('/authenticate', {
        username: email,
        password: password,
      })
      .then((res) => {
        // setJWT(res.data.token);
        // setUserID(res.data.userID);
        axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`;
        return res;
      });
  } catch (error) {
    throw error;
  }
};

const authenticate = async (email, password) => {
  try {
    return await axios
      .post('/authenticate', {
        username: email,
        password: password,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getStoresForAdmin = async () => {
  try {
    return await axios.get(`/stores?projection&size=1000`).then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getRoles = async () => {
  try {
    return await axios.get(`/roles`).then((res) => res.data._embedded.roles);
  } catch (error) {
    throw error;
  }
};

const createUser = async (data, createRole) => {
  try {
    return await getRoles()
      .then(async (res) => {
        const selectedRole =
          res.find((role) => role.name === createRole) && res.find((role) => role.name === createRole)._links.self.href;

        return axios
          .post('/users', { ...data, role: selectedRole })
          .then((res) => res)
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

const getLocationBySearch = async (province, city, published) => {
  try {
    return await axios
      .get(
        `/locations/search/multiParam?province=${province}${city ? `&city=${city}` : ``}${
          published ? `&hasPublishedStores=${published}` : ``
        }&size=1000`
      )
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createLocation = async (province, city) => {
  const body = {
    province: province,
    city: city,
  };

  try {
    return await axios.post(`/locations`, body).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const updateUser = async (id, data) => {
  try {
    return await axios.patch(`/users/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getUser = async (userID, headers) => {
  if (headers) {
    try {
      return await axios.get(`/users/${userID}`, { headers }).then((res) => res.data);
    } catch (error) {
      throw error;
    }
  } else {
    try {
      return await axios.get(`/users/${userID}`).then((res) => res.data);
    } catch (error) {
      throw error;
    }
  }
};

// simplified version to get all data
const getStores = async (type, locId, published) => {
  if (!isNaN(locId)) {
    locId = `${axios.defaults.baseURL}/locations/${locId}`;
  }

  const url = `/stores/search/multiParam?${type ? `category=${type}` : ``}${locId ? `&location=${locId}` : ``}${
    published ? `${locId || type ? `&` : ``}published=${published}` : ``
  }&size=1000`;

  try {
    return await axios.get(url).then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getStore = async (id, options) => {
  let endpoint = `/stores/${id}`;
  if (options?.projection) endpoint += `?projection=${options.projection}`;
  try {
    return await axios.get(endpoint).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createStore = async (data) => {
  data.owner = `${axios.defaults.baseURL}${data.owner}`;
  try {
    return await axios.post('/stores', data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateStore = async (id, data) => {
  try {
    return await axios.patch(`/stores/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const uploadImage = async (data) => {
  try {
    return await axios.post(`/files`, data).then((res) => res.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateImage = async (id, data) => {
  try {
    return await axios.patch(`/files/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteImage = async (id) => {
  try {
    return await axios.delete(`/files/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getStoreDetailedProjection = async (id) => {
  try {
    return await axios.get(`/stores/${id}?projection=detailed`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getStoreReviews = async (id) => {
  try {
    return await axios.get(`/stores/${id}/reviews`).then((res) => res.data._embedded.reviews);
  } catch (error) {
    throw error.response.data;
  }
};

const getEnhancedStoresForAdmin = async (page, size) => {
  const endpoint = '/stores';
  const projection = 'enhancedSummary';
  const sort = 'createDateTime,desc';
  try {
    return await axios
      .get(`${endpoint}?projection=${projection}&sort=${sort}&page=${page}&size=${size}`)
      .then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const deleteStore = async (id) => {
  try {
    return await axios.delete(`/stores/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const cloneStore = async (data) => {
  const base = `${axios.defaults.baseURL}/stores/copy?store=` + data;
  try {
    return await axios.post(base).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getStoreByName = async (name, size = 50) => {
  try {
    return await axios
      .get(`/stores/search/searchByName?projection=enhancedSummary&name=${name}&size=${size}`)
      .then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const sendConfirmation = async (email) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  const apiEnvironment = process.env.REACT_APP_ENV;
  try {
    await axios
      .post(`https://${apiEnvironment}.beautimap.com:8080/api/v1/users/requestConfirmEmail`, `"${email}"`, config)
      .then((res) => {});
  } catch (error) {
    throw error;
  }
};

const getUsersWithParams = async (page, size) => {
  try {
    return await axios.get(`/users?page=${page}&size=${size}&sort=createDateTime,desc`).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const promoteUser = async (id, type) => {
  try {
    return await getRoles()
      .then(async (res) => {
        const storeRole =
          res.find((role) => role.name === ROLES.ROLE_STORE_ADMIN) &&
          res.find((role) => role.name === ROLES.ROLE_STORE_ADMIN)._links.self.href;

        const brandRole =
          res.find((role) => role.name === ROLES.ROLE_BRAND_ADMIN) &&
          res.find((role) => role.name === ROLES.ROLE_BRAND_ADMIN)._links.self.href;

        const role = type === 'store' ? storeRole : type === 'brand' ? brandRole : '';

        const data = role ? { role: role } : {};

        return axios
          .patch(`/users/${id}`, data)
          .then((res) => res)
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (id) => {
  try {
    return await axios.delete(`/users/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getUserByUsername = async (username) => {
  try {
    return await axios.get(`/users/search/multiParam?username=${username}`).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const getLocations = async () => {
  try {
    return await axios.get(`/locations/search/findAllLocationsWithPublishedStore`).then((res) => res.data);
  } catch (error) {
    throw error.response.data;
  }
};

const confirmEmail = async (token) => {
  const config = { headers: { 'Content-Type': 'application/json' } };
  try {
    await axios.post('/users/confirmEmail', `"${token}"`, config).then((res) => {});
  } catch (error) {
    throw error;
  }
};

const getStoreRanks = async (id) => {
  //Deprecated - use getListByLocation instead
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createRanks = async (type, data, location, ranking) => {
  let url = '';

  switch (ranking) {
    case 'Featured':
      url = '/featuredRanks';
      break;

    case 'AllRanks':
      url = '/allRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    default:
      break;
  }
  // console.log("***Data createRanks: ", data);
  const posts = data.map((entity) =>
    axios.post(url, {
      rank: entity.rank,
      store: type ? entity.store._links.self.href : '',
      location: location,
      category: type ? type : '',
      deal:
        ranking === 'FeaturedDeal' || ranking === 'RecommendedDeal'
          ? entity.deal._links.self.href.replace('{?projection}', '')
          : '',
    })
  );

  try {
    return await Promise.all(posts);
  } catch (error) {
    throw error;
  }
};

const updateRanks = async (type, data) => {
  let url = '';

  switch (type) {
    case 'Featured':
      url = '/featuredRanks';
      break;

    case 'AllRanks':
      url = '/allRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    default:
      break;
  }

  const patches = data.map((rank) =>
    axios.patch(`${url}/${rank.id}`, {
      rank: rank.rank,
    })
  );

  try {
    return await Promise.all(patches);
  } catch (error) {
    throw error;
  }
};

const deleteRanks = async (type, ids) => {
  let url = '';
  switch (type) {
    case 'Featured':
      url = '/featuredRanks';
      break;

    case 'AllRanks':
      url = '/allRanks';
      break;

    case 'FeaturedDeal':
      url = '/featuredDealRanks';
      break;

    case 'RecommendedDeal':
      url = '/recommendedDealRanks';
      break;

    case 'DealOrdering':
      url = '/allDealRanks';
      break;

    default:
      break;
  }

  const deletes = ids.map((id) => axios.delete(`${url}/${id}`));

  try {
    return await Promise.all(deletes);
  } catch (error) {
    throw error;
  }
};

const getFeaturedRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}/featuredRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getLandingProjection = async (id) => {
  try {
    return await axios.get(`/locations/${id}?projection=landing`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createReview = async (data, storeId, userId) => {
  data = {
    ...data,
    store: `${axios.defaults.baseURL}/stores/${storeId}`,
    user: `${axios.defaults.baseURL}/users/${userId}`,
  };

  try {
    return await axios.post(`/reviews`, data).then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const updateReview = async (reviewId, data) => {
  try {
    return await axios.patch(`/reviews/${reviewId}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteReview = async (reviewId) => {
  try {
    return await axios.delete(`/reviews/${reviewId}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const sendReset = async (email) => {
  try {
    await axios
      .post('/users/requestResetEmail', {
        email: email,
      })
      .then((res) => {});
  } catch (error) {
    throw error;
  }
};

const changePassword = async (password, token) => {
  try {
    await axios
      .post('/users/changePassword', {
        password: password,
        token: token,
      })
      .then((res) => {});
  } catch (error) {
    throw error;
  }
};

const getStoresForUser = async (id) => {
  try {
    return await axios
      .get(`/users/${id}/stores?projection&size=1000&sort=createDateTime,desc`)
      .then((res) => res.data._embedded.stores);
  } catch (error) {
    throw error.response.data;
  }
};

const getAllStoresRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/allRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getFeaturedStoresRanks = async (id) => {
  try {
    return await axios.get(`/stores/${id}/featuredRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const deleteRank = async (path) => {
  try {
    return await axios.delete(path);
  } catch (error) {
    throw error.response.data;
  }
};

const getTopBanners = async () => {
  try {
    return await axios.get(`/topBanners`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createTopBanner = async (data) => {
  try {
    return await axios.post('/topBanners', data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const saveTopBanner = async (id, data) => {
  try {
    return await axios.patch(`/topBanners/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteTopBanner = async (id) => {
  try {
    return await axios.delete(`/topBanners/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getDeal = async (id) => {
  try {
    return await axios.get(`/deals/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createDeal = async (id, data) => {
  const body = {
    ...data,
    store: `${axios.defaults.baseURL}/stores/${id}`,
  };

  try {
    return await axios.post(`/deals`, body).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const updateDeal = async (id, data) => {
  try {
    return await axios.patch(`/deals/${id}`, data).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteDeal = async (id) => {
  try {
    return await axios.delete(`/deals/${id}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getDealsByLocation = async (id, published) => {
  //Ensure id is sent in the URI format
  if (!isNaN(id)) {
    id = `${axios.defaults.baseURL}/locations/${id}`;
  }

  try {
    return await axios
      .get(
        `/deals/search/multiParam?${id ? `location=${id}` : ``}${
          published ? `${id ? `&` : ``}published=${published}` : ``
        }&size=1000`
      )
      .then((res) => res.data._embedded.deals);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealRanks = async (id) => {
  try {
    return await axios.get(`/locations/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealRanksByLocation = async (id) => {
  try {
    return await axios.get(`/locations/${id}/featuredDealRanks`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealStore = async (id) => {
  try {
    return await axios.get(`/deals/${id}/store`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getDealsByLocationWithStore = async (id) => {
  // make a then chain here, maybe?
  return await getDealsByLocation(id, true).map(
    (deal) => (deal.store = getDealStore(parseIdFromLink(deal._links.store.href, -1, 'store{?projection}')))
  );
};

const getListByLocation = async (locID, endpoint, projection = null) => {
  //   if (!endpoint) throw new Error('Missing Endpoint');
  let fetchUrl = `/locations/${locID}`;
  if (endpoint) fetchUrl += `/${endpoint}`;
  if (projection) fetchUrl += `?projection=${projection}`;

  try {
    return await axios.get(fetchUrl).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const getServiceItemsByStore = async (storeId) => {
  if (!storeId) throw new Error('Missing Store ID');
  try {
    // return await axios.get(`/stores/${storeId}/serviceItems`).then((res) => res.data._embedded.serviceItems); // endpoint doesn't exist yet
    //Below is a work around by fetching ALL service items and filtering by store
    const allSvcItems = await axios.get(`/serviceItems`).then((res) => res.data._embedded.serviceItems);
    const allSvcItemHrefs = allSvcItems.map((item) => item._links.self.href);
    const allSvcItemsDetailed = await Promise.all(
      allSvcItemHrefs.map((href) => {
        return axios.get(href).then((res) => res.data);
      })
    );
    const svcItemsForCurrStore = allSvcItemsDetailed.filter((item) => {
      const svcItemsStoreId = item._embedded.store._links.self.href.split('/').pop().replace('{?projection}', '');
      // match storeId to the storeId of the service item
      return svcItemsStoreId === storeId;
    });
    console.log('%c API workaround for svc items by store', 'background: orange; color: black');

    return svcItemsForCurrStore;
  } catch (error) {
    const errorMsg = error?.response?.data?.message || `Error - ${error.status} - Could not fetch service items`;
    throw errorMsg;
  }
};

const createServiceItem = async (data) => {
  try {
    return await axios.post(`/serviceItems`, data).then((res) => res.data);
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};
const updateServiceItem = async (data) => {
  const serviceItemId = data.id;
  try {
    return await axios.patch(`/serviceItems/${serviceItemId}`, data).then((res) => res.data);
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

const deleteServiceItem = async (id) => {
  if (!id) throw new Error('Missing ID');
  try {
    return await axios.delete(`/serviceItems/${id}`).then((res) => res);
  } catch (error) {
    throw error.response ? error.response.data : error;
  }
};

const getMessagesByStore = async (storeID, msgTypeToFetch) => {
  if (msgTypeToFetch !== 'unread' && msgTypeToFetch !== 'read') throw new Error('Invalid message type');
  const MESSAGE_ENDPOINTS = {
    unread: 'adminUnreadMessages',
    read: 'adminReadMessages',
  };

  try {
    return await axios.get(`/stores/${storeID}/${MESSAGE_ENDPOINTS[msgTypeToFetch]}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const createAdminMessage = async (title, body, stores, brands) => {
  try {
    return await axios
      .post(`/adminMessages`, {
        title,
        body,
        unreadBrands: brands,
        unreadStores: stores,
      })
      .then((res) => res.data);
  } catch (error) {
    throw error;
  }
};

const deleteAdminMessage = async (id) => {
  try {
    return await axios.delete(`/adminMessages/${id}`).then((res) => res);
  } catch (error) {
    throw error.response.data;
  }
};

const markStoreMessageRead = async (messageId, storeIdLink) => {
  try {
    return await axios
      .delete(`/adminMessages/${messageId}/markStoresRead`, {
        data: storeIdLink,
        headers: { 'Content-Type': 'text/uri-list' },
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const createAppointment = async (appointmentData) => {
  try {
    return await axios.post(`/appointments/`, appointmentData).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getAppointmentsByStoreAndDate = async (storeIdLink, dateRange, options) => {
  //Projections:
  //default includes all fields,
  //datesOnly - only includes date fields
  let searchParameters = 'store=' + storeIdLink;
  if (dateRange) {
    searchParameters += `&beginDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  } else {
    // -[ ] TODO should make dynamic today + 1 year
    const WORKAROUND_DATE_RANGE = { startDate: '2024-01-01', endDate: '2026-03-03' };
    searchParameters += `&beginDate=${WORKAROUND_DATE_RANGE.startDate}&endDate=${WORKAROUND_DATE_RANGE.endDate}`;
  }
  if (options?.projection) searchParameters += `&projection=${options.projection}`;

  try {
    const appointments = await axios.get(
      `/appointments/search/findAppointmentsBetweenDates?${searchParameters}&size=500`
    );
    return appointments.data._embedded.appointments;
  } catch (error) {
    throw error;
  }
};

const updateAppointment = async (appointmentData) => {
  try {
    return await axios.patch(`/appointments/${appointmentData.id}`, appointmentData).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const deleteAppointment = async (appointmentId) => {
  try {
    return await axios.delete(`/appointments/${appointmentId}`).then((res) => res);
  } catch (error) {
    throw error;
  }
};

const api = {
  login,
  authenticate,
  getRoles,
  createUser,
  getLocationBySearch,
  createLocation,
  updateUser,
  getUser,
  getStoresForAdmin,
  getStores,
  getStore,
  createStore,
  updateStore,
  uploadImage,
  updateImage,
  deleteImage,
  getStoreDetailedProjection,
  getStoreReviews,
  getEnhancedStoresForAdmin,
  deleteStore,
  cloneStore,
  getStoreByName,
  sendConfirmation,
  getUsersWithParams,
  promoteUser,
  deleteUser,
  getUserByUsername,
  getLocations,
  confirmEmail,
  getStoreRanks,
  createRanks,
  updateRanks,
  deleteRanks,
  getFeaturedRanks,
  getLandingProjection,
  createReview,
  updateReview,
  deleteReview,
  sendReset,
  changePassword,
  getStoresForUser,
  getAllStoresRanks,
  getFeaturedStoresRanks,
  deleteRank,
  getTopBanners,
  createTopBanner,
  saveTopBanner,
  deleteTopBanner,
  getDeal,
  createDeal,
  updateDeal,
  deleteDeal,
  getDealsByLocation,
  getDealRanks,
  getDealStore,
  getDealsByLocationWithStore,
  getListByLocation,
  getDealRanksByLocation,
  getServiceItemsByStore,
  createServiceItem,
  updateServiceItem,
  deleteServiceItem,
  getMessagesByStore,
  createAdminMessage,
  deleteAdminMessage,
  markStoreMessageRead,
  createAppointment,
  getAppointmentsByStoreAndDate,
  updateAppointment,
  deleteAppointment,
};

export default api;
