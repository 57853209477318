/* 
TODO: 
- [] indicate curr time with bar
- [] Handle click on time
  - May need to subdivide bloces in to 30min (invisible)
- [] centralize css for blocks and times list
*/
import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import TimesOfDayList from './TimesOfDayList';
import DayTimeBlocks from './DayTimeBlocks';
import { makeStyles } from '@material-ui/core';
import { isSameDay } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  dayCalendarContainer: {
    maxHeight: '75vh',
    overflow: 'scroll',
  },
  daysHoursGrid: {
    display: 'flex',
  },
}));

const today = new Date();
const defaultSettings = {
  timeFormat: '24hr', //Default is 24hr, needs explict "12hr" to change
  daysToShow: 1,
  defaultAppointmentLength: 30,
};

export default function DayCalendar({
  selectedDay = today,
  appointments,
  daysToShow = defaultSettings.daysToShow,
  onApptClick,
  clientMode,
}) {
  const classes = useStyles();
  const containerRef = useRef(null);
  const BLOCK_HEIGHT = clientMode ? 72 : 48;

  useEffect(() => {
    // Sets the calendar scroll to approx 6am on load by setting the scrollTop of the container.
    if (containerRef.current) {
      const { scrollHeight } = containerRef.current;
      containerRef.current.scrollTop = scrollHeight * (clientMode ? 0.35 : 0.25); // Scroll to 25% of the container's scroll height
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /* Need to adjust daysToShow
  Need to fix options in order to control the display
  Options 1 day, 7 day
  1 day is selected date
  7 day is the week of the selected date (Sun - Sat)
  */
  const dayTitles = [];
  const dayItineray = [];
  const firstDayOfWeek = new Date(selectedDay);
  firstDayOfWeek.setDate(firstDayOfWeek.getDate() - firstDayOfWeek.getDay()); // Set to Sunday of the week
  for (let i = 0; i < daysToShow; i++) {
    const day = daysToShow === 1 ? selectedDay : new Date(firstDayOfWeek);
    day.setDate(day.getDate() + i);
    dayTitles.push(
      <h2 style={{ flexGrow: 1, textAlign: 'center' }}>
        {format(day, 'EEE')} - {format(day, 'do')}
      </h2>
    );
    dayItineray.push(
      <DayTimeBlocks
        appointments={appointments.filter((appointment) => {
          const apptDay = new Date(appointment.startDateTime);
          return isSameDay(apptDay, day);
        })}
        onApptClick={onApptClick}
        day={day}
        clientMode={clientMode}
        BLOCK_HEIGHT={BLOCK_HEIGHT}
      />
    );
  }

  return (
    <article className={classes.dayCalendarContainer} ref={containerRef}>
      <header style={{ display: 'flex', height: 'fit-content', fontSize: daysToShow > 1 ? '0.5rem' : '' }}>
        <div id="header-spacer" style={{ height: '100%', width: '48px' }} />
        <div id="day-titles" style={{ display: 'flex', width: '100%' }}>
          {dayTitles}
        </div>
      </header>
      <div className={classes.daysHoursGrid}>
        <TimesOfDayList timeFormat={defaultSettings.timeFormat} BLOCK_HEIGHT={BLOCK_HEIGHT} />
        <section className="day-area" style={{ display: 'flex', flexGrow: 1 }}>
          {dayItineray}
        </section>
      </div>
    </article>
  );
}
