/* Originally based off of SDAW filw uploader but stripped down to be single file only
[]ToDo there is likely a fair amount of unused / unneeded code still */

import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '95%',
    },
  },
  cancelContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '5%',
    },
    width: '0%',
  },
  thumb: {
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 35,
    marginBottom: 35,
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
    width: '100%',
    height: '100%',
    maxHeight: 400,
    maxWidth: 400,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    height: '100%',
    maxHeight: 400,
    maxWidth: 400,
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 150,
      width: 150,
    },
  },
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'dashed 3px #D1D1D1',
    borderRadius: 10,
    margin: theme.spacing(2),
  },
  wrapperError: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `dashed 3px ${theme.palette.error.main}`,
    borderRadius: 10,
    margin: theme.spacing(2),
  },
  dropzoneText: {
    margin: theme.spacing(6),
    color: '#3b3b3b',
    width: '60%',
  },
  dropzoneTextError: {
    margin: theme.spacing(6),
    color: '#5b5b5b',
    width: '60%',
  },
  dropzoneRejectedError: {
    margin: theme.spacing(6),
    color: theme.palette.error.main,
    width: '60%',
  },
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelIcon: {
    position: 'absolute',
    top: -24,
    right: -22,
    opacity: 1,
    color: theme.palette.error.main,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cancelIconMobile: {
    opacity: 1,
    color: theme.palette.error.main,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(-1, 0, 0, -1.5),
    height: 201,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  number: {
    position: 'absolute',
    top: -13,
    left: -20,
    backgroundColor: '#ebebeb',
    padding: theme.spacing(0, 1.5, 0, 1.5),
    borderRadius: '10px',
    color: theme.palette.common.black,
  },
  error: {
    color: theme.palette.error.main,
  },

  dimensions: {
    color: theme.palette.error.main,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'larger',
  },

  placeholderText: { margin: theme.spacing(1, 0, 1, 2) },
  placeholderButton: {
    margin: theme.spacing(1, 0, 1, 2),
  },
}));

function BannerUploader(props) {
  const { files, setFiles, error, multi, disabled } = props;
  const classes = useStyles();

  const [fileError, setFileError] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 10485760,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError(false);
      if (multi) {
        setFiles(
          [
            ...files,
            ...acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ].slice(0, 5)
        );
      } else {
        if (fileRejections.length > 0) {
          setFileError(true);
        } else {
          const myFile = acceptedFiles[0];

          Object.assign(myFile, {
            url: URL.createObjectURL(myFile),
          });
          setFiles(myFile);
        }
      }
    },
  });
  const removePreview = (i) => {
    setFiles([]);
  };
  const thumbs = files.preview && (
    <div>
      <div className={classes.thumb}>
        <div className={classes.thumbInner}>
          <img src={files.preview} className={classes.img} alt="Upload preview" />
        </div>
        <IconButton
          disabled={disabled}
          color="secondary"
          aria-label="remove-image-1"
          className={classes.cancelIcon}
          onClick={removePreview}
        >
          <CancelIcon />
        </IconButton>
      </div>
    </div>
  );

  return (
    <Paper
      elevation={2}
      className={classes.paper}
      onDragCapture={() => {
        $('#nav-bar-client-admin').hide();
      }}
      onDropCapture={() => {
        $('#nav-bar-client-admin').show();
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
    >
      <section className="container">
        <Typography variant="caption" align="center" className={classes.dimensions}>
          *** Please upload a banner atleast 1200 pixels wide and 500 pixels high for good quality ***
        </Typography>

        {!disabled && (
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={error || fileError ? classes.wrapperError : classes.wrapper}
          >
            <input {...getInputProps()} />
            <Typography
              variant="body2"
              align="center"
              className={error || fileError ? classes.dropzoneRejectedError : classes.dropzoneText}
            >
              Drag 'n' drop your image here, or click to select a file. Max image size is 10 MB.
            </Typography>
          </div>
        )}

        {disabled && (
          <div className={error ? classes.wrapperError : classes.wrapper}>
            <input {...getInputProps()} />
            <Typography variant="body2" align="center" className={classes.dropzoneTextError}>
              {`When using a brand's product, the images are selected by the brand`}
            </Typography>
          </div>
        )}
        <div className={classes.previewWrapper}>
          {files.length > 0 && <Typography variant="h5">Preview</Typography>}
          {disabled && (
            <div className={classes.thumbsContainer} style={{ height: '100%', width: '100%' }}>
              {thumbs}
            </div>
          )}
        </div>
      </section>
    </Paper>
  );
}

export default BannerUploader;
