import React from 'react';
import { DAYS_OF_THE_WEEK } from '../../../utils/constants';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  hoursContainer: {
    border: `12px solid ${theme.palette.common.background}`,
    borderRadius: '15px',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.common.font,
    '& > *': {
      margin: theme.spacing(0.5, 0),
    },
    [theme.breakpoints.down('sm')]: {
      '& > *': {
        margin: theme.spacing(0.25, 0),
      },
    },
  },
  hoursTitle: {
    fontSize: '2rem',
    color: theme.palette.common.font,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
  },
  hoursItem: {
    fontSize: '1.5rem',
    color: theme.palette.common.font,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  highlightDay: {
    color: theme.palette.primary.characters,
    textDecoration: 'underline',
    fontWeight: 'bold',
  },
}));
/* Example storeHours 
storeHours: {
    "mondayOpen": "07:00:00",
    "mondayClose": "13:00:00",
    "tuesdayOpen": "07:00:00",
    ...
  },
*/

export default function BusinessHours({ storeHours }) {
  const classes = useStyles();
  return (
    <div className={classes.hoursContainer}>
      <Typography variant="h2" className={classes.hoursTitle}>
        Hours
      </Typography>
      {DAYS_OF_THE_WEEK.map((day, index) => {
        const openTimeForDay = storeHours?.[`${day.toLowerCase()}Open`];
        const closeTimeForDay = storeHours?.[`${day.toLowerCase()}Close`];
        const formatTimeToDisplay = (time) => {
          /* Format Hours from 07:30:00  to 7:30am 
          or truncate if possible ie 13:00:00 to 1pm
          */
          const [hours, minutes] = time.split(':');
          const isPM = hours > 12;
          const formattedHours = isPM ? hours - 12 : hours.replace(/^0+/, ''); // Remove leading 0

          const formattedMinutes = minutes === '00' ? '' : `:${minutes}`;
          const formattedTime = `${formattedHours}${formattedMinutes}${isPM ? 'pm' : 'am'}`;
          return formattedTime;
        };
        const displayHours =
          openTimeForDay && closeTimeForDay
            ? `${formatTimeToDisplay(openTimeForDay)} - ${formatTimeToDisplay(closeTimeForDay)}`
            : 'Closed';

        //Assign class for all days of the week
        //If current day of the week, assign class to highlight

        let classForItem = `${classes.hoursItem}`;
        const dayMatchesCurrentDay = new Date().toLocaleString('en-us', { weekday: 'long' }) === day;
        dayMatchesCurrentDay && (classForItem += ` ${classes.highlightDay}`);
        return <div key={index} className={classForItem}>{`${day}: ${displayHours}`}</div>;
      })}
    </div>
  );
}
