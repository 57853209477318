import { useState, useEffect } from 'react';
import api from '../../../utils/api';

export default function useStoreData(storeId) {
  const [storeData, setStoreData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    api
      .getStore(storeId)
      .then((response) => {
        setStoreData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [storeId]);

  return { storeData, isLoading, error };
}
