import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import videoFirstFrame from '../assets/banners/landingBanner/firstFrame-lg.webp';
import bannerVideoWebm from '../assets/banners/landingBanner/landing-orgAspect.webm';
import bannerVideoMp4 from '../assets/banners/landingBanner/landing-orgAspect.mp4';

const useStyles = makeStyles((theme) => ({
  landingHero: {
    position: 'relative',
    width: '100%',
    marginBottom: '24px',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-start',
    height: '66vh',
    [theme.breakpoints.down('md')]: {
      height: '40vh',
    },
  },
  landingVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textOverlay: {
    zIndex: 1,
    position: 'absolute',
    color: theme.palette.common.white,
    alignSelf: 'center',
    fontSize: '4.5rem',
    fontFamily: 'Meow Script, cursive',
    textAlign: 'center',
    width: '80%',
    top: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)', //Centers text; otherwise it starts at the center of the video
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem',
    },
  },
  videoMask: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.10)',
  },
}));

function LandingHero(props) {
  const classes = useStyles();
  return (
    <div id="landingHero" className={classes.landingHero}>
      {/* autoPlay is caseSensitive (capital P is required) 
      playsinline needed for safari autoplay with explicit "true" string
      */}
      <video className={classes.landingVideo} preload autoPlay loop muted playsinline="true" poster={videoFirstFrame}>
        <source src={bannerVideoMp4} type="video/mp4" />
        <source src={bannerVideoWebm} type="video/webm" />
      </video>
      <div className={classes.videoMask} />
      <Typography className={classes.textOverlay} variant="h2">
        Get Connected to the Beauty Around You.
      </Typography>
    </div>
  );
}

export default LandingHero;
