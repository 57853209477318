/* 
Image grid to display stores work / portfolio.
- Cfm image limit - believe it is 10
- Will it ever be more?  If so should have a view all button
- When click on images will open larger image in a modal
- Modal should have a close button, and allow for scrolling through images with arrows
*/

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  headingText: {
    color: theme.palette.common.font,
    fontSize: '2rem',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    // textDecoration: 'underline',
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 0),
      fontSize: '1.25rem',
      marginBottom: '0.5rem',
    },
  },
  galleryImage: {
    maxWidth: '300px',
    maxHeight: '300px',
    objectFit: 'cover',
    // backgroundPosition: 'center',
    // cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      Height: '100%',
    },
  },
  imageGrid: {
    // Grid to display images 4x4
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    //Center items
    placeItems: 'center',
    gap: '10px',
    marginBottom: '20px',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}));

export default function ImageGrid({ imageArray, handleClickOpen = null }) {
  const classes = useStyles();
  return (
    <section className="imageGrid">
      <Typography variant="h2" className={classes.headingText}>
        See Our Work
      </Typography>
      {!imageArray?.length && (
        <Typography className={classes.noImages}>No images to display, check back later</Typography>
      )}
      {/* Refactor display section into a grid gallery */}

      {imageArray && (
        <div className={classes.imageGrid}>
          {imageArray &&
            imageArray
              .sort((a, b) => {
                return a.rank - b.rank;
              })
              .map((item, index) => (
                <img
                  key={index}
                  className={classes.galleryImage}
                  // style={{ backgroundImage: `url(${item.url})` }}
                  // onClick={() => handleClickOpen(index)}
                  src={item.url}
                  alt={item.name}
                />
              ))}
        </div>
      )}
    </section>
  );
}
