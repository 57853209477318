import React, { useState, useEffect, Fragment } from 'react';
import {
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  //TextField,
  Modal,
  Backdrop,
  Fade,
  // useMediaQuery,
} from '@material-ui/core';
//import MUIDataTable from "mui-datatables";
import { CATEGORIES, PROVINCES, STATUS } from '../utils/constants';
import api from '../utils/api';
import { parseIdFromLink } from '../utils/helpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactSortable } from 'react-sortablejs';
import SortItem from '../components/SortItem';
//import ClearIcon from "@material-ui/icons/Clear";
import $ from 'jquery';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentInnerContainer: {
    width: '100%',
    maxWidth: theme.screen.maxWidth,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1, 0, 1, 0),
  },
  note: {
    width: '100%',
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  table: {
    zIndex: '50!important',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  removeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    fontWeight: '700',
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  addButton: {
    margin: theme.spacing(2),
  },
  actionButtons: {
    margin: theme.spacing(1),
    alignSelf: 'flex-end',
  },
  formButton: {
    margin: theme.spacing(1),
    width: 100,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  statusMessage: {
    margin: theme.spacing(2),
  },
  statusAcceptButton: {
    color: theme.palette.common.white,
    margin: theme.spacing(2),
  },
  sharedLists: {
    display: 'flex',
  },
  leftList: {
    margin: theme.spacing(1),
  },
  rightList: {
    margin: theme.spacing(1),
  },
  pinnedItems: {
    padding: theme.spacing(1),
    border: '1px solid #cbcbcb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    marginTop: theme.spacing(2),
  },
  unpinnedItems: {
    padding: theme.spacing(1),
    border: '1px solid #cbcbcb',
    borderRadius: '5px',
    backgroundColor: '#ebebeb',
    marginTop: theme.spacing(2),
  },
}));

const getLocationBySearch = async (province, city) => {
  return api
    .getLocationBySearch(province, city, true)
    .then((res) => res.data._embedded.locations.sort((first, second) => first.city.localeCompare(second.city)))
    .catch(() => {
      throw STATUS.ERROR;
    });
};

const getListsForLocation = async (id, type) => {
  try {
    //Will get all lists, when scaling can add endpoing by type of list wanted (like stores, featuredRanks)
    const res = await api.getListByLocation(id);
    const includesCategory = (store) => store.category.toLowerCase().includes(type.toLowerCase());
    const featuredRanks = res.data._embedded?.featuredRanks?.filter(includesCategory) ?? [];
    const allStoresInLocation = res.data._embedded?.stores?.filter(includesCategory) ?? [];
    const customAllStoreRanks = res.data._embedded?.allRanks?.filter(includesCategory) ?? [];

    return { allStoresInLocation, featuredRanks, customAllStoreRanks };
  } catch (error) {
    console.log(error);
    throw STATUS.ERROR;
  }
};

const createRanks = async (type, data, location, ranking) => {
  return api.createRanks(type, data, location, ranking).catch((error) => {
    throw error;
  });
};

const updateRanks = async (type, data) => {
  return api.updateRanks(type, data).catch((error) => {
    throw error;
  });
};

const deleteRanks = async (type, ids) => {
  return api.deleteRanks(type, ids).catch((error) => {
    throw error;
  });
};

const STATUS_OPTIONS = {
  INVALID: 'INVALID',
  FETCH_FAIL: 'FETCH_FAIL',
  SAVING: 'SAVING',
  SAVE_FAIL: 'SAVE_FAIL',
  SUCCESS: 'SUCCESS',
  CONFIRM_SAVE: 'CONFIRM_SAVE',
  NO_CHANGES: 'NO_CHANGES',
};

function ManageFeaturedStoresView(props) {
  const classes = useStyles();

  const [type, setType] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [data, setData] = useState([]);
  const [previous, setPrevious] = useState([]);

  const [locations, setLocations] = useState('');

  const [locationsReady, setLocationsReady] = useState(false);
  const [featuredReady, setFeaturedReady] = useState(false);
  const [storeListReady, setStoreListReady] = useState(false);
  const [unpinned, setUnpinned] = useState([]);

  //const [storeList, setStoreList] = useState([]);

  const [status, setStatus] = useState('');

  useEffect(() => {
    setLocationsReady(false);
    setFeaturedReady(false);
    if (state && !city) {
      getLocationBySearch(state)
        .then((res) => {
          setLocations(res);
          setLocationsReady(true);
        })
        .catch((error) => {
          setStatus(STATUS_OPTIONS.FETCH_FAIL);
        });
    }
  }, [state, city]);

  useEffect(() => {
    const getAndSetLists = async () => {
      try {
        const locationId = parseIdFromLink(locations.find((location) => location.city === city)._links.self.href);
        const lists = await getListsForLocation(locationId, type);
        const { allStoresInLocation, customAllStoreRanks } = lists;
        setUnpinned(
          allStoresInLocation
            .filter(
              (item) =>
                !customAllStoreRanks.some(
                  (pinned) =>
                    pinned.store._links.self.href.replace('{?projection}', '') ===
                    item._links.self.href.replace('{?projection}', '')
                )
            )
            .sort((first, second) => first.name.localeCompare(second.name))
        );
        setStoreListReady(true);
        setPrevious(customAllStoreRanks);
        setData(customAllStoreRanks.sort((x, y) => x.rank - y.rank));
        setFeaturedReady(true);
      } catch (error) {
        console.log(error);
        setStatus(STATUS_OPTIONS.FETCH_FAIL);
      }
    };
    setFeaturedReady(false);
    setStoreListReady(false);

    if (city) {
      setLocationsReady(true);
    }

    if (type) {
      getAndSetLists();
    }
  }, [state, city, type, locations]);

  //const mobile = useMediaQuery("(max-width:767px)");

  const submitChanges = () => {
    setStatus(STATUS_OPTIONS.SAVING);

    //Compare previous and data and:
    // 1. Find all removed ranks (where previous has the rank link but data does not)
    let removed = [];

    previous.forEach((prev) => {
      if (!data.find((curr) => curr._links && prev._links && curr._links.self.href === prev._links.self.href))
        removed.push(parseIdFromLink(prev._links.self.href, 0, '{?projection}'));
    });

    // 2. Find all changed ranks (where previous and data have the same store link but with different ranks)
    let changed = [];

    previous.forEach((prev) => {
      data.forEach((curr, index) => {
        if (
          curr._links &&
          prev._links &&
          curr._links.self.href === prev._links.self.href &&
          index !== Number(prev.rank)
        ) {
          curr.rank = index;
          changed.push({
            ...curr,
            id: parseIdFromLink(curr._links.self.href, 0, '{?projection}'),
          });
        }
      });
    });

    // 3. Find all added ranks (where previous does not have the store link but data does)
    let added = [];

    data.forEach((curr, index) => {
      if (!previous.find((prev) => prev._links && curr._links && prev._links.self.href === curr._links.self.href))
        added.push({ store: { ...curr }, rank: index });
    });
    // data.forEach((curr, index) => {
    //   if (!curr.store && !curr.delivery && !curr.deal) {
    //     switch (type) {
    //       case "Storefront":
    //         added.push({ store: { ...curr }, rank: index });
    //         break;

    //       case "Delivery":
    //         added.push({ delivery: { ...curr }, rank: index });
    //         break;

    //       case "Recommended":
    //         added.push({ store: { ...curr }, rank: index });
    //         break;

    //       default:
    //         break;
    //     }
    //   } else if (
    //     !previous.some(
    //       (rank) => rank._links.self.href === curr._links.self.href
    //     )
    //   ) {
    //     added.push({ ...curr, rank: index });
    //   }
    // });
    if (removed.length === 0 && changed.length === 0 && added.length === 0) {
      setStatus(STATUS_OPTIONS.NO_CHANGES);
    } else {
      //Simultaneously make all DELETE, PATCH and POST calls to the /featuredStoreRanks or /featuredDeliveryRanks endpoints
      const location = locations.find((location) => location.province === state && location.city === city)._links.self
        .href;
      //console.log("Changed", changed, "Added:", added, "Removed: ", removed)
      Promise.all([
        createRanks(type, added, location, 'AllRanks'),
        deleteRanks('AllRanks', removed),
        updateRanks('AllRanks', changed),
      ])
        .then(() => {
          setFeaturedReady(false);
          const locationId = parseIdFromLink(locations.find((location) => location.city === city)._links.self.href);
          //[] TODO - state should be leveraged to update without a new fetch
          getListsForLocation(locationId, type)
            .then((lists) => {
              const { customAllStoreRanks } = lists;
              setPrevious(customAllStoreRanks);
              setData(customAllStoreRanks.sort((x, y) => x.rank - y.rank));
              setFeaturedReady(true);
              setStatus(STATUS_OPTIONS.SUCCESS);
            })
            .catch((error) => setStatus(STATUS_OPTIONS.FETCH_FAIL));
        })
        .catch((error) => setStatus(STATUS_OPTIONS.SAVE_FAIL));
    }
  };

  return (
    <div
      className={classes.root}
      onDragCapture={() => {
        $('#nav-bar-client-admin').hide();
      }}
      onDropCapture={() => {
        $('#nav-bar-client-admin').show();
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
    >
      <div className={classes.content}>
        <div className={classes.contentInnerContainer}>
          <Typography variant="h4" className={classes.title}>
            Manage Business Listings
          </Typography>
          <FormControl variant="filled" className={classes.formControl}>
            <InputLabel>Province</InputLabel>
            <Select
              value={state}
              onChange={(event) => {
                setPrevious([]);
                setData([]);
                setUnpinned([]);
                setCity('');
                setType('');
                setState(event.target.value);
              }}
            >
              {PROVINCES.map((province) => (
                <MenuItem key={province} value={province}>
                  {province}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl} disabled={!locationsReady}>
            <InputLabel>City</InputLabel>
            <Select
              value={city}
              onChange={(event) => {
                setPrevious([]);
                setData([]);
                setUnpinned([]);
                setType('');
                setCity(event.target.value);
              }}
            >
              {locationsReady &&
                locations.map((location) => (
                  <MenuItem key={location.city} value={location.city}>
                    {location.city}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="filled" className={classes.formControl} disabled={!state || !city}>
            <InputLabel>Category</InputLabel>
            <Select
              value={type}
              onChange={(event) => {
                setPrevious([]);
                setData([]);
                setUnpinned([]);
                setType(event.target.value);
              }}
            >
              {/* <MenuItem value={"Storefront"}>Platinum Storefronts</MenuItem>
              <MenuItem value={"Delivery"}>Platinum Deliveries</MenuItem>
              <MenuItem value={"Recommended"}>Most Popular Businesses</MenuItem> */}
              {CATEGORIES.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <Typography className={classes.note} variant="caption">
            Note: Only cities that have existing stores are shown.
          </Typography>
          {type && state && city && featuredReady && storeListReady && (
            <div className={classes.tableWrapper}>
              <div className={classes.sharedLists}>
                <div className={classes.leftList}>
                  <Typography variant="body">
                    Items in this list will be pinned to the top of their "All" section in the order they appear.{' '}
                    <strong>Drag and drop</strong> to add, remove and change the order of items.
                  </Typography>
                  <div className={classes.pinnedItems}>
                    <ReactSortable
                      list={data}
                      setList={setData}
                      animation={150}
                      group="setting-pinned"
                      style={{
                        minHeight: '100px',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {data.map((item) => (
                        <SortItem
                          key={item._links.self.href}
                          item={item.store ? item.store : item.delivery ? item.delivery : item.deal ? item.deal : item}
                          type={type}
                          pinned
                        ></SortItem>
                      ))}
                    </ReactSortable>
                  </div>
                </div>
                <div className={classes.rightList}>
                  <Typography variant="body">
                    Items in this list will be ordered alphabetically in their "All" section after all the pinned items.
                    You can <strong>drag items </strong>
                    from this list to the pinned section.
                  </Typography>
                  <div className={classes.unpinnedItems}>
                    <ReactSortable
                      list={unpinned}
                      setList={setUnpinned}
                      animation={150}
                      group="setting-pinned"
                      style={{
                        height: '100%',
                        width: '100%',
                        minHeight: '100px',
                      }}
                    >
                      {unpinned.map((item) => (
                        <SortItem
                          key={item._links.self.href}
                          item={item.store ? item.store : item.delivery ? item.delivery : item.deal ? item.deal : item}
                          type={type}
                        ></SortItem>
                      ))}
                    </ReactSortable>
                  </div>
                </div>
              </div>

              <div className={classes.actionButtons}>
                <Button
                  className={classes.formButton}
                  variant="contained"
                  onClick={() => {
                    setData([]);
                    setPrevious([]);
                    setType('');
                    setState('');
                    setCity('');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.formButton}
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus(STATUS_OPTIONS.CONFIRM_SAVE)}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        className={classes.modal}
        open={status !== '' && status !== STATUS_OPTIONS.SAVING}
        onClose={() => {
          setStatus('');
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={status !== ''}>
          <div className={classes.modalPaper}>
            {status === STATUS_OPTIONS.SUCCESS && (
              <Fragment>
                <Typography className={classes.statusMessage}>Successfully saved!</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.SAVE_FAIL && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  There was an issue while saving your data. Please try again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.FETCH_FAIL && (
              <Fragment>
                <Typography className={classes.statusMessage}>There was an issue fetching data.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.INVALID && (
              <Fragment>
                <Typography className={classes.statusMessage}>
                  You have set two or more stores with the same rank or left a rank empty. Review your inputted ranks
                  and try again.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Ok
                </Button>
              </Fragment>
            )}
            {(status === STATUS_OPTIONS.CONFIRM_SAVE || status === STATUS_OPTIONS.SAVING) && (
              <Fragment>
                <Typography className={classes.statusMessage}>Are you sure you want to save your changes?</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitChanges()}
                  className={classes.statusAcceptButton}
                >
                  {status === STATUS_OPTIONS.SAVING && <CircularProgress size={24} color="inherit" />}
                  {status !== STATUS_OPTIONS.SAVING && 'Save Changes'}
                </Button>
              </Fragment>
            )}
            {status === STATUS_OPTIONS.NO_CHANGES && (
              <Fragment>
                <Typography className={classes.statusMessage}>There are no changes to save.</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setStatus('')}
                  className={classes.statusAcceptButton}
                >
                  Close
                </Button>
              </Fragment>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ManageFeaturedStoresView;
