/* Notification icon for admin portal header
uses context to get and display the number of unread logs
*/

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAdminLogsContext } from './context/AdminLogsContext';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
}));

export default function LogNotification() {
  const classes = useStyles();
  const { logs, refreshAdminLogs } = useAdminLogsContext({ mode: 'admin' });
  const unreadLogCount = logs.unread.length;
  // Run refreshAdminLogs on mount to get the latest unread count
  useEffect(() => {
    refreshAdminLogs();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Badge that links to /admin/logs, with tool top of test */}
      <Tooltip title="Logged Client Changes" aria-label="Logged Client Changes">
        <Link to="/admin/logs/">
          <Badge badgeContent={unreadLogCount} max={99} className={classes.badge} color="error">
            <InfoIcon style={{ color: '#473C38' }} />
          </Badge>
        </Link>
      </Tooltip>
    </>
  );
}
