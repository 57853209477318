import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import getCroppedImg from './cropImage';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
// import cropperStyle from './style';

const useStyles = makeStyles((theme) => ({
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginTop: 8,
    },
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      //Clean up this CSS, seems redundant
      flexDirection: 'row',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      // alignItems: 'stretch',
    },
  },
  sliderLabel: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));

const CroppingTool = ({ imageSrc, applyEdit, setFiles, undoCrop, redoCrop, disableUndoRedo }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const resetTool = () => {
    setZoom(1);
    setRotation(0);
    setCrop({ x: 0, y: 0 });
  };
  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      setFiles(croppedImage);
      resetTool();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, imageSrc, setFiles]);

  const classes = useStyles();
  return (
    <div>
      <div className={classes.cropContainer}>
        <Cropper
          image={imageSrc}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={1245 / 500}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          objectFit="contain" //'contain', 'horizontal-cover' or 'vertical-cover'
        />
      </div>
      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={0}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
        <div className={classes.sliderContainer}>
          <Typography variant="overline" classes={{ root: classes.sliderLabel }}>
            Rotation
          </Typography>
          <Slider
            value={rotation}
            min={0}
            max={360}
            step={1}
            aria-labelledby="Rotation"
            classes={{ root: classes.slider }}
            onChange={(e, rotation) => setRotation(rotation)}
          />
        </div>
        <span>
          <Button
            disabled={disableUndoRedo.undo}
            onClick={undoCrop}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
          >
            <UndoIcon />
          </Button>
          <Button
            disabled={disableUndoRedo.redo}
            onClick={redoCrop}
            variant="contained"
            color="primary"
            classes={{ root: classes.cropButton }}
          >
            <RedoIcon />
          </Button>
        </span>
        <Button onClick={saveCroppedImage} variant="contained" color="primary" classes={{ root: classes.cropButton }}>
          Crop Image
        </Button>
      </div>
      {/* <ImgDialog img={croppedImage} onClose={onClose} /> */}
    </div>
  );
};

export default CroppingTool;
